export default theme => ({
  root: {
    padding: theme.spacing(0)
  },
  content: {
    marginTop: theme.spacing(9)
  },
  titleHolder: {
    height: 60,
    margin: 'auto',
    position: 'absolute',
    top: '30%',
    right: 0,
    left: 0,
    width: '75%',
    opacity: '0.9',
    zIndex: 2,
    backgroundColor: `rgba(255,  255,255, .1)`,
    '@media (max-width: 600px)': {
      width: '100%',
      height: 70,
      margin: '0 30px'
    }
  },
  titleBar: {
    height: '100%',
    background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' + 'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
  },
  title: {
    fontSize: '3rem !important',
    textAlign: 'center',
    fontWeight: 200,
    lineHeight: '3rem',
    overflow: 'visible',
    whiteSpace: 'normal',
    letterSpacing: '.2rem',
    textTransform: 'uppercase',
    fontFamily: 'Montserrat, sans-serif !important'
  },
  titleWrap: {
    height: 100
  },
  subtitle: {
    height: 0
  },
  progressWrapper: {
    width: '100%',
    textAlign: 'center'
  },
  productTitle: {
    letterSpacing: '.2rem',
    textTransform: 'uppercase',
    fontFamily: 'Montserrat, sans-serif !important'
  },
  productTitleWrap: {},
  productSubtitle: {
    letterSpacing: '.2rem',
    textTransform: 'uppercase',
    fontFamily: 'Montserrat, sans-serif !important'
  },
  headerImgContainer: {
    width: '100%',
    position: 'relative',
    '& div': {
      background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' + 'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
    },
    textAlign: 'center'
  },

  headerImg: {
    width: '100%'
  },

  section: {
    padding: '40px 40px',
    maxWidth: '1200px',
    margin: 'auto',
    '@media (max-width: 769px)': {
      padding: '10px 60px'
    },
    '@media (max-width: 569px)': {
      padding: '10px 20px'
    },
    zIndex: 3
  },
  loadMore: {
    marginTop: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },

  card: {
    padding: '30px 4% 30px',
    marginTop: '-300px',
    background: 'white',
    borderRadius: '2px',
    position: 'relative',
    zIndex: 3
  },

  h3: {
    marginTop: '30px',
    marginBottom: '15px',
    marginLeft: '0px',
    marginRight: '0px',
    fontSize: '28px',
    fontWeight: '700',
    '@media (maxwidth: 569px)': {
      fontSize: '18px'
    },
    letterSpacing: '.2rem',
    textTransform: 'uppercase',
    fontFamily: 'Montserrat, sans-serif !important'
  },

  h2: {
    marginTop: '30px',
    marginBottom: '15px',
    marginLeft: '0px',
    marginRight: '0px',
    fontSize: '38px',
    fontWeight: '700',
    '@media (maxwidth: 569px)': {
      fontSize: '28px'
    },
    letterSpacing: '.2rem',
    textTransform: 'uppercase',
    fontFamily: 'Montserrat, sans-serif !important'
  },

  h1: {
    margin: 'auto',
    fontSize: '48px',
    fontWeight: '200',
    '@media (maxwidth: 569px)': {
      fontSize: '38px'
    },
    letterSpacing: '.2rem',
    textTransform: 'uppercase',
    fontFamily: 'Montserrat, sans-serif !important',
    position: 'absolute',
    top: '30%',
    width: '100%',
    opacity: '0.9',
    color: 'white',
    zIndex: 2
  },

  p: {
    marginTop: '5px',
    marginBottom: '5px',
    marginLeft: '0px',
    marginRight: '0px',
    fontSize: '18px',
    '@media (maxwidth: 569px)': {
      fontsize: '14px'
    },
    letterSpacing: '0.3px'
  },

  overlay: {
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%'
  }
});
