import React, { useEffect, useState } from 'react';
import { Grid, Tab, Tabs, Typography, Box, Paper, withStyles, useMediaQuery } from '@material-ui/core';
// import { PersonOutlined, Assignment, Favorite, AttachMoney, Settings as Gear } from '@material-ui/icons';

//Screens:
import PublicProfile from './screens/PublicProfile';
import LovedScreen from './screens/Loved';
import OrderScreen from './screens/Orders';
import CommissionScreen from './screens/Commissions';
import SettingScreen from './screens/Settings';

import styles from './styles';
import { useStore } from 'easy-peasy';

const TabPanel = ({ value }) => {
  return (
    <>
      {value === 0 && <PublicProfile />}
      {value === 1 && <LovedScreen />}
      {value === 2 && <OrderScreen />}
      {value === 3 && <CommissionScreen />}
      {value === 4 && <SettingScreen />}
    </>
  );
};

const ProjectProPortal = ({ classes, history }) => {
  const [tabValue, setTabValue] = useState(0);
  const user = useStore(state => state.user.user);
  const mobile = useMediaQuery(theme => theme.breakpoints.down('xs'));

  useEffect(() => {
    if (user && user.id && !user.designer) {
      history.push('/');
    } else if (user && user.designer && !user.designer.pro) {
      history.push('/designer-portal');
    }
  }, [user, history]);

  const onTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className={classes.root}>
      {user && user.designer ? (
        <Paper className={classes.container}>
          <Grid item xs={12}>
            <Typography component={'div'}>
              <Box color={'text.secondary'} fontSize={24}>
                Hi, {user.designer.name}
              </Box>
            </Typography>
          </Grid>
          {/*<Grid item xs={12}>*/}
          {/*  <Tabs variant={mobile ? 'scrollable' : 'fullWidth'} scrollButtons="auto" classes={{ indicator: classes.tabIndicator }} value={tabValue} onChange={onTabChange} centered>*/}
          {/*    <Tab icon={<PersonOutlined />} label={'Public Profile'} />*/}
          {/*    <Tab icon={<Favorite />} label={'Loved'} />*/}
          {/*    <Tab icon={<Assignment />} label={'Orders'} />*/}
          {/*    <Tab icon={<AttachMoney />} label={'Commissions'} />*/}
          {/*    <Tab icon={<Gear />} label={'Settings'} />*/}
          {/*  </Tabs>*/}
          {/*</Grid>*/}
          <Grid item xs={12} />
          <Grid item xs={12}>
            <TabPanel value={tabValue} />
          </Grid>
        </Paper>
      ) : (
        <Typography>Loading...</Typography>
      )}
    </div>
  );
};

export default withStyles(styles)(ProjectProPortal);
