import { Button, FormControl, Grid, TextField, InputLabel, Typography } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';

const ContactForm = ({ onSubmit, user, classes }) => {
  const [state, setState] = useState(null);
  const companyRef = useRef();
  const titleRef = useRef();
  const line1Ref = useRef();
  const line2Ref = useRef();
  const cityRef = useRef();
  const stateRef = useRef();
  const zipCodeRef = useRef();
  const countryRef = useRef();
  const websiteRef = useRef();

  useEffect(() => {
    if (user.id) {
      let _state = user.designer.draft_profile ? { ...user.designer.draft_profile } : {};

      for (let key of Object.keys(_state)) {
        if (!_state[key]) {
          _state[key] = '';
        }
      }

      _state.email = user.designer.draft_profile && user.designer.draft_profile.email ? user.designer.draft_profile.email : user.designer.email;
      _state.phone = user.designer.draft_profile && user.designer.draft_profile.phone ? user.designer.draft_profile.phone : user.designer.phone;

      setState(_state);
    }
  }, [user]);

  const onSaveData = () => {
    const data = {
      company_name: companyRef.current.value,
      title: titleRef.current.value,
      address_line1: line1Ref.current.value,
      address_line2: line2Ref.current.value,
      city: cityRef.current.value,
      state: stateRef.current.value,
      zip_code: zipCodeRef.current.value,
      country: countryRef.current.value,
      website_url: websiteRef.current.value
    };

    onSubmit(data);
  };

  return state ? (
    <Grid container spacing={3}>
      <Grid item xs={12} className={classes.centered}>
        <Typography variant={'h2'} color={'textPrimary'} component={'h2'}>
          Contact Information
        </Typography>
        <Typography color={'textSecondary'}>If enabled, anyone looking to hire you will be able to access this information</Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.centered}>
        <FormControl fullWidth>
          <InputLabel shrink={true}>Company Name</InputLabel>
          <TextField id="company_name" margin="normal" variant="outlined" inputRef={companyRef} defaultValue={state.company_name} />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.centered}>
        <FormControl fullWidth>
          <InputLabel shrink={true}>Your Title</InputLabel>
          <TextField id="title" margin="normal" variant="outlined" defaultValue={state.title} inputRef={titleRef} />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.centered}>
        <FormControl fullWidth>
          <InputLabel shrink={true}>Street Address</InputLabel>
          <TextField id="address_line1" margin="normal" variant="outlined" defaultValue={state.address_line1} inputRef={line1Ref} />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.centered}>
        <FormControl fullWidth>
          <InputLabel shrink={true}>Street Address 2</InputLabel>
          <TextField id="address_line2" margin="normal" variant="outlined" defaultValue={state.address_line2} inputRef={line2Ref} />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.centered}>
        <FormControl fullWidth>
          <InputLabel shrink={true}>City</InputLabel>
          <TextField id="city" margin="normal" variant="outlined" defaultValue={state.city} inputRef={cityRef} />
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={3} className={classes.centered}>
        <FormControl fullWidth>
          <InputLabel shrink={true}>State</InputLabel>
          <TextField id="state" margin="normal" variant="outlined" defaultValue={state.state} inputRef={stateRef} />
        </FormControl>
      </Grid>
      <Grid item xs={6} sm={3} className={classes.centered}>
        <FormControl fullWidth>
          <InputLabel shrink={true}>Zip</InputLabel>
          <TextField id="zip_code" margin="normal" variant="outlined" defaultValue={state.zip_code} inputRef={zipCodeRef} />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.centered}>
        <FormControl fullWidth>
          <InputLabel shrink={true}>Country</InputLabel>
          <TextField id="country" margin="normal" variant="outlined" defaultValue={state.country} inputRef={countryRef} />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.centered}>
        <FormControl fullWidth>
          <InputLabel shrink={true}>Phone</InputLabel>
          <TextField id="phone" margin="normal" variant="outlined" disabled defaultValue={state.phone} />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.centered}>
        <FormControl fullWidth>
          <InputLabel shrink={true}>Email Address</InputLabel>
          <TextField id="email" margin="normal" variant="outlined" disabled defaultValue={state.email} />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.centered}>
        <FormControl fullWidth>
          <InputLabel shrink={true}>Your Website URL</InputLabel>
          <TextField id="website_url" margin="normal" variant="outlined" defaultValue={state.website_url} inputRef={websiteRef} />
        </FormControl>
      </Grid>
      <Grid item xs={12} className={classes.centered}>
        <Button variant={'contained'} color={'primary'} onClick={onSaveData}>
          Save Changes
        </Button>
      </Grid>
    </Grid>
  ) : (
    <Typography>Loading...</Typography>
  );
};

export default ContactForm;
