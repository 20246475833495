import { useContext, useEffect, useReducer } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { useApolloClient } from 'react-apollo-hooks';
import gql from 'graphql-tag';

import { onCreateComment } from '../graphql/subscriptions';
import UserContext from '../contexts/UserContext';

const queryComments = `query GetPost($id: ID!) {
  getPost(id: $id) {
    id
    comments(sortDirection:DESC) {
      items {
        id
        content
        avatar
        name
        createdAt
        likes
      }
      nextToken
    }
  }
}
`;

const initialState = [];

const reducer = (state, action) => {
  switch (action.type) {
    case 'get':
      return action.payload;
    case 'add':
      return [action.payload, ...state];
    default:
      return state;
  }
};

const useArticleComments = id => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { user } = useContext(UserContext);
  const client = useApolloClient();

  useEffect(() => {
    if (id) {
      client.query({ query: gql(queryComments), variables: { id: id } }).then(res => {
        dispatch({ type: 'get', payload: res.data.getPost.comments.items });
      });
    }
  }, [id]);

  useEffect(() => {
    if (id && user && user.id) {
      const subscription = API.graphql(graphqlOperation(onCreateComment, { owner: user.id })).subscribe(res => {
        if (res.value.data.onCreateComment.commentPostId === id) {
          dispatch({ type: 'add', payload: res.value.data.onCreateComment });
        }
      });

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [id, user]);

  return state;
};

export default useArticleComments;
