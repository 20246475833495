import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, IconButton, Button, Dialog } from '@material-ui/core';
import { SearchInput } from 'components';
import styles from './styles';
import ProgressBar from 'components/ProgressBar';
import { Refresh as RefreshIcon } from '@material-ui/icons';
import PostDetail from '../PostDetail/PostDetail';

const PostsToolbar = props => {
  const { classes, className, search, user, selectedPost, onClose } = props;
  const [loaded] = useState(null);
  const [openNewArticle, setOpenNewArticle] = useState(false);

  const refresh = () => {
    props.refetch();
  };

  const rootClassName = classNames(classes.root, className);

  return (
    <div className={rootClassName}>
      {loaded && (
        <div className={classes.row}>
          <ProgressBar progress={loaded} />
        </div>
      )}
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button color="primary" size="small" variant="outlined" onClick={() => setOpenNewArticle(true)}>
          Create New Article
        </Button>
      </div>
      <div className={classes.row}>
        <SearchInput className={classes.searchInput} placeholder="Search post" onChange={search} />
        <span className={classes.spacer} />
        <IconButton aria-label="Add" onClick={refresh}>
          <RefreshIcon color="primary" />
        </IconButton>
      </div>
      <Dialog fullScreen open={openNewArticle || !!selectedPost} onClose={() => setOpenNewArticle(false)}>
        <PostDetail
          user={user}
          post={selectedPost}
          onClose={() => {
            setOpenNewArticle(false);
            onClose();
          }}
        />
      </Dialog>
    </div>
  );
};

PostsToolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PostsToolbar);
