import React, { useState } from 'react';
import { Button, Grid, Typography, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, useMediaQuery, useTheme } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import uuid from 'uuid';

import UploadPhotoComponent from '../../../../../../../components/UploadPhoto';
import { Storage } from 'aws-amplify';
import ProgressBar from '../../../../../../../components/ProgressBar';

const ProfileImages = ({ onSubmit, user, classes, setSnackbar }) => {
  const [dialog, setDialog] = useState({ open: false });
  const [dropZone, setDropZone] = useState();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const openDialog = (title, content, type, action, loading = false) => {
    setDialog({ open: true, title, content, loading, type, action });
  };

  const closeDialog = () => {
    setDialog({ open: false });
  };

  const openSnackBar = message => {
    setSnackbar({ open: true, message: message });
  };

  const [loaded, setLoaded] = useState();

  const uploadVideo = file => {
    let ext = file.name.split('.').pop();
    let key = uuid() + '.' + ext;

    const videoUrl = user.designer.draft_profile.feature_video;

    if (videoUrl)
      Storage.remove(videoUrl, {
        bucket: 'hdtv-prod',
        level: 'public'
      })
        .then(res => null)
        .catch(err => null);

    return Storage.put('videos/' + key, file, {
      bucket: 'hdtv-prod',
      level: 'public',
      progressCallback(progress) {
        setLoaded(progress);
      }
    }).then(async res => {
      onSubmit({ feature_video: res.key }, false);
      setLoaded();
    });
  };

  const onChangeProfilePhoto = async image => {
    closeDialog();
    onSubmit(
      {
        photo_url: image
      },
      false
    );
    openSnackBar('Saved Profile Photo');
  };

  const onChangeCoverPhoto = async image => {
    closeDialog();
    onSubmit(
      {
        cover_url: image
      },
      false
    );
    openSnackBar('Saved Cover Image');
  };

  const onFeaturedVideoDrop = async file => {
    dropZone.state.fileObjects = [];
    await uploadVideo(file);
    openSnackBar('Successfully saved the video');
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid container item xs={12} className={classes.centered} direction={'column'}>
          <Typography variant={'h2'} color={'textPrimary'}>
            Profile Images
          </Typography>
          <Typography color={'textSecondary'}>These images will be displayed at the top of your profile</Typography>
        </Grid>
        <Grid container item xs={12} className={classes.centered} direction={'column'}>
          <Typography>Upload a Profile Picture</Typography>
        </Grid>
        <Grid item container xs={12} className={classes.centered} direction={'column'}>
          <Typography variant={'caption'}>This photo will show up on the TDN site if your projects or boards are featured.</Typography>
          <Typography variant={'caption'}>Image must ge at least 240 x 240 pixels to ensure quality</Typography>
          <Button variant={'contained'} color={'primary'} className={classes.button} onClick={() => openDialog('Upload Profile Photo', null, 'profile', onChangeProfilePhoto)}>
            Change Profile Photo
          </Button>
        </Grid>
        <Grid item container xs={12} className={classes.centered} direction={'column'}>
          <Typography>Upload a Cover Photo</Typography>
        </Grid>
        <Grid item container xs={12} className={classes.centered} direction={'column'}>
          <Typography variant={'caption'}>Image must be at least 2880 x 820 pixels to ensure quality.</Typography>
          <Button variant={'contained'} color={'primary'} className={classes.button} onClick={() => openDialog('Upload Cover Photo', null, 'cover', onChangeCoverPhoto)}>
            Change Cover Photo
          </Button>
        </Grid>
        <Grid item container xs={12} className={classes.centered} direction={'column'}>
          <Typography>Upload a Featured Video</Typography>
        </Grid>
        {loaded ? (
          <Grid item container xs={12} className={classes.centered} direction={'column'}>
            <Typography variant={'caption'}>Uploading...</Typography>
            <ProgressBar progress={loaded} />
          </Grid>
        ) : (
          <Grid item container xs={12} className={classes.centered} direction={'column'}>
            <DropzoneArea
              ref={ref => setDropZone(ref)}
              filesLimit={1}
              dropzoneText={''}
              showFileNames
              maxFileSize={10000000000}
              acceptedFiles={['video/*']}
              onDrop={onFeaturedVideoDrop}
              showAlerts={false}
              clearOnUnmount
            />
          </Grid>
        )}
        <Grid container justify={'center'} alignItems={'center'}>
          <Button disabled={!!loaded} onClick={() => onSubmit()}>
            Save Changes
          </Button>
        </Grid>
      </Grid>
      <Dialog open={dialog.open} fullScreen={fullScreen}>
        <DialogTitle>{dialog.title ? dialog.title : ''}</DialogTitle>
        <DialogContent>
          {dialog.content ? <DialogContentText>{dialog.content}</DialogContentText> : <UploadPhotoComponent user={user} type={dialog.type} onDone={dialog.action} />}
        </DialogContent>
        <DialogActions>
          <Button variant={'contained'} color={'primary'} onClick={closeDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProfileImages;
