export default theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(5)
  },
  link: {
    '&:hover': {
      textDecoration: 'none'
    }
  }
});
