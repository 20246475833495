import { useState, useEffect } from 'react';
import { useApolloClient } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { listOrders } from '../graphql/queries';
import { getSkuByIds } from '../services/stripe';

export default function useUserOrders(user_id, fetchPolicy = 'network-only') {
  const [orders, setOrders] = useState(null);
  const client = useApolloClient();

  useEffect(() => {
    if (user_id)
      (async function() {
        const querySub = client
          .watchQuery({
            query: gql(listOrders),
            variables: { limit: 100000, filter: { orderCustomerId: { eq: user_id } } },
            fetchPolicy: fetchPolicy
          })
          .subscribe(async res => {
            if (res.data) {
              res.data.listOrders.items.sort((a, b) => {
                const dateA = new Date(a.createdAt);
                const dateB = new Date(b.createdAt);

                return dateB - dateA;
              });

              if (res.data.listOrders.items.length > 0 && typeof res.data.listOrders.items[0].order !== 'string') {
                return setOrders(res.data.listOrders.items);
              }

              let ids = [];
              for (let item of res.data.listOrders.items) {
                item.order = JSON.parse(item.order);
                ids = [...ids, ...item.order.items.filter(item => item.type === 'sku').map(item => item.parent)];
              }

              ids = [...new Set(ids)];

              const skus = await getSkuByIds(ids);

              for (let item of res.data.listOrders.items) {
                for (let e of item.order.items) {
                  if (e.type === 'sku') {
                    const sku = skus.filter(sku => sku.id === e.parent);
                    e.sku = sku.length > 0 ? sku[0] : null;
                  }
                }
              }

              setOrders(res.data.listOrders.items);
            } else {
              setOrders([]);
            }
            querySub.unsubscribe();
          });
      })();
  }, [user_id]);

  return orders;
}
