export default theme => ({
  root: {
    padding: theme.spacing(3),
    minHeight: '100vh',
    backgroundColor: '#FFF',
    '@media (max-width: 1280px)': {
      marginTop: 70
    }
  },
  content: {
    marginTop: theme.spacing(10),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    '& div': {
      textAlign: 'center',
      '& p': {
        textAlign: 'left'
      }
    }
  },
  mobileContent: {
    '& div': {
      textAlign: 'center',
      '& p': {
        textAlign: 'left'
      }
    }
  },
  descriptionContainer: {
    marginTop: '-21px',
    paddingTop: '21px',
    background: 'linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 40%, rgba(255,255,255,1) 100%)',
    width: '100%',
    height: '100%',
    paddingLeft: '30%',
    marginLeft: '-30%',
    '& p': {
      paddingLeft: '30px',
      paddingRight: '30px',
      textAlign: 'justify !important',
      textJustify: 'inter-word !important'
    }
  },
  imgContainer: {
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top -20px center',
    '& img': {
      alignSelf: 'center',
      width: '100%'
    }
  },
  designerName: {
    fontSize: 32,
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 200
  },
  divider: {
    padding: 30,
    background: 'transparent'
  },
  cursorPointer: {
    cursor: 'pointer'
  }
});

export const modalStyles = theme => ({
  root: {
    height: '100%',
    overflow: 'scroll',
    padding: theme.spacing(1),
    margin: theme.spacing(1)
  },
  cursorContainer: {
    cursor: 'pointer'
  },
  imgContainer: {
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: '15px',
    width: '100%',
    height: '100%',
    '& img': {
      alignSelf: 'center'
    }
  },
  descriptionContainer: {
    marginTop: '-21px',
    paddingTop: '21px',
    background: 'linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 40%, rgba(255,255,255,1) 100%)',
    width: '100%',
    height: '100%',
    paddingLeft: '30%',
    marginLeft: '-30%',
    '& p': {
      paddingLeft: '30px',
      paddingRight: '30px',
      textAlign: 'justify !important',
      textJustify: 'inter-word !important'
    }
  },
  modalContainer: {
    marginTop: '64px',
    overflow: 'scroll',
    height: '80vh'
  },
  hintText: {
    opacity: 0.4
  },
  h2: {
    fontSize: 22,
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 600
  },
  videosContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '15px'
  },
  videosHolder: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)'
  }
});
