export default theme => ({
  root: {
    //borderBottom: `1px solid ${theme.palette.border}`,
    //backgroundColor: 'transparent',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    height: '32px',
    zIndex: theme.zIndex.appBar,
    fontFamily: 'American Typewriter, serif'
  },
  toolbar: {
    backgroundColor: 'rgba(255, 255, 255, .5)',
    minHeight: '32px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    '& .MuiButton-label': {
      color: 'rgb(91,19,173) !important',
      fontWeight: 400
    }
  },
  imgLink: {
    marginLeft: 30,
    marginBottom: 0,
    alignItems: 'center !important',
    textAlign: 'center !important',
    color: '#A333C8',
    opacity: '0.7',
    '& a': {
      padding: '1px 5px',
      opacity: '0.7',
      textDecoration: 'none',
      color: '#A333C8',
      '& img': {
        // border: '1px solid black',
        // borderRadius: '50%',
        maxWidth: '10px',
        height: '110px',
        color: '#A333C8',
        paddingRight: 8,
        paddingLeft: 8,
        marginLeft: 5,
        marginRight: 5
      }
    }
  },
  fLink: {
    marginLeft: 10
  }
});
