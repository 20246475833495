import React, { useContext, useState } from 'react';
import { css } from 'glamor';

import { Auth } from 'aws-amplify';
import { TextField, Button, Typography, Link } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useHistory } from 'react-router-dom';

import UserContext from '../../../contexts/UserContext';
import { Spinner } from '../../../components/Cart/components/Button';
import ConfirmPage from './ConfirmPage';
import WelcomeScreen from './WelcomeScreen';

const SignUp = props => {
  const [state, setState] = useState({
    password: '',
    email: '',
    phone_number: '',
    authCode: '',
    showConfirmation: false
  });

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const { updateCurrentUser } = useContext(UserContext);

  const onChange = (key, value) => {
    setState({
      ...state,
      [key]: value
    });
  };

  const signUp = () => {
    const { password, email, phone_number } = state;
    if (phone_number.length === 0) {
      return props.updateErrorMessage('Phone Number is required!');
    }
    setLoading(true);
    props.updateErrorMessage(null);
    const phone = phone_number
      .replace('(', '')
      .replace(')', '')
      .replace('-', '')
      .split(' ')
      .join('');

    Auth.signUp({
      username: email.toLowerCase(),
      password,
      attributes: {
        email: email.toLowerCase(),
        phone_number: '+' + phone
      }
    })
      .then(async () => {
        // props.updateErrorMessage('Verification Code has been sent to ' + state.email);
        await Auth.signIn(email.toLowerCase(), password);
        updateCurrentUser();
        await Auth.verifyCurrentUserAttribute('email');
        setState({ ...state, showConfirmation: true });
      })
      .catch(err => {
        console.log('error signing up: ', err);
        props.updateErrorMessage(err.message ? err.message : err);
        setLoading(false);
      });
  };

  const { showConfirmation } = state;
  return (
    <form {...css(styles.container)}>
      {!showConfirmation && (
        <div {...css(styles.formContainer)}>
          <div {...css(styles.signUpHeader)}>
            <h2>Create a new account</h2>
            <Typography variant={'caption'} style={{ fontSize: '.9em' }}>
              Are you a content creator?
              <Link style={{ marginLeft: 5, cursor: 'pointer' }} onClick={() => history.push('/become-a-designer')}>
                Sign up here!
              </Link>
            </Typography>
          </div>
          <p>Email *</p>
          <TextField value={state.email} variant={'outlined'} {...css(styles.input)} placeholder="Email" onChange={evt => onChange('email', evt.target.value)} />
          <p>Password *</p>
          <TextField
            value={state.password}
            variant={'outlined'}
            {...css(styles.input)}
            placeholder="Password"
            autoComplete={'on'}
            type="password"
            onChange={evt => onChange('password', evt.target.value)}
          />
          <p>Phone Number *</p>
          <PhoneInput
            inputStyle={{ width: '100%', lineHeight: '1.1875em', height: 56 }}
            country={'us'}
            value={state.phone_number}
            onChange={phone => onChange('phone_number', phone)}
          />
          <span {...css(styles.actionContainer)}>
            <span>
              <Typography variant={'caption'}>Have an account? </Typography>
              <Link onClick={props.showSignIn}>
                <Typography variant={'caption'} style={{ fontWeight: 500 }}>
                  Sign in
                </Typography>
              </Link>
            </span>
            <Button disabled={loading} variant={'contained'} color={'primary'} {...css(styles.button)} onClick={signUp}>
              {loading ? <Spinner /> : <p {...css(styles.buttonText)}>Sign Up</p>}
            </Button>
          </span>
        </div>
      )}
      {showConfirmation && (
        // /*<ConfirmPage state={state} onChange={onChange} resendSignUp={props.resendSignUp} confirmSignUp={confirmSignUp} styles={styles} />*/
        <WelcomeScreen />
      )}
    </form>
  );
};

const styles = {
  signUpHeader: {
    textAlign: 'left',
    margin: '0px 0px 30px',
    '& h2': {
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 5
    },
    '@media (max-width: 500px)': {
      margin: '0px 0px 15px'
    }
  },
  button: {
    padding: '10px 60px',
    marginTop: 10,
    marginBottom: 10,
    cursor: 'pointer',
    height: 45,
    width: 153
  },
  buttonText: {
    margin: 0,
    color: 'white'
  },
  container: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingTop: '10%',
    paddingBottom: 50,
    margin: 'auto',
    paddingRight: 10,
    paddingLeft: 10
  },
  formContainer: {
    padding: '35px 40px',
    width: 400,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, .2)',
    backgroundColor: '#fff',
    borderRadius: 6,
    '> p': {
      fontSize: 14,
      margin: '0px 0px 8px 0px'
    },
    '@media (max-width: 500px)': {
      width: '100% !important',
      boxSizing: 'border-box',
      padding: '20px 30px'
    }
  },
  input: {
    marginBottom: '20px !important',
    fontSize: '14px',
    '::placeholder': {
      color: 'rgba(0, 0, 0, .3)'
    },
    '@media (max-width: 500px)': {
      marginBottom: '10px !important'
    }
  },
  phoneInput: {
    width: '100%'
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    '& .MuiTypography-caption': {
      fontSize: 14
    },
    '& a': {
      '> .MuiTypography-caption': {
        color: 'rgb(163, 51, 200)',
        cursor: 'pointer'
      }
    }
  }
};

export default SignUp;
