import React from 'react';
import { withStyles } from '@material-ui/core';
import compose from 'recompose/compose';

//styles
import styles from './styles';

const Pros = props => {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <div className={classes.content}></div>
    </div>
  );
};

export default compose(withStyles(styles))(Pros);
