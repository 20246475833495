import React from 'react';
import './VideoList.scss';
import ResponsiveVideo from 'components/ResponsiveVideo';
import VideoInfo from 'components/VideoInfo';

const VideoList = props => {
  const { video, goToVideoDetail } = props;

  return (
    <button onClick={() => goToVideoDetail(video.id)} className="VideoList-container">
      <div className="VideoList-video">
        <ResponsiveVideo key={video.id} videoId={video.id} date={video.createdAt} goToVideoDetail={goToVideoDetail} />
      </div>
      <div className="VideoList-info">
        <VideoInfo key={video.id + 'vi'} video={video} />
      </div>
    </button>
  );
};

export default VideoList;
