export default theme => ({
  textField: {},
  dialogComponent: {
    minWidth: theme.spacing(50)
  },
  transparentPaper: {
    marginTop: theme.spacing(3),
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none'
  }
});
