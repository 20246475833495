export default theme => ({
  root: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(6)
  },
  container: {
    maxWidth: 900,
    minHeight: '100vh,',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 600
    }
  },
  content: {
    padding: theme.spacing(5),
    '& iframe': {
      width: '100%',
      height: '100vh'
    },
    '& p': {
      paddingLeft: '30px',
      paddingRight: '30px',
      textAlign: 'justify !important',
      textJustify: 'inter-word !important'
    },
    '& h2': {
      opacity: 0.8,
      fontWeight: 300
    }
  },
  innerHtml: {
    maxWidth: '100%',
    '& img': {
      maxWidth: '100% !important',
      height: 'auto !important'
    }
  },
  mobileContent: {
    padding: theme.spacing(0),
    '& h1': {
      fontSize: '1.2rem'
    },
    '& h2': {
      fontSize: '1rem',
      opacity: 0.8,
      fontWeight: 200
    },
    '& h3': {
      fontSize: '.8rem'
    },
    '& p': {
      textAlign: 'justify !important',
      textJustify: 'inter-word !important'
    }
  },
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  }
});
