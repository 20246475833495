export default theme => ({
  root: {
    padding: theme.spacing(0)
  },
  content: {
    padding: theme.spacing(3)
  },
  mobileContent: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(2)
  },
  title: {
    fontSize: '1.2rem',
    fontWeight: 600
  }
});
