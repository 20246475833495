import React from 'react';
import { Grid, useMediaQuery, Typography } from '@material-ui/core';
import styled from 'styled-components';

const SliderWrapperDiv = styled.div`
  padding: 3% 0;
  overflow: hidden;
  position: relative;
`;

const ContainerDiv = styled.div`
  padding-top: 30px;
`;

const CategoryTypography = styled(({ variant, color, children, className }) => {
  return (
    <Typography className={className} variant={variant}>
      {children}
    </Typography>
  );
})`
  color: #000 !important;
  opacity: 0.9;
  margin-left: 30px !important;
`;

const SliderWrapper = ({ children, categories }) => {
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <ContainerDiv>
      {categories && (
        <Grid item xs={12} container={mobile} style={{ justifyContent: mobile ? 'center' : '' }}>
          <CategoryTypography color={'white'} variant={'h4'}>
            {categories[0]}
          </CategoryTypography>
        </Grid>
      )}
      <SliderWrapperDiv>{children}</SliderWrapperDiv>
    </ContainerDiv>
  );
};

export default SliderWrapper;
