export const getPost = `query GetPost($id: ID!) {
  getPost(id: $id) {
    id
    owner
    title
    description
    content
    status
    thumbnail
    createdAt
    likes
    editors {
      items {
        id
        editor {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
        owner
        post {
          id
          owner
          title
          description
          content
          status
          thumbnail
          createdAt
          likes
          liked
        }
      }
      nextToken
    }
    comments {
      items {
        id
        owner
        content
        avatar
        name
        commentPostId
        commentVideoId
        createdAt
        likes
        subs
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
      }
      nextToken
    }
  }
}
`;
