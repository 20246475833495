import { API, graphqlOperation } from 'aws-amplify';
import { deleteCart, updateCart } from '../graphql/mutations';

export default async function updateCartData(id, payload, delete_flag = { delete: false }) {
  if (delete_flag.delete) {
    return API.graphql(graphqlOperation(deleteCart, { input: { id } }));
  }
  const res = await API.graphql(graphqlOperation(updateCart, { input: { id, ...payload } }));
  return res.data.updateCart;
}
