/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, LinearProgress } from '@material-ui/core';
import { useStore } from 'easy-peasy';
import { Auth } from 'aws-amplify';

import { AccountDetails } from './components';
// Component styles
const styles = theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    marginTop: theme.spacing(8)
  }
});

const Account = ({ staticContext, ...props }) => {
  const { classes } = props;
  const user = useStore(state => state.user.user);
  const [authData, setAuthData] = useState(null);

  useEffect(() => {
    Auth.currentAuthenticatedUser({ bypassCache: true }).then(data => setAuthData(data));
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {!user.id || !authData ? (
          <div>
            <LinearProgress />
          </div>
        ) : (
          <AccountDetails authData={authData} user={user} />
        )}
      </div>
    </div>
  );
};

Account.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Account);
