import React, { useEffect, useState } from 'react';
import { css } from 'glamor';
import { Button, Typography, Collapse, Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const WelcomeScreen = props => {
  const history = useHistory();
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const timeoutId1 = setTimeout(() => {
      setShowMessage(true);
    }, 200);
    const timeoutId2 = setTimeout(() => {
      setShowMessage(false);
    }, 3000);

    return () => {
      clearTimeout(timeoutId1);
      clearTimeout(timeoutId2);
    };
  }, []);

  return (
    <div {...css(styles.formContainer)}>
      <h2 {...css(styles.signUpHeader)}>Welcome To HDTV</h2>
      <div {...css(styles.text)}>
        <Typography variant={'subtitle1'}>Dive into the world of Home Design and decor in a new way! Stream shows, buy what you see, interact with experts!</Typography>
      </div>
      <span {...css(styles.actionContainer)}>
        <Button variant={'contained'} color={'primary'} fullWidth disableRipple onClick={() => history.push('/')}>
          <p style={{ color: '#fff' }}>Start watching now</p>
        </Button>
      </span>
      <span {...css(styles.actionContainer)}>
        <Collapse in={showMessage}>
          <Paper {...css(styles.verifyCodeSent)}>
            <Typography variant={'body2'} style={{ textAlign: 'center' }}>
              A verification code has been sent to your email. Verify your email in your account page.
            </Typography>
          </Paper>
        </Collapse>
      </span>
    </div>
  );
};

const styles = {
  signUpHeader: {
    textAlign: 'left',
    margin: '0px 0px 30px',
    '& h2': {
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 5
    },
    '@media (max-width: 500px)': {
      margin: '0px 0px 15px'
    }
  },
  button: {
    padding: '10px 60px',
    marginTop: 10,
    marginBottom: 10,
    cursor: 'pointer',
    height: 45,
    width: 153
  },
  buttonText: {
    margin: 0,
    color: 'white'
  },
  formContainer: {
    padding: '35px 40px',
    width: 400,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, .2)',
    backgroundColor: '#fff',
    borderRadius: 6,
    '> p': {
      fontSize: 14,
      margin: '0px 0px 8px 0px'
    },
    '@media (max-width: 500px)': {
      width: '100% !important',
      boxSizing: 'border-box',
      padding: '20px 30px'
    }
  },
  text: {
    marginBottom: 30,
    marginTop: 10
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    '& .MuiTypography-caption': {
      fontSize: 14
    },
    '& a': {
      '> .MuiTypography-caption': {
        color: 'rgb(163, 51, 200)',
        cursor: 'pointer'
      }
    }
  },
  verifyCodeSent: {
    padding: 20,
    width: '100%'
  }
};

export default WelcomeScreen;
