import React, { useEffect, useState } from 'react';
import { Button, withStyles, Dialog, DialogContent, Slide, Link } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import SignIn from '../SignIn';
import { Close } from '@material-ui/icons';
import { useActions, useStore } from 'easy-peasy';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const LoginButton = ({ classes, history, autoScroll, onSidebarClose }) => {
  const [isLogin, setIsLogin] = useState(false);

  const { close } = useActions(actions => actions.modal);
  const { route, open } = useStore(state => state.modal);

  useEffect(() => {
    setIsLogin(route === 'login' && open);
  }, [route, open]);

  const closeLogin = () => close() && setIsLogin(false);

  return (
    <>
      <Link className={classes.linkItem}>
        <Button
          color="secondary"
          className={classes.button}
          onClick={() => {
            autoScroll();
            onSidebarClose();
            history.push('/auth');
          }}
        >
          Sign In
        </Button>
      </Link>
      <Dialog open={isLogin} style={{ zIndex: 9999 }} keepMounted TransitionComponent={Transition}>
        <DialogContent>
          <>
            <Button onClick={closeLogin} className={classes.closeButton}>
              <Close />
            </Button>
            <SignIn onClose={closeLogin} />
          </>
        </DialogContent>
      </Dialog>
    </>
  );
};

LoginButton.propTypes = {
  autoScroll: PropTypes.func,
  onSidebarClose: () => {}
};

LoginButton.defaultProps = {
  autoScroll: () => {},
  onSidebarClose: () => {}
};

export default withRouter(
  withStyles(theme => ({
    button: {
      fontSize: '14px',
      fontWeight: 300,
      margin: '0px 10px 0px 10px',
      '&:hover': {
        backgroundColor: 'transparent',
        color: '#A333C8'
      },
      fontFamily: 'Montserrat, sans-serif'
    },
    closeButton: {
      position: 'absolute',
      top: 20,
      right: 20,
      height: 50,
      width: 50
    }
  }))(LoginButton)
);
