import { useEffect, useReducer } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { onCreateProject, onDeleteProject, onUpdateProject } from '../graphql/subscriptions';
import { useUser } from './useUser';

const initialState = { projects: null };

function reducer(state, action) {
  switch (action.type) {
    case 'set':
      return { projects: action.payload };
    case 'add':
      return { projects: [action.payload, ...state.projects] };
    case 'remove':
      const trimmedData = state.projects.filter(project => project.id !== action.payload.id);
      return { projects: trimmedData };
    case 'edit':
      const updatedData = state.projects.map(project => {
        if (project.id === action.payload.id) {
          project.title = action.payload.title;
          project.description = action.payload.description;
        }
        return project;
      });
      return { projects: updatedData };
    default:
      return;
  }
}

export default function useDesignerProject(policy = 'network-only') {
  const [state, dispatch] = useReducer(reducer, initialState);
  const user = useUser();

  const projectCreateSubscription = data => {
    dispatch({ type: 'add', payload: data.value.data.onCreateProject });
  };

  const projectDeleteSubscription = data => {
    dispatch({ type: 'remove', payload: data.value.data.onDeleteProject });
  };

  const projectEditSubscription = data => {
    dispatch({ type: 'edit', payload: data.value.data.onUpdateProject });
  };

  useEffect(() => {
    if (user.id) {
      dispatch({ type: 'set', payload: user.designer.projects.items.reverse() });
      try {
        const onCreateSubscription = API.graphql(graphqlOperation(onCreateProject, { owner: user.id })).subscribe({
          next: projectCreateSubscription
        });

        const onDeleteSubscription = API.graphql(graphqlOperation(onDeleteProject, { owner: user.id })).subscribe({
          next: projectDeleteSubscription
        });

        const onUpdateSubscription = API.graphql(graphqlOperation(onUpdateProject, { owner: user.id })).subscribe({
          next: projectEditSubscription
        });

        return () => {
          onCreateSubscription.unsubscribe();
          onDeleteSubscription.unsubscribe();
          onUpdateSubscription.unsubscribe();
        };
      } catch (e) {
        throw new Error(e);
      }
    }
  }, [user]);

  return state.projects;
}
