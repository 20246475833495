import { thunk } from 'easy-peasy';

import modal from './modal';
import user from './user';
import stripeCart from './stripe-cart';
import checkout from './checkout';

export default {
  initialize: thunk(async (_, { client, user_id }, { dispatch }) => {
    await dispatch.cart.setClient(client);
    await dispatch.cart.getCart({ client, user_id });
  }),
  modal,
  user,
  cart: stripeCart,
  checkout
};
