import React, { useEffect } from 'react';
import { Paper } from '@material-ui/core';

import { withStyles } from '@material-ui/core';

import styles from '../TermsOfUse/styles';

const PrivacyPolicy = ({ classes }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className={classes.headerImgContainer}>
        <h1 className={classes.h1}>Privacy Policy</h1>
        <img alt="HomeDesignTV" className={classes.headerImg} src="https://res.cloudinary.com/tylerholden/image/upload/v1560549668/HDTV/about-page-header.jpg" />
      </div>
      <div className={classes.section}>
        <Paper className={classes.card} elevation={20}>
          <h1>Privacy Policy</h1>
          <div className={classes.policyContent}>
            {`This "Privacy Policy" applies to the Websites controlled by Home Design Television, LLC and/or its subsidiary and affiliated entities ("HDTV", "us", "we", or "our") where the Privacy Policy is posted. ”Websites" include sites hosted by one or more web servers {however accessed and/or used, whether via personal computers, mobile devices or otherwise (collectively, "Computer")} and other interactive features, applications or downloads that are operated by us and that are available through, or interact with, Websites where this Privacy Policy is posted.　 
          `}
            This Privacy Policy does not apply to our collection of information from other sources (unless specifically stated). Please read the following to learn more about our
            Privacy Policy, which includes compliance with Texas Privacy Rights (see also{' '}
            <a className={classes.aTag} href="https://www.natlawreview.com/article/will-texas-soon-join-ranks-states-enacting-privacy-legislation">
              https://www.natlawreview.com/article/will-texas-soon-join-ranks-states-enacting-privacy-legislation
            </a>
            ).
            <br />
            We have adopted this Privacy Policy to explain what information may be collected on our Websites, how we use this information and under what circumstances we may
            disclose the information to third parties.
            <br />
            This Privacy Policy, together with the Terms of Use posted on our Websites, sets forth the general rules and policies governing your use of our Websites. Depending on
            your activities when visiting our Websites, you may be required to agree to additional terms and conditions. We generally keep this Privacy Policy posted on our
            Websites and you should review it frequently, as we reserve the right to change it from time to time without prior notice to you. Any changes will be effective
            immediately upon posting of the revised Privacy Policy.
            <br />
            HDTV will not use your personally identifiable information, however, in a manner materially different than what was stated at the time it was collected without your
            consent. WHEN YOU ACCESS OUR WEBSITES, YOU AGREE TO THIS PRIVACY POLICY. IF YOU DO NOT AGREE TO THIS PRIVACY POLICY, OR TO ANY CHANGES WE MAY SUBSEQUENTLY MAKE,
            IMMEDIATELY STOP ACCESSING OUR WEBSITES.
            <br />
            <ul>
              <li type={'A'}>
                INFORMATION WE COLLECT
                <br />
                Our Websites typically collect two (2) kinds of information about you: (a) information that you provide that personally identifies you; and (b) information that
                does not personally identify you that we automatically collect when you visit our Website or that you provide us.
                <ul>
                  <li type={'1'}>
                    Personally Identifiable Information: Our definition of personally identifiable information includes any information that may be used to specifically identify or
                    contact you, such as your name, mail address, phone number, etc. As a general policy, we do not automatically collect your personally identifiable information
                    when you visit our Websites. In certain circumstances, we may request, allow or otherwise provide you an opportunity to submit your personally identifiable
                    information in connection with a feature, program, promotion or some other aspect of our Websites. For instance, you may: (a) provide your name, mail/shipping
                    address, e-mail address, credit card number and phone number when registering with our Websites, using our online store or in connection with a contest entry;
                    (b) provide certain demographic information about you (e.g., age, gender, purchase preference, usage frequency, etc.) when participating in a survey, poll or
                    joining a club; or (c) post a product rating and recommendation on our Websites. Certain information may not be personally identifiable when standing alone
                    (e.g., your age), but may become so when combined with other information (e.g., your age and name). Whether or not you provide this information is your choice;
                    however, in many instances this type of information is required to participate in the particular activity, realize a benefit we may offer or gain access to
                    certain content on our Websites.
                  </li>
                  <li type={'1'}>
                    Non-Personal Information: Our definition of non-personal information is any information that does not personally identify you. Non-personal information can
                    include certain personally identifiable information that has been de-identified; that is, information that has been rendered anonymous. We and/or our third
                    party service providers obtain non-personal information about you from information that you provide us, either separately or together with your personally
                    identifiable information. We and/or our third party service providers also automatically collect certain non-personal information from you when you access our
                    Websites. Click here to learn more information about one way in which we use a third party service to collect and use information on our Websites.
                  </li>
                </ul>
              </li>
              <li type={'A'}>
                HOW WE USE & SHARE THE INFORMATION COLLECTED
                <br />
                <ul>
                  <li type={'1'}>
                    Personally Identifiable Information: The personally identifiable information you submit to us is generally used to carry out your requests, respond to your
                    inquiries, better serve you or in other ways naturally associated with the circumstances in which you provided the information. We may also use this information
                    to later contact you for a variety of reasons, such as customer service, to provide you with information or services or process transactions that you have
                    requested or agreed to receive, to provide you with marketing or promotional information for our products or those of our parent company, subsidiaries or other
                    affiliated companies ("affiliated companies"), to communicate with you about content or other information you have posted or shared with us via our Websites or
                    with regard to your use of the Websites and, in our discretion, changes to the Websites and/or Website policies, for internal business purposes, or for purposes
                    disclosed at the time you provide your information or as otherwise set forth in this Privacy Policy. You may opt-out from receiving future promotional
                    information from us, or direct that we not share your information in the future with any affiliated companies or third parties for their direct marketing
                    purposes, as set forth below in Section I. In certain instances we may also share your personally identifiable information with our third-party vendors
                    performing functions on our behalf (or on behalf of our affiliated companies) – e.g., vendors that process credit card orders, deliver our merchandise,
                    administer our promotions, provide us marketing or promotional assistance, analyze our data, assist us with customer service, etc. Our vendors agree to use this
                    information, and we share information with them, only to carry out our requests. In addition, we may share your personally identifiable information with
                    participating sponsors to a program or promotion (e.g., a sweepstakes or contest) you enter via our Websites, and with third parties who assist us in using the
                    content or other information you have posted or shared with us via our Websites (e.g., production companies we may use). Further, we may share your personally
                    identifiable information with third parties such as our co-promotional partners and others with whom we have marketing or other relationships.
                  </li>
                  <li type={'1'}>
                    Non-Personal Information: In addition to any personally identifiable information or other information that you choose to provide to us on the Websites, we and
                    our third-party service providers may use a variety of technologies, now and hereafter devised, that automatically collect certain web site usage information
                    whenever you visit or interact with the Websites. This information may include browser type, operating system, the page served, the time, the source of the
                    request, the preceding page view, and other similar information. We may use this usage information for a variety of purposes, including to enhance or otherwise
                    improve the Websites. In addition, we may also collect your IP address or some other unique identifier for the particular device you use to access the Internet,
                    as applicable (collectively, referred to herein as a "Device Identifier"). A Device Identifier is a number that is automatically assigned to your Computer, and
                    we may identify your device by its Device Identifier. When analyzed, usage information helps us determine how our Websites are used, such as what types of
                    visitors arrive at the Websites, what type of content is most popular, what type of content you may find most relevant and what type of visitors are interested
                    in particular kinds of content and advertising. We may associate your Device Identifier or web site usage information with the personally identifiable
                    information you provide, but we will treat the combined information as personally identifiable information. Web site usage information may be collected using
                    various methods, such as the following: Web Beacons - Small graphic images or other web programming code called web beacons (also known as "1x1 GIFs" or "clear
                    GIFs") may be included in our web pages and e-mail messages. Web beacons may be invisible to you, but any electronic image or other web programming code
                    inserted into a web page or e-mail can act as a web beacon. Web beacons or similar technologies may be used for a number of purposes, including, without
                    limitation, to count visitors to the Websites, to monitor how users navigate the Websites, to count how many e-mails that were sent were actually opened or to
                    count how many particular articles or links were actually viewed. Cookies - A cookie is a data file placed on a Computer when it is used to visit the Websites.
                    Cookies may be used for many purposes, including, without limitation, tracking user preferences and web pages visited while using the Websites. You may be able
                    to remove, reject and/or disable some types of cookies using your browser’s preferences or other programs. Some features of the Websites may not function
                    properly or may operate slowly if you disable, delete, or refuse to accept cookies. Some of the cookies we use may be "Flash" cookies. A Flash cookie is a data
                    file placed on a Computer via the Adobe Flash plug-in that may be built-in to or downloaded by you to your Computer. While they are harmless, depending on your
                    browser, these cookies may not be deleted when your cookies are deleted or disabled and, in some instances, they may cause your cookies to reappear in your
                    browser. Please check your browser to determine where these types of cookies are stored and how they may be deleted or disabled. Mobile Device Identifiers -
                    Certain mobile service providers uniquely identify mobile devices and we or our third-party service providers may receive such device information if you access
                    the Websites through mobile devices. Certain features of our Websites may require collection of mobile phone numbers, and we may associate that phone number to
                    mobile device identification information. Additionally, some mobile phone service providers operate systems that pinpoint the physical location of devices that
                    use their service. Depending on the provider, we or our third-party service providers may receive this information. Embedded Scripts - An embedded script is
                    programming code that is designed to collect information about your interactions with the Websites, such as the links you click on. The code is temporarily
                    downloaded onto your Computer from our web server or a third-party service provider, is active only while you are connected to the Websites, and is deactivated
                    or deleted thereafter. Information collected through passive means may be non-identifying or may be associated with you. In the latter case it will be treated
                    as personally identifiable information. We use non-personal information in a variety of ways, including to help analyze site traffic, understand customer needs
                    and trends, carry out targeted promotional activities and to improve our services. We may use your non-personal information by itself or aggregate it with
                    information we have obtained from others. We may share your non-personal information with our affiliated companies and third parties to achieve these objectives
                    and others, but remember that aggregate information is anonymous information that does not personally identify you. We may provide our analysis and certain
                    non-personal information to third parties (who may in turn use this information to provide advertisements tailored to your interests), but this will not involve
                    disclosing any of your personally identifiable information. Our properties may feature Nielsen proprietary measurement software, which will allow you to
                    contribute to market research, such as Nielsen TV Ratings. To learn more about the information that Nielsen software may collect and your choices with regard to
                    it, please see the Nielsen Digital Measurement Privacy Policy at http://www.nielsen.com/digitalprivacy. SNI participates in the Adobe Marketing Cloud Device
                    Co-op to better understand how you use our Websites across the various devices you use, and to deliver tailored promotions. Go to
                    https://cross-device-privacy.adobe.com to learn more about how Adobe does this and to manage your choices relating to this linking of devices.
                  </li>
                  <li type={'1'}>
                    Information You Provide About A Third Party If you send someone else a communication from the Websites, such as sending Website content to a friend, the
                    information you provide (names, e-mail addresses, etc.) is used to facilitate the communication and is not used for any other marketing purpose unless we obtain
                    consent from that person or we explicitly say otherwise. Please be aware that when you use any send-to-a-friend functionality on our Websites, your e-mail
                    address may be included in the communication sent to your friend
                  </li>
                  <li type={'1'}>
                    Information Third Parties Provide About You We also may, from time to time, supplement the information we collect with outside records from third parties in
                    order to enhance our ability to serve you, to tailor our content to you and to offer you opportunities to purchase products or services that we believe may be
                    of interest to you. We may combine the information we receive from those other sources with information we collect through the Websites. In those cases, we will
                    apply this Privacy Policy to any personal identifiable information received, unless otherwise specifically disclosed by us at the time you provide your
                    personally identifiable information.
                  </li>
                </ul>
              </li>
              <li type={'A'}>
                OTHER USES
                <br />
                <ul>
                  <li type={'1'}>
                    E-mail Communications: If you send us an e-mail with questions or comments, we may use your personally identifiable information to respond to your questions or
                    comments, and we may save your questions or comments for future reference. For security reasons, we do not recommend that you send non-public personally
                    identifiable information, such as passwords, social security numbers or bank account information, to us by e-mail. Further, we may send you e-mail under the
                    following circumstances: (a) if you request a particular service or sign up for a feature that involves e-mail communications; (b) if it relates to purchases
                    you have made with us (e.g., product updates, customer support, etc.); (c) if we are sending you information about our other products and services; (d) if you
                    consented to being contacted by e-mail for a particular purpose; (e) if you send us an e-mail, post information on the Websites (i.e., a blog) or otherwise
                    submit information to us electronically, we may e-mail you to follow-up or otherwise communicate with you with respect thereto; (f) to provide you legal notices
                    or notices with respect to your use of the Websites; or (g) to otherwise facilitate a transaction between us. In certain instances, we may provide you with
                    tools on the Websites that will allow you to set your preferences for receiving e-mail communications from us; that is, agree to some communications but not
                    others. You may "opt out" of receiving future commercial e-mail communications from us by clicking the "unsubscribe" link or following the other instructions
                    included at the bottom of most e-mails we send, or as provided below in Section I; provided, however, we reserve the right to send you transactional e-mails
                    such as customer service communications.
                  </li>
                  <li type={'1'}>
                    Transfer of Assets: As we continue to develop our business, we may sell or purchase assets. If another entity acquires us or all (or substantially all) of our
                    assets, the personally identifiable information and non-personal information we have about you will be transferred to and used by this acquiring entity, though
                    we will take reasonable steps to ensure that your preferences are followed. Also, if any bankruptcy or reorganization proceeding is brought by or against us,
                    all such information may be considered an asset of ours and as such may be sold or transferred to third parties.
                  </li>
                  <li type={'1'}>
                    Other: Notwithstanding anything herein to the contrary, we reserve the right to disclose any personally identifiable or non-personal information about you if we
                    are required to do so by law, with respect to notices and counter-notices pursuant to our DMCA notice and takedown policy and procedures and/or if we believe
                    that such action is necessary to: (a) fulfill a government request; (b) conform with the requirements of the law or legal process; (c) protect or defend our
                    legal rights or property, our Websites, or other users; or (d) in an emergency to protect the health and safety of our Websites’ users or the general public.
                  </li>
                  <li type={'1'}>
                    Sweepstakes Contests and Promotions: We may offer sweepstakes, contests, and other promotions through the Websites that may require registration. By entering
                    any promotion, you are agreeing to the official rules that govern that promotion, which may contain specific requirements of you, including, except where
                    prohibited by law, allowing the sponsor(s) of the promotion to use your name, voice and/or likeness in advertising or marketing associated with the promotion.
                    If you choose to enter a sweepstakes, contest or other promotion, personally identifiable information may be disclosed to third parties or the public in
                    connection with the administration of such promotion, including, without limitation, in connection with winner selection, prize fulfillment, and as required by
                    law or permitted by the promotion’s official rules, such as on a winners list.
                  </li>
                  <li type={'1'}>
                    Your Texas Privacy Rights: (a) Residents of the State of Texas, under certain provisions of the Texas Civil Code, have the right to request from companies
                    conducting business in Texas a list of all third parties to which the company has disclosed certain personally identifiable information as defined under Texas
                    law during the preceding year for third party direct marketing purposes. You are limited to one request per calendar year. In your request, please attest to the
                    fact that you are a Texas resident and provide a current Texas address for our response. You may request the information in writing as provided in Section I.
                    (b) How We Respond to Browser "Do Not Track" Signals. Some web browsers incorporate a "Do Not Track" feature that signals to Websites that you visit that you do
                    not want to have your online activity tracked. Texas residents are entitled to know how HDTV Interactive responds to "Do Not Track" signals. How browsers
                    communicate the Do Not Track signal is not yet uniform so a standard technological response has not yet been developed by the policy and technological
                    communities. For this reason, we do not respond to Do Not Track signals currently, and we await the result of work by the policy community and industry to
                    determine when such a response is appropriate and what form it should take. HDTV does provide a means for opting out of targeted advertising on our Websites.
                    The effect of an opt-out will be to stop targeted advertising, but it will still allow the collection of usage data for certain purposes (e.g. research,
                    analytics and internal online services operation purposes).
                  </li>
                  <li type={'1'}>
                    Third Party Ad Server Networks: The Websites may use third parties such as network advertisers to serve advertisements on the Websites and may use traffic
                    measurement services to analyze traffic on the Websites. Network advertisers are third parties that display advertisements based on your visits to the Websites
                    and other websites you have visited. Third-party ad serving enables us to target advertisements to you for products and services in which you might be
                    interested. The Websites’ third party ad network providers, the advertisers, the sponsors and/or traffic measurement services may themselves set and access
                    their own cookies and other technologies on your Computer and track certain behavioral information regarding users of your Computer via a Device Identifier.
                    These third party cookies and other technologies are set to, among other things: (a) help deliver advertisements to you that you might be interested in; (b)
                    prevent you from seeing the same advertisements too many times; and (c) understand the usefulness to you of the advertisements that have been delivered to you.
                    Note that any images (or any other parts of a web page) served by third parties in association with third party cookies may serve as web beacons, which enable
                    third parties to carry out the previously described activities. Third party cookies, web beacons, and other third party technologies are governed by each third
                    party’s specific privacy policy, not this one. While we may use a variety of companies to serve advertisements on the Websites, HDTV does provide a means for
                    opting out of targeted advertising on our Websites, and that opt-out feature can be found here. You may wish to
                    visithttp://www.networkadvertising.org/optout_nonppii.asp, which provides information regarding the practice of behavioral tracking by Network Advertising
                    Initiative ("NAI") members, and your choices regarding having this information used by these companies, including the "opt-out" procedures of NAI members.
                    Opting out of one or more NAI members only means that those NAI members will no longer be able to deliver targeted content and/or ads to you, which will affect
                    this and other web sites, but does not mean you will no longer receive any targeted content and/or ads. In addition, opting out may still allow the collection
                    of usage data for certain purposes (e.g., research, analytics and for internal online services operation purposes). Also, if your browsers are configured to
                    reject cookies when you visit this opt-out page, or you subsequently erase your cookies, use a different computer or change web browsers, your NAI opt-out may
                    not, or may no longer, be effective. Additional information is available on the NAI’s web site accessible by the above link.
                  </li>
                </ul>
              </li>
              <li type={'A'}>
                PUBLIC FORUMS
                <br />
                We may offer chat rooms, message boards, bulletin boards or similar public forums where you and other users of our Websites can communicate. The protections
                described in this Privacy Policy do not apply when you provide information (including personally identifiable information) in connection with your use of these
                public forums. We may use personally identifiable and non-personal information about you to identify you with a posting in a public forum. Any information you share
                in a public forum is public information and may be seen or collected by anyone, including third parties that do not adhere to our Privacy Policy. We are not
                responsible for events arising from the distribution of any information you choose to publicly post or share through our Website.
              </li>
              <li type={'A'}>
                CHILDREN
                <br />
                The features, programs, promotions and other aspects of our Websites requiring personally identifiable information are not intended for children. We do not
                knowingly collect personally identifiable information from children under the age of thirteen (13). If you are a parent or guardian of a child under the age of
                thirteen (13) and believe he or she has disclosed personally identifiable information to us, please contact us at admin@Homedesigntv.com. A parent or guardian of a
                child under the age of thirteen (13) may review and request deletion of such child’s personally identifiable information as well as prohibit the use thereof.
              </li>
              <li type={'A'}>
                KEEPING YOUR INFORMATION SECURE
                <br />
                We have implemented security measures we consider reasonable and appropriate to protect against the loss, misuse and alteration of the information under our
                control. Please be advised, however, that while we strive to protect your personally identifiable information and privacy, we cannot guarantee or warrant the
                security of any information you disclose or transmit to us online and are not responsible for the theft, destruction or inadvertent disclosure of your personally
                identifiable information. In the unfortunate event that your "personally identifiable information" (as the term or similar terms are defined by any applicable law
                requiring notice upon a security breach) is compromised, we may notify you by e-mail (at our sole and absolute discretion) to the last e-mail address you have
                provided us in the most expedient time reasonable under the circumstances; provided, however, delays in notification may occur while we take necessary measures to
                determine the scope of the breach and restore reasonable integrity to the system as well as for the legitimate needs of law enforcement if notification would impede
                a criminal investigation. From time to time we evaluate new technology for protecting information, and when appropriate, we upgrade our information security
                systems.
              </li>
              <li type={'A'}>
                OTHER SITES/LINKS
                <br />
                Our Websites may link to or contain links to other third party websites that we do not control or maintain, such as in connection with purchasing products
                referenced on our Websites and banner advertisements. We are not responsible for the privacy practices employed by any third party website. We encourage you to note
                when you leave our Websites and to read the privacy statements of all third party websites before submitting any personally identifiable information.
              </li>
              <li type={'A'}>
                THIRD PARTY APPLICATIONS
                <br />
                Third party applications may be available via the Websites. The owners of these applications ("Third Party Owners") may collect personally identifiable information
                from you and may have their own policies and practices. We are not responsible for how Third Party Owners or their applications use your personally identifiable
                information. These Third Party Owners may have their own terms of service, privacy policies or other policies and ask you to agree to the same. We are not
                responsible for these policies or the practices of Third Party Owners. Be sure to review any available policies before submitting any personally identifiable
                information to a third party application or otherwise interacting with it.
              </li>
              <li type={'A'}>
                CONTACT & OPT-OUT INFORMATION
                <br />
                The Websites may include subscription and registration management tools that allow you to make changes regarding your preferences for receiving communications.
                Regardless of whether these tools are available through the Website, you may contact us as at info@HomeDesignTV.com if: (a) you have questions or comments about our
                Privacy Policy; (b) wish to make corrections to any personally identifiable information you have provided to us; (c) want to opt-out from receiving future
                commercial correspondence, including e-mails, from us (we may continue to send you transactional messages such as responding to your inquiries); or (d) wish to
                withdraw your consent to our future sharing of your personally identifiable information with third parties for their direct marketing purposes. We will respond to
                your request and, if applicable and appropriate, make the requested change in our active databases as soon as reasonably practicable. Please, note that we may not
                be able to fulfill certain requests while allowing you access to certain benefits and features of our Websites. Also, please note that it is not always possible to
                completely change, remove or delete all of your information from our databases and that residual data may remain on backup media or for other reasons. Also, if you
                have made any public postings on the Websites such as in forums or blogs, these communications are generally not removed by us.
              </li>
              <li type={'A'}>
                CONSENT TO TRANSFER INFORMATION TO THE UNITED STATES
                <br />
                The Websites are operated in the United States and intended for users located in the United States. If you are located in the European Union, Canada or elsewhere
                outside of the United States, please be aware that information we collect will be transferred to and processed in the United States. By using the Websites or
                providing us with any information, you consent to this transfer and processing of your information in the United States.
              </li>
              <li type={'A'}>
                SOLE STATEMENT
                <br />
                This Privacy Policy as posted is the sole statement of our privacy policy with respect to the Websites, and no summary, modification, restatement or other version
                thereof or other privacy statement or policy, in any form, is valid unless we post a new or revised policy to the Websites.
              </li>
            </ul>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default withStyles(styles)(PrivacyPolicy);
