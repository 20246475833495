import { Storage } from 'aws-amplify';
import uuid from 'uuid';

const removeFile = file => {
  return Storage.remove(file, {
    bucket: 'hdtv-prod',
    level: 'public'
  })
    .then(e => null)
    .catch(err => null);
};

const uploadFile = async (file, progressCallback, video = false) => {
  const ext = file.name.split('.').pop();
  const key = uuid() + '.' + ext;
  return await Storage.put('images/' + key, file, {
    bucket: video ? 'hdtv-videos-source-2osorqelt13t' : 'hdtv-prod',
    level: 'public',
    progressCallback
  });
};

export { removeFile, uploadFile };
