import React from 'react';
import ResponsiveVideo from 'components/ResponsiveVideo';
import { withStyles } from '@material-ui/core';
//styles
import styles from './styles';

const SubscriptionVideo = props => {
  const { data } = props;

  return <ResponsiveVideo videoId={data.video.id} details />;
};

export default withStyles(styles)(SubscriptionVideo);
