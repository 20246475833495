import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

//amplify
import Amplify, { Auth, Hub } from 'aws-amplify';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';
import { Rehydrated } from 'aws-appsync-react';

import awsconfig from './aws-exports';

import PubSub from '@aws-amplify/pubsub';

PubSub.configure();

// retrieve temporary AWS credentials and sign requests
Amplify.configure(awsconfig);

const client = new AWSAppSyncClient(
  {
    url: awsconfig.aws_appsync_graphqlEndpoint,
    region: awsconfig.aws_appsync_region,
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken()
    },
    disableOffline: false
  },
  {
    defaultOptions: {
      name: 'cognito'
    }
  }
);

export const apiKeyClient = new AWSAppSyncClient(
  {
    url: awsconfig.aws_appsync_graphqlEndpoint,
    region: awsconfig.aws_appsync_region,
    auth: {
      type: AUTH_TYPE.API_KEY,
      apiKey: awsconfig.aws_appsync_apiKey
    },
    disableOffline: true
  },
  {
    defaultOptions: {
      name: 'apiKey'
    }
  }
);

const WithProvider = () => {
  const [loggedIn, setLoggedIn] = useState('false');
  React.useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(data => setLoggedIn(true))
      .catch(e => setLoggedIn(false));
    Hub.listen('auth', data => {
      Auth.currentAuthenticatedUser()
        .then(data => setLoggedIn(true))
        .catch(e => setLoggedIn(false));
    });
  }, []);

  return (
    <ApolloProvider client={loggedIn ? client : apiKeyClient}>
      <ApolloHooksProvider client={loggedIn ? client : apiKeyClient}>
        <Rehydrated>
          <App />
        </Rehydrated>
      </ApolloHooksProvider>
    </ApolloProvider>
  );
};

ReactDOM.render(<WithProvider />, document.getElementById('root'));

serviceWorker.unregister();
