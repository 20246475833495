import React, { useRef, useState } from 'react';
import { withStyles, Paper, Grid, TextField, Button, Snackbar } from '@material-ui/core';
import compose from 'recompose/compose';

import styles from './styles';

const ContactUs = props => {
  const { classes } = props;
  const formRef = useRef(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });

  const submitForm = async e => {
    if (!formRef.current.name.checkValidity()) {
      setSnackbar({ open: true, message: formRef.current.name.validationMessage });
      return formRef.current.name.focus();
    }

    if (!formRef.current.email.checkValidity()) {
      setSnackbar({ open: true, message: formRef.current.email.validationMessage });
      return formRef.current.email.focus();
    }

    if (!formRef.current.message.checkValidity()) {
      setSnackbar({ open: true, message: formRef.current.message.validationMessage });
      return formRef.current.message.focus();
    }

    formRef.current.submit();
  };

  return (
    <div>
      <div className={classes.headerImgContainer}>
        <h1 className={classes.h1}>HomeDesignTv</h1>
        <img alt="HomeDesignTV" className={classes.headerImg} src="https://res.cloudinary.com/tylerholden/image/upload/v1560549668/HDTV/about-page-header.jpg" />
      </div>
      <div className={classes.section}>
        <Paper className={classes.card} elevation={20}>
          <h2 className={classes.h2}>Contact Us</h2>
          <form ref={formRef} action={'https://formspree.io/moqqyzgn'} method={'POST'} noValidate={false}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  id="standard-number"
                  label="Name"
                  name={'name'}
                  type="text"
                  variant={'outlined'}
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  margin="normal"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="email"
                  label="Email"
                  name={'email'}
                  type="email"
                  variant={'outlined'}
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  margin="normal"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="message"
                  label="Message"
                  name={'message'}
                  type="text"
                  rows={8}
                  multiline
                  variant={'outlined'}
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  margin="normal"
                  required
                />
              </Grid>
            </Grid>
          </form>
          <br />
          <br />
          <Grid item xs={12}>
            <Button onClick={submitForm} variant={'outlined'}>
              Send
            </Button>
          </Grid>
          <br />
          <br />
        </Paper>
      </div>
      <br />
      <br />
      <br />
      <Snackbar open={snackbar.open} message={snackbar.message} autoHideDuration={3000} onClose={() => setSnackbar({ open: false, message: '' })} />
    </div>
  );
};

export default compose(withStyles(styles))(ContactUs);
