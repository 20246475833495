import React, { useState, useEffect } from 'react';
import ResponsiveVideo from 'components/ResponsiveVideo';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { withStyles, CircularProgress, useMediaQuery, Button } from '@material-ui/core';

import SliderContext from 'components/Slider/context';
//styles
import styles from './styles';

//graphql stuff
import gql from 'graphql-tag';
import { getVideo } from 'graphql/queries';
import { useQuery } from 'react-apollo-hooks';
import { useActions, useStore } from 'easy-peasy';

const GetVideoQuery = gql`
  ${getVideo}
`;

const Video = ({ history, match, classes, videos, ...props }) => {
  const { data } = useQuery(GetVideoQuery, { variables: { id: match.params.id ? match.params.id : '4dae2c08-5abc-43b7-811d-6784ee02acc3' } });
  const [video, setVideo] = useState([]);

  const user = useStore(state => state.user.user);
  const { goToLogin } = useActions(actions => actions.modal);
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const goToVideoDetail = () => {
    user && user.id ? history.push('/videos/' + video.id) : goToLogin();
  };

  useEffect(() => {
    if (data.getVideo && data.getVideo !== 'undefined') setVideo(data.getVideo);
  }, [data]);

  return (
    <div className={classes.root}>
      <div className={classes.videoPlayer}>
        {!videos.listVideos && (
          <div className={classes.progressWrapper}>
            <CircularProgress />
          </div>
        )}
        <SliderContext.Consumer>
          {({ currentPlaying, loading }) => {
            if (currentPlaying && currentPlaying.id) {
              setVideo(currentPlaying);
              return (
                <>
                  {user.id && video.id && <ResponsiveVideo videoId={currentPlaying.id} {...props} playing />}
                  <span className={!mobile ? classes.videoInfo : classes.mobileVideoInfo}>
                    {currentPlaying.status === 'active' && (
                      <span className={classes.hdtvLogo}>
                        <img src="/images/logos/logo.png" height="20px" alt="hdtv logo" /> <span>SERIES</span>
                      </span>
                    )}
                    <p>
                      <span className={classes.title}>{currentPlaying.title}</span>
                    </p>
                    <p>
                      <span className={classes.description}>{currentPlaying.description}</span>
                    </p>
                    <p>
                      <span className={classes.actionButtons}>
                        <Button variant="outlined" color="primary" onClick={() => goToVideoDetail()}>
                          Play
                        </Button>
                        {/* <WatchlistButton video={currentPlaying} /> */}
                      </span>
                    </p>
                  </span>
                  {/* <VideoComments video={currentPlaying} /> */}
                </>
              );
            } else {
              return (
                <div>
                  {!loading && <ResponsiveVideo {...props} videoId={video.id} playing={true} />}
                  <div className={!mobile ? classes.videoInfo : classes.mobileVideoInfo}>
                    {video.status === 'active' && (
                      <span className={classes.hdtvLogo}>
                        <img src="/images/logos/logo.png" height="20px" alt="hdtv logo" /> <span>SERIES</span>
                      </span>
                    )}
                    <p>
                      <span className={classes.title}>{video.title}</span>
                    </p>
                    <p>
                      <span className={classes.description}>{video.description}</span>
                    </p>
                    <p>
                      <span className={classes.actionButtons}>
                        <Button variant="outlined" color="primary" onClick={() => goToVideoDetail()}>
                          Play
                        </Button>
                        {/* <WatchlistButton video={video} /> */}
                      </span>
                    </p>
                  </div>
                  {/* <VideoComments video={video} /> */}
                </div>
              );
            }
          }}
        </SliderContext.Consumer>
      </div>
    </div>
  );
};

export default compose(withRouter, withStyles(styles))(Video);
