import { Auth } from 'aws-amplify';
import { getUser } from 'graphql/queries';
import { updateUser } from 'graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';

const updateUserData = async (data, type, payload) => {
  try {
    const authData = await Auth.currentAuthenticatedUser({ bypassCache: false });
    if (authData) {
      const res = await API.graphql(graphqlOperation(getUser, { id: authData.attributes.sub }));
      if (res) {
        if (payload) {
          await API.graphql(graphqlOperation(updateUser, { input: { id: authData.attributes.sub, ...payload } }));
        } else if (res.data.getUser[type] && Array.isArray(res.data.getUser[type])) {
          await API.graphql(graphqlOperation(updateUser, { input: { id: authData.attributes.sub, [type]: [...res.data.getUser[type], ...data] } }));
        } else {
          await API.graphql(graphqlOperation(updateUser, { input: { id: authData.attributes.sub, [type]: data } }));
        }
      }
    }
  } catch (error) {
    throw new Error(error);
  }
};

export default updateUserData;
