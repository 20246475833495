import React, { useState, useEffect } from 'react';
import compose from 'recompose/compose';
import { withStyles, Typography, List, ListItem } from '@material-ui/core';
//styles
import styles from './styles';
import OrderHeader from './OrderHeader';
import OrderContent from './OrderContent';
import useUserOrders from '../../hooks/useUserOrders';
import { useStore } from 'easy-peasy';

const Orders = ({ classes }) => {
  const user = useStore(state => state.user.user);
  const orders = useUserOrders(user && user.id ? user.id : '');

  const renderOrders = () => {
    return (
      <List>
        {orders.map(({ order }, idx) => {
          return (
            <React.Fragment key={order.id + idx}>
              <ListItem alignItems="flex-start" divider={true} className={classes.listItem}>
                <OrderHeader order={order} />
                <OrderContent items={order.items.filter(item => item.type === 'sku')} />
              </ListItem>
            </React.Fragment>
          );
        })}
      </List>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>{orders ? orders.length > 0 ? renderOrders() : <Typography>No order history.</Typography> : <Typography>Loading ...</Typography>}</div>
    </div>
  );
};

export default compose(withStyles(styles))(Orders);
