import { action } from 'easy-peasy';

export const normalizeAttributes = target =>
  [...target.attributes].reduce((attributes, a) => {
    const startsWith = 'data-moltin-';

    if (!a.name.startsWith(startsWith)) return false;

    return {
      ...attributes,
      [a.name.replace(startsWith, '')]: a.value
    };
  }, {});

export const changeRoute = route =>
  action(state => {
    state.route = route;
    state.open = true;
  });

export const pluralize = (count, noun, suffix = 's') => `${count} ${noun}${count !== 1 ? suffix : ''}`;

export const validateEmail = email => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
};

export function formatMoney(number, decPlaces = 2, decSep, thouSep) {
  decSep = typeof decSep === 'undefined' ? '.' : decSep;
  thouSep = typeof thouSep === 'undefined' ? ',' : thouSep;
  let sign = number < 0 ? '-' : '';
  let i = String(parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces))));
  let j = i.length > 3 ? i % 3 : 0;

  return (
    sign +
    (j ? i.substr(0, j) + thouSep : '') +
    i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, '$1' + thouSep) +
    (decPlaces
      ? decSep +
        Math.abs(number - i)
          .toFixed(decPlaces)
          .slice(2)
      : '')
  );
}
