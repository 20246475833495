export default theme => ({
  card: {
    height: '100%'
  },
  cardActions: {
    bottom: 0
  },
  cardMedia: {
    maxHeight: '30vh'
  },
  paper: {
    height: '100%'
  },
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  }
});
