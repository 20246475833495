import React, { useContext } from 'react';
import { withRouter, Link as RouterLink, NavLink } from 'react-router-dom';
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withStyles, useMediaQuery } from '@material-ui/core';
import { Divider, Link, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';

// Material icons
import {
  TrendingUpOutlined as TrendingIcon,
  PeopleOutlined as PeopleIcon,
  HistoryOutlined as HistoryIcon,
  WatchLaterOutlined as WatchLaterIcon,
  FavoriteBorderOutlined as LikedIcon,
  PersonOutlineOutlined,
  Input as InputIcon,
  VideoLibraryOutlined,
  PeopleOutlineOutlined,
  ListOutlined
} from '@material-ui/icons';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { Auth } from 'aws-amplify';

// Component styles
import styles from './styles';
import UserContext from '../../../../contexts/UserContext';
import { useStore } from 'easy-peasy';

const Sidebar = ({ onCloseSidebar, ...props }) => {
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const { updateCurrentUser, openSnackBar } = useContext(UserContext);
  const user = useStore(state => state.user.user);

  const handleSignOut = () => {
    const { history } = props;

    Auth.signOut({ global: true })
      .then(() => {
        updateCurrentUser();
        history.push('/');
      })
      .catch(err => null);
    onCloseSidebar();
  };

  const { classes, className } = props;

  const rootClassName = classNames(classes.root, className);
  const SidebarLink = React.forwardRef((props, ref) => <NavLink innerRef={ref} {...props} />);

  return (
    <nav className={rootClassName}>
      <div className={classes.logoWrapper}>
        <Link className={classes.logoLink} to="/" onClick={onCloseSidebar}>
          <img alt="HDTV logo" className={classes.logoImage} src="/images/logos/logo.png" style={{ height: 30 }} />
        </Link>
      </div>
      <Divider className={classes.logoDivider} />
      <div className={classes.profile}>
        <Typography className={classes.nameText} variant="h6">
          Welcome to HDTV
        </Typography>
        {user && (
          <Typography className={classes.bioText} variant="caption">
            {user.id ? (user.first_name && user.last_name ? user.first_name + ' ' + user.last_name : 'New Viewer') : ''}
          </Typography>
        )}
      </div>
      <Divider className={classes.profileDivider} />
      <List component="div" disablePadding>
        {mobile && (
          <>
            <ListItem activeClassName={classes.activeListItem} className={classes.listItem} component={SidebarLink} to="/shows" onClick={onCloseSidebar}>
              <ListItemIcon className={classes.listItemIcon}>
                <VideoLibraryOutlined />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.listItemText }} primary="Shows" />
            </ListItem>
            <ListItem activeClassName={classes.activeListItem} className={classes.listItem} component={SidebarLink} to="/categories" onClick={onCloseSidebar}>
              <ListItemIcon className={classes.listItemIcon}>
                <AddShoppingCartIcon />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.listItemText }} primary="Shop" />
            </ListItem>
            <ListItem activeClassName={classes.activeListItem} className={classes.listItem} component={SidebarLink} to="/designers" onClick={onCloseSidebar}>
              <ListItemIcon className={classes.listItemIcon}>
                <PeopleOutlineOutlined />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.listItemText }} primary="Designers" />
            </ListItem>
            <ListItem activeClassName={classes.activeListItem} className={classes.listItem} component={SidebarLink} to="/project-pros" onClick={onCloseSidebar}>
              <ListItemIcon className={classes.listItemIcon}>
                <PeopleOutlineOutlined />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.listItemText }} primary="Project Pros" />
            </ListItem>
            <ListItem activeClassName={classes.activeListItem} className={classes.listItem} component={SidebarLink} to="/articles" onClick={onCloseSidebar}>
              <ListItemIcon className={classes.listItemIcon}>
                <ListOutlined />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.listItemText }} primary="Articles" />
            </ListItem>
            <Divider className={classes.profileDivider} />
          </>
        )}
        {user && user.designer && (
          <>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classes.listItem}
              component={SidebarLink}
              to={user.designer.pro ? '/project-pro-portal' : '/designer-portal'}
              onClick={onCloseSidebar}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.listItemText }} primary={user.designer.pro ? 'Project Pro Portal' : 'Designer Portal'} />
            </ListItem>
            <Divider className={classes.profileDivider} />
          </>
        )}
        {user && user.id ? (
          <>
            <ListItem activeClassName={classes.activeListItem} className={classes.listItem} component={SidebarLink} to="/trending" onClick={onCloseSidebar}>
              <ListItemIcon className={classes.listItemIcon}>
                <TrendingIcon />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.listItemText }} primary="Trending" />
            </ListItem>
            <ListItem activeClassName={classes.activeListItem} className={classes.listItem} component={SidebarLink} to={'/subscriptions'} onClick={onCloseSidebar}>
              <ListItemIcon className={classes.listItemIcon}>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.listItemText }} primary="Subscriptions" />
            </ListItem>
            <Divider className={classes.profileDivider} />
            <ListItem activeClassName={classes.activeListItem} className={classes.listItem} component={SidebarLink} to="/history" onClick={onCloseSidebar}>
              <ListItemIcon className={classes.listItemIcon}>
                <HistoryIcon />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.listItemText }} primary="History" />
            </ListItem>
            <ListItem activeClassName={classes.activeListItem} className={classes.listItem} component={SidebarLink} to="/watchlist" onClick={onCloseSidebar}>
              <ListItemIcon className={classes.listItemIcon}>
                <WatchLaterIcon />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.listItemText }} primary="Watchlist" />
            </ListItem>
            <ListItem activeClassName={classes.activeListItem} className={classes.listItem} component={SidebarLink} to="/favorites" onClick={onCloseSidebar}>
              <ListItemIcon className={classes.listItemIcon}>
                <LikedIcon />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.listItemText }} primary="Favorites" />
            </ListItem>
            <Divider className={classes.profileDivider} />
            <ListItem activeClassName={classes.activeListItem} className={classes.listItem} component={SidebarLink} to="/orders" onClick={onCloseSidebar}>
              <ListItemIcon className={classes.listItemIcon}>
                <AddShoppingCartIcon />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.listItemText }} primary="Orders" />
            </ListItem>
            <ListItem activeClassName={classes.activeListItem} className={classes.listItem} component={SidebarLink} to="/account" onClick={onCloseSidebar}>
              <ListItemIcon className={classes.listItemIcon}>
                <PersonOutlineOutlined />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.listItemText }} primary="Account" />
            </ListItem>
            <ListItem className={classes.listItem} onClick={() => handleSignOut()}>
              <ListItemIcon className={classes.listItemIcon}>
                <InputIcon />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.listItemText }} primary="Sign Out" />
            </ListItem>
            <div className={classes.socialIcons}>
              <Link target={'_blank'} href="https://www.facebook.com/HomeDesignTelevsion/">
                <img alt="facebook" src="/assets/facebook_social_icon.svg" />
              </Link>
              <Link target={'_blank'} href="https://twitter.com/TheHDTVnetwork">
                <img alt="twitter" src="/assets/twitter_social_icon.svg" />
              </Link>
              <Link target={'_blank'} href="https://www.instagram.com/homedesigntelevision/">
                <img alt="Instagram" src="/assets/instagram_social_icon.svg" />
              </Link>
              <Link target={'_blank'} href="https://www.pinterest.com/HomeDesignTV/">
                <img alt="pinterest" src="/assets/pinterest_social_icon.svg" />
              </Link>
            </div>
          </>
        ) : (
          <ListItem activeClassName={classes.activeListItem} className={classes.listItem} component={SidebarLink} to={'/auth'} onClick={onCloseSidebar}>
            <ListItemIcon className={classes.listItemIcon}>
              <InputIcon />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }} primary="Sign In" />
          </ListItem>
        )}
      </List>
    </nav>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default compose(withRouter, withStyles(styles))(Sidebar);
