import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles, withWidth } from '@material-ui/core';
import { Drawer } from '@material-ui/core';
import { Sidebar, Topbar, Footer, UpperTopbar } from './components';

// Component styles
import styles from './styles';
import { useStore } from 'easy-peasy';
//import { UpperTopbar } from './components';

const Main = props => {
  const isMobile = ['xs', 'sm', 'md'].includes(props.width);
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const user = useStore(state => state.user.user);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const { classes, title, children } = props;

  const shiftTopbar = isOpen && !isMobile;
  const shiftContent = isOpen && !isMobile;

  return (
    <div className={classes.mainLayout}>
      <UpperTopbar
        className={classNames(classes.UpperTopbar, {
          [classes.topbarShift]: shiftTopbar
        })}
      />
      <div style={{ paddingBottom: 32 }}></div>
      {history && history.location.pathname !== '/auth' && (
        <Topbar
          className={classNames(classes.topbar, {
            [classes.topbarShift]: shiftTopbar
          })}
          isSidebarOpen={isOpen}
          onToggleSidebar={handleToggleOpen}
          onSidebarClose={handleClose}
          title={title}
          user={user}
        />
      )}
      <Drawer anchor="left" classes={{ paper: classes.drawerPaper }} onClose={handleClose} open={isOpen} variant={isMobile ? 'temporary' : 'persistent'} elevation={10}>
        <Sidebar className={classes.sidebar} onCloseSidebar={handleClose} />
      </Drawer>
      <main
        className={classNames(classes.content, {
          [classes.contentShift]: shiftContent
        })}
      >
        {children}
        {history && history.location.pathname !== '/auth' && <Footer />}
      </main>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  width: PropTypes.string.isRequired
};

export default withStyles(styles)(withWidth()(Main));

//{history && history.location.pathname !== '/auth' && <UpperTopbar />}
