// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id: 'us-east-1:59f127e3-d7de-4a78-85c6-7c5036d71139',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_TEnYLcrYP',
  aws_user_pools_web_client_id: '6g52kvc62ebfufo93bdb8ocepa',
  oauth: {},
  aws_user_files_s3_bucket: 'hdtv-prod',
  aws_user_files_s3_bucket_region: 'us-east-1',
  aws_appsync_graphqlEndpoint: 'https://pestqkg7dbgcvpfrlxfegsqv6m.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'da2-lixh45zs6nflzclfnsrk5xvomi',
  aws_cloud_logic_custom: [
    {
      name: 'StripeProductsAPI',
      endpoint: 'https://zdu12kfwol.execute-api.us-east-1.amazonaws.com/prod',
      region: 'us-east-1'
    }
  ],
  aws_mobile_analytics_app_id: '3ec5380bb49148e4b1767c608f9d3aa8',
  aws_mobile_analytics_app_region: 'us-east-1'
};

export default awsmobile;
