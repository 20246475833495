export default theme => ({
  root: {
    padding: '100px 0',
    boxSizing: 'border-box',
    minHeight: '100vh'
  },
  container: {
    padding: 40,
    height: '100%',
    width: '100%',
    backgroundColor: 'white',
    boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]: {
      padding: 15
    }
  },
  tabRoot: {
    minWidth: theme.spacing(5)
  },
  tabIndicator: {
    backgroundColor: '#000',
    height: 1
  }
});
