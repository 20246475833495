export default theme => ({
  root: {
    padding: theme.spacing(9),
    minHeight: '100vh',
    textAlign: 'center'
  },
  mobile: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(12),
    minHeight: '100vh',
    textAlign: 'center'
  },
  designerName: {
    fontSize: 32,
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 200
  }
});
