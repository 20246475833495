export default theme => ({
  root: {},
  productContainerWrapper: {
    background: 'white',
    borderRadius: '10px',
    boxShadow: '0px 3px 7px rgba(0, 0, 0, 0.25)',
    padding: '6px'
  },
  productContainerInner: {
    display: 'flex',
    alignItems: 'flex-start',
    margin: '0',
    padding: '0'
  },
  productImage: {
    height: '88px',
    width: '88px',
    flexShrink: '0',
    objectFit: 'cover',
    borderRadius: '6px',
    overflow: 'hidden'
  },
  productInfo: {
    flex: '1',
    paddingLeft: '6px',
    display: 'flex',
    minHeight: '88px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& h3': {
      fontSize: '14px',
      margin: '0',
      letterSpacing: '0.3px'
    },
    '& h4': {
      fontSize: '12px',
      margin: '0',
      letterSpacing: '0.3px'
    },
    '& p': {
      fontSize: '10px',
      letterSpacing: '0.1px',
      margin: '4px 0px',
      opacity: '0.7'
    }
  },
  productFooter: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& p': {
      margin: '0px',
      borderRadius: '8px',
      background: '#e4e4e4',
      padding: '2px 8px'
    },
    '& button': {
      background: 'transparent',
      color: '#000000'
    }
  },
  productContainer: {
    maxHeight: '200px',
    transition: '2s linear',
    opacity: '1',
    height: 'auto',
    padding: '6px',
    '& button': {
      margin: theme.spacing(1)
    }
  },
  upNextProduct: {
    opacity: '0.2'
  },
  hideProduct: {
    opacity: '0',
    height: 0
  },
  cartItemInfo: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTop: '1px solid #fbfbfb',
    marginTop: '10px'
  }
});
