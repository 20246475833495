import React, { useContext, useState } from 'react';
import { css } from 'glamor';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { Button, Link, Snackbar, TextField, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import UserContext from '../../../../../../contexts/UserContext';
import { Spinner } from '../../../../../../components/Cart/components/Button';
import { useActions } from 'easy-peasy';

const SignIn = props => {
  const [state, setState] = useState({
    username: '',
    password: '',
    showConfirmation: false,
    user: {},
    authCode: ''
  });

  const { close } = useActions(actions => actions.modal);

  const { openSnackBar } = useContext(UserContext);

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const [error, setError] = useState(null);

  const context = useContext(UserContext);

  const updateErrorMessage = message => {
    if (openSnackBar && message) {
      return openSnackBar(message, 'Error');
    }
    setError(message);
  };

  const onChange = (key, value) => {
    updateErrorMessage(null);
    setState({
      ...state,
      [key]: value
    });
  };

  const showSignUp = () => {
    close();
    history.push({ pathname: '/auth', state: { signUp: true } });
  };

  const signIn = () => {
    setLoading(true);
    const { updateCurrentUser } = context;
    Auth.signIn(state.username.toLowerCase(), state.password)
      .then(user => {
        if (!user.signInUserSession) {
          setState({ ...state, user, showConfirmation: true });
        } else {
          updateCurrentUser(user);
          props.onClose();
        }
        setLoading(false);
      })
      .catch(err => {
        console.log('error signing in...: ', err);
        if (err.code === 'UserNotConfirmedException') {
          setState({ ...state, showConfirmation: true });
        }
        updateErrorMessage(err.message);
        setLoading(false);
      });
  };

  const confirmSignUp = () => {
    Auth.confirmSignUp(state.username.toLowerCase(), state.authCode)
      .then(() => {
        signIn();
      })
      .catch(err => {
        console.log('error confirming signing up: ', err);
        updateErrorMessage(err.message ? err.message : err);
      });
  };

  const resendSignUp = () => {
    Auth.resendSignUp(state.username.toLowerCase())
      .then(() => {
        updateErrorMessage('Resent code to ' + state.username);
      })
      .catch(err => {
        updateErrorMessage(err.message ? err.message : err);
      });
  };

  return (
    <div {...css(styles.container)}>
      <Snackbar open={Boolean(error)} autoHideDuration={5000} message={error} onClose={() => updateErrorMessage(null)} />
      {!state.showConfirmation && (
        <form {...css(styles.formContainer)}>
          <h2 {...css(styles.signInHeader)}>Sign in to your account</h2>
          <p>Email *</p>
          <TextField
            variant={'outlined'}
            disabled={loading}
            value={state.email}
            onChange={evt => onChange('username', evt.target.value)}
            {...css(styles.input)}
            placeholder="Email"
          />
          <p>Password *</p>
          <TextField
            variant={'outlined'}
            type="password"
            value={state.password}
            onChange={evt => onChange('password', evt.target.value)}
            {...css(styles.input)}
            placeholder="Password"
            disabled={loading}
          />
          {/*<span {...css(styles.forgetPassword)}>*/}
          {/*  <Typography variant={'caption'}>Forget your password? </Typography>*/}
          {/*  <Link onClick={props.showForgotPassword}>*/}
          {/*    <Typography variant={'caption'}>Reset password</Typography>*/}
          {/*  </Link>*/}
          {/*</span>*/}
          <span {...css(styles.createAccount)}>
            <span>
              <Typography variant={'caption'}>No account? </Typography>
              <Link onClick={showSignUp}>
                <Typography variant={'caption'} style={{ fontWeight: 500 }}>
                  Create account
                </Typography>
              </Link>
            </span>
            <Button variant={'contained'} disabled={loading} {...css(styles.button)} color={'primary'} onClick={signIn}>
              {loading ? <Spinner /> : 'Sign In'}
            </Button>
          </span>
        </form>
      )}
      {state.showConfirmation && (
        <div {...css(styles.formContainer)}>
          <h2 {...css(styles.signUpHeader)}>Confirm Sign Up</h2>
          <p>Confirmation Code *</p>
          <TextField
            value={state.authCode}
            variant={'outlined'}
            {...css(styles.input)}
            onChange={evt => onChange('authCode', evt.target.value)}
            {...css(styles.input)}
            placeholder="Confirmation Code"
          />
          <span {...css(styles.createAccount)}>
            <span>
              <Typography variant={'caption'}>Lost your code? </Typography>
              <Link onClick={() => resendSignUp(state.username)}>
                <Typography variant={'caption'}>Resend Code</Typography>
              </Link>
            </span>
            <Button variant={'contained'} color={'primary'} {...css(styles.button)} onClick={confirmSignUp}>
              <p {...css(styles.buttonText)}>Confirm</p>
            </Button>
          </span>
        </div>
      )}
    </div>
  );
};

SignIn.propTypes = {
  onClose: PropTypes.func
};

SignIn.defaultProps = {
  onClose: () => {}
};

const styles = {
  signInHeader: {
    textAlign: 'left',
    margin: '0px 0px 30px',
    fontSize: 18,
    fontWeight: 500,
    '@media (max-width: 500px)': {
      margin: '0px 0px 15px'
    }
  },
  button: {
    padding: '10px 60px',
    marginTop: 10,
    marginBottom: 10,
    cursor: 'pointer',
    height: 45,
    width: 153
  },
  buttonText: {
    margin: 0,
    color: 'white'
  },
  input: {
    marginBottom: '20px !important',
    fontSize: '16px',
    '::placeholder': {
      color: 'rgba(0, 0, 0, .3)'
    },
    '@media (max-width: 500px)': {
      marginBottom: 10
    }
  },
  container: {
    flex: 1,
    margin: '5% auto 50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingRight: 10,
    paddingLeft: 10
  },
  formContainer: {
    padding: '0 5px',
    width: 400,
    display: 'flex',
    flexDirection: 'column',
    '> p': {
      fontSize: 14,
      margin: '0px 0px 8px 0px'
    },
    '@media (max-width: 500px)': {
      width: '100% !important',
      boxSizing: 'border-box',
      padding: '20px 30px'
    }
  },
  forgetPassword: {
    marginBottom: 30,
    '> a': {
      '> .MuiTypography-caption': {
        color: 'rgb(163, 51, 200)',
        cursor: 'pointer'
      }
    }
  },
  createAccount: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiTypography-caption': {
      fontSize: 14
    },
    '& a': {
      '> .MuiTypography-caption': {
        color: 'rgb(163, 51, 200)',
        cursor: 'pointer'
      }
    }
  }
};

export default SignIn;
