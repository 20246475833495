import React from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';

// Views

import About from './views/About';
import Account from './views/Account/Account';
import Articles from './views/Articles';
import ArticleDetail from './views/Articles/components/ArticleDetail';
import Categories from './views/Categories/Categories';
import Category from './views/Categories/components/Category/Category';
import Feed from './views/Feed';
import Designers from './views/Designers/Designers';
import ProjectPros from './views/ProjectPros';
import DIY from './views/DIY/DIY';
import Pros from './views/Pros/Pros';
import Orders from './views/Orders/Orders';
import Subscriptions from './views/Subscriptions/Subscriptions';
import Videos from './views/Videos';
import VideoDetail from './views/VideoDetail/VideoDetail';
import UnderDevelopment from './views/UnderDevelopment';
import NotFound from './views/NotFound';
import Trending from './views/Trending/Trending';
import BecomeADesigner from './views/BecomeADesigner';
import DesignerPortal from './views/DesignerPortal/DesignerPortal';
import ProjectProPortal from './views/DesignerPortal/ProjectProPortal';
import PrivacyPolicy from './views/PrivacyPolicy';
import Terms from './views/TermsOfUse';
import ContactUs from './views/ContactUs';
import Sponsors from './views/Sponsors';
import Authenticator from './views/Authenticator';
import { DesignerProfile } from './components/DesignerProfile';
import LoadingAppScreen from './components/LoadingAppScreen';
import { useStore } from 'easy-peasy';
import CodeVerification from './views/CodeVerification';

const PrivateRoute = withRouter(({ component, ...props }) => {
  const user = useStore(state => state.user.user);

  return !user ? <LoadingAppScreen /> : user.id ? <Route component={component} {...props} /> : <Redirect to={'/auth'} />;
});

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/shows" />
      <Route component={About} exact path="/about" />
      <Route component={PrivacyPolicy} exact path="/privacy" />
      <Route component={Terms} exact path="/terms" />
      <Route component={ContactUs} exact path="/contact-us" />
      <PrivateRoute component={Account} exact path="/account" />
      <Route component={Sponsors} exact path="/sponsors" />
      <Route component={Articles} exact path="/articles" />
      <Route component={ArticleDetail} exact path="/articles/:id" />
      <Route component={Categories} exact path="/categories" />
      <Route component={Category} exact path="/categories/:category" />
      <Route component={Designers} exact path="/designers" />
      <Route component={DesignerProfile} exact path="/designers/:id" />
      <Route component={ProjectPros} exact path="/project-pros" />
      <Route component={DesignerPortal} exact path="/designer-portal" />
      <Route component={ProjectProPortal} exact path="/project-pro-portal" />
      <Route component={DIY} exact path="/diy" />
      <PrivateRoute component={Feed} exact path="/favorites" />
      <PrivateRoute component={Feed} exact path="/history" />
      <PrivateRoute component={Orders} exact path="/orders" />
      <Route component={Pros} exact path="/pros" />
      <Route component={Videos} exact path="/shows" />
      <PrivateRoute component={Subscriptions} exact path="/subscriptions" />
      <Route component={Trending} exact path="/trending" />
      <Route component={UnderDevelopment} exact path="/under-development" />
      <PrivateRoute component={VideoDetail} exact path="/videos/:id" />
      <PrivateRoute component={Feed} exact path="/watchlist" />
      <Route component={NotFound} exact path="/not-found" />
      <Route component={BecomeADesigner} exact path="/become-a-designer" />
      <Route component={Authenticator} exact path={'/auth'} />
      <Route component={CodeVerification} exact path={'/verify-code'} />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
