export default theme => ({
  UpperTopbar: {
    position: 'fixed',
    width: '100%',
    top: 0,
    left: 0,
    right: 'auto',
    paddingBottom: 0,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),

    '& a': {
      padding: '1px 0px',
      opacity: '0.7',
      textDecoration: 'none',
      color: '#A333C8',
      '& img': {
        // border: '1px solid black',
        // borderRadius: '50%',
        maxWidth: '20px',
        height: '20px',
        color: '#A333C8',
        paddingRight: 0,
        paddingLeft: 0,
        marginLeft: 0,
        marginRight: 0
      },
      '&:hover': {
        opacity: '1',
        transform: 'scale(1.4)',
        textDecoration: 'none',
        //color: "#A333C8",

        color: theme.palette.primary.main
      }
    }
  },
  topbar: {
    position: 'fixed',
    width: '100%',
    top: 32,
    left: 0,
    right: 'auto',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  topbarShift: {
    marginLeft: '270px',
    width: 'calc(-270px + 100vw)'
  },
  drawerPaper: {
    zIndex: 1200,
    width: '270px',
    boxShadow: '0px 6px 6px -3px rgba(0,0,0,0.2), 0px 10px 14px 1px rgba(0,0,0,0.14), 0px 4px 18px 3px rgba(0,0,0,0.12)'
  },
  cartDrawer: {
    zIndex: 1200,
    width: '500px',
    boxShadow: '0px 6px 6px -3px rgba(0,0,0,0.2), 0px 10px 14px 1px rgba(0,0,0,0.14), 0px 4px 18px 3px rgba(0,0,0,0.12)'
  },
  content: {
    marginTop: '0px',
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  contentShift: {
    marginLeft: '270px'
  }
});
