import React, { useState } from 'react';
import compose from 'recompose/compose';
import { withStyles, IconButton } from '@material-ui/core';
import { WatchLaterOutlined } from '@material-ui/icons';
import { useMutation } from 'react-apollo-hooks';
import { updateUser } from 'graphql/mutations';
import gql from 'graphql-tag';

//styles
import styles from './styles';

const UpdateUserQuery = gql`
  ${updateUser}
`;

const WatchlistButton = props => {
  const { classes, video, userId, watchlist, setWatchlist } = props;
  const [disabled, setDisabled] = useState(false);

  const UpdateUser = useMutation(UpdateUserQuery);

  const addToWatchList = async () => {
    let current = watchlist
      ? watchlist.reduce((result, x) => {
          if (x.watchlistVideoId !== video.id) {
            delete x.__typename;
            result.push({
              watchlistVideoId: x.watchlistVideoId,
              createdAt: x.createdAt
            });
          }
          return result;
        }, [])
      : [];
    let newWatchlistEntry = {
      watchlistVideoId: video.id,
      createdAt: new Date()
    };
    let result = await UpdateUser({ variables: { input: { id: userId, watchlist: [...current, newWatchlistEntry] } } });
    setWatchlist([...current, newWatchlistEntry]);
    if (result) setDisabled(true);
  };

  return (
    <>
      {userId && video && (
        <IconButton disabled={disabled} aria-label={`star ${video.title}`} onClick={() => addToWatchList(video.id)}>
          <WatchLaterOutlined className={classes.title} />
        </IconButton>
      )}
    </>
  );
};

export default compose(withStyles(styles))(WatchlistButton);
