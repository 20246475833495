export default theme => ({
  root: {
    //borderBottom: `1px solid ${theme.palette.border}`,
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    height: '64px',
    zIndex: theme.zIndex.appBar,
    fontFamily: 'Montserrat, sans-serif'
  },
  toolbar: {
    backgroundColor: 'rgba(255, 255, 255, .5)',
    minHeight: '64px',
    width: '100%',
    transitionTimingFunction: 'ease-in',
    transition: 'all 1s',
    display: 'flex',
    flexDirection: 'row',
    '& .MuiButton-label': {
      color: 'rgb(91,19,173) !important',
      fontWeight: 500,
      transition: 'all 1s linear'
    }
  },
  toolbarBlack: {
    backgroundColor: 'rgb(255, 255, 255)',
    minHeight: '64px',
    width: '100%',
    transitionTimingFunction: 'ease-in',
    transition: 'all 1s',
    display: 'flex',
    flexDirection: 'row',
    boxShadow: '0px 3px 2px -1px rgba(0,0,0,0.2), 0px 4px 3px 0px rgba(0,0,0,0.14), 0px 4px 3px 0px rgba(0,0,0,0.12)',
    '& .MuiButton-label': {
      transition: 'all 1s linear',
      fontWeight: 400,
      color: 'rgb(91,19,173) !important'
    }
  },

  title: {
    marginLeft: theme.spacing(1)
  },
  linkItem: {
    textDecoration: 'none',
    flex: 1,
    textAlign: 'center'
  },
  titleBarLogo: {
    flex: 1,
    textAlign: 'center',
    marginLeft: -15
    //
  },
  button: {
    textAlign: 'left',
    fontSize: '14px',
    margin: '0px 10px 0px 10px',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#A333C8'
    },
    fontFamily: 'Montserrat, sans-serif'
  },
  logo: {
    height: '25px'
  },
  logoHorizontallyCenter: {
    position: 'relative',
    transform: 'translate(-50%, -50%)'
  },
  menuButton: {
    marginLeft: '-4px',
    color: '#66788A' //marginRight: '42px'
  },
  notificationsButton: {
    marginLeft: 'auto',
    color: '#66788A'
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
    color: '#66788A'
  }
});
