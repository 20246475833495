export default `query ListDesigners(
  $filter: ModelDesignerFilterInput
  $limit: Int
  $nextToken: String
) {
  listDesigners(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      posts {
        items {
          id
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      pro
      profile {
        first_name
        last_name
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        photo_url
        cover_url
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
