import { API } from 'aws-amplify';

const apiName = 'StripeProductsAPI';

async function getData(apiName, path) {
  return await API.get(apiName, path, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

async function postData(apiName, path, data) {
  return await API.post(apiName, path, {
    headers: { 'Content-Type': 'application/json' },
    body: data
  });
}

async function getProducts() {
  try {
    const res = await getData(apiName, '/stripe/products');
    return res.data ? res.data.data : res;
  } catch (e) {
    throw new Error(e);
  }
}

async function getProductsByIds(ids) {
  try {
    const res = await postData(apiName, '/stripe/products/ids', { ids });
    return res.success ? res.data.data : res;
  } catch (e) {
    console.log(e);
    throw new Error(e);
  }
}

async function getSkus() {
  try {
    const res = await getData(apiName, '/stripe/sku');
    return res.data ? res.data.data : res;
  } catch (e) {
    throw new Error(e);
  }
}

async function getSkuByProductId(id) {
  try {
    const res = await getData(apiName, `/stripe/sku/product/${id}`);
    return res.data ? res.data.data : res;
  } catch (e) {
    throw new Error(e);
  }
}

async function getSkuById(id) {
  try {
    const res = await getData(apiName, `/stripe/sku/${id}`);
    return res.data ? res.data : res;
  } catch (e) {
    throw new Error(e);
  }
}

async function getSkuByIds(ids) {
  try {
    const res = await postData(apiName, `/stripe/sku/ids`, { ids });
    return res.data ? res.data : res;
  } catch (e) {
    throw new Error(e);
  }
}

async function createOrder(sku_ids, name, line1, city, state, country, postal_code, email) {
  const data = {
    items: sku_ids.map(({ id, quantity }) => {
      return { sku_id: id, quantity: quantity };
    }),
    name,
    line1,
    city,
    state,
    country,
    postal_code,
    email
  };
  try {
    const res = await postData(apiName, `/stripe/orders`, data);

    return res.data ? res.data : res;
  } catch (e) {
    throw new Error(e);
  }
}

async function payOrder(order_id, stripe_token) {
  try {
    const res = await postData(apiName, `/stripe/pay/${order_id}`, { stripe_token });
    return res.data ? res.data : res;
  } catch (e) {
    throw new Error(e);
  }
}

async function charge() {
  try {
    const res = await postData(apiName, `/stripe/pay`, {});
    return res.data ? res.data.data : res;
  } catch (e) {
    throw new Error(e);
  }
}

export { getProducts, getSkus, getSkuByProductId, getSkuById, createOrder, payOrder, charge, getData, postData, getProductsByIds, getSkuByIds };
