import { useEffect, useReducer } from 'react';
import { listVideos } from 'graphql/queries';
import { useApolloClient } from 'react-apollo-hooks';
import gql from 'graphql-tag';

const initialState = { trending: [] };

function reducer(state, action) {
  switch (action.type) {
    case 'set':
      return { trending: action.payload };
    default:
      return;
  }
}

export function useTrending(fetchPolicy = 'network-only') {
  const [state, dispatch] = useReducer(reducer, initialState);
  const client = useApolloClient();

  useEffect(() => {
    const fetchTrendingVideos = () => {
      client
        .query({
          query: gql`
            ${listVideos}
          `,
          variables: { filter: { status: { eq: 'active' } }, limit: 50 },
          fetchPolicy
        })
        .then(data => {
          dispatch({
            type: 'set',
            payload: data.data.listVideos.items
          });
        })
        .catch(error => console.error(error));
    };
    fetchTrendingVideos();
  }, [client]);

  return state.trending;
}
