import React, { useEffect } from 'react';
import { useStore, useActions } from 'easy-peasy';
import { Badge } from '@material-ui/core';
import { PrimaryButton } from './Button';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { green } from '@material-ui/core/colors';
import purple from '@material-ui/core/colors/purple';
import { useApolloClient } from 'react-apollo-hooks';

function CartButton({ user }) {
  const { count } = useStore(({ cart }) => cart);
  const client = useApolloClient();
  const {
    initialize,
    modal: { goToCart }
  } = useActions(actions => actions);

  useEffect(() => {
    if (client && user.id) {
      initialize({ client, user_id: user.id });
    }
  }, [initialize, client, user]);

  return (
    <PrimaryButton className="shopkit-cart-button" onClick={goToCart}>
      <Badge
        badgeContent={count}
        style={{ color: purple[400] }}

        // variant="dot"
      >
        <AddShoppingCartIcon />
      </Badge>
    </PrimaryButton>
  );
}

export default CartButton;
