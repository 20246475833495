import { Button, withStyles } from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors';

export const SquaredButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(blueGrey[500]),
    backgroundColor: blueGrey[500],
    '&:hover': {
      backgroundColor: blueGrey[700]
    },
    borderRadius: 0,
    padding: theme.spacing(2),
    marginTop: 40,
    '@media(min-width: 869px)': {
      width: '30%'
    }
  }
}))(Button);
