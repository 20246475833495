import { useEffect, useReducer } from 'react';
import { Hub } from '@aws-amplify/core';

const initialState = { videoTime: [] };

function reducer(state, action) {
  switch (action.type) {
    case 'set':
      return { videoTime: action.payload };
    default:
      return;
  }
}

export function useVideoTime(time) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const HubListener = () => {
      Hub.listen('videoTime', data => {
        dispatch({
          type: 'set',
          payload: data.payload.data
        });
      });
    };
    HubListener();

    return () => {
      Hub.remove('videoTime');
    };
  }, []);

  useEffect(() => {
    const setVideoTime = async () => {
      Hub.dispatch('videoTime', {
        data: time
      });
    };
    setVideoTime();
  }, [time]);

  return state.videoTime;
}
