import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import SliderContext from './context';
import SlideButton from './SlideButton';
import SliderWrapper from './SliderWrapper';
import useSliding from './useSliding';
import useSizeElement from './useSizeElement';
import VideoSlider from 'components/VideoSlider';
import Video from 'components/Video';
import { Button, useMediaQuery } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import styled from 'styled-components';
import { useActions, useStore } from 'easy-peasy';
import './Slider.scss';

const VideoWrapper = styled.div`
  position: relative;
  max-height: ${props => (!props.expand ? '70vh' : '1000px')};
  overflow: hidden;
  transition: all 0.4s linear;
`;

const ExpandButton = styled(Button)`
  position: absolute !important;
  bottom: 10px;
  right: 50%;
  left: 50%;
`;

const Slider = ({ children, categories, activeSlide, data, history, loading, hideMainVideo }) => {
  const [currentSlide, setCurrentSlide] = useState(activeSlide);
  const [expandTrailer, setExpandTrailer] = useState(false);
  const user = useStore(state => state.user.user);
  const { goToLogin } = useActions(actions => actions.modal);
  const { width, elementRef } = useSizeElement();
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  // TODO: fix the width of elementRef
  const { handlePrev, handleNext, slideProps, containerRef, hasNext, hasPrev } = useSliding(220, React.Children.count(children));

  const handleSelect = video => {
    setCurrentSlide(video);
  };

  const handleClose = () => setCurrentSlide(null);

  const handlePlay = video => {
    user && user.id ? history.push('/videos/' + video.id) : goToLogin();
  };

  const contextValue = {
    onSelectSlide: handlePlay,
    onSlideHover: handleSelect,
    onSlideOut: handleClose,
    elementRef,
    currentSlide,
    loading: loading
  };

  return (
    <SliderContext.Provider value={contextValue}>
      {!mobile && (
        <>
          {hideMainVideo ? null : (
            <VideoWrapper expand={expandTrailer}>
              <Video controls={false} volumeButton playButton playing muted videos={data} />
              <ExpandButton onClick={() => setExpandTrailer(!expandTrailer)}>
                {expandTrailer ? <ExpandLess style={{ filter: 'invert(1)', height: 50, width: 50 }} /> : <ExpandMore style={{ filter: 'invert(1)', height: 50, width: 50 }} />}
              </ExpandButton>
            </VideoWrapper>
          )}

          <SliderWrapper categories={categories} data={data}>
            <div className={cx('slider', { 'slider--open': currentSlide != null })}>
              <div ref={containerRef} className="slider__container" {...slideProps}>
                {children}
              </div>
            </div>
            {hasPrev && <SlideButton onClick={handlePrev} type="prev" />}
            {hasNext && <SlideButton onClick={handleNext} type="next" />}
          </SliderWrapper>
        </>
      )}
      {mobile && (
        <>
          {hideMainVideo ? null : <Video controls={false} volumeButton playing muted videos={data} />}
          <VideoSlider categories={['Home Renovation']} setSelectedCategory={['Home Renovation']} />
        </>
      )}
    </SliderContext.Provider>
  );
};

export default withRouter(Slider);
