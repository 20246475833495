import React, { useState, useEffect } from 'react';
import {
  withStyles,
  Grid,
  Button,
  InputBase,
  LinearProgress,
  Typography,
  GridList,
  GridListTile,
  List,
  ListItem,
  GridListTileBar,
  useMediaQuery,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Divider
} from '@material-ui/core';

import { useApolloClient } from 'react-apollo-hooks';
import gql from 'graphql-tag';

import styles from './styles';
import NewVideoModal from './components/NewVideoModal';
import { useUser } from '../../../../../../hooks/useUser';
import { onCreateVideo } from '../../../../../../graphql/subscriptions';
import { onCreateVideoCast } from '../../../../../../graphql/subscriptions';
import useDesignerVideos from '../../../../../../hooks/useDesignerVideos';
import usePendingVideos from '../../../../../../hooks/usePendingVideos';
import ReactPlayer from 'react-player';
import MediaPreviewModal from '../../../../../../components/MediaPreviewModal';

const VideoUpload = ({ classes }) => {
  const [text, setText] = useState('');
  const [videoModal, setVideoModal] = useState({ open: false });
  const [previewVideo, setPreviewVideo] = useState(null);
  const user = useUser();
  const videos = useDesignerVideos(user.id ? user.designer.id : null);
  const pendingVideos = usePendingVideos(user.id ? user.designer.id : null);
  const client = useApolloClient();
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [status, setStatus] = useState('all');

  const statuses = ['Pending', 'Approved', 'Inactive', 'All'];

  useEffect(() => {
    if (user.id) {
      const videoSubscriber = client
        .subscribe({
          query: gql(onCreateVideo),
          variables: {
            owner: user.id
          }
        })
        .subscribe(res => null);

      const videoCastSubscriber = client
        .subscribe({
          query: gql(onCreateVideoCast),
          variables: {
            owner: user.id
          }
        })
        .subscribe(res => null);

      return () => {
        videoSubscriber.unsubscribe();
        videoCastSubscriber.unsubscribe();
      };
    }
  }, [client, user]);

  useEffect(() => {
    // perform search here
  }, [text]);

  const openVideoModal = video => () => {
    setVideoModal({ open: true, video });
  };

  const closeVideoModal = () => {
    setVideoModal({ open: false });
  };

  return (
    <>
      {(!user.id || !videos || !pendingVideos) && (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      )}
      {user.id && (
        <Grid container spacing={3} className={classes.root}>
          <Grid item xs={12}>
            <Grid container direction={'row'} className={classes.search}>
              <div className={classes.fullWidth}>
                <InputBase
                  placeholder="Search…"
                  classes={{
                    root: classes.inputRoot,
                    input: !mobile ? classes.inputInput : null
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                  value={text}
                  onChange={e => setText(e.target.value)}
                />
                <Button variant={'contained'} onClick={openVideoModal()}>
                  Add Video
                </Button>
              </div>
            </Grid>
          </Grid>
          <GridList container cols={mobile ? 1 : 2.5} className={mobile ? null : classes.gridList}>
            {videos &&
              videos.map(video => (
                <GridListTile onClick={() => setPreviewVideo(video.url)} key={video.id} className={classes.gridListTile}>
                  <ReactPlayer url={video.url} />
                  <GridListTileBar title={video.title} />
                </GridListTile>
              ))}
            {videos && videos.length === 0 && (
              <Grid item xs={12}>
                <Typography variant={'caption'}>No videos</Typography>
              </Grid>
            )}
          </GridList>

          <Grid container spacing={3}>
            {pendingVideos && pendingVideos.length > 0 && (
              <>
                {' '}
                <Grid item xs={12}>
                  <Typography variant={'h3'}>Pending: </Typography>
                  {statuses.map(stat => (
                    <Button variant={stat.toLowerCase() === status ? 'outlined' : 'default'} onClick={() => setStatus(stat.toLowerCase())}>
                      {stat}
                    </Button>
                  ))}
                </Grid>
                {!mobile ? (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Title</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Show</TableCell>
                        <TableCell>Category</TableCell>
                        <TableCell>Season</TableCell>
                        <TableCell>Episode</TableCell>
                        <TableCell>Duration</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {pendingVideos
                        .filter(vid => status === 'all' || vid.status === status)
                        .map(video => (
                          <TableRow key={video.id}>
                            <TableCell component={'th'} scope={'row'}>
                              <Typography>{video.title}</Typography>
                            </TableCell>
                            <TableCell component={'th'} scope={'row'}>
                              <Typography>{video.description}</Typography>
                            </TableCell>
                            <TableCell component={'th'} scope={'row'}>
                              <Typography>{video.show.title}</Typography>
                            </TableCell>
                            <TableCell component={'th'} scope={'row'}>
                              <Typography>{video.category.name}</Typography>
                            </TableCell>
                            <TableCell component={'th'} scope={'row'}>
                              <Typography>{video.season}</Typography>
                            </TableCell>
                            <TableCell component={'th'} scope={'row'}>
                              <Typography>{video.episode}</Typography>
                            </TableCell>
                            <TableCell component={'th'} scope={'row'}>
                              <Typography>{video.duration}</Typography>
                            </TableCell>
                            <TableCell component={'th'} scope={'row'}>
                              <Typography>{video.status}</Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                ) : (
                  <List>
                    {pendingVideos
                      .filter(vid => status === 'all' || vid.status === status)
                      .map(video => (
                        <>
                          <ListItem key={video.id}>
                            <Grid container item xs={12} justify={'center'} alignItems={'flex-start'} direction={'column'}>
                              <Typography>Title: {video.title}</Typography>
                              <Typography>Description: {video.description}</Typography>
                              <Typography>Show: {video.show.title}</Typography>
                              <Typography>Category: {video.category.title}</Typography>
                              <Typography>Season: {video.season}</Typography>
                              <Typography>Episode: {video.episode}</Typography>
                              <Typography>Duration: {video.Duration}</Typography>
                              <Divider />
                            </Grid>
                          </ListItem>
                          <Divider />
                        </>
                      ))}
                  </List>
                )}
              </>
            )}
          </Grid>
          <NewVideoModal open={videoModal.open} video={videoModal.video} onClose={closeVideoModal} designerId={user.designer.id} />

          <MediaPreviewModal classes={classes} type={'video'} src={previewVideo} onClose={() => setPreviewVideo(null)} open={!!previewVideo} />
        </Grid>
      )}
    </>
  );
};

export default withStyles(styles)(VideoUpload);
