import React, { useState, useEffect } from 'react';
import { withStyles, GridList, GridListTile, GridListTileBar, LinearProgress, useMediaQuery } from '@material-ui/core';
import Products from './Products';
import styles from './styles';
import { getProductsByIds } from '../../../../services/stripe';
import useProductCategory from '../../../../hooks/useProductCategory';
import { formatImage } from '../../../../utils';

const Category = props => {
  const {
    classes,
    match: {
      params: { category: category_id }
    }
  } = props;

  const category = useProductCategory(category_id);

  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState({ data: null });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (category) {
      if (category.subCategories) {
        for (let cat of category.subCategories.items) {
          cat.image = formatImage(cat.image, cat.slug);
        }
      }
      setCategories(category.subCategories ? category.subCategories.items : []);
      (async function() {
        const ids = category.products.items.map(item => item.product_id);
        const _products = await getProductsByIds(ids);

        setProducts(_products);

        setLoading(false);
      })();
    }
  }, [category]);

  const goToCategory = id => {
    props.history.push('/categories/' + id);
  };

  const xl = useMediaQuery(theme => theme.breakpoints.up('xl'));
  const lg = useMediaQuery(theme => theme.breakpoints.up('lg'));
  const md = useMediaQuery(theme => theme.breakpoints.up('md'));
  const getGridListCols = () => {
    if (xl) {
      return 4;
    }

    if (lg) {
      return 3;
    }

    if (md) {
      return 2;
    }

    return 1;
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {categories.length > 0 && (
          <GridList cellHeight={450} className={classes.gridList} cols={getGridListCols()}>
            {categories.length > 0 &&
              categories.map((category, i) => {
                return (
                  <GridListTile key={category.id} onClick={() => goToCategory(category.id)}>
                    <img src={category.image} alt={category.name} />
                    <GridListTileBar classes={{ title: classes.title, subtitle: classes.subtitle, root: classes.titleBar, titleWrap: classes.titleWrap }} title={category.name} />
                  </GridListTile>
                );
              })}
          </GridList>
        )}
        <Products category={category} loading={loading} products={products} {...props} />
      </div>
    </div>
  );
};
export default withStyles(styles)(Category);
