export default theme => ({
  root: {
    padding: theme.spacing(0),
    minHeight: '100vh'
  },
  content: {
    marginTop: theme.spacing(9)
  },
  titleBar: {
    height: '100%',
    background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' + 'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
  },
  title: {
    fontSize: '3rem !important',
    textAlign: 'center',
    fontWeight: 200,
    lineHeight: '4rem',
    letterSpacing: '.2rem',
    textTransform: 'uppercase',
    fontFamily: 'Montserrat, sans-serif !important'
  },
  titleWrap: {
    height: 100
  },
  subtitle: {
    height: 0
  }
});
