import React, { useState } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { createStore, StoreProvider, useActions, useStore } from 'easy-peasy';

//styles
import { Snackbar, withStyles } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';
import model from 'components/Cart/model';

//import pages
import { Main as MainLayout } from './layouts';
import Routes from './Routes';

import { useUser } from './hooks/useUser';

import UserContext from './contexts/UserContext';

const browserHistory = createBrowserHistory();

const store = createStore(model, {});

const HdtvTheme = {
  button: {
    backgroundColor: `#A333C8`
  },
  signInButton: {
    backgroundColor: `#A333C8`
  },
  signInButtonIcon: {
    backgroundColor: `#A333C8`
  },
  signInButtonContent: {
    backgroundColor: `#A333C8`
  },
  navButton: {
    backgroundColor: `#A333C8`
  },
  a: {
    color: '#A333C8'
  },
  toast: {
    display: 'none'
  }
};

function App({ classes }) {
  const [snackbar, setSnackbar] = useState({ open: false, message: '', type: 'Success' });
  const [userFlag, setUserFlag] = useState(false);
  const user = useUser(undefined, userFlag);
  const setUser = useActions(actions => actions.user.setUser);

  React.useEffect(() => {
    setUser(user);
  }, [user]);

  const appContext = {
    updateCurrentUser: () => setUserFlag(!userFlag),
    openSnackBar: (message, type = 'Success') => setSnackbar({ open: true, message: message, type })
  };
  return (
    <>
      <UserContext.Provider value={appContext}>
        <MainLayout>
          <Routes />
        </MainLayout>
      </UserContext.Provider>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={5000}
        open={snackbar.open}
        message={snackbar.message}
        onClose={() => setSnackbar({ open: false, message: '' })}
        classes={{ root: snackbar.type ? classes[`snackbar${snackbar.type}`] : null }}
      />
    </>
  );
}

const AppContainer = withStyles(theme => ({
  snackbarSuccess: {
    '& >div': {
      backgroundColor: 'rgb(103, 173, 92)'
    }
  },
  snackbarWarning: {
    '& >div': {
      backgroundColor: 'rgb(241, 157, 57)'
    }
  },
  snackbarError: {
    '& >div': {
      backgroundColor: 'rgb(225, 81, 66)'
    }
  }
}))(({ classes }) => {
  return (
    <>
      <StoreProvider store={store}>
        <ThemeProvider theme={theme}>
          <Router history={browserHistory}>
            <App classes={classes} />
          </Router>
        </ThemeProvider>
      </StoreProvider>
    </>
  );
});

export default () => <AppContainer />;
