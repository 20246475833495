import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { withStyles, useMediaQuery } from '@material-ui/core';
import { PlayerIcon } from 'react-player-controls';
import { useVideo } from 'hooks/useVideo';
import updateUserData from 'hooks/useUpdateUser';
//styles
import styles from './styles';
import { useStore } from 'easy-peasy';

const ResponsiveVideo = ({ classes, videoId, goToVideoDetail, details, playing, muted, onProgress, controls = true, volumeButton, playButton, ...props }) => {
  const video = useVideo(videoId);
  const player = useRef(null);
  const [updated, setUpdated] = useState(false);
  const [_muted, setMuted] = useState(true);
  const [_playing, setPlaying] = useState(false);
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const user = useStore(state => state.user.user);

  useEffect(() => {
    setMuted(mobile || playing || muted);
    setPlaying(playing);
  }, [playing, muted, mobile]);

  const onError = () => {
    if (playing) {
      if (!updated && player.current) {
        player.current.getInternalPlayer().currentTime = 1;
        setUpdated(true);
      }
    }
  };

  const addToHistory = async () => {
    if (user && user.id)
      updateUserData(
        [
          {
            historyVideoId: video.id,
            createdAt: new Date()
          }
        ],
        'history'
      );
  };

  const onSoundOn = () => {
    setMuted(false);
    player.current.getInternalPlayer().volume = 0.5;
  };

  const onPlay = () => setPlaying(true);

  const onPause = () => setPlaying(false);

  const onSoundOff = () => {
    setMuted(true);
    player.current.getInternalPlayer().volume = 0;
  };

  return !video ? null : (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.video}>
          <ReactPlayer
            ref={player}
            style={{ backgroundColor: '#000000' }}
            className={classes.reactPlayer}
            url={video.url}
            progressInterval={1000}
            playsinline
            controls={controls}
            onProgress={onProgress ? onProgress : undefined}
            muted={_muted}
            light={!playing ? video.thumbnail : false}
            onEnded={addToHistory}
            playing={_playing}
            height={'100%'}
            width={'100%'}
            onError={onError}
          />
          <div className={classes.controls}>
            {playButton &&
              (_playing ? <PlayerIcon.Pause onClick={onPause} className={classes.controlButton} /> : <PlayerIcon.Play onClick={onPlay} className={classes.controlButton} />)}
            {volumeButton &&
              (_muted ? (
                <PlayerIcon.SoundOff onClick={onSoundOn} className={classes.controlButton} />
              ) : (
                <PlayerIcon.SoundOn onClick={onSoundOff} className={classes.controlButton} />
              ))}
          </div>
        </div>
        {details && (
          <div className={classes.overlay} onClick={goToVideoDetail ? () => goToVideoDetail(video.id) : undefined}>
            <p className={classes.title}>
              <span>{video.title}</span>{' '}
            </p>
          </div>
        )}
        {details && (
          <div className={classes.details}>
            <span className={classes.detailTitle}>{video.title}</span>
            <span className={classes.detailDescription}>{video.description}</span>
          </div>
        )}
      </div>
      {/*)}*/}
    </div>
  );
};

export default withStyles(styles)(ResponsiveVideo);
