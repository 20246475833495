import { white } from '../../common/colors';

export default theme => ({
  root: {
    minHeight: '90vh'
  },
  header: {
    height: 485,
    backgroundImage: 'url(https://hdtv-prod.s3.amazonaws.com/public/images/cabin-fever_t20_oolrle.jpg)',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '@media (max-width: 769px)': {
      height: 350
    },
    '@media (max-width: 569px)': {
      height: 300
    },
    '& h1': {
      color: '#FFF',
      textAlign: 'center',
      '@media (min-width: 769px)': {
        fontSize: '2.5em'
      }
    },
    '& p': {
      color: '#FFF',
      width: '50%',
      textAlign: 'center',
      lineHeight: theme.spacing(0.2),
      '@media (max-width: 769px)': {
        width: '75%',
        padding: 0,
        margin: 0
      },
      '@media (max-width: 569px)': {
        width: '90%',
        padding: 0,
        margin: 0
      },
      '@media (min-width: 769px)': {
        fontSize: '1.2em'
      }
    }
  },
  content: {
    padding: 15,
    '& h1': {
      textAlign: 'center',
      fontWeight: 400
    },
    '& .MuiCollapse-entered': {
      width: '100%',
      minHeight: '30vh !important',
      '& .MuiCollapse-wrapper': {
        height: '100%',
        '&. MuiCollapse-wrapperInner': {
          height: '100%'
        }
      }
    },
    '@media (max-width: 569px)': {
      '& h1': {
        fontSize: '1.7em'
      }
    }
  },
  section: {
    minHeight: 350,
    paddingBottom: 60,
    '& img': {
      width: '100%'
    },
    '& .MuiSvgIcon-root': {
      color: '#649FCB',
      height: 16
    },
    '& .MuiTypography-caption': {
      marginBottom: 15,
      display: 'inline-block'
    },
    '& p': {
      marginBottom: 5
    },
    '& .applyNow': {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 20,
      minHeight: 350,
      '& h1': {
        color: white,
        fontSize: theme.spacing(3),
        fontWeight: 800
      }
    }
  },
  complete: {
    minHeight: '60vh',
    maxWidth: 900,
    margin: '70px auto 0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& .completeContent': {
      marginBottom: 50
    }
  },
  inputSection: {
    width: 569,
    marginBottom: 10,
    '@media (max-width: 569px)': {
      width: '100%'
    }
  }
});
