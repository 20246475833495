import { useState, useEffect } from 'react';

export default function useVideosFilter(videos, category) {
  const [_videos, setVideos] = useState(null);

  useEffect(() => {
    if (videos) {
      if (!category || category === 'All') {
        return setVideos(videos);
      }

      if (category === 'Other') {
        return setVideos(videos.filter(item => !item.category));
      }

      setVideos(videos.filter(item => item.category && item.category.name === category));
    }
  }, [videos, category]);

  return _videos;
}
