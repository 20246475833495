import React from 'react';
import IconArrowDown from '../Icons/IconArrowDown';
import styled from 'styled-components';

const SlideButtonMain = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 55px;
  background: rgba(0, 0, 0, 0.5);
  border: 0;
  outline: 0;
  padding: 0;
  margin: 40px 0;
  z-index: 4;
  cursor: pointer;

  span {
    width: 25px;
    color: #fff;
    display: block;
    margin: 0 auto;
  }
  ${props =>
    props.type === 'next'
      ? `right: 0;
        span {
          transform: rotateZ(-90deg);
        }`
      : `left: 0;
      span {
        transform: rotateZ(90deg);
      }`};
`;

const SlideButton = ({ onClick, type }) => {
  return (
    <SlideButtonMain type={type} onClick={onClick}>
      <span>
        <IconArrowDown />
      </span>
    </SlideButtonMain>
  );
};
export default SlideButton;
