import { API, graphqlOperation } from 'aws-amplify';
import { updateDesigner } from '../graphql/mutations';
import { getDesigner } from '../graphql/queries';

export const updateDesignerData = async (id, data, type, payload) => {
  try {
    let res = await API.graphql(graphqlOperation(getDesigner, { id }));
    if (res && res.data.getDesigner.id) {
      if (payload) {
        res = await API.graphql(graphqlOperation(updateDesigner, { input: { id, ...payload } }));
      } else if (res.data.getDesigner[type] && Array.isArray(res.data.getDesigner[type])) {
        res = await API.graphql(
          graphqlOperation(updateDesigner, {
            input: {
              id: id,
              [type]: [...res.data.getDesigner[type], ...data]
            }
          })
        );
      } else {
        res = await API.graphql(graphqlOperation(updateDesigner, { input: { id, [type]: data } }));
      }
      return res;
    }
  } catch (error) {
    console.log(error);
    throw new Error(error);
  }
};
