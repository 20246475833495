import React, { useState } from 'react';
import { withStyles, List, ListItem, Grid, Link, Button, Typography, useMediaQuery } from '@material-ui/core';
import { useActions } from 'easy-peasy';

import ProductModal from '../../components/ProductModal';
import ProductModalMobile from '../../components/ProductModalMobile';
import { Spinner } from '../../components/Cart/components/Button';
import { currencyFormat } from '../../utils';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  buyAgainButton: {
    marginTop: theme.spacing(2)
  },
  image: {
    width: '100%',
    maxHeight: 200,
    objectFit: 'cover',
    '@media (max-width: 599px)': {
      maxHeight: 150
    }
  },
  productContainer: {
    lineHeight: '3rem'
  },
  pointerCursor: {
    cursor: 'pointer'
  }
});

const OrderContent = ({ items, classes }) => {
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);

  const {
    cart: { addToCart },
    modal: { goToCart }
  } = useActions(({ cart, modal }) => ({ cart, modal }));

  const onBuyAgain = ({ sku: { product, ...restSku } }) => () => {
    product.sku = restSku;
    addToCart(product);
    goToCart();
  };

  const toggleModal = (product = null) => {
    setOpen(!open);
  };

  const onProductModalOpen = ({ sku: { product, ...restSku } }) => () => {
    product.sku = restSku;

    toggleModal(product);
  };

  return items ? (
    <List className={classes.root}>
      {items.map((item, idx) => {
        console.log(item);
        const {
          sku: { product }
        } = item.sku ? item : { sku: {} };
        return product ? (
          <React.Fragment key={product.id}>
            <ListItem key={idx.toString()} alignItems="flex-start">
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <img className={classes.image} alt={product.name} src={product.images[0]} />
                </Grid>
                <Grid item xs={8}>
                  <Grid item xs={12}>
                    <Link className={classes.pointerCursor} onClick={onProductModalOpen(item)}>
                      {item.description}
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Quantity: {item.quantity}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Paid: {currencyFormat(item.amount)}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant={'outlined'} color={'primary'} onClick={onBuyAgain(item)} className={classes.buyAgainButton}>
                      Buy Again
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {product.id &&
                (!mobile ? (
                  <ProductModal product={product} open={open} toggleModal={toggleModal} />
                ) : (
                  <ProductModalMobile product={product} open={open} toggleModal={toggleModal} />
                ))}
            </ListItem>
          </React.Fragment>
        ) : (
          <Typography key={idx.toString()} variant={'caption'}>
            Product no longer available!
          </Typography>
        );
      })}
    </List>
  ) : (
    <Spinner />
  );
};

export default withStyles(styles)(OrderContent);
