import React, { useState } from 'react';
import ShowDetailsButton from '../../components/Slider/ShowDetailsButton';
import Mark from '../../components/Slider/Mark';
import styled from 'styled-components';
import { Typography, withStyles } from '@material-ui/core';
import ResponsiveVideo from '../../components/ResponsiveVideo';

const ItemMain = styled.div`
  flex: 0 0 19.7%;
  transition: transform 300ms ease 100ms;
  margin: 15px 5px;
  position: relative;
  cursor: pointer;

  img {
    height: 100%;
    width: 100%;
    vertical-align: top;
    border-radius: 2px;
  }
`;

const ThumbnailWrapper = styled.div`
  position: relative;
`;

const ContentDescription = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  min-height: 20px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.2);

  p {
    color: white;
  }
`;

const RelatedVideoComponent = ({ video, currentSlide, onSelectSlide, goToVideoDetail }) => {
  const [isHovering, setIsHovering] = useState(false);

  const onSlideHover = video => {
    setIsHovering(true);
    onSelectSlide(video);
  };

  const onSlideOut = () => {
    setIsHovering(false);
    onSelectSlide(null);
  };

  const renderContent = (onSelectSlide, currentSlide) => {
    return (
      <ThumbnailWrapper>
        {isHovering && currentSlide && currentSlide.id === video.id ? (
          <ResponsiveVideo videoId={video.id} controls={false} playing muted video />
        ) : (
          <img src={video.thumbnail} alt="" />
        )}
        <ContentDescription>
          <Typography>{video.title}</Typography>
        </ContentDescription>
      </ThumbnailWrapper>
    );
  };

  return (
    <ItemMain
      onMouseEnter={() => {
        setIsHovering(true);
        onSlideHover(video);
      }}
      onClick={() => {
        goToVideoDetail(video.id);
      }}
      onMouseLeave={() => onSlideOut()}
    >
      {renderContent(onSelectSlide, currentSlide)}
      <ShowDetailsButton onClick={() => onSelectSlide(video)} />
      {isHovering && currentSlide && currentSlide.id === video.id && <Mark />}
    </ItemMain>
  );
};

export default withStyles(theme => ({}))(RelatedVideoComponent);
