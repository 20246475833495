import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
  useMediaQuery,
  withStyles,
  Snackbar,
  Typography,
  LinearProgress
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import ReactPlayer from 'react-player';
import { DropzoneArea } from 'material-ui-dropzone';

// graphql stuff
import { useApolloClient } from 'react-apollo-hooks';
import gql from 'graphql-tag';

import { listVideos } from '../../../../../../../../graphql/queries';
import { createPendingVideo } from '../../../../../../../../graphql/mutations';

// utilities
import useCategories from '../../../../../../../../hooks/useCategories';
import useShows from '../../../../../../../../hooks/useShows';
import { uploadFile } from '../../../../../../../../services/storage';
import ProgressBar from '../../../../../../../../components/ProgressBar';
import styles from './styles';

const NewVideoModal = ({ classes, open, video, onClose, designerId }) => {
  const categories = useCategories();
  const shows = useShows();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const client = useApolloClient();
  const [snackbar, setSnackbar] = useState({ open: false });
  const [state, setState] = useState({});
  const [file, setFile] = useState([]);
  const [status, setStatus] = useState(null);
  const [loaded, setLoaded] = useState(null);
  const [objectUrl, setObjectUrl] = useState(null);

  useEffect(() => {
    (async function() {
      await client.query({
        query: gql(listVideos),
        variables: {
          filter: {
            src_video: {
              contains: 'public'
            }
          }
        },
        fetchPolicy: 'no-cache'
      });
    })();
  }, [client]);

  const onSubmit = async () => {
    const { title, description, category, show, season, episode, duration } = state;

    if (!state.title || state.title.length === 0) {
      return openSnackbar('Title is required');
    }

    if (!state.description || state.description.length === 0) {
      return openSnackbar('Description is required');
    }

    if (!state.category || state.category.length === 0) {
      return openSnackbar('Category is required');
    }

    if (!state.show || state.show.length === 0) {
      return openSnackbar('Show is required');
    }

    if (!state.season || state.season.length === 0) {
      return openSnackbar('Season is required');
    }

    if (!state.episode || state.episode.length === 0) {
      return openSnackbar('Episode is required');
    }

    if (!state.duration || state.duration.length === 0) {
      return openSnackbar('Duration is required');
    }

    if (!file || file.length === 0) {
      return openSnackbar('No file added');
    }

    try {
      setStatus('Uploading');
      let res = await uploadFile(file[0], progress => setLoaded(progress), true);

      await client.query({
        query: gql(listVideos),
        variables: {
          limit: 1000,
          filter: {
            src_video: {
              contains: res.key
            }
          }
        },
        fetchPolicy: 'no-cache'
      });

      setLoaded(null);
      setStatus('Saving');

      const payload = {
        title,
        description,
        status: 'pending',
        src_video: res.key,
        pendingVideoCategoryId: category,
        pendingVideoShowId: show,
        pendingVideoDesignerId: designerId,
        season,
        episode,
        duration
      };

      res = await client.mutate({
        mutation: gql(createPendingVideo),
        variables: { input: payload }
      });

      setStatus(null);
      openSnackbar('Successfully submitted the video');
      onClose();
    } catch (e) {
      setStatus(null);
      setLoaded(null);
      openSnackbar('Something went wrong! Please try again in a little bit!');
      onClose();
      throw new Error(e);
    }
  };

  const onCategorySelectChange = e => {
    setState({ ...state, category: e.target.value });
  };

  const onShowSelectChange = e => {
    setState({ ...state, show: e.target.value });
  };

  const onFileChange = files => {
    setFile(files);
    setObjectUrl(URL.createObjectURL(files[0]));
  };

  const openSnackbar = message => {
    setSnackbar({ open: true, message });
  };

  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <DialogTitle>{video ? 'Edit Video' : 'Add New Video'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={3} className={classes.dialogComponent}>
          <Grid item xs={12}>
            <Typography variant={'h5'}>Warning: once submitted, video info will not be able to be changed</Typography>
          </Grid>
          <Grid item xs={mobile ? 12 : 6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  id="title"
                  fullWidth
                  label={'Title'}
                  className={classes.textField}
                  margin="normal"
                  variant={'outlined'}
                  value={state.title}
                  InputLabelProps={
                    state.title && state.title.length > 0
                      ? {
                          shrink: true
                        }
                      : {}
                  }
                  onChange={e => setState({ ...state, title: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="description"
                  fullWidth
                  label={'Description'}
                  className={classes.textField}
                  multiline
                  rows={15}
                  InputLabelProps={
                    state.description && state.description.length > 0
                      ? {
                          shrink: true
                        }
                      : {}
                  }
                  margin="normal"
                  variant={'outlined'}
                  value={state.description}
                  onChange={e => setState({ ...state, description: e.target.value })}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined" required className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">Category</InputLabel>
                  <Select variant={'outlined'} id="demo-simple-select-outlined" value={state.category ? state.category : ''} onChange={onCategorySelectChange}>
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {categories &&
                      categories.map(category => {
                        return (
                          <MenuItem key={category.id} value={category.id}>
                            {category.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined" required className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">Show</InputLabel>
                  <Select variant={'outlined'} id="demo-simple-select-outlined" value={state.show ? state.show : ''} onChange={onShowSelectChange}>
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {shows &&
                      shows.map(show => {
                        return (
                          <MenuItem key={show.id} value={show.id}>
                            {show.title}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="title"
                  fullWidth
                  label={'Season'}
                  className={classes.textField}
                  margin="normal"
                  variant={'outlined'}
                  value={state.season}
                  type={'number'}
                  InputLabelProps={
                    state.season && state.season.length > 0
                      ? {
                          shrink: true
                        }
                      : {}
                  }
                  onChange={e => setState({ ...state, season: e.target.value })}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="title"
                  fullWidth
                  label={'Episode'}
                  className={classes.textField}
                  type={'number'}
                  margin="normal"
                  variant={'outlined'}
                  value={state.episode}
                  InputLabelProps={
                    state.episode && state.episode.length > 0
                      ? {
                          shrink: true
                        }
                      : {}
                  }
                  onChange={e => setState({ ...state, episode: e.target.value })}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="duration"
                  fullWidth
                  label={'Duration'}
                  className={classes.textField}
                  margin="normal"
                  variant={'outlined'}
                  value={state.duration}
                  type={'number'}
                  InputLabelProps={
                    state.duration && state.duration.length > 0
                      ? {
                          shrink: true
                        }
                      : {}
                  }
                  onChange={e => setState({ ...state, duration: e.target.value })}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={mobile ? 12 : 6}>
            {file.length > 0 && (
              <>
                <Grid container spacing={1} justify={'flex-end'}>
                  <Button
                    onClick={() => {
                      setFile([]);
                      setObjectUrl(null);
                    }}
                  >
                    <Close />
                  </Button>
                </Grid>
                <ReactPlayer style={{ backgroundColor: '#000000' }} url={objectUrl} progressInterval={1000} playsinline controls height={'100%'} width={'100%'} />
              </>
            )}
            {file.length === 0 && (
              <DropzoneArea
                filesLimit={1}
                maxFileSize={1000000000}
                onChange={onFileChange}
                acceptedFiles={['video/*']}
                showFileNames
                dropzoneText={'Drag and drop a video file here or click'}
              />
            )}
          </Grid>
          <Snackbar open={snackbar.open} message={snackbar.message} autoHideDuration={3000} onClose={() => setSnackbar({ open: false })} />
          <Dialog open={!!status}>
            <DialogTitle>
              <Typography>{status}...</Typography>
            </DialogTitle>
            <DialogContent>
              {status === 'Uploading' && loaded && <ProgressBar progress={loaded} />}
              {status === 'Saving' && <LinearProgress />}
            </DialogContent>
          </Dialog>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        {!video ? <Button onClick={onSubmit}>Add</Button> : <Button>Save</Button>}
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(NewVideoModal);
