import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, Button, TextField, FormControl, MenuItem, InputLabel, Select, Grid, Typography, Dialog, DialogContent, DialogActions } from '@material-ui/core';

//graphql
import { useMutation } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { updatePost } from 'graphql/mutations';
import { createPost } from 'graphql/mutations';
import { createPostEditor } from 'graphql/mutations';

//service
import { removeFile, uploadFile } from '../../../../../../../../../../services/storage';

// Shared components
import { Portlet, PortletHeader, PortletLabel, PortletContent, PortletFooter } from 'components';

// Component styles
import styles from './styles';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Spinner } from '../../../../../../../../../../components/Cart/components/Button';

const UpdatePostQuery = gql`
  ${updatePost}
`;
const CreatePostQuery = gql`
  ${createPost}
`;
const CreatePostEditorQuery = gql(createPostEditor);

const quillOptions = {
  modules: {
    clipboard: {},
    toolbar: [
      [{ header: [1, 2, 3, false] }], // custom button values
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],
      ['clean'],
      ['blockquote', 'link', 'image', 'code-block', 'video']
    ]
  },
  placeholder: 'Compose an epic...'
};

const PostDetails = ({ id, onClose, user, ...props }) => {
  const [post, setPost] = useState({ ...props.post });
  const { classes, className } = props;
  const rootClassName = classNames(classes.root, className);
  const [create, setCreate] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [loaded, setLoaded] = useState(null);
  const [error, setError] = useState(null);

  const updatePost = useMutation(UpdatePostQuery);
  const createPost = useMutation(CreatePostQuery);
  const createPostEditor = useMutation(CreatePostEditorQuery);

  const allowedFields = ['id', 'title', 'description', 'content', 'status', 'thumbnail', 'imageFile'];

  useEffect(() => {
    if (!id && !props.post) {
      setCreate(true);
    }
  }, [id, props.post, user]);

  const handleSubmit = async () => {
    let file = null;
    try {
      setDisabled(true);

      const input = Object.keys(post).reduce((object, key) => {
        if (allowedFields.includes(key) && post[key] !== null) {
          object[key] = post[key];
        }
        return object;
      }, {});

      if (post.imageFile) {
        file = await uploadFile(post.imageFile, progress => setLoaded(progress));

        await removeFile(post.thumbnail);
        input.thumbnail = file.key;
        setLoaded(null);
        delete input.imageFile;
      }

      if (!create) {
        input.id = post.id;
      }

      const payload = {
        variables: {
          input: input
        }
      };

      let data = await (create ? createPost(payload) : updatePost(payload));

      if (create) {
        await createPostEditor({
          variables: {
            input: {
              postEditorPostId: data.data.createPost.id,
              postEditorEditorId: user.designer.id
            }
          }
        });
      }
      onClose();
    } catch (e) {
      setLoaded(null);
      setError('No permission granted. Contact administrator for details');
      if (file) removeFile(file.key);
    }
  };

  const onEditorChange = value => {
    setPost({ ...post, content: value });
  };

  const onChange = (event, name) => {
    if (allowedFields.includes(name)) {
      if (event.target.files && event.target.files.length > 0) {
        setPost({ ...post, [name]: event.target.files[0] });
      } else {
        setPost({ ...post, [name]: event.target.value });
      }
    }
  };

  return (
    <>
      <Portlet className={rootClassName}>
        <PortletHeader>
          <PortletLabel title={post.title ? post.title : 'Title'} />
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="status-simple">Status</InputLabel>
            <Select
              value={post.status ? post.status : 'draft'}
              onChange={e => onChange(e, 'status')}
              inputProps={{
                name: 'status',
                id: 'status-simple'
              }}
            >
              <MenuItem value="draft">Draft</MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
            </Select>
          </FormControl>
        </PortletHeader>
        <PortletContent>
          <form className={classes.form}>
            <TextField
              className={classes.textField}
              label="Title"
              name="title"
              onChange={event => onChange(event, 'title')}
              type="text"
              value={post.title ? post.title : ''}
              variant="standard"
            />
            <TextField
              className={classes.textField}
              label="Description"
              name="description"
              onChange={event => onChange(event, 'description')}
              type="text"
              value={post.description ? post.description : ''}
              variant="standard"
              multiline={true}
              rows="5"
            />
            <Grid container spacing={3}>
              {post.thumbnail && !post.imageFile && (
                <Grid item md={6} sm={12} xs={12}>
                  <img src={'https://hdtv-prod.s3.amazonaws.com/public/' + post.thumbnail} className={classes.img} alt={'thumbnail'} />
                </Grid>
              )}
              <Grid item md={post.thumbnail && !post.imageFile ? 6 : 12} sm={12} xs={12}>
                <input type={'file'} accept="image/*" onChange={e => onChange(e, 'imageFile')} />
              </Grid>
            </Grid>
            <div style={{ height: '600px' }}>
              <ReactQuill value={post.content ? post.content : ''} onChange={onEditorChange} style={{ height: '90%' }} {...quillOptions} />
            </div>
          </form>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button color="primary" variant="outlined" onClick={handleSubmit} disabled={disabled || !post.thumbnail}>
            Save
          </Button>
          <Button color="primary" variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          {!post.thumbnail && <Typography style={{ color: 'red' }}>Please fill out info and upload a thumbnail</Typography>}
        </PortletFooter>
      </Portlet>
      <Dialog open={disabled}>
        <DialogContent>
          <Grid container justify={'center'} alignItems={'center'} direction={'column'}>
            {error && <Typography>{error}</Typography>}
            {!error && (
              <>
                <Typography>{loaded ? 'Uploading Image...' : 'Saving...'}</Typography>
                <Spinner />
              </>
            )}
          </Grid>
        </DialogContent>
        {error && (
          <DialogActions>
            <Button
              onClick={() => {
                setDisabled(false);
                setError(null);
                onClose();
              }}
            >
              Close
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

PostDetails.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PostDetails);
