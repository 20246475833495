import React, { useEffect, useState } from 'react';

import { Grid, Avatar, withStyles, Button, Typography, useMediaQuery, Box, Paper, Tabs, Tab, LinearProgress, GridList, GridListTile, Link, Chip } from '@material-ui/core';

import ReactPlayer from 'react-player';

import styles from './styles';
import { ArrowBackIos } from '@material-ui/icons';
import SubscribeButton from '../SubscribeButton';
import Portlet from '../Portlet';
import PortletHeader from '../PortletHeader';
import PortletContent from '../PortletContent';
import MediaPreviewModal from '../MediaPreviewModal';
import useDesignerVideos from '../../hooks/useDesignerVideos';
import ResponsiveVideo from '../ResponsiveVideo';
import { isEmailAddress } from '../../utils';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { getDesigner } from '../../graphql/custom-queries';
import { useActions, useStore } from 'easy-peasy';

const DesignerProfile = ({ classes, draft = false, history, designer: _designer = {}, onClose, ...props }) => {
  const [designer, setDesigner] = useState({});
  const videos = useDesignerVideos(designer.id);
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [viewImage, setViewImage] = useState({ open: false, src: '' });
  const [tabValue, setTabValue] = useState(0);
  const cols = mobile ? 1 : 3;
  const [profile, setProfile] = useState(null);
  const user = useStore(state => state.user.user);
  const { goToLogin } = useActions(actions => actions.modal);

  const { data, error } = useQuery(gql(getDesigner), { variables: { id: props.match ? props.match.params.id : _designer.id } });

  useEffect(() => {
    if (error) throw error;
  }, [error]);

  React.useEffect(() => {
    if (_designer.id) {
      setDesigner(_designer);
    }

    if (data.getDesigner) {
      setDesigner(data.getDesigner);
    }
  }, [data, _designer]);

  React.useEffect(() => {
    if (designer.id) setProfile(draft ? designer.draft_profile : designer.profile);
  }, [designer]);

  React.useEffect(() => {}, []);

  const goToVideoDetail = id => () => {
    user && user.id ? history.push('/videos/' + id) : goToLogin();
  };

  const renderHomeTab = () => {
    return (
      <div className={classes.tab}>
        {profile && profile.feature_video && profile.feature_video.trim() && (
          <Portlet style={{ width: '100%', padding: '1% 5%', boxSizing: 'border-box' }}>
            <PortletContent>
              <div>
                <ReactPlayer
                  className={'featureVideo'}
                  height={'100%'}
                  width={'100%'}
                  playsinline
                  playing
                  controls
                  url={`https://hdtv-prod.s3.amazonaws.com/public/${profile.feature_video}`}
                />
              </div>
            </PortletContent>
          </Portlet>
        )}
        <Portlet elevation={3} className={'section'}>
          <PortletHeader>
            <Typography variant={'h6'}>Biography: </Typography>
          </PortletHeader>
          <PortletContent>
            <Box textAlign={'left'}>
              <Typography variant={'body2'}>
                {draft ? (profile && profile.bio && profile.bio.trim() ? profile.bio : 'No content') : designer.bio && designer.bio.trim() ? designer.bio : 'No content'}
              </Typography>
            </Box>
          </PortletContent>
        </Portlet>
      </div>
    );
  };

  const renderVideos = _videos =>
    !_videos ? (
      <LinearProgress />
    ) : _videos.length === 0 ? (
      <Box textAlign={'center'}>
        <Typography variant={'caption'}>No content found</Typography>
      </Box>
    ) : (
      <GridList cellHeight={'auto'} cols={mobile ? 1 : cols} className={mobile ? {} : classes.videosHolder}>
        {_videos.map(x => {
          return (
            <GridListTile key={x.id}>
              <ResponsiveVideo videoId={x.id} date={x.createdAt} goToVideoDetail={goToVideoDetail(x.id)} details />
            </GridListTile>
          );
        })}
      </GridList>
    );

  const renderAboutTab = () => (
    <div className={classes.tab}>
      <Portlet className={'section'}>
        <PortletHeader>
          <Typography variant={'h6'}>Available for</Typography>
        </PortletHeader>
        <PortletContent>{profile && profile.availables && profile.availables.map(item => <Chip key={item} label={item} />)}</PortletContent>
      </Portlet>
      {!designer.pro && (
        <Portlet className={'section'}>
          <PortletHeader>
            <Typography variant={'h6'}>Certifications</Typography>
          </PortletHeader>
          <PortletContent>
            {profile && profile.certifications && profile.certifications.length > 0 ? (
              profile.certifications.map(item => <Chip key={item} label={item} />)
            ) : (
              <Typography variant={'caption'}>No certifications</Typography>
            )}
          </PortletContent>
        </Portlet>
      )}
      {renderLinks && (
        <Portlet className={'section'}>
          <PortletHeader>
            <Typography variant={'h6'}>Links </Typography>
          </PortletHeader>
          <PortletContent>{renderLinks()}</PortletContent>
        </Portlet>
      )}
      {/*{profile.allow_contact_email && */}
      <Portlet className={'section'}>
        <PortletHeader>
          <Typography variant={'h6'}>Contact</Typography>
        </PortletHeader>
        <PortletContent>
          <Box component={'span'}>
            <Typography variant={'body1'}>Email:</Typography>
            <Typography variant={'caption'}>{designer.email}</Typography>
          </Box>
          <Box component={'span'}>
            <Typography variant={'body1'}>Phone:</Typography>
            <Typography variant={'caption'}>{designer.phone}</Typography>
          </Box>
          {profile && (
            <>
              {profile.company_name && profile.company_name.trim() && (
                <Box component={'span'}>
                  <Typography variant={'body1'}>Company:</Typography>
                  <Typography variant={'caption'}>{profile.company_name}</Typography>
                </Box>
              )}
              {profile.address_line1 && profile.address_line1.trim() && (
                <Box component={'span'}>
                  <Typography variant={'body1'}>Address:</Typography>
                  <Typography variant={'caption'}>{profile.address_line1}</Typography>
                  <br />
                  {profile.address_line2 && profile.address_line2.trim() && <Typography variant={'caption'}>{profile.address_line2}</Typography>}
                </Box>
              )}
              {profile.city && profile.city.trim() && (
                <Box component={'span'}>
                  <Typography variant={'body1'}>City:</Typography>
                  <Typography variant={'caption'}>{profile.city}</Typography>
                </Box>
              )}

              {profile.state && profile.state.trim() && (
                <Box component={'span'}>
                  <Typography variant={'body1'}>State:</Typography>
                  <Typography variant={'caption'}>{profile.state}</Typography>
                </Box>
              )}

              {profile.zip_code && (
                <Box component={'span'}>
                  <Typography variant={'body1'}>Zip code:</Typography>
                  <Typography variant={'caption'}>{profile.zip_code}</Typography>
                </Box>
              )}

              {profile.country && profile.country.trim() && (
                <Box component={'span'}>
                  <Typography variant={'body1'}>Country:</Typography>
                  <Typography variant={'caption'}>{profile.country}</Typography>
                </Box>
              )}

              {profile.website_url && profile.website_url.trim() && (
                <Box component={'span'}>
                  <Typography variant={'body1'}>Website:</Typography>
                  <Typography variant={'caption'}>{profile.website_url}</Typography>
                </Box>
              )}
            </>
          )}
        </PortletContent>
      </Portlet>
      {/*}*/}
    </div>
  );

  const renderTab = () => {
    return (
      <Grid item xs={12} container style={{ minHeight: '60vh' }}>
        <Grid item xs={12}>
          {/*Home Tab*/}
          {tabValue === 0 && renderHomeTab()}
          {/*Videos Tab*/}
          {tabValue === 1 && (
            <Paper elevation={3} style={{ width: '100%', minHeight: 300, padding: 10 }}>
              {renderVideos(videos)}
            </Paper>
          )}
          {/*About Tab*/}
          {tabValue === 2 && renderAboutTab()}
        </Grid>
      </Grid>
    );
  };

  const renderLinks = () => {
    if (
      !profile ||
      ((!profile.facebook_handle || !profile.facebook_handle.trim()) &&
        (!profile.twitter_handle || !profile.twitter_handle.trim()) &&
        (!profile.youtube_handle || !profile.youtube_handle.trim()) &&
        (!profile.pinterest_handle || !profile.pinterest_handle.trim()) &&
        (!profile.instagram_handle || !profile.instagram_handle.trim()) &&
        (!profile.linkedin_url || !profile.linkedin_url.trim()) &&
        (!profile.blog_url || !profile.blog_url.trim()) &&
        (!profile.google_plus_url || !profile.google_plus_url.trim()) &&
        (!profile.houzz_url || !profile.houzz_url.trim()))
    )
      return false;

    return (
      <div className={'links'}>
        {profile.facebook_handle && profile.facebook_handle.trim() && (
          <Link target={'_blank'} href={`https://www.fb.com/${profile.facebook_handle.replace('@', '')}`}>
            <Typography variant={'body2'}>facebook</Typography>
          </Link>
        )}
        {profile.twitter_handle && profile.twitter_handle.trim() && (
          <Link target={'_blank'} href={`https://twitter.com/${profile.twitter_handle.replace('@', '')}`}>
            <Typography variant={'body2'}>twitter</Typography>
          </Link>
        )}
        {profile.youtube_handle && profile.youtube_handle.trim() && (
          <Link target={'_blank'} href={`https://www.youtube.com/${profile.youtube_handle.replace('@', '')}`}>
            <Typography variant={'body2'}>youtube</Typography>
          </Link>
        )}
        {profile.pinterest_handle && profile.pinterest_handle.trim() && (
          <Link target={'_blank'} href={`https://www.pinterest.com/${profile.pinterest_handle.replace('@', '')}`}>
            <Typography variant={'body2'}>pinterest</Typography>
          </Link>
        )}
        {profile.instagram_handle && profile.instagram_handle.trim() && (
          <Link target={'_blank'} href={`https://www.instagram.com/${profile.instagram_handle.replace('@', '')}`}>
            <Typography variant={'body2'}>instagram</Typography>
          </Link>
        )}
        {profile.linkedin_url && profile.linkedin_url.trim() && (
          <Link target={'_blank'} href={profile.linkedin_url}>
            <Typography variant={'body2'}>linkedin</Typography>
          </Link>
        )}
        {profile.houzz_url && profile.houzz_url.trim() && (
          <Link target={'_blank'} href={isEmailAddress(profile.houzz_url) ? `mailto:${profile.houzz_url}` : profile.houzz_url}>
            <Typography variant={'body2'}>houzz</Typography>
          </Link>
        )}
        {profile.google_plus_url && profile.google_plus_url.trim() && (
          <Link target={'_blank'} href={isEmailAddress(profile.google_plus_url) ? `mailto:${profile.google_plus_url}` : profile.google_plus_url}>
            <Typography variant={'body2'}>google plus</Typography>
          </Link>
        )}
        {profile.blog_url && profile.blog_url.trim() && (
          <Link target={'_blank'} href={profile.blog_url}>
            <Typography variant={'body2'}>blog</Typography>
          </Link>
        )}
      </div>
    );
  };

  const renderSocialIcons = () => {
    return profile ? (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 'fit-content', position: 'absolute', bottom: 0 }}>
        <div className={classes.socialIcons}>
          {profile.facebook_handle && profile.facebook_handle.trim() && (
            <Link target={'_blank'} href={`https://www.fb.com/${profile.facebook_handle.replace('@', '')}`}>
              <img alt="facebook" src="/assets/facebook_social_icon.svg" />
            </Link>
          )}
          {profile.twitter_handle && profile.twitter_handle.trim() && (
            <Link target={'_blank'} href={`https://twitter.com/${profile.twitter_handle.replace('@', '')}`}>
              <img alt="twitter" src="/assets/twitter_social_icon.svg" />
            </Link>
          )}
          {profile.youtube_handle && profile.youtube_handle.trim() && (
            <Link target={'_blank'} href={`https://www.youtube.com/${profile.youtube_handle.replace('@', '')}`}>
              <img alt="youtube" src="/assets/youtube.svg" />
            </Link>
          )}
          {profile.instagram_handle && profile.instagram_handle.trim() && (
            <Link target={'_blank'} href={`https://www.instagram.com/${profile.instagram_handle.replace('@', '')}`}>
              <img alt="instagram" src="/assets/instagram.svg" />
            </Link>
          )}
        </div>
      </div>
    ) : null;
  };

  return (
    <Portlet>
      <PortletHeader>
        {_designer.id && (
          <Button onClick={onClose}>
            <ArrowBackIos />
          </Button>
        )}
      </PortletHeader>
      <PortletContent noPadding>
        <div className={classes.root}>
          <Grid container className={classes.portletContent}>
            <Grid
              item
              xs={12}
              className={'header'}
              container
              style={{
                backgroundImage: `url(${
                  profile && profile.cover_url
                    ? `https://hdtv-prod.s3.amazonaws.com/public/${profile.cover_url}`
                    : 'https://images-na.ssl-images-amazon.com/images/S/amazon-cover-photo/default.png'
                })`,
                position: 'relative'
              }}
            >
              <Grid item xs={12} sm={4} container direction={'column'} justify={'center'} alignItems={'center'}>
                <Avatar
                  onClick={() =>
                    setViewImage({
                      open: true,
                      src: designer.photo_url
                        ? `https://hdtv-prod.s3.amazonaws.com/public/${designer.photo_url}`
                        : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'
                    })
                  }
                  className={classes.avatar}
                  size={'large'}
                  variant={'rounded'}
                  srcSet={
                    designer.photo_url
                      ? `https://hdtv-prod.s3.amazonaws.com/public/${designer.photo_url}`
                      : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'
                  }
                />
                <span className={classes.headerTitle}>
                  <Typography variant={'h3'} align={'center'}>
                    {draft ? (profile ? profile.name : '') : designer.name}
                  </Typography>
                  {designer.id && user && <SubscribeButton designer={designer} />}
                </span>
              </Grid>
              {renderSocialIcons()}
            </Grid>
            <div className={'content'}>
              {designer.id ? (
                <Grid item container direction={mobile ? 'column' : 'row'} xs={12} spacing={1}>
                  <Grid item xs={12}>
                    <Tabs value={tabValue} indicatorColor="primary" textColor="primary" onChange={(_, newValue) => setTabValue(newValue)}>
                      <Tab label={'Home'} />
                      <Tab label={'Videos'} />
                      <Tab label={'About'} />
                    </Tabs>
                  </Grid>
                  {renderTab()}
                </Grid>
              ) : (
                <Typography>Loading...</Typography>
              )}
            </div>
          </Grid>
        </div>
      </PortletContent>
      <MediaPreviewModal open={viewImage.open} src={viewImage.src} onClose={() => setViewImage({ open: false, src: '' })} />
    </Portlet>
  );
};

export default withStyles(styles)(DesignerProfile);
