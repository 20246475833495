import React, { useContext, useState } from 'react';

import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import ForgotPassword from './components/ForgotPassword';
import { Auth } from 'aws-amplify';
import { Button, Paper, Toolbar, Typography } from '@material-ui/core';
import { useStore } from 'easy-peasy';
import { css } from 'glamor';

const Authenticator = props => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [currentState, setCurrentState] = useState(props.history.location.state && props.history.location.state.signUp ? 'showSignUp' : 'showSignIn');

  const user = useStore(state => state.user.user);

  const switchState = currentState => {
    setCurrentState(currentState);
  };

  React.useEffect(() => {
    if (user && user.id) {
      props.history.push('/');
    }
  }, []);

  const updateErrorMessage = errorMessage => {
    setErrorMessage(errorMessage);
  };

  const resendSignUp = email => {
    Auth.resendSignUp(email)
      .then(() => {
        updateErrorMessage('Resent code to ' + email);
      })
      .catch(err => {
        console.log('error signing up: ', err);
        updateErrorMessage(err.message);
      });
  };

  const navigateHomePage = () => {
    props.history.push('/');
  };

  return (
    <div style={styles.container}>
      <Toolbar {...css(styles.topBar)}>
        <Button onClick={navigateHomePage}>
          <img {...css(styles.logo)} src="/images/logos/logo.png" alt="Home Design TV" />
        </Button>
      </Toolbar>
      {currentState === 'showSignIn' && (
        <SignIn
          {...props}
          updateErrorMessage={updateErrorMessage}
          resendSignUp={resendSignUp}
          showForgotPassword={() => switchState('showForgotPassword')}
          showSignUp={() => switchState('showSignUp')}
        />
      )}
      {currentState === 'showSignUp' && <SignUp {...props} updateErrorMessage={updateErrorMessage} resendSignUp={resendSignUp} showSignIn={() => switchState('showSignIn')} />}
      {currentState === 'showForgotPassword' && (
        <ForgotPassword switchState={switchState} {...props} updateErrorMessage={updateErrorMessage} showSignIn={() => switchState('showSignIn')} />
      )}
      {errorMessage && (
        <div>
          <Paper style={styles.errorMessage}>
            <Typography variant={'subtitle1'} style={{ color: '#fff' }}>
              {errorMessage}
            </Typography>
          </Paper>
        </div>
      )}
    </div>
  );
};

export default Authenticator;

const styles = {
  linkContainer: {
    marginTop: 30
  },
  container: {
    height: '100vh',
    backgroundColor: 'rgb(52,71,90)',
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.65) 100%)'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  toggle: {
    paddingBottom: '10px',
    cursor: 'pointer',
    marginTop: 10,
    marginBottom: 0,
    borderBottom: '2px solid transparent',
    ':hover': {
      opacity: 0.6
    }
  },
  errorMessage: {
    textAlign: 'center',
    width: 'fit-content',
    margin: 'auto',
    padding: '10px 30px',
    minWidth: 400,
    backgroundColor: '#a3a3a3'
  },
  topBar: {
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    height: '64px',
    fontFamily: 'Montserrat, sans-serif'
  },
  logo: {
    margin: 0,
    textAlign: 'center',
    height: '25px',
    cursor: 'pointer'
  }
};
