import React from 'react';
import { Dialog, IconButton, useMediaQuery, useTheme, withStyles } from '@material-ui/core';

import styles from './styles';
import ReactPlayer from 'react-player';
import { Close } from '@material-ui/icons';

const MediaPreviewModal = ({ classes, open, onClose, type = 'image', src, onDelete }) => {
  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog open={open} fullScreen={fullscreen} onBackdropClick={onClose}>
      {fullscreen && (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
      )}
      {type === 'image' && <img className={classes.img} src={src} alt={src} />}
      {type === 'video' && <ReactPlayer style={{ backgroundColor: '#000000' }} url={src} progressInterval={1000} playsinline controls height={'100%'} width={'100%'} />}
    </Dialog>
  );
};

export default withStyles(styles)(MediaPreviewModal);
