export default theme => ({
  root: {
    padding: theme.spacing(0),
    minHeight: '100vh'
  },
  content: {
    padding: theme.spacing(6),
    paddingTop: theme.spacing(9),
    textAlign: 'center'
  },
  mainContainer: {
    width: '100%',
    display: 'flex'
  },
  videoContainer: {
    width: '50%',
    paddingBottom: theme.spacing(3)
  },
  infoContainer: {
    width: '50%'
  },
  mobileContent: {
    padding: 0,
    paddingTop: theme.spacing(9)
  },
  categoryTitle: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '1.2rem',
    textAlign: 'center',
    padding: theme.spacing(2),
    color: '#A333C8'
  },
  mobileTitle: {
    zIndex: 2,
    position: 'sticky',
    top: '64px',
    background: '#3D0062',
    color: '#ffffff'
  }
});
