import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withStyles } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { LinearProgress, Typography, Table, TableBody, TableFooter, TableCell, TableHead, TableRow, TablePagination, Link } from '@material-ui/core';
import { PostsToolbar } from './components';
import { Portlet, PortletContent } from 'components';
import styles from './styles';

//graphql
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';

const getPosts = gql`
  query ListPosts($filter: ModelPostFilterInput, $limit: Int, $nextToken: String) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        title
        description
        content
        status
        thumbnail
        editors {
          items {
            id
            editor {
              id
            }
          }
          nextToken
        }
        comments {
          items {
            id
            owner
            content
            avatar
            name
            commentPostId
            commentVideoId
            createdAt
            likes
            subs
          }
          nextToken
        }
        createdAt
        likes
        liked
      }
      nextToken
    }
  }
`;

const PostList = props => {
  const { user, classes } = props;
  const [limit] = useState(10000);
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');

  const PostLink = React.forwardRef((props, ref) => <RouterLink innerRef={ref} {...props} />);

  const { data, loading, refetch } = useQuery(getPosts, {
    variables: { limit: limit },
    fetchPolicy: 'cache-first'
  });

  useEffect(() => {
    if (data.listPosts !== null && typeof data.listPosts !== 'undefined') {
      setPosts(data.listPosts.items.filter(post => post.editors.items.length > 0 && post.editors.items.filter(item => item.editor.id === user.designer.id).length > 0));
    }
  }, [data, user.designer.id]);

  useEffect(() => {
    setPage(window.localStorage.getItem('postListPage') === null ? 0 : window.localStorage.getItem('postListPage'));
  }, []);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, posts.length - page * rowsPerPage);

  function handleChangePage(event, newPage) {
    window.localStorage.setItem('postListPage', newPage);
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  const onSearch = event => {
    setPage(0);
    setSearch(event.target.value);
  };

  function filterPosts(q, item) {
    function escapeRegExp(s) {
      return s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
    }
    const words = q
      .split(/\s+/g)
      .map(s => s.trim())
      .filter(s => !!s);
    const hasTrailingSpace = q.endsWith(' ');
    const searchRegex = new RegExp(
      words
        .map((word, i) => {
          if (i + 1 === words.length && !hasTrailingSpace) {
            // The last word - ok with the word being "startswith"-like
            return `(?=.*\\b${escapeRegExp(word)})`;
          } else {
            // Not the last word - expect the whole word exactly
            return `(?=.*\\b${escapeRegExp(word)}\\b)`;
          }
        })
        .join('') + '.+',
      'gi'
    );
    return (
      searchRegex.test(item.id) ||
      searchRegex.test(item.title) ||
      searchRegex.test(item.description) ||
      searchRegex.test(
        moment(item.createdAt.slice(0, 16) + '+0000')
          .tz('America/Chicago')
          .format('llll')
      )
    );
  }

  const onCloseNewPostModal = () => {
    setSelectedPost(null);
  };

  return (
    <div className={classes.root}>
      <PostsToolbar selectedPost={selectedPost} refetch={refetch} user={user} search={onSearch} onClose={onCloseNewPostModal} />
      <div className={classes.content}>
        {loading && (
          <div className={classes.progressWrapper}>
            <LinearProgress />
          </div>
        )}
        {posts.length === 0 && <Typography variant="h6">There are no posts available</Typography>}
        {posts.length > 0 && (
          <Portlet>
            <PortletContent noPadding>
              <PerfectScrollbar>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">GUID</TableCell>
                      <TableCell align="left">Title</TableCell>
                      <TableCell align="left">Desc</TableCell>
                      <TableCell align="left">Upload date</TableCell>
                      <TableCell align="left">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {posts
                      .filter(post => filterPosts(search, post))
                      .sort(function compare(a, b) {
                        var dateA = new Date(a.createdAt.slice(0, 16));
                        var dateB = new Date(b.createdAt.slice(0, 16));
                        return dateB - dateA;
                      })
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map(post => (
                        <TableRow className={classes.tableRow} hover key={post.id}>
                          <TableCell className={classes.tableCell}>
                            <Link component={PostLink} onClick={() => setSelectedPost(post)}>
                              {post.id}
                            </Link>
                          </TableCell>
                          <TableCell className={classes.tableCell}>{post.title}</TableCell>
                          <TableCell className={classes.tableCell}>{post.description}</TableCell>
                          <TableCell className={classes.tableCell}>
                            {moment(post.createdAt.slice(0, 16) + '+0000')
                              .tz('America/Chicago')
                              .format('llll')}
                          </TableCell>
                          <TableCell className={classes.tabbleCell}>{post.status}</TableCell>
                        </TableRow>
                      ))}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 56 * emptyRows }}>
                        <TableCell colSpan={4} />
                      </TableRow>
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        colSpan={4}
                        count={posts.length}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        page={parseInt(page)}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[5, 10, 25]}
                        SelectProps={{
                          inputProps: { 'aria-label': 'Rows per page' },
                          native: true
                        }}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </PerfectScrollbar>
            </PortletContent>
          </Portlet>
        )}
      </div>
    </div>
  );
};

PostList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PostList);
