import React from 'react';
import { useActions, useStore } from 'easy-peasy';

import { PrimaryButton } from '../Cart/components/Button';

function BuyButton({ product, sku, onFinish }) {
  const { addToCart } = useActions(({ cart }) => cart);
  const user = useStore(state => state.user.user);
  const { goToLogin } = useActions(actions => actions.modal);

  function onAddItem() {
    product.sku = sku;
    addToCart(product);
    onFinish();
  }

  return (
    <PrimaryButton className="shopkit-buy-button" onClick={user && user.id ? onAddItem : goToLogin}>
      {user && user.id ? 'Add to Cart' : 'Sign In'}
    </PrimaryButton>
  );
}

export default BuyButton;
