export const listDesigners = `query ListDesigners(
  $filter: ModelDesignerFilterInput
  $limit: Int
  $nextToken: String
) {
  listDesigners(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      posts {
        items {
          id
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      pro
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      profile {
        first_name
        last_name
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
    }
    nextToken
  }
}
`;

export const getProductCategory = `query GetProductCategory($id: ID!) {
  getProductCategory(id: $id) {
    id
    owner
    name
    slug
    image
    isMainCategory
    subCategories {
      items {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        productCategoryMainCategoryId
        products {
          nextToken
        }
      }
      nextToken
    }
    mainCategory {
      id
      owner
      name
      slug
      image
      createdAt
      isMainCategory
      subCategories {
        items {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        nextToken
      }
      mainCategory {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        productCategoryMainCategoryId
        products {
          nextToken
        }
      }
      productCategoryMainCategoryId
      products {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
    }
    products (limit: 1000) {
      items {
        id
        owner
        name
        description
        price
        image
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
        product_id
        meta
        createdAt
        videos {
          nextToken
        }
      }
      nextToken
    }
  }
}`;

export const getDesigner = `query GetDesigner($id: ID!) {
  getDesigner(id: $id) {
    id
    owner
    email
    phone
    name
    photo_url
    bio
    location
    active
    createdAt
    posts (limit: 100000){
      items {
        id
        post {
          id
          owner
          title
          description
          content
          status
          thumbnail
          createdAt
          likes
          liked
        }
        editor {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    tags {
      items {
        id
        tag {
          id
          tag
          type
          owner
        }
        tagsDesignersTagId
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
        tagsDesignersDesignerId
      }
      nextToken
    }
    videos (limit: 100000) {
      items {
        id
        owner
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
      }
      nextToken
    }
    pendingVideos {
      items {
        id
        owner
        title
        description
        status
        createdAt
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        pendingVideoCategoryId
        show {
          id
          owner
          title
          description
          release_date
        }
        pendingVideoShowId
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
        pendingVideoDesignerId
        season
        episode
        duration
      }
      nextToken
    }
    pro
    subs {
      items {
        id
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
        subDesignerId
        stripeId
        stripeSubId
        createdAt
        owner
      }
      nextToken
    }
    profile {
      first_name
      last_name
      name
      company_name
      bio
      title
      address_line1
      address_line2
      city
      state
      zip_code
      country
      website_url
      allow_contact_email
      facebook_handle
      twitter_handle
      youtube_handle
      pinterest_handle
      instagram_handle
      linkedin_url
      blog_url
      google_plus_url
      houzz_url
      feature_video
      availables
      certifications
      photo_url
      cover_url
      status
    }
    draft_profile {
      first_name
      last_name
      name
      company_name
      bio
      title
      address_line1
      address_line2
      city
      state
      zip_code
      country
      website_url
      allow_contact_email
      facebook_handle
      twitter_handle
      youtube_handle
      pinterest_handle
      instagram_handle
      linkedin_url
      blog_url
      google_plus_url
      houzz_url
      feature_video
      availables
      certifications
      photo_url
      cover_url
      status
    }
    projects {
      items {
        id
        title
        description
        images
        videos
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
        projectDesignerId
        owner
      }
      nextToken
    }
  }
}`;

export const getVideo = `query GetVideo($id: ID!) {
  getVideo(id: $id) {
    id
    owner
    title
    description
    status
    url
    thumbnail
    createdAt
    views {
      user
      video
      createdAt
    }
    comments {
      items {
        id
        owner
        content
        avatar
        name
        post {
          id
          owner
          title
          description
          content
          status
          thumbnail
          createdAt
          likes
          liked
        }
        commentPostId
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        commentVideoId
        createdAt
        likes
        subs
      }
      nextToken
    }
    products (limit: 10000) {
      items {
        id
        owner
        product {
          id
          owner
          name
          description
          price
          image
          product_id
          meta
          createdAt
        }
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        timestamp
      }
      nextToken
    }
    category {
      id
      owner
      name
      description
      createdAt
      videos {
        items {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
    }
    designers {
      items {
        id
        owner
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
      }
      nextToken
    }
    show {
      id
      owner
      title
      description
      release_date
      videos {
        items {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
    }
    season
    episode
    release_date
    duration
    likes
    src_video
    tags {
      items {
        id
        tag {
          id
          tag
          owner
        }
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
      }
      nextToken
    }
  }
}
`;
