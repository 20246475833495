import { useState, useEffect } from 'react';
import { useApolloClient } from 'react-apollo-hooks';
import { listPendingVideos } from '../graphql/queries';
import gql from 'graphql-tag';

import { onCreatePendingVideo } from '../graphql/subscriptions';

export default function usePendingVideos(designer_id, user_id, status) {
  const [videos, setVideos] = useState(null);
  const client = useApolloClient();

  useEffect(() => {
    if (designer_id) {
      (async function() {
        const { data } = await client.query({
          query: gql(listPendingVideos),
          variables: {
            limit: 10000,
            filter: {
              pendingVideoDesignerId: {
                eq: designer_id
              }
            }
          }
        });

        console.log('pending videos: ', data);

        if (!status) {
          setVideos(data.listPendingVideos.items);
        } else {
          setVideos(data.listPendingVideos.items.filter(item => item.status === status));
        }
      })();

      if (user_id) {
        const subcriber = client
          .subscribe({
            query: gql(onCreatePendingVideo),
            variables: { owner: user_id }
          })
          .subscribe(res => {
            setVideos([...videos, res.data.onCreatePendingVideo]);
          });

        return () => subcriber.unsubscribe();
      }
    }
  }, [designer_id, user_id, client]);

  return videos;
}
