import React from 'react';
import { css } from 'glamor';
import { Button, Link, TextField, Typography } from '@material-ui/core';

const ConfirmPage = ({ state, confirmSignUp, resendSignUp, onChange }) => {
  return (
    <div {...css(styles.formContainer)}>
      <h2 {...css(styles.signUpHeader)}>Confirm Sign Up</h2>
      <p>Confirmation Code *</p>
      <TextField
        value={state.authCode}
        variant={'outlined'}
        {...css(styles.input)}
        onChange={evt => onChange('authCode', evt.target.value)}
        {...css(styles.input)}
        placeholder="Confirmation Code"
      />
      <span {...css(styles.actionContainer)}>
        <span>
          <Typography variant={'caption'}>Lost your code? </Typography>
          <Link onClick={() => resendSignUp(state.email)}>
            <Typography variant={'caption'}>Resend Code</Typography>
          </Link>
        </span>
        <Button variant={'contained'} color={'primary'} {...css(styles.button)} onClick={confirmSignUp}>
          <p {...css(styles.buttonText)}>Confirm</p>
        </Button>
      </span>
    </div>
  );
};

const styles = {
  signUpHeader: {
    textAlign: 'left',
    margin: '0px 0px 30px',
    '& h2': {
      fontSize: 18,
      fontWeight: 500,
      marginBottom: 5
    },
    '@media (max-width: 500px)': {
      margin: '0px 0px 15px'
    }
  },
  button: {
    padding: '10px 60px',
    marginTop: 10,
    marginBottom: 10,
    cursor: 'pointer',
    height: 45,
    width: 153
  },
  buttonText: {
    margin: 0,
    color: 'white'
  },
  formContainer: {
    padding: '35px 40px',
    width: 400,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, .2)',
    backgroundColor: '#fff',
    borderRadius: 6,
    '> p': {
      fontSize: 14,
      margin: '0px 0px 8px 0px'
    },
    '@media (max-width: 500px)': {
      width: '100% !important',
      boxSizing: 'border-box',
      padding: '20px 30px'
    }
  },
  input: {
    marginBottom: '20px !important',
    fontSize: '14px',
    '::placeholder': {
      color: 'rgba(0, 0, 0, .3)'
    },
    '@media (max-width: 500px)': {
      marginBottom: '10px !important'
    }
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    '& .MuiTypography-caption': {
      fontSize: 14
    },
    '& a': {
      '> .MuiTypography-caption': {
        color: 'rgb(163, 51, 200)',
        cursor: 'pointer'
      }
    }
  }
};

export default ConfirmPage;
