import React, { useEffect } from 'react';

import { withStyles, Grid, Typography, useTheme, useMediaQuery, Paper, SvgIcon } from '@material-ui/core';
import { CheckOutlined, AttachMoneyTwoTone, FileCopyTwoTone, SpeakerGroupTwoTone } from '@material-ui/icons';

import styles from './styles';
import { SquaredButton } from '../../components/SquaredButton';

const Sponsors = ({ classes, history }) => {
  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const goContactUs = () => {
    window.scrollTo(0, 0);
    history.push('contact-us');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Paper className={'header-content'}>
          <h1>Become a Sponsor</h1>
          <p>Partner with the fasting growing TV network all about home</p>
          <Typography>
            <CheckOutlined />
            Establish direct impact to your target audiences via interactive medium
          </Typography>
          <Typography>
            <CheckOutlined />
            Achieve measurable outcomes over promises
          </Typography>
          <Typography>
            <CheckOutlined />
            Acquire exclusive market research into your target customers
          </Typography>
        </Paper>
      </div>
      <div className={classes.content}>
        <Grid item xs={12} container className={classes.section}>
          <Grid item xs={12} md={4} className={'sub-section'}>
            <SpeakerGroupTwoTone fontSize={'large'} />
            <Typography align={'center'} variant={'body1'}>
              When it comes to live interactions with the customers -- Hands down, HDTV brings you the interactive platform that just enables the exciting possibilities. A deeper
              connection, from your brand to your audience, provided by our recommendation.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} className={'sub-section'}>
            <AttachMoneyTwoTone fontSize={'large'} />
            <Typography align={'center'} variant={'body1'}>
              Want to ensure that you're on the right track of increasing brand exposure or new customer acquisition? With HDTV, the outcome is updated on the fly, including social
              mentions, on-site engagement, and brand preference, directly from the customers.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} className={'sub-section'}>
            <FileCopyTwoTone fontSize={'large'} />
            <Typography align={'center'} variant={'body1'}>
              Knowing your ideal customers' comprehensive perspectives into your products is just one step away. YOUR key marketing questions can be in our next follow up survey:
              keeping you informed with the latest insights!
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.section}>
          <Grid item xs={12} className={'applyNow'} container>
            <h1>Interested in being a sponsor?</h1>
            <Typography variant={'caption'} align={'right'}>
              HDTV works with brands who share our passion for all things home.
            </Typography>
            <SquaredButton size={'large'} onClick={goContactUs} variant={'contained'} color={'secondary'}>
              Become a Sponsor
            </SquaredButton>
          </Grid>
          <div className={'background'} />
        </Grid>
      </div>
    </div>
  );
};

export default withStyles(styles)(Sponsors);
