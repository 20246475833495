export default theme => ({
  img: {
    width: '100%'
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    left: 10
  }
});
