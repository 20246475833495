import { useEffect, useState } from 'react';
import { useApolloClient } from 'react-apollo-hooks';
import gql from 'graphql-tag';

const queryVideos = `query GetDesigner($id: ID!) {
  getDesigner(id: $id) {
    id
    videos(sortDirection:DESC) {
      items {
        video {
            id
            title
            description
            thumbnail
            url
            duration
            status
        }
      }
      nextToken
    }
  }
}
`;

export default function(designer_id) {
  const [videos, setVideos] = useState(null);
  const client = useApolloClient();

  useEffect(() => {
    if (designer_id)
      (async function() {
        const res = await client.query({ query: gql(queryVideos), fetchPolicy: 'network-only', variables: { id: designer_id } });
        if (res.data) {
          setVideos(res.data.getDesigner.videos.items.map(item => ({ ...item.video })).filter(video => video.status === 'active'));
        }
      })();
  }, [designer_id]);

  return videos;
}
