import React, { useRef, useState } from 'react';
import { Button, Collapse, FormControl, Grid, InputLabel, Select, TextField, useMediaQuery, MenuItem, withStyles } from '@material-ui/core';
import useTags from '../../../../hooks/useTags';
import { Spinner } from '../../../../components/Cart/components/Button';
import PhoneInput from 'react-phone-input-2';

const ProjectProInput = ({ onSubmit, classes, setSnackbar, user }) => {
  const [state, setState] = useState({ email: '', password: '', phone_number: '' });
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [service, setService] = useState('');
  const [loading, setLoading] = useState(false);
  const { tags } = useTags('Designer');

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const businessNameRef = useRef();

  const onSaveNames = () => {
    if (firstNameRef.current.value === '') {
      setSnackbar({ open: true, message: 'All fields are required!' });
      return firstNameRef.current.focus();
    }

    if (lastNameRef.current.value === '') {
      setSnackbar({ open: true, message: 'All fields are required!' });
      return lastNameRef.current.focus();
    }

    if (businessNameRef.current.value === '') {
      setSnackbar({ open: true, message: 'All fields are required!' });
      return businessNameRef.current.focus();
    }

    if (!(user && user.id)) {
      if (state.email.length < 5) {
        return setSnackbar({ open: true, message: 'Email is invalid!' });
      }

      if (state.password.length < 5) {
        return setSnackbar({ open: true, message: 'Password must be more than 5 characters!' });
      }

      if (state.phone_number.length < 5) {
        return setSnackbar({ open: true, message: 'Phone number is invalid!' });
      }
    }
    setState({ ...state, first_name: firstNameRef.current.value, last_name: lastNameRef.current.value, name: businessNameRef.current.value });
  };

  const onChange = (key, value) => {
    setState({
      ...state,
      [key]: value
    });
  };

  return (
    <>
      <Collapse in={!state.first_name}>
        <Grid item xs={12} container justify={'center'} alignItems={'center'}>
          <h2>What is your name?</h2>
        </Grid>
        <Grid item xs={12} container justify={'center'} alignItems={'center'} direction={'column'}>
          <div className={classes.section}>
            <FormControl fullWidth={mobile} style={mobile ? null : { width: '50%' }}>
              <InputLabel shrink={true}>First Name</InputLabel>
              <TextField inputRef={firstNameRef} id="first_name" margin="normal" variant="outlined" inputProps={{ 'aria-label': 'bare' }} />
            </FormControl>
            <FormControl fullWidth={mobile} style={mobile ? null : { width: '50%' }}>
              <InputLabel shrink={true}>Last Name</InputLabel>
              <TextField inputRef={lastNameRef} id="last_name" margin="normal" variant="outlined" inputProps={{ 'aria-label': 'bare' }} />
            </FormControl>
          </div>
          <div className={classes.section}>
            <FormControl fullWidth>
              <InputLabel shrink={true}>Business Name</InputLabel>
              <TextField inputRef={businessNameRef} id="business_name" margin="normal" variant="outlined" inputProps={{ 'aria-label': 'bare' }} />
            </FormControl>
          </div>
          {!(user && user.id) && (
            <>
              <div className={classes.section}>
                <FormControl fullWidth>
                  <InputLabel shrink={true}>Email</InputLabel>
                  <TextField
                    value={state.email}
                    disabled={loading}
                    type={'email'}
                    onChange={e => onChange('email', e.target.value)}
                    margin="normal"
                    variant="outlined"
                    inputProps={{ 'aria-label': 'bare' }}
                  />
                </FormControl>
              </div>
              <div className={classes.section}>
                <FormControl fullWidth>
                  <InputLabel shrink={true}>Password</InputLabel>
                  <TextField
                    value={state.password}
                    disabled={loading}
                    type={'password'}
                    onChange={e => onChange('password', e.target.value)}
                    margin="normal"
                    variant="outlined"
                    inputProps={{ 'aria-label': 'bare' }}
                  />
                </FormControl>
              </div>
              <div className={classes.section}>
                <p>Phone Number *</p>
                <PhoneInput
                  inputStyle={{ width: '100%', lineHeight: '1.1875em', height: 56 }}
                  disabled={loading}
                  country={'us'}
                  value={state.phone_number}
                  onChange={phone => onChange('phone_number', phone)}
                />
              </div>
            </>
          )}
          <Button variant={'contained'} size={'large'} color={'primary'} onClick={onSaveNames}>
            Next
          </Button>
        </Grid>
      </Collapse>
      <Collapse in={!!state.first_name}>
        <Grid item xs={12} container justify={'center'} alignItems={'center'}>
          <h2>What is your Primary Service Category?</h2>
        </Grid>
        <Grid item xs={12} container justify={'center'} alignItems={'center'}>
          <div className={classes.section}>
            {tags ? (
              <FormControl variant={'outlined'} fullWidth>
                <InputLabel id="service-label">Select Service</InputLabel>
                <Select
                  disabled={loading}
                  variant={'outlined'}
                  value={service}
                  inputProps={{
                    name: 'Select Service',
                    id: 'select_service'
                  }}
                  onChange={event => setService(event.target.value)}
                >
                  {tags.map(tag => (
                    <MenuItem key={tag.id} value={tag.id}>
                      {tag.tag}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Spinner />
            )}
          </div>
        </Grid>
        <Grid item xs={12} container justify={'center'} alignItems={'center'}>
          <Button
            variant={'contained'}
            color={'primary'}
            size={'large'}
            disabled={service === '' || loading}
            onClick={async () => {
              if (service === '') {
                return setSnackbar({ open: true, message: 'Please choose your primary service' });
              }
              setLoading(true);
              await onSubmit({ ...state, serviceId: service });
              setLoading(false);
            }}
          >
            {loading ? <Spinner /> : 'Apply'}
          </Button>
        </Grid>
      </Collapse>
    </>
  );
};

export default withStyles(theme => ({
  section: {
    width: 569,
    marginBottom: 10,
    '@media (max-width: 569px)': {
      width: '100%'
    }
  }
}))(ProjectProInput);
