export default theme => ({
  descriptionContainer: {
    background: 'linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 40%, rgba(255,255,255,1) 100%)',
    display: 'flex',
    width: '100%',
    height: '100%'
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    '& img': {
      alignSelf: 'center',
      width: '100%'
    }
  },
  productName: {
    fontSize: '2rem !important',
    fontWeight: 200,
    lineHeight: '2rem',
    letterSpacing: '.2rem',
    textTransform: 'uppercase',
    fontFamily: 'Montserrat, sans-serif !important'
  },
  manufacturer: {
    fontSize: '1rem !important',
    fontWeight: 200,
    lineHeight: '1rem',
    letterSpacing: '.2rem',
    textTransform: 'uppercase',
    fontFamily: 'Montserrat, sans-serif !important'
  },
  price: {
    paddingTop: 10,
    fontSize: '3rem',
    lineHeight: '3rem'
  },
  description: {
    paddingTop: 10,
    paddingBottom: 20
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});
