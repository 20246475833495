import { action, thunk, select } from 'easy-peasy';
import { graphqlOperation, API } from 'aws-amplify';
import uuid from 'uuid';
import gql from 'graphql-tag';

import { createCart, createOrder as createGraphqlOrder } from '../../../graphql/mutations';

import { createOrder, payOrder } from '../../../services/stripe';
import { currencyFormat } from '../../../utils';
import { getUser } from '../../../graphql/queries';
import updateUserData from '../../../hooks/useUpdateUser';
import updateCartData from '../../../hooks/useUpdateCart';

export default {
  id: null,
  meta: null,
  items: [],
  client: null,
  cart_id: null,

  isEmpty: select(({ items }) => items.length === 0),

  count: select(({ items }) => items.reduce((sum, { quantity }) => sum + quantity, 0)),

  cartItems: select(({ items }) => items.filter(({ type }) => type === 'cart_item' || type === 'custom_item')),

  promotionItems: select(({ items }) => items.filter(({ type }) => type === 'promotion_item')),

  subTotal: select(({ items }) => {
    let subTotal = 0;
    for (let item of items) {
      subTotal += item.quantity * item.sku.price;
    }
    return currencyFormat(subTotal);
  }),

  setClient: action((state, client) => {
    state.client = client;
  }),

  setCart: action((state, { data, id, meta }) => {
    state.items = data;
    state.cart_id = id;
    state.meta = meta;
  }),

  addToCart: action((state, product, quantity = 1, type = 'cart_item') => {
    let found_prod = state.items.filter(p => p.id === product.id && p.sku.id === product.sku.id);

    if (found_prod.length > 0) {
      found_prod = found_prod[0];
      found_prod.quantity += quantity;
      state.items = [found_prod, ...state.items.filter(p => p.id !== found_prod.id && p.sku.id !== found_prod.sku.id)];
    } else {
      product.type = type;
      product.quantity = quantity;
      state.items = [product, ...state.items];
    }

    updateCartData(state.cart_id, { items: state.items.map(item => JSON.stringify(item)) }).then(res => null);
  }),

  removeItem: action((state, product, quantity = 1) => {
    let found_prod = state.items.filter(p => p.sku.id === product.sku.id);

    if (found_prod.length > 0) {
      found_prod = found_prod[0];

      if (quantity < 0 || found_prod.quantity <= quantity) {
        state.items = state.items.filter(p => p.sku.id !== product.sku.id);
      } else {
        found_prod.quantity -= quantity;
      }

      updateCartData(state.cart_id, { items: state.items.map(item => JSON.stringify(item)) }).then(res => null);
    }
  }),

  deleteCart: action(state => {
    state.items = [];
    updateCartData(state.cart_id, null, { delete: true }).then(res => null);
  }),

  getCart: thunk(async (actions, { user_id, client }, { dispatch, getState }) => {
    try {
      const {
        data: { getUser: user }
      } = await client.query({
        query: gql(getUser),
        variables: { id: user_id }
      });
      if (user.cart) {
        actions.setCart({ data: user.cart.items.map(item => JSON.parse(item)), id: user.cart.id });
      } else {
        const {
          data: {
            createCart: { items, id }
          }
        } = await client.mutate({
          mutation: gql(createCart),
          variables: {
            input: {
              cartUserId: user_id,
              items: []
            }
          }
        });

        await updateUserData(id, 'userCartId');
        actions.setCart({ data: items, id });
      }
    } catch (e) {
      console.warn(e);
    }
  }),

  createOrder: thunk(
    async (actions, { customer: { id, email }, shipping_address: { city, country, county, first_name, last_name, line_1, phone_number, postcode } }, { getState }) => {
      const sku_ids = getState().items.map(p => ({ id: p.sku.id, quantity: p.quantity }));

      try {
        const order = await createOrder(sku_ids, first_name + ' ' + last_name, line_1, city, county, country, postcode, email);

        const payload = {
          id: uuid(),
          total: order.amount,
          order: JSON.stringify(order),
          orderCustomerId: id
        };

        API.graphql(graphqlOperation(createGraphqlOrder, { input: payload }));

        return order;
      } catch (e) {
        console.warn(e);
      }
    }
  ),

  payForOrder: thunk(async (actions, { orderId, token }, { getState }) => {
    try {
      await payOrder(orderId, token);
    } catch (e) {
      console.warn(e);
    }
  }),

  addPromotion: thunk(async (actions, code, { getState }) => {
    try {
    } catch ({ statusCode }) {
      throw new Error('Code expired or invalid');
    }
  })
};
