export const listPosts = `query ListPosts(
  $filter: ModelPostFilterInput
  $limit: Int
  $nextToken: String
) {
  listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      title
      description
      content
      status
      thumbnail
      createdAt
      likes
      editors {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
