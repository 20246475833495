import React, { useContext, useState } from 'react';
import { Grid, Tab, Tabs, withStyles } from '@material-ui/core';

import Profile from './tabs/Profile';
import Portfolio from './tabs/Portfolio';
import VideoUpload from './tabs/VideoUpload';
import styles from './styles';
import Posts from './tabs/Posts/Posts';
import { useStore } from 'easy-peasy';
import UserContext from '../../../../contexts/UserContext';

const TabPanel = ({ value, user, classes, refetchUser }) => {
  return (
    <div className={classes.tabContainer}>
      {value === 0 && <Profile user={user} refetchUser={refetchUser} classes={classes} />}
      {value === 1 && <Portfolio user={user} refetchUser={refetchUser} />}
      {value === 2 && <VideoUpload refetchUser={refetchUser} />}
      {value === 3 && <Posts user={user} refetchUser={refetchUser} />}
    </div>
  );
};

const PublicProfile = ({ classes }) => {
  const [tabValue, setTabValue] = useState(0);
  const user = useStore(state => state.user.user);
  const { updateCurrentUser } = useContext(UserContext);

  const onTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <div className={classes.root}>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Tabs classes={{ indicator: classes.tabIndicator }} variant={'fullWidth'} value={tabValue} onChange={onTabChange} centered>
            <Tab label={'Profile'} />
            <Tab label={'Portfolio'} />
            <Tab label={'Videos'} />
            <Tab label={'Articles'} />
          </Tabs>
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={12}>
          <TabPanel user={user} refetchUser={updateCurrentUser} classes={classes} value={tabValue} />
        </Grid>
      </div>
    </>
  );
};

export default withStyles(styles)(PublicProfile);
