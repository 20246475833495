export default theme => ({
  root: {
    minHeight: '100vh',
    paddingTop: 70,
    '@media (min-width: 1000px)': {
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10)
    },
    '@media (min-width: 600px)': {
      paddingLeft: 10,
      paddingRight: 10
    },
    '@media (max-width: 600px)': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    }
  },
  content: {
    textAlign: 'center'
  },
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  },
  listItem: {
    flexDirection: 'column',
    marginBottom: 30
  }
});
