export default theme => ({
  root: {
    // maxWidth: 1000,
    width: '100%',
    margin: 'auto',
    minHeight: '100vh'
  },
  headerTitle: {
    margin: 'auto',
    display: 'flex',
    alignItems: 'center'
  },
  portletContent: {
    '& .header': {
      height: 300,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    },
    '& .content': {
      padding: 10,
      width: 1000,
      margin: 'auto',
      marginBottom: 5,
      minHeight: '60vh',
      '@media (max-width: 1000px)': {
        width: '100%'
      }
    }
  },
  header: {
    height: theme.spacing(30),
    '& img': {
      width: '100%',
      height: theme.spacing(30),
      objectFit: 'cover'
    }
  },
  avatar: {
    width: theme.spacing(20),
    height: theme.spacing(20),
    top: 25
  },
  caption: {
    color: '#66788A',
    fontSize: 12,
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    letterSpacing: 0.3
  },
  socialIcons: {
    width: 'fit-content',
    position: 'absolute',
    bottom: 20,
    right: '10%',
    alignItems: 'center !important',
    textAlign: 'center !important',
    '& a': {
      padding: '3px',
      opacity: '0.7',
      textDecoration: 'none',
      '& img': {
        maxWidth: '30px',
        height: '30px',
        paddingRight: 8,
        paddingLeft: 8,
        marginLeft: 5,
        marginRight: 5
      },
      '&:hover': {
        opacity: '1',
        transform: 'scale(11)',
        textDecoration: 'none',
        color: theme.palette.primary.main
      }
    },
    '@media (max-width: 599px)': {
      position: 'inherit',
      bottom: 10,
      right: 'auto'
    }
  },
  tab: {
    '& .textInfo': {
      padding: 10
    },
    '& .section': {
      width: '100%',
      height: '100%',
      marginTop: 10
    },
    '& .featureVideo': {
      '& video': {
        outline: 'none'
      }
    },
    '& .links': {
      '& a': {
        padding: '3px',
        opacity: '0.7',
        textDecoration: 'none',
        '& p': {
          color: 'inherit'
        },
        '&:hover': {
          opacity: '1',
          transform: 'scale(11)',
          textDecoration: 'none',
          color: theme.palette.primary.main
        }
      }
    }
  },
  noPadding: {
    padding: 0
  }
});
