export default theme => ({
  root: {
    padding: theme.spacing(0)
  },
  company: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1) * 0.5
  },
  ngFooter: {
    width: '100%',
    borderTop: '1px solid rgb(218, 218, 218)',
    textAlign: 'center',
    paddingTop: '20px',
    paddingBottom: '10px',
    background: 'linear-gradient(0deg, rgba(245, 237, 244, 0.7), rgba(255, 255, 255, 0.88)), url("assets/footer.jpg")',

    '& p': {
      margin: '20px',
      fontSize: '13px'
    },

    '& div': {
      textAlign: 'left',
      '& div': {
        textAlign: 'left',
        padding: '10px',
        '@media (max-width: 769px)': {
          padding: '10px 10%'
        },
        '& img': {
          width: '120px',
          paddingTop: '16px'
        },
        '& h4': {
          fontSize: '16px'
        },
        '& a': {
          cursor: 'pointer',
          fontSize: '14px',
          opacity: '0.5',
          color: 'inherit',
          lineHeight: '24px',
          textDecoration: 'none',
          '&:hover': {
            opacity: '1',
            textDecoration: 'none',
            color: theme.palette.primary.main
          }
        }
      }
    }
  },
  ngFooterBottomContainer: {
    margin: '30px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center !important',
    textAlign: 'center !important',
    flexDirection: 'row',
    '@media (max-width: 469px)': {
      flexDirection: 'column'
    },

    '& div': {
      flex: 1,
      display: 'inline-block',
      alignItems: 'center !important',
      textAlign: 'center !important',
      '@media (max-width: 469px)': {
        maxWidth: '100%'
      }
    },

    flexWrap: 'wrap'
  },
  socialIcons: {
    marginTop: 10,
    alignItems: 'center !important',
    textAlign: 'center !important',
    '& a': {
      padding: '3px',
      opacity: '0.7',
      textDecoration: 'none',
      '& img': {
        // border: '1px solid black',
        // borderRadius: '50%',
        maxWidth: '30px',
        height: '30px',
        paddingRight: 8,
        paddingLeft: 8,
        marginLeft: 5,
        marginRight: 5
      },
      '&:hover': {
        opacity: '1',
        transform: 'scale(11)',
        textDecoration: 'none',
        color: theme.palette.primary.main
      }
    }
  },
  ngMiddleSpacer: {
    '@media (max-width: 869px)': {
      maxWidth: '100%',
      order: '3'
    },
    maxWidth: '100%'
  }
});
