import { useEffect, useReducer } from 'react';
import { getVideo } from '../graphql/custom-queries';
import { useApolloClient } from 'react-apollo-hooks';
import gql from 'graphql-tag';

const initialState = { video: [] };

function reducer(state, action) {
  switch (action.type) {
    case 'set':
      return { video: action.payload };
    default:
      return;
  }
}

export function useVideo(id, policy = 'cache-first') {
  const [state, dispatch] = useReducer(reducer, initialState);
  const client = useApolloClient();

  useEffect(() => {
    const fetchVideoData = async () => {
      client
        .query({
          query: gql`
            ${getVideo}
          `,
          variables: { id: id },
          fetchPolicy: policy
        })
        .then(data => {
          dispatch({
            type: 'set',
            payload: data.data.getVideo
          });
        })
        .catch(error => console.error(error));
    };
    if (id) fetchVideoData();
  }, [client, id, policy]);

  return state.video;
}
