import React, { useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  withStyles,
  useMediaQuery,
  useTheme,
  LinearProgress,
  Snackbar,
  Slide,
  Paper
} from '@material-ui/core';
import { Add, Close } from '@material-ui/icons';

import PortfolioListComponent from './components/PortfolioList';
import NewProjectForm from './components/NewProjectForm';
import { createProject, deleteProject, updateProject } from '../../../../../../graphql/mutations';
import useDesignerProject from '../../../../../../hooks/useDesignerProjects';
import PortfolioDetail from './components/PortfolioDetail';
import { removeFile } from '../../../../../../services/storage';
import styles from './styles';

const Portfolio = ({ classes, user }) => {
  const [dialog, setDialog] = useState({ open: false });
  const [snackbar, setSnackBar] = useState({ open: false });
  const [projectInfo, setProjectInfo] = useState({});
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const projects = useDesignerProject();
  const [selected, setSelected] = useState(null);

  const onAddProject = () => {
    openDialog('New Project');
  };

  const onCreateProject = async () => {
    const { title, description } = projectInfo;
    if (!title || title.length === 0) {
      return openDialog('Invalid Input', 'Title is required', true);
    }

    if (!description || description.length === 0) {
      return openDialog('Invalid Input', 'Description is required', true);
    }

    openDialog('Saving...', null, false, true);

    const payload = {
      title,
      description,
      projectDesignerId: user.designer.id,
      images: [],
      videos: []
    };

    await API.graphql(graphqlOperation(createProject, { input: payload }));
    closeDialog();
    openSnackBar('Successfully Created new Project');
  };

  const openSnackBar = message => {
    setSnackBar({ open: true, message });
  };

  const openDialog = (title, content, alert = false, saving = false, editElement) => {
    setDialog({ open: true, title, content, saving, alert, editElement });
  };

  const closeDialog = () => {
    setDialog({ open: false });
  };

  const onBackToList = () => {
    setSelected(null);
  };

  const onDeleteProject = async () => {
    openDialog('Deleting...', null, false, true);
    if (selected.images) {
      for (let file of selected.images) {
        await removeFile(file);
      }
    }
    if (selected.videos) {
      for (let file of selected.videos) {
        await removeFile(file);
      }
    }

    await API.graphql(graphqlOperation(deleteProject, { input: { id: selected.id } }));

    setSelected(null);
    closeDialog();
    openSnackBar('Removed project');
  };

  const openEditDialog = () => {
    openDialog('Edit Project', null, null, null, selected);
  };

  const onEditProject = async () => {
    const { title, description } = projectInfo;
    if (!title || title.length === 0) {
      return openDialog('Invalid Input', 'Title is required', true);
    }

    if (!description || description.length === 0) {
      return openDialog('Invalid Input', 'Description is required', true);
    }

    openDialog('Saving...', null, false, true);

    const payload = {
      id: selected.id,
      title,
      description
    };

    await API.graphql(graphqlOperation(updateProject, { input: payload }));

    closeDialog();
    openSnackBar('Successfully saved Project');
  };

  return (
    <>
      {!projects && <LinearProgress />}
      <Grid container spacing={3}>
        <Grid item xs={12} container alignItems={fullScreen ? 'center' : 'flex-start'} direction={'column'}>
          <Typography variant={'h2'} color={'textPrimary'}>
            Portfolio
          </Typography>
        </Grid>

        <Grid item xs={12} container alignItems={fullScreen ? 'center' : 'flex-start'} direction={'column'}>
          <Slide direction={'right'} in={!selected} mountOnEnter unmountOnExit>
            <Paper className={classes.transparentPaper}>
              <PortfolioListComponent projects={projects} setSelected={setSelected} />
            </Paper>
          </Slide>
          {selected && (
            <Slide direction={'right'} in={selected} mountOnEnter unmountOnExit>
              <Paper className={classes.transparentPaper}>
                <PortfolioDetail project={selected} onBack={onBackToList} onDelete={onDeleteProject} onEdit={openEditDialog} />
              </Paper>
            </Slide>
          )}
        </Grid>

        {projects && !selected && (
          <Grid item xs={12} container alignItems={fullScreen ? 'center' : 'flex-start'} direction={'column'}>
            <Button onClick={onAddProject}>
              <Grid container direction={'column'} justify={'center'} alignItems={'center'}>
                <Add />
                <Typography>Create a</Typography>
                <Typography>New Project</Typography>
              </Grid>
            </Button>
          </Grid>
        )}
        <Dialog open={dialog.open} fullScreen={fullScreen}>
          <DialogTitle>
            <Grid container justify={'space-between'}>
              <Typography variant={'h4'}>{dialog.title ? dialog.title : ''}</Typography>
              {!dialog.alert && !dialog.saving && (
                <Button onClick={closeDialog}>
                  <Close />
                </Button>
              )}
            </Grid>
          </DialogTitle>
          <DialogContent>
            {dialog.content && <DialogContentText>{dialog.content}</DialogContentText>}
            {dialog.saving && <LinearProgress />}
            {!dialog.alert && !dialog.saving && <NewProjectForm onValuesChange={state => setProjectInfo(state)} classes={classes} editElement={dialog.editElement} />}
          </DialogContent>
          {!dialog.saving && !dialog.alert && (
            <DialogActions>{!dialog.editElement ? <Button onClick={onCreateProject}>Create Project</Button> : <Button onClick={onEditProject}>Save</Button>}</DialogActions>
          )}
          {dialog.alert && <Button onClick={closeDialog}>Close</Button>}
        </Dialog>
        <Snackbar open={snackbar.open} autoHideDuration={3000} onClose={() => setSnackBar({ open: false })} message={snackbar.message ? snackbar.message : ''} />
      </Grid>
    </>
  );
};

export default withStyles(styles)(Portfolio);
