import React, { useState } from 'react';
import compose from 'recompose/compose';
import { withStyles, IconButton } from '@material-ui/core';
import { FavoriteBorderOutlined } from '@material-ui/icons';
import { useMutation } from 'react-apollo-hooks';
import { updateUser } from 'graphql/mutations';
import gql from 'graphql-tag';
import styles from './styles';

const UpdateUserQuery = gql`
  ${updateUser}
`;

const FavoriteButton = props => {
  const { classes, video, userId, favorites, setFavorites } = props;
  const [disabled, setDisabled] = useState(false);

  const UpdateUser = useMutation(UpdateUserQuery);

  const addToFavoriteList = async () => {
    let current = favorites
      ? favorites.reduce((result, x) => {
          if (x.favoriteVideoId !== video.id) {
            delete x.__typename;
            result.push({
              favoriteVideoId: x.favoriteVideoId,
              createdAt: x.createdAt
            });
          }
          return result;
        }, [])
      : [];
    let newFavoriteEntry = {
      favoriteVideoId: video.id,
      createdAt: new Date()
    };
    let result = await UpdateUser({ variables: { input: { id: userId, favorites: [...current, newFavoriteEntry] } } });
    setFavorites([...current, newFavoriteEntry]);
    if (result) setDisabled(true);
  };

  return (
    <>
      {userId && video && (
        <IconButton disabled={disabled} aria-label={`star ${video.title}`} onClick={() => addToFavoriteList(video.id)}>
          <FavoriteBorderOutlined className={classes.title} />
        </IconButton>
      )}
    </>
  );
};

export default compose(withStyles(styles))(FavoriteButton);
