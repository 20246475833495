import React, { useState } from 'react';
import SliderContext from './context';
import ShowDetailsButton from './ShowDetailsButton';
import Mark from './Mark';
import styled from 'styled-components';
import ResponsiveVideo from '../ResponsiveVideo';
import { Typography } from '@material-ui/core';

const ItemMain = styled.div`
  flex: 0 0 19.7%;
  transition: transform 200ms ease 100ms;
  margin: 0 2px;
  position: relative;
  cursor: pointer;

  img {
    height: 100%;
    width: 100%;
    vertical-align: top;
    border-radius: 2px;
  }

  &:hover {
    transform: scale(1.4);
    z-index: 2;
    margin: 0 35px;
  }
`;

const ThumbnailWrapper = styled.div`
  position: relative;
`;

const ContentDescription = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  min-height: 20px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.2);

  p {
    color: white;
  }
`;

const Item = ({ video }) => {
  const [isHovering, setIsHovering] = useState(false);
  const renderContent = (onSelectSlide, currentSlide) => {
    return isHovering && currentSlide && currentSlide.id === video.id ? (
      <ThumbnailWrapper>
        <ContentDescription>
          <Typography>{video.title}</Typography>
        </ContentDescription>
        <ResponsiveVideo videoId={video.id} controls={false} playing muted video />
      </ThumbnailWrapper>
    ) : (
      <img src={video.thumbnail} alt="" />
    );
  };

  return (
    <SliderContext.Consumer>
      {({ onSelectSlide, onSlideHover, currentSlide, onSlideOut, elementRef }) => {
        return (
          <ItemMain
            ref={elementRef}
            onMouseEnter={() => {
              setIsHovering(true);
              onSlideHover(video);
            }}
            onClick={() => {
              setIsHovering(false);
              onSelectSlide(video);
            }}
            onMouseLeave={() => onSlideOut()}
          >
            {renderContent(onSelectSlide, currentSlide)}
            <ShowDetailsButton onClick={() => onSelectSlide(video)} />
            {isHovering && currentSlide && currentSlide.id === video.id && <Mark />}
          </ItemMain>
        );
      }}
    </SliderContext.Consumer>
  );
};

export default Item;
