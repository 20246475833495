import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import VideoProductItem from './components/VideoProductItem';

import styles from './styles';
import { getProductsByIds } from '../../services/stripe';

const VideoProducts = props => {
  const { classes } = props;
  const [products, setProducts] = useState([]);

  React.useEffect(() => {
    if (props.products && props.products.items.length > 0) {
      const ids = props.products.items.map(product => product.product.product_id);

      (async function() {
        const _products = await getProductsByIds(ids);
        for (let prod of _products) {
          const _product = props.products.items.filter(item => item.product.product_id === prod.id)[0];
          prod.timestamp = _product.timestamp;
        }

        _products.sort((a, b) => b.timestamp - a.timestamp);

        setProducts(_products);
      })();
    }
  }, [props.products]);

  return (
    <div className={classes.productListContainer}>
      {products.length > 0 &&
        products.map(product => {
          return <VideoProductItem key={product.id} product={product} timestamp={product.timestamp} />;
        })}
    </div>
  );
};
export default withStyles(styles)(VideoProducts);
