export default theme => ({
  root: {
    padding: theme.spacing(3),
    backgroundColor: '#000000'
  },
  input: {
    marginTop: 10,
    width: '100%'
  },
  // content: {
  //     marginTop: theme.spacing(5),
  //     backgroundColor: '#000000'
  // },
  inline: {
    // display: 'inline',
    // color: '#f8f8f8'
    paddingLeft: '0 !important'
  }
  // commentBox: {
  //     marginTop: '-300px',
  //     height: '300px',
  //     background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%)',
  //     opacity: 0,
  //     transition: '1s linear',
  //     '&:hover': {
  //         opacity: 1
  //     },
  //     overflowY: 'scroll'
  // },
  // list: {
  //     height: '100%',
  //     display: 'flex',
  //     flexDirection: 'column',
  //     alignItems: 'flex-end',
  //     justifyContent: 'flex-end'
  // },
  // show: {
  //     opacity: 1
  // },
  // commentContent: {
  //     fontSize: '1rem',
  //     fontWeight: 400,
  //     lineHeight: 1.5,
  //     letterSpacing: '.01em',
  //     color: '#fff'
  // }
});
