import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  }
});

export default function LinearDeterminate(props) {
  const classes = useStyles();
  const [completed, setCompleted] = React.useState(0);

  React.useEffect(() => {
    setCompleted(() => {
      let pct = (props.progress.loaded / props.progress.total) * 100;
      return pct > 0 ? pct : 0;
    });
  }, [props]);

  return (
    <div className={classes.root}>
      <LinearProgress variant="determinate" value={completed} />
    </div>
  );
}
