export default theme => ({
  headerImgContainer: {
    width: '100%',
    position: 'relative',
    '& h1': {
      marginTop: '30px',
      marginBottom: '15px',
      marginLeft: '0px',
      marginRight: '0px',
      fontSize: '48px',
      fontWeight: '900',
      '@media (maxwidth: 569px)': {
        fontSize: '38px'
      },
      letterSpacing: '2px',
      fontFamily: '"Lato", sansserif',
      position: 'absolute',
      width: '36%',
      left: '14%',
      opacity: '0.9',
      top: '40%',
      color: 'white'
    }
  },
  headerImg: {
    width: '100%'
  },
  document: {
    width: '100%'
  },
  section: {
    padding: '40px 40px',
    maxWidth: '1200px',
    margin: 'auto',
    '@media (max-width: 769px)': {
      padding: '10px 60px'
    },
    '@media (max-width: 569px)': {
      padding: '10px 20px'
    }
  },

  card: {
    padding: '10px 4%',
    marginTop: '-100px',
    background: 'white',
    borderRadius: '2px',
    position: 'relative',
    wordWrap: 'break-word',
    '@media (max-width: 769px)': {
      maxHeight: '100vh',
      overflow: 'scroll'
    },
    '& h1': {
      textAlign: 'center',
      opacity: 0.8
    },
    '& h3': {
      marginTop: '30px',
      marginBottom: '15px',
      marginLeft: '0px',
      marginRight: '0px',
      fontSize: '28px',
      fontWeight: '700',
      '@media (maxwidth: 569px)': {
        fontSize: '18px'
      },
      letterSpacing: '1px',
      fontFamily: '"OpenSans", sans-serif'
    },
    '& h4': {
      opacity: 0.8
    },
    '& p': {
      marginTop: '5px',
      marginBottom: '5px',
      fontFamily: '"Open Sans", sansserif',
      marginLeft: '0px',
      marginRight: '0px',
      fontSize: '18px',
      '@media (maxwidth: 569px)': {
        fontsize: '14px'
      },
      letterSpacing: '0.3px'
    },
    '& h2': {
      marginTop: '30px',
      marginBottom: '15px',
      marginLeft: '0px',
      marginRight: '0px',
      fontSize: '38px',
      fontWeight: '700',
      '@media (maxwidth: 569px)': {
        fontSize: '28px'
      },
      letterSpacing: '1px',
      fontFamily: '"Lato", sansserif'
    },
    '& *': {
      lineHeight: theme.spacing(0.23),
      fontFamily: '"Lato", sansserif'
    },
    '& li': {
      '& ul': {
        padding: theme.spacing(2)
      }
    }
  },
  policyContent: {
    opacity: 0.8
  },
  aTag: {
    color: '#000'
  }
});
