import React from 'react';
import { withStyles, useMediaQuery } from '@material-ui/core';

import styles from './styles';

const VideoInfo = props => {
  const { classes, video } = props;
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <div className={!mobile ? classes.content : classes.mobileContent}>
      <div className={classes.titleContainer}>
        <span className={classes.title}>{video.title}</span>
      </div>
      <div className={classes.descriptionContainer}>
        <span className={classes.description}>{video.description}</span>
      </div>
    </div>
  );
};
export default withStyles(styles)(VideoInfo);
