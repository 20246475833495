import React from 'react';
import { withStyles, Grid, CircularProgress, Typography, Link, Paper, LinearProgress } from '@material-ui/core';
import compose from 'recompose/compose';
import Article from './components/Article';
import { Link as RouterLink } from 'react-router-dom';

//graphql
import { useQuery } from 'react-apollo-hooks';
import { listPosts } from './query';
import gql from 'graphql-tag';

//styles
import styles from './styles';
import { CardLoadingHolder } from '../../components/LoadingHolder';

const ListPostsQuery = gql`
  ${listPosts}
`;
const ArticleLink = React.forwardRef((props, ref) => <RouterLink innerRef={ref} {...props} />);

const Articles = props => {
  const { classes } = props;
  const { data, loading, error } = useQuery(ListPostsQuery, {
    variables: {
      limit: 12,
      filter: {
        status: {
          eq: 'published'
        }
      }
    },
    fetchPolicy: 'network-only'
  });

  React.useEffect(() => error && console.log(error), [error]);

  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.content}>
        {loading && data.length === 0 && (
          <div className={classes.progressWrapper}>
            <CircularProgress />
          </div>
        )}
        {data.length === 0 && <Typography variant="h6">There are no articles available</Typography>}
        {data.listPosts ? (
          data.listPosts.items.map(post => (
            <Grid item key={post.id} lg={4} md={6} xs={12}>
              <Link className={classes.link} component={ArticleLink} to={'/articles/' + post.id}>
                <Article post={post} />
              </Link>
            </Grid>
          ))
        ) : (
          <>
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <Paper className={classes.paper} elevation={10}>
                <CardLoadingHolder />
              </Paper>
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <Paper className={classes.paper} elevation={10}>
                <CardLoadingHolder />
              </Paper>
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <Paper className={classes.paper} elevation={10}>
                <CardLoadingHolder />
              </Paper>
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <Paper className={classes.paper} elevation={10}>
                <CardLoadingHolder />
              </Paper>
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <Paper className={classes.paper} elevation={10}>
                <CardLoadingHolder />
              </Paper>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default compose(withStyles(styles))(Articles);
