import React from 'react';
import compose from 'recompose/compose';
import { withStyles, Typography, useMediaQuery } from '@material-ui/core';
import SubscriptionDesigner from './SubscriptionDesigner';

import styles from './styles';
import { useStore } from 'easy-peasy';

const Subscriptions = props => {
  const { classes } = props;
  const user = useStore(state => state.user.user);
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <div className={!mobile ? classes.root : classes.mobile}>
      {user ? (
        user.subs && user.subs.items.length > 0 ? (
          user.subs.items.map(x => {
            return <SubscriptionDesigner key={x.id} designer={x} />;
          })
        ) : (
          <Typography>Your subs is empty. Go browse designers and subscribe!</Typography>
        )
      ) : (
        <Typography>Loading...</Typography>
      )}
    </div>
  );
};

export default compose(withStyles(styles))(Subscriptions);
