export default theme => ({
  pullLeft: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  centered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2)
  },
  pullRight: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end'
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)'
  },
  gridListTile: {
    minHeight: 250,
    cursor: 'pointer'
  },
  mediaList: {
    marginBottom: theme.spacing(5)
  },
  uploadingFile: {
    margin: '40px 0'
  }
});
