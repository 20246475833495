import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { Tabs, Tab, GridListTileBar, GridListTile, GridList, Grid, useMediaQuery } from '@material-ui/core';
import SliderContext from 'components/Slider/context';
import { useQuery } from 'react-apollo-hooks';
import { listVideos } from 'graphql/queries';
import gql from 'graphql-tag';
import styles from './styles';
import WatchlistButton from 'components/WatchlistButton';
import FavoriteButton from 'components/FavoriteButton';
import { useUser } from 'hooks/useUser';
import useVideosFilter from '../../hooks/useVideosFilter';
import { grey } from '../../common/colors';
import { useActions, useStore } from 'easy-peasy';

const ListVideosQuery = gql`
  ${listVideos}
`;

const VideoSlider = props => {
  const { classes } = props;
  const { data } = useQuery(ListVideosQuery, {
    variables: {
      limit: 100,
      filter: {
        status: {
          eq: 'active'
        }
      }
    }
  });
  const [isHovering, setIsHovering] = useState({ hovering: false });
  const [watchlist, setWatchlist] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [state, setState] = useState({ categories: [], selectedCategory: null, categoryIdx: 0 });
  const videos = useVideosFilter(data.listVideos ? data.listVideos.items : null, state.selectedCategory);
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const user = useStore(state => state.user.user);
  const { goToLogin } = useActions(actions => actions.modal);

  useEffect(() => {
    if (user) {
      if (user.watchlist) setWatchlist(user.watchlist);
      if (user.favorites) setFavorites(user.favorites);
    }
  }, [user]);

  useEffect(() => {
    if (data.listVideos) {
      let _categories = ['All'];
      _categories = _categories.concat(
        data.listVideos.items
          .map(item => {
            if (item.category) {
              return item.category.name;
            }
            return null;
          })
          .filter(item => !!item)
      );
      _categories.push('Other');

      setState({ ...state, videos: data.listVideos.items, categories: [...new Set(_categories)] });
    }
  }, [data, state]);

  const handleMouseHover = id => {
    setIsHovering({ id: id, hovering: true });
  };

  const handleMouseLeave = () => {
    setIsHovering({ ...isHovering, hovering: false });
  };

  const onCategoryChange = (_, value) => {
    setState({ ...state, categoryIdx: value, selectedCategory: state.categories[value] });
  };

  const goToVideoDetail = id => {
    user && user.id ? props.history.push('/videos/' + id) : goToLogin();
  };

  return (
    <Grid container spacing={3}>
      {state.categories && (
        <Grid item xs={12} container justify={'center'}>
          <TabsContent categories={state.categories} categoryIdx={state.categoryIdx} onCategoryChange={onCategoryChange} mobile={mobile} />
        </Grid>
      )}
      <Grid item xs={12}>
        <div className={classes.root}>
          <SliderContext.Consumer>
            {({ onSelectSlide, currentSlide, elementRef }) => {
              return (
                <GridList className={classes.gridList}>
                  {videos &&
                    videos.length > 0 &&
                    videos.map(video => (
                      <GridListTile
                        key={video.id}
                        className={classes.videoContainer}
                        onClick={() => onSelectSlide(video)}
                        onMouseEnter={() => handleMouseHover(video.id)}
                        onMouseLeave={() => handleMouseLeave()}
                      >
                        <div className="item">
                          {/*{isHovering.hovering && isHovering.id === video.id && <ResponsiveVideo videoId={video.id} goToVideoDetail={goToVideoDetail} />}*/}
                          {isHovering.hovering && isHovering.id === video.id && (
                            <img
                              onClick={() => {
                                window.scrollTo(0, 0);
                                goToVideoDetail(video.id);
                              }}
                              src={video.thumbnail}
                              alt={video.title}
                            />
                          )}
                          {(!isHovering.hovering || isHovering.id !== video.id) && <img src={video.thumbnail} alt={video.title} />}
                          <GridListTileBar
                            title={video.title}
                            classes={{
                              root: classes.titleBar,
                              title: classes.title
                            }}
                            actionIcon={
                              <>
                                {user && user.id && <FavoriteButton video={video} userId={user.id} setFavorites={setFavorites} favorites={favorites} />}
                                {user && user.id && <WatchlistButton video={video} userId={user.id} setWatchlist={setWatchlist} watchlist={watchlist} />}
                              </>
                            }
                          />
                        </div>
                      </GridListTile>
                    ))}
                  {!videos && <div />}
                </GridList>
              );
            }}
          </SliderContext.Consumer>
        </div>
      </Grid>
    </Grid>
  );
};
export default compose(withRouter, withStyles(styles))(VideoSlider);

const TabsContent = withStyles(theme => ({
  root: {
    marginTop: 5
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#635ee7'
    }
  },
  selected: {
    backgroundColor: grey[200],
    '& span': {
      fontSize: 14,
      color: '#000'
    }
  }
}))(({ categories, classes, onCategoryChange, categoryIdx, mobile }) => (
  <Tabs
    textColor={'primary'}
    classes={{ root: classes.root, indicator: classes.indicator }}
    value={categoryIdx}
    onChange={onCategoryChange}
    variant={mobile ? 'scrollable' : 'fullWidth'}
  >
    {categories.map(category => (
      <Tab key={category} classes={{ selected: classes.selected }} wrapped label={category} />
    ))}
  </Tabs>
));
