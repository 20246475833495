import { useEffect, useReducer } from 'react';
import { Auth } from 'aws-amplify';
import { getUser } from 'graphql/queries';
import { useApolloClient } from 'react-apollo-hooks';
import gql from 'graphql-tag';

import { onUpdateUser } from '../graphql/subscriptions';

const initialState = { user: null };

function reducer(state, action) {
  switch (action.type) {
    case 'set':
      return { user: action.payload };
    default:
      return;
  }
}

export function useUser(policy = 'network-only', refetch = false) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const client = useApolloClient();

  useEffect(() => {
    let subscription;
    const fetchUserData = async () => {
      try {
        dispatch({
          type: 'set',
          payload: null
        });
        const authData = await Auth.currentAuthenticatedUser({ bypassCache: policy === 'network-only' });
        if (authData && !authData.attributes) throw 'not authenticated';
        if (authData) {
          client
            .query({
              query: gql`
                ${getUser}
              `,
              variables: { id: authData.attributes.sub },
              fetchPolicy: policy
            })
            .then(data => {
              // delete data.data.getUser.type;
              dispatch({
                type: 'set',
                payload: data.data.getUser
              });
            })
            .catch(error => console.error(error));

          if (!subscription)
            subscription = client
              .subscribe({
                query: gql`
                  ${onUpdateUser}
                `,
                variables: { owner: authData.attributes.sub },
                fetchPolicy: policy
              })
              .subscribe(res => {
                dispatch({
                  type: 'set',
                  payload: res.data.onUpdateUser
                });
              });
        } else {
          if (subscription) {
            subscription.unsubscribe();
          }
        }
      } catch (error) {
        if (error === 'not authenticated') {
          return dispatch({
            type: 'set',
            payload: {}
          });
        }
        throw new Error(error);
      }
    };

    if (client.defaultOptions.name === 'cognito') fetchUserData();
    else {
      dispatch({
        type: 'set',
        payload: {}
      });
    }
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [client, policy, refetch]);

  return state.user;
}
