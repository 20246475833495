import React, { useContext, useEffect, useState } from 'react';
import Slider from 'components/Slider';
import { withStyles, useMediaQuery } from '@material-ui/core';
import styled from 'styled-components';

import styles from './styles';

import { useQuery } from 'react-apollo-hooks';
import { listVideos } from 'graphql/queries';
import gql from 'graphql-tag';
import UserContext from '../../contexts/UserContext';

const ListVideosQuery = gql`
  ${listVideos}
`;

const BackgroundDiv = styled.div`
  box-sizing: border-box;
  position: relative;
  height: 100%;
  background-image: ${props => (!props.mobile ? 'linear-gradient(to bottom, rgba(255, 255, 255, .9), rgba(255, 255, 255, 1))' : '')};
`;

const Showcase = props => {
  const { classes } = props;
  const { openSnackBar } = useContext(UserContext);
  const { data, error } = useQuery(ListVideosQuery, { variables: { limit: 100, filter: { episode: { eq: 1}, status: { eq: 'active' } } }, fetchPolicy: 'network-only' });
  const [categories, setCategories] = useState([]);
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [videos, setVideos] = useState([]);

  React.useEffect(() => {
    if (data.listVideos) {
      setVideos(data.listVideos.items);
    }
  }, [data]);

  const filterVideos = category => {
    if (!category || category === 'All') {
      return videos;
    }

    if (category === 'Other') {
      return videos.filter(item => !item.category);
    }

    return videos.filter(item => item.category && item.category.name === category);
  };

  useEffect(() => {
    if (error) {
      console.log(error);
      openSnackBar('Something went wrong!');
    }
  }, [error]);

  useEffect(() => {
    if (data.listVideos) {
      let _categories = [];
      _categories = _categories.concat(
        data.listVideos.items
          .map(item => {
            if (item.category) {
              return item.category.name;
            }
            return null;
          })
          .filter(item => !!item)
      );
      _categories.push('Other');

      setCategories([...new Set(_categories)]);
    }
  }, [data]);

  return (
    <div className={!mobile ? classes.root : classes.mobile}>
      <>
        <Slider
          hideMainVideo={false}
          loading={!videos || videos.length === 0}
          categories={[categories[0]]}
          setSelectedCategory={[categories[0]]}
          data={data}
          activeSlide={data.listVideos ? data.listVideos.items[3] : null}
        >
          {filterVideos(categories[0]).map(video => (
            <Slider.Item video={video} key={video.id} />
          ))}
        </Slider>
        <BackgroundDiv mobile={mobile}>
          {categories
            .filter((category, index) => index > 0)
            .map(category => (
              <Slider
                hideMainVideo={true}
                loading={!videos || videos.length === 0}
                categories={[category]}
                setSelectedCategory={[category]}
                data={data}
                activeSlide={data.listVideos ? data.listVideos.items[3] : null}
              >
                {filterVideos(category).map(video => (
                  <Slider.Item video={video} key={video.id} />
                ))}
              </Slider>
            ))}
        </BackgroundDiv>
      </>
    </div>
  );
};

export default withStyles(styles)(Showcase);
