import { useEffect, useReducer } from 'react';
import { getDesigner } from '../graphql/custom-queries';
import { useApolloClient } from 'react-apollo-hooks';
import gql from 'graphql-tag';

const initialState = { designer: [] };

function reducer(state, action) {
  switch (action.type) {
    case 'set':
      return { designer: action.payload };
    default:
      return;
  }
}

export function useDesigner(id, policy = 'cache-first', refetch = false) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const client = useApolloClient();

  useEffect(() => {
    if (id) {
      const fetchDesignerData = async () => {
        client
          .query({
            query: gql`
              ${getDesigner}
            `,
            variables: { id: id },
            fetchPolicy: policy
          })
          .then(data => {
            dispatch({
              type: 'set',
              payload: data.data.getDesigner
            });
          })
          .catch(error => console.error(error));
      };
      fetchDesignerData();
    }
  }, [client, id, refetch, policy]);

  return state.designer;
}
