const theme = {
  primary: '#a333c8',
  dark: '#332E40',
  text: '#333',
  border: '#C5CCD6',
  divider: '#D8DFEB',
  error: '#E62F17',
  success: '#5FC8AA',
  white: '#fff',
  placeholder: '#58697F',
  cursor: '#E9EBF0',

  textSmall: '0.875rem',
  textBase: '0.9375rem',
  textLarge: '1.125rem',
  textExtraLarge: '1.25rem'
};

export default theme;
