export default theme => ({
  root: {},
  list: {
    maxHeight: '40vh',
    overflow: 'scroll'
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 120
  }
});
