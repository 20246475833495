export default theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: '#ffffff'
  },
  gridList: {
    // height: '180px',
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
    // marginBottom: '-17px !important',
    // padding: '48px 80px 65px 80px',
    padding: 15,
    '&:hover li': {
      transform: 'translateX(-25%)'
      // opacity: '0.5'
    },
    '& li:hover': {
      transform: 'scale(1.1) !important',
      opacity: '1'
    },
    '& li:hover ~ li': {
      transform: 'translateX(25%)',
      opacity: '0.5'
    }
  },
  videoContainer: {
    transition: 'transform 300ms ease 100ms',
    height: 'auto !important',
    '& .item': {
      transition: 'transform 300ms ease 100ms',
      margin: '0 ',
      position: 'relative',

      '& img': {
        height: '100%',
        width: '100%',
        verticalAlign: 'top'
      }
    },
    '@media (max-width: 600px)': {
      width: '90% !important'
    }
  },
  title: {
    color: theme.palette.primary.light
  },
  titleBar: {
    cursor: 'pointer',
    background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
  }
});
