import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@material-ui/core';
import { Videos } from '../Videos';
//import { VideoList } from 'components/VideoList';
import ResponsiveVideo from 'components/ResponsiveVideo';
import { useVideo } from 'hooks/useVideo';
import VideoComments from 'components/VideoComments';
import VideoProducts from 'components/VideoProducts';
import styled from 'styled-components';
import { useVideoComments } from '../../hooks/useVideoComments';
import { useVideoTime } from '../../hooks/useVideoTime';
import { HeaderLoadingHolder, TextLoadingHolder, VideoLoadingHolder } from '../../components/LoadingHolder';
import RelatedVideo from './RelatedVideo';

const VideoDetailContainer = styled.div`
  padding-top: 88px;
  padding-left: 24px;
  padding-right: 24px;
  max-width: 1200px;
  margin: auto;
  @media (max-width: 959px) {
    padding-top: 64px;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const VideoDetailVideoContainer = styled.div`
  position: relative;
  z-index: 10;
  @media (max-width: 959px) {
    position: -webkit-sticky;
    position: sticky;
    top: 64px;
  }
`;

const VideoDetailVideoContent = styled.div`
  padding: 8px 12px;
`;

const VideoDetailRelatedVideosContainer = styled.div`
  padding: 12px;
`;

const VideoDetail = props => {
  const [isHovering, setIsHovering] = useState({ hovering: false });
  const [videoId, setVideoId] = useState(props.match.params.id);
  const [currentSlide, onSelectSlide] = useState(null);
  const video = useVideo(videoId, 'network-only');
  const [playedSeconds, setPlayedSeconds] = useState();
  useVideoTime(playedSeconds);
  const comments = useVideoComments(video.id);

  useEffect(() => {
    setVideoId(props.match.params.id);
    window.scrollTo(0, 0);
  }, [props.match.params.id]);

  const goToVideoDetail = id => {
    props.history.push('/videos/' + id);
  };

  const handleMouseHover = id => {
    setIsHovering({ id: id, hovering: true });
  };

  const handleMouseLeave = () => {
    setIsHovering({ ...isHovering, hovering: false });
  };

  const onVideoProgress = data => {
    setPlayedSeconds(data.playedSeconds);
  };

  return (
    <VideoDetailContainer>
      <Grid container>
        <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
          <VideoDetailVideoContainer>
            <ResponsiveVideo onProgress={onVideoProgress} videoId={video.id} playing={true} />
          </VideoDetailVideoContainer>
          <VideoDetailVideoContent>
            {video.url ? (
              <>
                <h1>{video.title}</h1>
                <p>{video.description}</p>
                <p>{video.views} views</p>
              </>
            ) : (
              <>
                <HeaderLoadingHolder />
                <TextLoadingHolder />
                <TextLoadingHolder />
              </>
            )}
            <VideoComments video={video} comments={comments} />
          </VideoDetailVideoContent>
        </Grid>
        <Grid item sm={12} md={3}>
          <VideoProducts products={video.products} />
          <VideoDetailRelatedVideosContainer>
            <h3>Related Videos</h3>
            {video.category && video.category.videos && video.category.videos.items ? (
              video.category.videos.items.map(x => {
                if (video.id !== x.id)
                  return <RelatedVideo currentSlide={currentSlide} onSelectSlide={onSelectSlide} key={x.id} video={x} goToVideoDetail={goToVideoDetail} details />;
                return <div key={x.id} />;
                //{(!isHovering.hovering || isHovering.id !== video.id) && <img src={video.thumbnail} alt={video.title} />}
              })
            ) : (
              <>
                <VideoLoadingHolder />
                <TextLoadingHolder />
                <VideoLoadingHolder />
                <TextLoadingHolder />
              </>
            )}
          </VideoDetailRelatedVideosContainer>
        </Grid>
      </Grid>
    </VideoDetailContainer>
  );
};
export default VideoDetail;
