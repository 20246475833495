import React, { useRef, useState } from 'react';
import { Grid, Typography, withStyles, Collapse, useMediaQuery, Button, TextField, InputLabel, FormControl, Snackbar } from '@material-ui/core';

import CompleteScreen from './components/CompleteScreen/CompleteScreen';

import styles from './styles';
import { CheckOutlined } from '@material-ui/icons';
import { SquaredButton } from '../../components/SquaredButton';
import ProjectProInput from './components/ProjectProInput';
import { grey } from '../../common/colors';
import { useStore } from 'easy-peasy';
import PhoneInput from 'react-phone-input-2';
import { Spinner } from '../../components/Cart/components/Button';
import { Auth } from 'aws-amplify';

const MainContent = ({ classes, user, redirect, onBecomeDesigner, onBecomeProjectPro, setSnackbar }) => {
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [state, setState] = useState({ phone_number: '', email: '', password: '' });
  const [type, setType] = useState(null);
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const businessNameRef = useRef();
  const [loading, setLoading] = useState(false);

  const onChange = (key, value) => {
    setState({
      ...state,
      [key]: value
    });
  };

  const onSubmit = async () => {
    setLoading(true);
    await onBecomeDesigner(firstNameRef, lastNameRef, businessNameRef, state);
    setLoading(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <h1>Become a Content Creator</h1>
        <p>Build your online presence by becoming a part of HDTV's interactive Home Design TV network.</p>
      </div>
      <div className={classes.content}>
        <Grid item xs={12} container className={classes.section}>
          <Grid item xs={12} justify={'center'} container alignItems={'center'}>
            <h1>Ready to be a part of HDTV community?</h1>
          </Grid>
          <Grid item xs={12}>
            <Collapse in={!type}>
              <Grid container spacing={3}>
                <Grid item xs={12} container justify={'flex-start'} alignItems={'center'} spacing={3} direction={'column'} style={{ minHeight: 200 }}>
                  <Typography variant={'caption'}>HDTV provides a trusted space for you to tell your story to your ideal audience</Typography>
                  <Typography align={'center'}>
                    <CheckOutlined />
                    Bring your design one step closer to your viewer
                  </Typography>
                  <Typography align={'center'}>
                    <CheckOutlined />
                    Know your audience at this interactive medium: The platform is your playground
                  </Typography>
                  <Typography align={'center'}>
                    <CheckOutlined />
                    Engage with other designers with HDTV designer network
                  </Typography>
                </Grid>
                <Grid item xs={12} container justify={'center'} alignItems={'center'}>
                  <Grid container item xs={12} style={{ padding: 0 }}>
                    <Grid item xs={12} md={6} container justify={'center'} className={'applyNow'} style={{ backgroundColor: 'rgba(204, 204, 204,1)' }}>
                      <h1>Are you a Designer?</h1>
                      <Typography variant={'caption'} align={'center'}>
                        Initiate and getting attention for your essential design
                      </Typography>
                      <SquaredButton
                        onClick={
                          user && user.designer && !user.designer.pro
                            ? () => {
                                window.scrollTo(0, 0);
                                redirect('designer-portal');
                              }
                            : () => {
                                // window.scrollTo(0, 0);
                                setType('designer');
                              }
                        }
                        disabled={loading}
                      >
                        {!user || !user.designer || user.designer.pro ? 'Sign Up' : 'Designer Portal'}
                      </SquaredButton>
                    </Grid>
                    <Grid item xs={12} md={6} container justify={'center'} className={'applyNow'} style={{ backgroundColor: 'rgba(0, 122, 223,1)' }}>
                      <h1>Are you a Project Pro?</h1>
                      <Typography variant={'caption'} align={'center'} style={{ color: grey[400] }}>
                        Establish your own voice: Your unique style that only you can offer
                      </Typography>
                      <SquaredButton
                        onClick={
                          user && user.designer && user.designer.pro
                            ? () => {
                                window.scrollTo(0, 0);
                                redirect('project-pro-portal');
                              }
                            : () => {
                                // window.scrollTo(0, 0);
                                setType('pro');
                              }
                        }
                        disabled={loading}
                      >
                        {!user || !user.designer || !user.designer.pro ? 'Sign Up' : 'Project Pro Portal'}
                      </SquaredButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
          {/*<Button*/}
          {/*  variant={'contained'}*/}
          {/*  onClick={() => {*/}
          {/*    if (user.designer) {*/}
          {/*      return redirect('designer-portal');*/}
          {/*    }*/}
          {/*    setStart(true);*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {user.designer ? 'Designer Portal' : 'Apply Now'}*/}
          {/*</Button>*/}
          <Grid item xs={12}>
            <Collapse in={!!type}>
              {type === 'designer' && (
                <>
                  <Grid item xs={12} container justify={'center'} alignItems={'center'}>
                    <h2>What is your name?</h2>
                  </Grid>
                  <Grid item xs={12} container justify={'center'} alignItems={'center'} direction={'column'}>
                    <div className={classes.inputSection}>
                      <FormControl fullWidth={mobile} style={mobile ? null : { width: '50%' }}>
                        <InputLabel shrink={true}>First Name</InputLabel>
                        <TextField disabled={loading} inputRef={firstNameRef} id="first_name" margin="normal" variant="outlined" inputProps={{ 'aria-label': 'bare' }} />
                      </FormControl>
                      <FormControl fullWidth={mobile} style={mobile ? null : { width: '50%' }}>
                        <InputLabel shrink={true}>Last Name</InputLabel>
                        <TextField disabled={loading} inputRef={lastNameRef} id="last_name" margin="normal" variant="outlined" inputProps={{ 'aria-label': 'bare' }} />
                      </FormControl>
                    </div>
                    <div className={classes.inputSection}>
                      <FormControl fullWidth>
                        <InputLabel shrink={true}>Business Name</InputLabel>
                        <TextField disabled={loading} inputRef={businessNameRef} id="business_name" margin="normal" variant="outlined" inputProps={{ 'aria-label': 'bare' }} />
                      </FormControl>
                    </div>

                    {!(user && user.id) && (
                      <>
                        <div className={classes.inputSection}>
                          <FormControl fullWidth>
                            <InputLabel shrink={true}>Email</InputLabel>
                            <TextField
                              value={state.email}
                              disabled={loading}
                              type={'email'}
                              onChange={e => onChange('email', e.target.value)}
                              margin="normal"
                              variant="outlined"
                              inputProps={{ 'aria-label': 'bare' }}
                            />
                          </FormControl>
                        </div>
                        <div className={classes.inputSection}>
                          <FormControl fullWidth>
                            <InputLabel shrink={true}>Password</InputLabel>
                            <TextField
                              value={state.password}
                              disabled={loading}
                              type={'password'}
                              onChange={e => onChange('password', e.target.value)}
                              margin="normal"
                              variant="outlined"
                              inputProps={{ 'aria-label': 'bare' }}
                            />
                          </FormControl>
                        </div>
                        <div className={classes.inputSection}>
                          <p>Phone Number *</p>
                          <PhoneInput
                            inputStyle={{ width: '100%', lineHeight: '1.1875em', height: 56 }}
                            disabled={loading}
                            country={'us'}
                            value={state.phone_number}
                            onChange={phone => onChange('phone_number', phone)}
                          />
                        </div>
                      </>
                    )}
                    <Button variant={'contained'} disabled={loading} color={'primary'} onClick={onSubmit}>
                      {loading ? <Spinner /> : 'Apply'}
                    </Button>
                  </Grid>
                </>
              )}
              {type === 'pro' && <ProjectProInput user={user} setSnackbar={setSnackbar} onSubmit={onBecomeProjectPro} />}
            </Collapse>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const BecomeADesigner = ({ classes, history }) => {
  const user = useStore(state => state.user.user);
  const [done, setDone] = useState(false);
  const [state, setState] = useState({});
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });

  const openSnackbar = message => setSnackbar({ open: true, message });

  const onBecomeDesigner = async (firstName, lastName, businessName, credentials) => {
    if (firstName.current.value === '') {
      firstName.current.focus();
      return openSnackbar('Fill in your name!');
    }

    if (lastName.current.value === '') {
      lastName.current.focus();
      return openSnackbar('Fill in your name');
    }

    if (businessName.current.value === '') {
      businessName.current.focus();
      return openSnackbar('Fill in your business name');
    }

    if (user && user.id) {
      setState({ first_name: firstName.current.value, last_name: lastName.current.value, name: businessName.current.value, user, isPro: false });
      setDone(true);
    } else {
      if (credentials.email.length < 5) {
        return openSnackbar('Email is invalid!');
      }

      if (credentials.password.length < 5) {
        return openSnackbar('Password must be more than 5 characters!');
      }

      if (credentials.phone_number.length < 5) {
        return openSnackbar('Phone number is invalid!');
      }

      setState({ first_name: firstName.current.value, last_name: lastName.current.value, name: businessName.current.value, user, isPro: false, email: credentials.email });

      const { password, email, phone_number } = credentials;
      try {
        await Auth.signUp({
          username: email,
          password,
          attributes: {
            email,
            phone_number: phone_number
              .replace('(', '')
              .replace(')', '')
              .replace('-', '')
              .split(' ')
              .join('')
          }
        });

        await Auth.signIn(email, password);
        setDone(true);
      } catch (e) {
        openSnackbar(e.message ? e.message : e);
        console.log(e);
      }
    }
  };

  const onBecomeProjectPro = async ({ first_name, last_name, name, serviceId, email, password, phone_number }) => {
    if (user && user.id) {
      setState({ first_name, last_name, name, service_id: serviceId, user, isPro: true });
      setDone(true);
    } else {
      setState({ first_name, last_name, name, service_id: serviceId, user, isPro: true, email });

      try {
        await Auth.signUp({
          username: email,
          password,
          attributes: {
            email,
            phone_number: phone_number
              .replace('(', '')
              .replace(')', '')
              .replace('-', '')
              .split(' ')
              .join('')
          }
        });

        await Auth.signIn(email, password);
        setDone(true);
      } catch (e) {
        openSnackbar(e.message ? e.message : e);
        console.log(e);
      }
    }
  };

  const onRedirect = path => {
    history.push(path);
  };

  return (
    <>
      <div className={classes.container}>
        <Snackbar open={snackbar.open} message={snackbar.message} autoHideDuration={3000} onClose={() => setSnackbar({ open: false, message: '' })} />
        {(!(user && user.id) || !user.type) && (
          <Collapse in={!done} className={classes.root}>
            <MainContent
              setSnackbar={setSnackbar}
              redirect={onRedirect}
              user={user}
              onBecomeDesigner={onBecomeDesigner}
              onBecomeProjectPro={onBecomeProjectPro}
              classes={classes}
            />
          </Collapse>
        )}
        <Collapse in={done} className={done ? classes.root : null}>
          {done && <CompleteScreen classes={classes} user={user} state={state} onRedirect={onRedirect} />}
        </Collapse>
      </div>
    </>
  );
};

export default withStyles(styles)(BecomeADesigner);
