import React, { useContext, useEffect, useState } from 'react';

import { Auth } from 'aws-amplify';
import { Paper, Typography } from '@material-ui/core';
import { useStore } from 'easy-peasy';
import ConfirmPage from '../Authenticator/components/ConfirmPage';
import UserContext from '../../contexts/UserContext';

const CodeVerification = props => {
  const [errorMessage, setErrorMessage] = useState(null);

  const user = useStore(state => state.user.user);
  const context = useContext(UserContext);
  const [state, setState] = useState({ authCode: '' });
  const [authData, setAuthData] = useState(null);

  useEffect(() => {
    Auth.currentAuthenticatedUser({ bypassCache: true })
      .then(data => {
        if (data.attributes.email_verified) {
          return props.history.push('/account');
        }
        setAuthData(data);
      })
      .catch(e => props.history.push('/auth'));
  }, []);

  const updateErrorMessage = errorMessage => {
    setErrorMessage(errorMessage);
  };

  const onChange = (type, code) => setState({ ...state, [type]: code });

  const resendSignUp = () => {
    Auth.verifyCurrentUserAttribute('email')
      .then(() => {
        updateErrorMessage('Resent code to ' + authData.attributes.email);
      })
      .catch(err => {
        console.log('error signing up: ', err);
        updateErrorMessage(err.message);
      });
  };

  const confirmSignUp = () => {
    Auth.verifyCurrentUserAttributeSubmit('email', state.authCode)
      .then(() => {
        props.history.push('/account');
      })
      .catch(err => {
        console.log('error confirming signing up: ', err.toLowerC);
        updateErrorMessage(err.message ? err.message : err);
      });
  };

  return (
    <div style={styles.container}>
      {authData ? <ConfirmPage confirmSignUp={confirmSignUp} state={state} onChange={onChange} resendSignUp={resendSignUp} {...props} /> : <Typography>Loading...</Typography>}
      <Paper style={errorMessage ? styles.errorMessage : { opacity: 0 }}>
        <Typography variant={'subtitle1'} style={{ color: '#fff' }}>
          {errorMessage ? errorMessage : '__'}
        </Typography>
      </Paper>
    </div>
  );
};

export default CodeVerification;

const styles = {
  linkContainer: {
    marginTop: 30
  },
  container: {
    height: '100vh',
    backgroundColor: 'rgb(52,71,90)',
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.65) 100%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 10
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  toggle: {
    paddingBottom: '10px',
    cursor: 'pointer',
    marginTop: 10,
    marginBottom: 0,
    borderBottom: '2px solid transparent',
    ':hover': {
      opacity: 0.6
    }
  },
  errorMessage: {
    textAlign: 'center',
    width: 'fit-content',
    marginTop: 20,
    padding: '10px 30px',
    minWidth: 400,
    backgroundColor: '#a3a3a3'
  },
  topBar: {
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    height: '64px',
    fontFamily: 'Montserrat, sans-serif'
  },
  logo: {
    margin: 0,
    textAlign: 'center',
    height: '25px',
    cursor: 'pointer'
  }
};
