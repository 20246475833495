/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = `mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
    owner
    email
    phone
    first_name
    last_name
    photo_url
    cover_url
    notes
    active
    createdAt
    watchlist {
      watchlistVideoId
      createdAt
    }
    currentVideo {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    history {
      historyVideoId
      createdAt
    }
    favorites {
      favoriteVideoId
      createdAt
    }
    userDesignerId
    orders {
      items {
        id
        owner
        customer {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        orderCustomerId
        total
        order
        createdAt
      }
      nextToken
    }
    cart {
      id
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      owner
      items
    }
    subs {
      items {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subDesignerId
        stripeId
        stripeSubId
        createdAt
        owner
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
  }
}
`;
export const updateUser = `mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    id
    owner
    email
    phone
    first_name
    last_name
    photo_url
    cover_url
    notes
    active
    createdAt
    watchlist {
      watchlistVideoId
      createdAt
    }
    currentVideo {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    history {
      historyVideoId
      createdAt
    }
    favorites {
      favoriteVideoId
      createdAt
    }
    userDesignerId
    orders {
      items {
        id
        owner
        customer {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        orderCustomerId
        total
        order
        createdAt
      }
      nextToken
    }
    cart {
      id
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      owner
      items
    }
    subs {
      items {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subDesignerId
        stripeId
        stripeSubId
        createdAt
        owner
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
  }
}
`;
export const deleteUser = `mutation DeleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    id
    owner
    email
    phone
    first_name
    last_name
    photo_url
    cover_url
    notes
    active
    createdAt
    watchlist {
      watchlistVideoId
      createdAt
    }
    currentVideo {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    history {
      historyVideoId
      createdAt
    }
    favorites {
      favoriteVideoId
      createdAt
    }
    userDesignerId
    orders {
      items {
        id
        owner
        customer {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        orderCustomerId
        total
        order
        createdAt
      }
      nextToken
    }
    cart {
      id
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      owner
      items
    }
    subs {
      items {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subDesignerId
        stripeId
        stripeSubId
        createdAt
        owner
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
  }
}
`;
export const createCart = `mutation CreateCart($input: CreateCartInput!) {
  createCart(input: $input) {
    id
    user {
      id
      owner
      email
      phone
      first_name
      last_name
      photo_url
      cover_url
      notes
      active
      createdAt
      watchlist {
        watchlistVideoId
        createdAt
      }
      currentVideo {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      history {
        historyVideoId
        createdAt
      }
      favorites {
        favoriteVideoId
        createdAt
      }
      userDesignerId
      orders {
        items {
          id
          owner
          orderCustomerId
          total
          order
          createdAt
        }
        nextToken
      }
      cart {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        owner
        items
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      designer {
        id
        owner
        email
        phone
        name
        photo_url
        bio
        location
        active
        createdAt
        pro
        profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        draft_profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subs {
          nextToken
        }
        tags {
          nextToken
        }
        pendingVideos {
          nextToken
        }
        videos {
          nextToken
        }
        projects {
          nextToken
        }
        posts {
          nextToken
        }
      }
    }
    owner
    items
  }
}
`;
export const updateCart = `mutation UpdateCart($input: UpdateCartInput!) {
  updateCart(input: $input) {
    id
    user {
      id
      owner
      email
      phone
      first_name
      last_name
      photo_url
      cover_url
      notes
      active
      createdAt
      watchlist {
        watchlistVideoId
        createdAt
      }
      currentVideo {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      history {
        historyVideoId
        createdAt
      }
      favorites {
        favoriteVideoId
        createdAt
      }
      userDesignerId
      orders {
        items {
          id
          owner
          orderCustomerId
          total
          order
          createdAt
        }
        nextToken
      }
      cart {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        owner
        items
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      designer {
        id
        owner
        email
        phone
        name
        photo_url
        bio
        location
        active
        createdAt
        pro
        profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        draft_profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subs {
          nextToken
        }
        tags {
          nextToken
        }
        pendingVideos {
          nextToken
        }
        videos {
          nextToken
        }
        projects {
          nextToken
        }
        posts {
          nextToken
        }
      }
    }
    owner
    items
  }
}
`;
export const deleteCart = `mutation DeleteCart($input: DeleteCartInput!) {
  deleteCart(input: $input) {
    id
    user {
      id
      owner
      email
      phone
      first_name
      last_name
      photo_url
      cover_url
      notes
      active
      createdAt
      watchlist {
        watchlistVideoId
        createdAt
      }
      currentVideo {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      history {
        historyVideoId
        createdAt
      }
      favorites {
        favoriteVideoId
        createdAt
      }
      userDesignerId
      orders {
        items {
          id
          owner
          orderCustomerId
          total
          order
          createdAt
        }
        nextToken
      }
      cart {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        owner
        items
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      designer {
        id
        owner
        email
        phone
        name
        photo_url
        bio
        location
        active
        createdAt
        pro
        profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        draft_profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subs {
          nextToken
        }
        tags {
          nextToken
        }
        pendingVideos {
          nextToken
        }
        videos {
          nextToken
        }
        projects {
          nextToken
        }
        posts {
          nextToken
        }
      }
    }
    owner
    items
  }
}
`;
export const createSub = `mutation CreateSub($input: CreateSubInput!) {
  createSub(input: $input) {
    id
    user {
      id
      owner
      email
      phone
      first_name
      last_name
      photo_url
      cover_url
      notes
      active
      createdAt
      watchlist {
        watchlistVideoId
        createdAt
      }
      currentVideo {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      history {
        historyVideoId
        createdAt
      }
      favorites {
        favoriteVideoId
        createdAt
      }
      userDesignerId
      orders {
        items {
          id
          owner
          orderCustomerId
          total
          order
          createdAt
        }
        nextToken
      }
      cart {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        owner
        items
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      designer {
        id
        owner
        email
        phone
        name
        photo_url
        bio
        location
        active
        createdAt
        pro
        profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        draft_profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subs {
          nextToken
        }
        tags {
          nextToken
        }
        pendingVideos {
          nextToken
        }
        videos {
          nextToken
        }
        projects {
          nextToken
        }
        posts {
          nextToken
        }
      }
    }
    subDesignerId
    stripeId
    stripeSubId
    createdAt
    owner
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
  }
}
`;
export const updateSub = `mutation UpdateSub($input: UpdateSubInput!) {
  updateSub(input: $input) {
    id
    user {
      id
      owner
      email
      phone
      first_name
      last_name
      photo_url
      cover_url
      notes
      active
      createdAt
      watchlist {
        watchlistVideoId
        createdAt
      }
      currentVideo {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      history {
        historyVideoId
        createdAt
      }
      favorites {
        favoriteVideoId
        createdAt
      }
      userDesignerId
      orders {
        items {
          id
          owner
          orderCustomerId
          total
          order
          createdAt
        }
        nextToken
      }
      cart {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        owner
        items
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      designer {
        id
        owner
        email
        phone
        name
        photo_url
        bio
        location
        active
        createdAt
        pro
        profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        draft_profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subs {
          nextToken
        }
        tags {
          nextToken
        }
        pendingVideos {
          nextToken
        }
        videos {
          nextToken
        }
        projects {
          nextToken
        }
        posts {
          nextToken
        }
      }
    }
    subDesignerId
    stripeId
    stripeSubId
    createdAt
    owner
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
  }
}
`;
export const deleteSub = `mutation DeleteSub($input: DeleteSubInput!) {
  deleteSub(input: $input) {
    id
    user {
      id
      owner
      email
      phone
      first_name
      last_name
      photo_url
      cover_url
      notes
      active
      createdAt
      watchlist {
        watchlistVideoId
        createdAt
      }
      currentVideo {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      history {
        historyVideoId
        createdAt
      }
      favorites {
        favoriteVideoId
        createdAt
      }
      userDesignerId
      orders {
        items {
          id
          owner
          orderCustomerId
          total
          order
          createdAt
        }
        nextToken
      }
      cart {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        owner
        items
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      designer {
        id
        owner
        email
        phone
        name
        photo_url
        bio
        location
        active
        createdAt
        pro
        profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        draft_profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subs {
          nextToken
        }
        tags {
          nextToken
        }
        pendingVideos {
          nextToken
        }
        videos {
          nextToken
        }
        projects {
          nextToken
        }
        posts {
          nextToken
        }
      }
    }
    subDesignerId
    stripeId
    stripeSubId
    createdAt
    owner
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
  }
}
`;
export const createCategory = `mutation CreateCategory($input: CreateCategoryInput!) {
  createCategory(input: $input) {
    id
    owner
    name
    description
    createdAt
    videos {
      items {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      nextToken
    }
    pendingVideos {
      items {
        id
        owner
        title
        description
        status
        createdAt
        src_video
        pendingVideoCategoryId
        pendingVideoShowId
        pendingVideoDesignerId
        season
        episode
        duration
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
  }
}
`;
export const updateCategory = `mutation UpdateCategory($input: UpdateCategoryInput!) {
  updateCategory(input: $input) {
    id
    owner
    name
    description
    createdAt
    videos {
      items {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      nextToken
    }
    pendingVideos {
      items {
        id
        owner
        title
        description
        status
        createdAt
        src_video
        pendingVideoCategoryId
        pendingVideoShowId
        pendingVideoDesignerId
        season
        episode
        duration
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
  }
}
`;
export const deleteCategory = `mutation DeleteCategory($input: DeleteCategoryInput!) {
  deleteCategory(input: $input) {
    id
    owner
    name
    description
    createdAt
    videos {
      items {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      nextToken
    }
    pendingVideos {
      items {
        id
        owner
        title
        description
        status
        createdAt
        src_video
        pendingVideoCategoryId
        pendingVideoShowId
        pendingVideoDesignerId
        season
        episode
        duration
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
  }
}
`;
export const createShow = `mutation CreateShow($input: CreateShowInput!) {
  createShow(input: $input) {
    id
    owner
    title
    description
    release_date
    videos {
      items {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      nextToken
    }
    pendingVideos {
      items {
        id
        owner
        title
        description
        status
        createdAt
        src_video
        pendingVideoCategoryId
        pendingVideoShowId
        pendingVideoDesignerId
        season
        episode
        duration
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
  }
}
`;
export const updateShow = `mutation UpdateShow($input: UpdateShowInput!) {
  updateShow(input: $input) {
    id
    owner
    title
    description
    release_date
    videos {
      items {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      nextToken
    }
    pendingVideos {
      items {
        id
        owner
        title
        description
        status
        createdAt
        src_video
        pendingVideoCategoryId
        pendingVideoShowId
        pendingVideoDesignerId
        season
        episode
        duration
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
  }
}
`;
export const deleteShow = `mutation DeleteShow($input: DeleteShowInput!) {
  deleteShow(input: $input) {
    id
    owner
    title
    description
    release_date
    videos {
      items {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      nextToken
    }
    pendingVideos {
      items {
        id
        owner
        title
        description
        status
        createdAt
        src_video
        pendingVideoCategoryId
        pendingVideoShowId
        pendingVideoDesignerId
        season
        episode
        duration
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
  }
}
`;
export const createVideo = `mutation CreateVideo($input: CreateVideoInput!) {
  createVideo(input: $input) {
    id
    owner
    title
    description
    status
    url
    thumbnail
    createdAt
    views {
      user
      video
      createdAt
    }
    season
    episode
    release_date
    duration
    likes
    src_video
    category {
      id
      owner
      name
      description
      createdAt
      videos {
        items {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
    }
    show {
      id
      owner
      title
      description
      release_date
      videos {
        items {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
    }
    tags {
      items {
        id
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        owner
        tag {
          id
          tag
          type
          owner
        }
      }
      nextToken
    }
    products {
      items {
        id
        owner
        timestamp
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        product {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
      }
      nextToken
    }
    designers {
      items {
        id
        owner
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    comments {
      items {
        id
        owner
        content
        avatar
        name
        commentPostId
        commentVideoId
        createdAt
        likes
        subs
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        post {
          id
          owner
          title
          description
          content
          status
          thumbnail
          createdAt
          likes
          liked
        }
      }
      nextToken
    }
  }
}
`;
export const updateVideo = `mutation UpdateVideo($input: UpdateVideoInput!) {
  updateVideo(input: $input) {
    id
    owner
    title
    description
    status
    url
    thumbnail
    createdAt
    views {
      user
      video
      createdAt
    }
    season
    episode
    release_date
    duration
    likes
    src_video
    category {
      id
      owner
      name
      description
      createdAt
      videos {
        items {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
    }
    show {
      id
      owner
      title
      description
      release_date
      videos {
        items {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
    }
    tags {
      items {
        id
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        owner
        tag {
          id
          tag
          type
          owner
        }
      }
      nextToken
    }
    products {
      items {
        id
        owner
        timestamp
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        product {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
      }
      nextToken
    }
    designers {
      items {
        id
        owner
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    comments {
      items {
        id
        owner
        content
        avatar
        name
        commentPostId
        commentVideoId
        createdAt
        likes
        subs
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        post {
          id
          owner
          title
          description
          content
          status
          thumbnail
          createdAt
          likes
          liked
        }
      }
      nextToken
    }
  }
}
`;
export const deleteVideo = `mutation DeleteVideo($input: DeleteVideoInput!) {
  deleteVideo(input: $input) {
    id
    owner
    title
    description
    status
    url
    thumbnail
    createdAt
    views {
      user
      video
      createdAt
    }
    season
    episode
    release_date
    duration
    likes
    src_video
    category {
      id
      owner
      name
      description
      createdAt
      videos {
        items {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
    }
    show {
      id
      owner
      title
      description
      release_date
      videos {
        items {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
    }
    tags {
      items {
        id
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        owner
        tag {
          id
          tag
          type
          owner
        }
      }
      nextToken
    }
    products {
      items {
        id
        owner
        timestamp
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        product {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
      }
      nextToken
    }
    designers {
      items {
        id
        owner
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    comments {
      items {
        id
        owner
        content
        avatar
        name
        commentPostId
        commentVideoId
        createdAt
        likes
        subs
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        post {
          id
          owner
          title
          description
          content
          status
          thumbnail
          createdAt
          likes
          liked
        }
      }
      nextToken
    }
  }
}
`;
export const createTagsDesigners = `mutation CreateTagsDesigners($input: CreateTagsDesignersInput!) {
  createTagsDesigners(input: $input) {
    id
    tagsDesignersTagId
    tagsDesignersDesignerId
    owner
    tag {
      id
      tag
      type
      designers {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      owner
    }
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
  }
}
`;
export const updateTagsDesigners = `mutation UpdateTagsDesigners($input: UpdateTagsDesignersInput!) {
  updateTagsDesigners(input: $input) {
    id
    tagsDesignersTagId
    tagsDesignersDesignerId
    owner
    tag {
      id
      tag
      type
      designers {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      owner
    }
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
  }
}
`;
export const deleteTagsDesigners = `mutation DeleteTagsDesigners($input: DeleteTagsDesignersInput!) {
  deleteTagsDesigners(input: $input) {
    id
    tagsDesignersTagId
    tagsDesignersDesignerId
    owner
    tag {
      id
      tag
      type
      designers {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      owner
    }
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
  }
}
`;
export const createTagsVideos = `mutation CreateTagsVideos($input: CreateTagsVideosInput!) {
  createTagsVideos(input: $input) {
    id
    video {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    owner
    tag {
      id
      tag
      type
      designers {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      owner
    }
  }
}
`;
export const updateTagsVideos = `mutation UpdateTagsVideos($input: UpdateTagsVideosInput!) {
  updateTagsVideos(input: $input) {
    id
    video {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    owner
    tag {
      id
      tag
      type
      designers {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      owner
    }
  }
}
`;
export const deleteTagsVideos = `mutation DeleteTagsVideos($input: DeleteTagsVideosInput!) {
  deleteTagsVideos(input: $input) {
    id
    video {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    owner
    tag {
      id
      tag
      type
      designers {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      owner
    }
  }
}
`;
export const createTag = `mutation CreateTag($input: CreateTagInput!) {
  createTag(input: $input) {
    id
    tag
    type
    designers {
      items {
        id
        tagsDesignersTagId
        tagsDesignersDesignerId
        owner
        tag {
          id
          tag
          type
          owner
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    videos {
      items {
        id
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        owner
        tag {
          id
          tag
          type
          owner
        }
      }
      nextToken
    }
    owner
  }
}
`;
export const updateTag = `mutation UpdateTag($input: UpdateTagInput!) {
  updateTag(input: $input) {
    id
    tag
    type
    designers {
      items {
        id
        tagsDesignersTagId
        tagsDesignersDesignerId
        owner
        tag {
          id
          tag
          type
          owner
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    videos {
      items {
        id
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        owner
        tag {
          id
          tag
          type
          owner
        }
      }
      nextToken
    }
    owner
  }
}
`;
export const deleteTag = `mutation DeleteTag($input: DeleteTagInput!) {
  deleteTag(input: $input) {
    id
    tag
    type
    designers {
      items {
        id
        tagsDesignersTagId
        tagsDesignersDesignerId
        owner
        tag {
          id
          tag
          type
          owner
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    videos {
      items {
        id
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        owner
        tag {
          id
          tag
          type
          owner
        }
      }
      nextToken
    }
    owner
  }
}
`;
export const createPendingVideo = `mutation CreatePendingVideo($input: CreatePendingVideoInput!) {
  createPendingVideo(input: $input) {
    id
    owner
    title
    description
    status
    createdAt
    src_video
    pendingVideoCategoryId
    pendingVideoShowId
    pendingVideoDesignerId
    season
    episode
    duration
    category {
      id
      owner
      name
      description
      createdAt
      videos {
        items {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
    }
    show {
      id
      owner
      title
      description
      release_date
      videos {
        items {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
    }
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
  }
}
`;
export const updatePendingVideo = `mutation UpdatePendingVideo($input: UpdatePendingVideoInput!) {
  updatePendingVideo(input: $input) {
    id
    owner
    title
    description
    status
    createdAt
    src_video
    pendingVideoCategoryId
    pendingVideoShowId
    pendingVideoDesignerId
    season
    episode
    duration
    category {
      id
      owner
      name
      description
      createdAt
      videos {
        items {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
    }
    show {
      id
      owner
      title
      description
      release_date
      videos {
        items {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
    }
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
  }
}
`;
export const deletePendingVideo = `mutation DeletePendingVideo($input: DeletePendingVideoInput!) {
  deletePendingVideo(input: $input) {
    id
    owner
    title
    description
    status
    createdAt
    src_video
    pendingVideoCategoryId
    pendingVideoShowId
    pendingVideoDesignerId
    season
    episode
    duration
    category {
      id
      owner
      name
      description
      createdAt
      videos {
        items {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
    }
    show {
      id
      owner
      title
      description
      release_date
      videos {
        items {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
    }
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
  }
}
`;
export const createProductVideo = `mutation CreateProductVideo($input: CreateProductVideoInput!) {
  createProductVideo(input: $input) {
    id
    owner
    timestamp
    video {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    product {
      id
      upc
      owner
      name
      description
      caption
      collection
      attributes
      price
      image
      product_id
      meta
      active
      createdAt
      box_size
      designer
      productCategoryId
      mainProductCategoryId
      videos {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      relatedItems {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      relatedTo {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      category {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
      supplier {
        id
        name
        description
        address
        phone
        contact
        url
        api
        schema
        products {
          nextToken
        }
        owner
      }
    }
  }
}
`;
export const updateProductVideo = `mutation UpdateProductVideo($input: UpdateProductVideoInput!) {
  updateProductVideo(input: $input) {
    id
    owner
    timestamp
    video {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    product {
      id
      upc
      owner
      name
      description
      caption
      collection
      attributes
      price
      image
      product_id
      meta
      active
      createdAt
      box_size
      designer
      productCategoryId
      mainProductCategoryId
      videos {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      relatedItems {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      relatedTo {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      category {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
      supplier {
        id
        name
        description
        address
        phone
        contact
        url
        api
        schema
        products {
          nextToken
        }
        owner
      }
    }
  }
}
`;
export const deleteProductVideo = `mutation DeleteProductVideo($input: DeleteProductVideoInput!) {
  deleteProductVideo(input: $input) {
    id
    owner
    timestamp
    video {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    product {
      id
      upc
      owner
      name
      description
      caption
      collection
      attributes
      price
      image
      product_id
      meta
      active
      createdAt
      box_size
      designer
      productCategoryId
      mainProductCategoryId
      videos {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      relatedItems {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      relatedTo {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      category {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
      supplier {
        id
        name
        description
        address
        phone
        contact
        url
        api
        schema
        products {
          nextToken
        }
        owner
      }
    }
  }
}
`;
export const createProduct = `mutation CreateProduct($input: CreateProductInput!) {
  createProduct(input: $input) {
    id
    upc
    owner
    name
    description
    caption
    collection
    attributes
    price
    image
    product_id
    meta
    active
    createdAt
    box_size
    designer
    productCategoryId
    mainProductCategoryId
    videos {
      items {
        id
        owner
        timestamp
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        product {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
      }
      nextToken
    }
    relatedItems {
      items {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      nextToken
    }
    relatedTo {
      id
      upc
      owner
      name
      description
      caption
      collection
      attributes
      price
      image
      product_id
      meta
      active
      createdAt
      box_size
      designer
      productCategoryId
      mainProductCategoryId
      videos {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      relatedItems {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      relatedTo {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      category {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
      supplier {
        id
        name
        description
        address
        phone
        contact
        url
        api
        schema
        products {
          nextToken
        }
        owner
      }
    }
    category {
      id
      owner
      name
      slug
      image
      createdAt
      isMainCategory
      productCategoryMainCategoryId
      products {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      subCategories {
        items {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        nextToken
      }
      mainCategory {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
    }
    supplier {
      id
      name
      description
      address
      phone
      contact
      url
      api
      schema
      products {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      owner
    }
  }
}
`;
export const updateProduct = `mutation UpdateProduct($input: UpdateProductInput!) {
  updateProduct(input: $input) {
    id
    upc
    owner
    name
    description
    caption
    collection
    attributes
    price
    image
    product_id
    meta
    active
    createdAt
    box_size
    designer
    productCategoryId
    mainProductCategoryId
    videos {
      items {
        id
        owner
        timestamp
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        product {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
      }
      nextToken
    }
    relatedItems {
      items {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      nextToken
    }
    relatedTo {
      id
      upc
      owner
      name
      description
      caption
      collection
      attributes
      price
      image
      product_id
      meta
      active
      createdAt
      box_size
      designer
      productCategoryId
      mainProductCategoryId
      videos {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      relatedItems {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      relatedTo {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      category {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
      supplier {
        id
        name
        description
        address
        phone
        contact
        url
        api
        schema
        products {
          nextToken
        }
        owner
      }
    }
    category {
      id
      owner
      name
      slug
      image
      createdAt
      isMainCategory
      productCategoryMainCategoryId
      products {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      subCategories {
        items {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        nextToken
      }
      mainCategory {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
    }
    supplier {
      id
      name
      description
      address
      phone
      contact
      url
      api
      schema
      products {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      owner
    }
  }
}
`;
export const deleteProduct = `mutation DeleteProduct($input: DeleteProductInput!) {
  deleteProduct(input: $input) {
    id
    upc
    owner
    name
    description
    caption
    collection
    attributes
    price
    image
    product_id
    meta
    active
    createdAt
    box_size
    designer
    productCategoryId
    mainProductCategoryId
    videos {
      items {
        id
        owner
        timestamp
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        product {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
      }
      nextToken
    }
    relatedItems {
      items {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      nextToken
    }
    relatedTo {
      id
      upc
      owner
      name
      description
      caption
      collection
      attributes
      price
      image
      product_id
      meta
      active
      createdAt
      box_size
      designer
      productCategoryId
      mainProductCategoryId
      videos {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      relatedItems {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      relatedTo {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      category {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
      supplier {
        id
        name
        description
        address
        phone
        contact
        url
        api
        schema
        products {
          nextToken
        }
        owner
      }
    }
    category {
      id
      owner
      name
      slug
      image
      createdAt
      isMainCategory
      productCategoryMainCategoryId
      products {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      subCategories {
        items {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        nextToken
      }
      mainCategory {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
    }
    supplier {
      id
      name
      description
      address
      phone
      contact
      url
      api
      schema
      products {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      owner
    }
  }
}
`;
export const createProductCategory = `mutation CreateProductCategory($input: CreateProductCategoryInput!) {
  createProductCategory(input: $input) {
    id
    owner
    name
    slug
    image
    createdAt
    isMainCategory
    productCategoryMainCategoryId
    products {
      items {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      nextToken
    }
    subCategories {
      items {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
      nextToken
    }
    mainCategory {
      id
      owner
      name
      slug
      image
      createdAt
      isMainCategory
      productCategoryMainCategoryId
      products {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      subCategories {
        items {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        nextToken
      }
      mainCategory {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
    }
  }
}
`;
export const updateProductCategory = `mutation UpdateProductCategory($input: UpdateProductCategoryInput!) {
  updateProductCategory(input: $input) {
    id
    owner
    name
    slug
    image
    createdAt
    isMainCategory
    productCategoryMainCategoryId
    products {
      items {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      nextToken
    }
    subCategories {
      items {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
      nextToken
    }
    mainCategory {
      id
      owner
      name
      slug
      image
      createdAt
      isMainCategory
      productCategoryMainCategoryId
      products {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      subCategories {
        items {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        nextToken
      }
      mainCategory {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
    }
  }
}
`;
export const deleteProductCategory = `mutation DeleteProductCategory($input: DeleteProductCategoryInput!) {
  deleteProductCategory(input: $input) {
    id
    owner
    name
    slug
    image
    createdAt
    isMainCategory
    productCategoryMainCategoryId
    products {
      items {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      nextToken
    }
    subCategories {
      items {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
      nextToken
    }
    mainCategory {
      id
      owner
      name
      slug
      image
      createdAt
      isMainCategory
      productCategoryMainCategoryId
      products {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      subCategories {
        items {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        nextToken
      }
      mainCategory {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
    }
  }
}
`;
export const createSupplier = `mutation CreateSupplier($input: CreateSupplierInput!) {
  createSupplier(input: $input) {
    id
    name
    description
    address
    phone
    contact
    url
    api
    schema
    products {
      items {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      nextToken
    }
    owner
  }
}
`;
export const updateSupplier = `mutation UpdateSupplier($input: UpdateSupplierInput!) {
  updateSupplier(input: $input) {
    id
    name
    description
    address
    phone
    contact
    url
    api
    schema
    products {
      items {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      nextToken
    }
    owner
  }
}
`;
export const deleteSupplier = `mutation DeleteSupplier($input: DeleteSupplierInput!) {
  deleteSupplier(input: $input) {
    id
    name
    description
    address
    phone
    contact
    url
    api
    schema
    products {
      items {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      nextToken
    }
    owner
  }
}
`;
export const createOrder = `mutation CreateOrder($input: CreateOrderInput!) {
  createOrder(input: $input) {
    id
    owner
    customer {
      id
      owner
      email
      phone
      first_name
      last_name
      photo_url
      cover_url
      notes
      active
      createdAt
      watchlist {
        watchlistVideoId
        createdAt
      }
      currentVideo {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      history {
        historyVideoId
        createdAt
      }
      favorites {
        favoriteVideoId
        createdAt
      }
      userDesignerId
      orders {
        items {
          id
          owner
          orderCustomerId
          total
          order
          createdAt
        }
        nextToken
      }
      cart {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        owner
        items
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      designer {
        id
        owner
        email
        phone
        name
        photo_url
        bio
        location
        active
        createdAt
        pro
        profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        draft_profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subs {
          nextToken
        }
        tags {
          nextToken
        }
        pendingVideos {
          nextToken
        }
        videos {
          nextToken
        }
        projects {
          nextToken
        }
        posts {
          nextToken
        }
      }
    }
    orderCustomerId
    total
    order
    createdAt
  }
}
`;
export const updateOrder = `mutation UpdateOrder($input: UpdateOrderInput!) {
  updateOrder(input: $input) {
    id
    owner
    customer {
      id
      owner
      email
      phone
      first_name
      last_name
      photo_url
      cover_url
      notes
      active
      createdAt
      watchlist {
        watchlistVideoId
        createdAt
      }
      currentVideo {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      history {
        historyVideoId
        createdAt
      }
      favorites {
        favoriteVideoId
        createdAt
      }
      userDesignerId
      orders {
        items {
          id
          owner
          orderCustomerId
          total
          order
          createdAt
        }
        nextToken
      }
      cart {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        owner
        items
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      designer {
        id
        owner
        email
        phone
        name
        photo_url
        bio
        location
        active
        createdAt
        pro
        profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        draft_profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subs {
          nextToken
        }
        tags {
          nextToken
        }
        pendingVideos {
          nextToken
        }
        videos {
          nextToken
        }
        projects {
          nextToken
        }
        posts {
          nextToken
        }
      }
    }
    orderCustomerId
    total
    order
    createdAt
  }
}
`;
export const deleteOrder = `mutation DeleteOrder($input: DeleteOrderInput!) {
  deleteOrder(input: $input) {
    id
    owner
    customer {
      id
      owner
      email
      phone
      first_name
      last_name
      photo_url
      cover_url
      notes
      active
      createdAt
      watchlist {
        watchlistVideoId
        createdAt
      }
      currentVideo {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      history {
        historyVideoId
        createdAt
      }
      favorites {
        favoriteVideoId
        createdAt
      }
      userDesignerId
      orders {
        items {
          id
          owner
          orderCustomerId
          total
          order
          createdAt
        }
        nextToken
      }
      cart {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        owner
        items
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      designer {
        id
        owner
        email
        phone
        name
        photo_url
        bio
        location
        active
        createdAt
        pro
        profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        draft_profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subs {
          nextToken
        }
        tags {
          nextToken
        }
        pendingVideos {
          nextToken
        }
        videos {
          nextToken
        }
        projects {
          nextToken
        }
        posts {
          nextToken
        }
      }
    }
    orderCustomerId
    total
    order
    createdAt
  }
}
`;
export const createVideoCast = `mutation CreateVideoCast($input: CreateVideoCastInput!) {
  createVideoCast(input: $input) {
    id
    owner
    video {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
  }
}
`;
export const updateVideoCast = `mutation UpdateVideoCast($input: UpdateVideoCastInput!) {
  updateVideoCast(input: $input) {
    id
    owner
    video {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
  }
}
`;
export const deleteVideoCast = `mutation DeleteVideoCast($input: DeleteVideoCastInput!) {
  deleteVideoCast(input: $input) {
    id
    owner
    video {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
  }
}
`;
export const createDesigner = `mutation CreateDesigner($input: CreateDesignerInput!) {
  createDesigner(input: $input) {
    id
    owner
    email
    phone
    name
    photo_url
    bio
    location
    active
    createdAt
    pro
    profile {
      first_name
      last_name
      name
      bio
      company_name
      title
      address_line1
      address_line2
      city
      state
      zip_code
      country
      website_url
      allow_contact_email
      facebook_handle
      twitter_handle
      youtube_handle
      pinterest_handle
      instagram_handle
      linkedin_url
      blog_url
      google_plus_url
      houzz_url
      feature_video
      availables
      certifications
      photo_url
      cover_url
      status
    }
    draft_profile {
      first_name
      last_name
      name
      bio
      company_name
      title
      address_line1
      address_line2
      city
      state
      zip_code
      country
      website_url
      allow_contact_email
      facebook_handle
      twitter_handle
      youtube_handle
      pinterest_handle
      instagram_handle
      linkedin_url
      blog_url
      google_plus_url
      houzz_url
      feature_video
      availables
      certifications
      photo_url
      cover_url
      status
    }
    user {
      id
      owner
      email
      phone
      first_name
      last_name
      photo_url
      cover_url
      notes
      active
      createdAt
      watchlist {
        watchlistVideoId
        createdAt
      }
      currentVideo {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      history {
        historyVideoId
        createdAt
      }
      favorites {
        favoriteVideoId
        createdAt
      }
      userDesignerId
      orders {
        items {
          id
          owner
          orderCustomerId
          total
          order
          createdAt
        }
        nextToken
      }
      cart {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        owner
        items
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      designer {
        id
        owner
        email
        phone
        name
        photo_url
        bio
        location
        active
        createdAt
        pro
        profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        draft_profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subs {
          nextToken
        }
        tags {
          nextToken
        }
        pendingVideos {
          nextToken
        }
        videos {
          nextToken
        }
        projects {
          nextToken
        }
        posts {
          nextToken
        }
      }
    }
    subs {
      items {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subDesignerId
        stripeId
        stripeSubId
        createdAt
        owner
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    tags {
      items {
        id
        tagsDesignersTagId
        tagsDesignersDesignerId
        owner
        tag {
          id
          tag
          type
          owner
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    pendingVideos {
      items {
        id
        owner
        title
        description
        status
        createdAt
        src_video
        pendingVideoCategoryId
        pendingVideoShowId
        pendingVideoDesignerId
        season
        episode
        duration
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    videos {
      items {
        id
        owner
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    projects {
      items {
        id
        title
        description
        images
        videos
        projectDesignerId
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
        owner
      }
      nextToken
    }
    posts {
      items {
        id
        editor {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
        owner
        post {
          id
          owner
          title
          description
          content
          status
          thumbnail
          createdAt
          likes
          liked
        }
      }
      nextToken
    }
  }
}
`;
export const updateDesigner = `mutation UpdateDesigner($input: UpdateDesignerInput!) {
  updateDesigner(input: $input) {
    id
    owner
    email
    phone
    name
    photo_url
    bio
    location
    active
    createdAt
    pro
    profile {
      first_name
      last_name
      name
      bio
      company_name
      title
      address_line1
      address_line2
      city
      state
      zip_code
      country
      website_url
      allow_contact_email
      facebook_handle
      twitter_handle
      youtube_handle
      pinterest_handle
      instagram_handle
      linkedin_url
      blog_url
      google_plus_url
      houzz_url
      feature_video
      availables
      certifications
      photo_url
      cover_url
      status
    }
    draft_profile {
      first_name
      last_name
      name
      bio
      company_name
      title
      address_line1
      address_line2
      city
      state
      zip_code
      country
      website_url
      allow_contact_email
      facebook_handle
      twitter_handle
      youtube_handle
      pinterest_handle
      instagram_handle
      linkedin_url
      blog_url
      google_plus_url
      houzz_url
      feature_video
      availables
      certifications
      photo_url
      cover_url
      status
    }
    user {
      id
      owner
      email
      phone
      first_name
      last_name
      photo_url
      cover_url
      notes
      active
      createdAt
      watchlist {
        watchlistVideoId
        createdAt
      }
      currentVideo {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      history {
        historyVideoId
        createdAt
      }
      favorites {
        favoriteVideoId
        createdAt
      }
      userDesignerId
      orders {
        items {
          id
          owner
          orderCustomerId
          total
          order
          createdAt
        }
        nextToken
      }
      cart {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        owner
        items
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      designer {
        id
        owner
        email
        phone
        name
        photo_url
        bio
        location
        active
        createdAt
        pro
        profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        draft_profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subs {
          nextToken
        }
        tags {
          nextToken
        }
        pendingVideos {
          nextToken
        }
        videos {
          nextToken
        }
        projects {
          nextToken
        }
        posts {
          nextToken
        }
      }
    }
    subs {
      items {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subDesignerId
        stripeId
        stripeSubId
        createdAt
        owner
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    tags {
      items {
        id
        tagsDesignersTagId
        tagsDesignersDesignerId
        owner
        tag {
          id
          tag
          type
          owner
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    pendingVideos {
      items {
        id
        owner
        title
        description
        status
        createdAt
        src_video
        pendingVideoCategoryId
        pendingVideoShowId
        pendingVideoDesignerId
        season
        episode
        duration
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    videos {
      items {
        id
        owner
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    projects {
      items {
        id
        title
        description
        images
        videos
        projectDesignerId
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
        owner
      }
      nextToken
    }
    posts {
      items {
        id
        editor {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
        owner
        post {
          id
          owner
          title
          description
          content
          status
          thumbnail
          createdAt
          likes
          liked
        }
      }
      nextToken
    }
  }
}
`;
export const deleteDesigner = `mutation DeleteDesigner($input: DeleteDesignerInput!) {
  deleteDesigner(input: $input) {
    id
    owner
    email
    phone
    name
    photo_url
    bio
    location
    active
    createdAt
    pro
    profile {
      first_name
      last_name
      name
      bio
      company_name
      title
      address_line1
      address_line2
      city
      state
      zip_code
      country
      website_url
      allow_contact_email
      facebook_handle
      twitter_handle
      youtube_handle
      pinterest_handle
      instagram_handle
      linkedin_url
      blog_url
      google_plus_url
      houzz_url
      feature_video
      availables
      certifications
      photo_url
      cover_url
      status
    }
    draft_profile {
      first_name
      last_name
      name
      bio
      company_name
      title
      address_line1
      address_line2
      city
      state
      zip_code
      country
      website_url
      allow_contact_email
      facebook_handle
      twitter_handle
      youtube_handle
      pinterest_handle
      instagram_handle
      linkedin_url
      blog_url
      google_plus_url
      houzz_url
      feature_video
      availables
      certifications
      photo_url
      cover_url
      status
    }
    user {
      id
      owner
      email
      phone
      first_name
      last_name
      photo_url
      cover_url
      notes
      active
      createdAt
      watchlist {
        watchlistVideoId
        createdAt
      }
      currentVideo {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      history {
        historyVideoId
        createdAt
      }
      favorites {
        favoriteVideoId
        createdAt
      }
      userDesignerId
      orders {
        items {
          id
          owner
          orderCustomerId
          total
          order
          createdAt
        }
        nextToken
      }
      cart {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        owner
        items
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      designer {
        id
        owner
        email
        phone
        name
        photo_url
        bio
        location
        active
        createdAt
        pro
        profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        draft_profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subs {
          nextToken
        }
        tags {
          nextToken
        }
        pendingVideos {
          nextToken
        }
        videos {
          nextToken
        }
        projects {
          nextToken
        }
        posts {
          nextToken
        }
      }
    }
    subs {
      items {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subDesignerId
        stripeId
        stripeSubId
        createdAt
        owner
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    tags {
      items {
        id
        tagsDesignersTagId
        tagsDesignersDesignerId
        owner
        tag {
          id
          tag
          type
          owner
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    pendingVideos {
      items {
        id
        owner
        title
        description
        status
        createdAt
        src_video
        pendingVideoCategoryId
        pendingVideoShowId
        pendingVideoDesignerId
        season
        episode
        duration
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    videos {
      items {
        id
        owner
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    projects {
      items {
        id
        title
        description
        images
        videos
        projectDesignerId
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
        owner
      }
      nextToken
    }
    posts {
      items {
        id
        editor {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
        owner
        post {
          id
          owner
          title
          description
          content
          status
          thumbnail
          createdAt
          likes
          liked
        }
      }
      nextToken
    }
  }
}
`;
export const createProject = `mutation CreateProject($input: CreateProjectInput!) {
  createProject(input: $input) {
    id
    title
    description
    images
    videos
    projectDesignerId
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
    owner
  }
}
`;
export const updateProject = `mutation UpdateProject($input: UpdateProjectInput!) {
  updateProject(input: $input) {
    id
    title
    description
    images
    videos
    projectDesignerId
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
    owner
  }
}
`;
export const deleteProject = `mutation DeleteProject($input: DeleteProjectInput!) {
  deleteProject(input: $input) {
    id
    title
    description
    images
    videos
    projectDesignerId
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
    owner
  }
}
`;
export const createPostEditor = `mutation CreatePostEditor($input: CreatePostEditorInput!) {
  createPostEditor(input: $input) {
    id
    editor {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
    owner
    post {
      id
      owner
      title
      description
      content
      status
      thumbnail
      createdAt
      likes
      liked
      editors {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
  }
}
`;
export const updatePostEditor = `mutation UpdatePostEditor($input: UpdatePostEditorInput!) {
  updatePostEditor(input: $input) {
    id
    editor {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
    owner
    post {
      id
      owner
      title
      description
      content
      status
      thumbnail
      createdAt
      likes
      liked
      editors {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
  }
}
`;
export const deletePostEditor = `mutation DeletePostEditor($input: DeletePostEditorInput!) {
  deletePostEditor(input: $input) {
    id
    editor {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
    owner
    post {
      id
      owner
      title
      description
      content
      status
      thumbnail
      createdAt
      likes
      liked
      editors {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
  }
}
`;
export const createPost = `mutation CreatePost($input: CreatePostInput!) {
  createPost(input: $input) {
    id
    owner
    title
    description
    content
    status
    thumbnail
    createdAt
    likes
    liked
    editors {
      items {
        id
        editor {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
        owner
        post {
          id
          owner
          title
          description
          content
          status
          thumbnail
          createdAt
          likes
          liked
        }
      }
      nextToken
    }
    comments {
      items {
        id
        owner
        content
        avatar
        name
        commentPostId
        commentVideoId
        createdAt
        likes
        subs
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        post {
          id
          owner
          title
          description
          content
          status
          thumbnail
          createdAt
          likes
          liked
        }
      }
      nextToken
    }
  }
}
`;
export const updatePost = `mutation UpdatePost($input: UpdatePostInput!) {
  updatePost(input: $input) {
    id
    owner
    title
    description
    content
    status
    thumbnail
    createdAt
    likes
    liked
    editors {
      items {
        id
        editor {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
        owner
        post {
          id
          owner
          title
          description
          content
          status
          thumbnail
          createdAt
          likes
          liked
        }
      }
      nextToken
    }
    comments {
      items {
        id
        owner
        content
        avatar
        name
        commentPostId
        commentVideoId
        createdAt
        likes
        subs
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        post {
          id
          owner
          title
          description
          content
          status
          thumbnail
          createdAt
          likes
          liked
        }
      }
      nextToken
    }
  }
}
`;
export const deletePost = `mutation DeletePost($input: DeletePostInput!) {
  deletePost(input: $input) {
    id
    owner
    title
    description
    content
    status
    thumbnail
    createdAt
    likes
    liked
    editors {
      items {
        id
        editor {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
        owner
        post {
          id
          owner
          title
          description
          content
          status
          thumbnail
          createdAt
          likes
          liked
        }
      }
      nextToken
    }
    comments {
      items {
        id
        owner
        content
        avatar
        name
        commentPostId
        commentVideoId
        createdAt
        likes
        subs
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        post {
          id
          owner
          title
          description
          content
          status
          thumbnail
          createdAt
          likes
          liked
        }
      }
      nextToken
    }
  }
}
`;
export const createComment = `mutation CreateComment($input: CreateCommentInput!) {
  createComment(input: $input) {
    id
    owner
    content
    avatar
    name
    commentPostId
    commentVideoId
    createdAt
    likes
    subs
    video {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    post {
      id
      owner
      title
      description
      content
      status
      thumbnail
      createdAt
      likes
      liked
      editors {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
  }
}
`;
export const updateComment = `mutation UpdateComment($input: UpdateCommentInput!) {
  updateComment(input: $input) {
    id
    owner
    content
    avatar
    name
    commentPostId
    commentVideoId
    createdAt
    likes
    subs
    video {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    post {
      id
      owner
      title
      description
      content
      status
      thumbnail
      createdAt
      likes
      liked
      editors {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
  }
}
`;
export const deleteComment = `mutation DeleteComment($input: DeleteCommentInput!) {
  deleteComment(input: $input) {
    id
    owner
    content
    avatar
    name
    commentPostId
    commentVideoId
    createdAt
    likes
    subs
    video {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    post {
      id
      owner
      title
      description
      content
      status
      thumbnail
      createdAt
      likes
      liked
      editors {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
  }
}
`;
export const createLike = `mutation CreateLike($input: CreateLikeInput!) {
  createLike(input: $input) {
    id
    subject
    objectId
    createdBy
    createdAt
    liked
    owner
  }
}
`;
export const updateLike = `mutation UpdateLike($input: UpdateLikeInput!) {
  updateLike(input: $input) {
    id
    subject
    objectId
    createdBy
    createdAt
    liked
    owner
  }
}
`;
export const deleteLike = `mutation DeleteLike($input: DeleteLikeInput!) {
  deleteLike(input: $input) {
    id
    subject
    objectId
    createdBy
    createdAt
    liked
    owner
  }
}
`;
