import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Grid, LinearProgress } from '@material-ui/core';
import { PostDetails } from './components';
import uuid from 'uuid';

//graphql stuff
import gql from 'graphql-tag';
import { getPost } from '../../../../../../../../graphql/queries';
import { useQuery } from 'react-apollo-hooks';

const getPostQuery = gql`
  ${getPost}
`;

const styles = theme => ({
  root: {
    padding: theme.spacing(4)
  },
  button: {
    paddingBottom: '5px'
  }
});

const PostDetail = props => {
  const { id, classes, onClose } = props;
  const { data, loading } = useQuery(getPostQuery, { variables: { id } });
  const [post, setPost] = useState({ id: uuid(), title: '', content: '', status: 'draft', thumbnail: '' });

  useEffect(() => {
    if (props.post) {
      return setPost(props.post);
    }
    if (data.getPost && data.getPost !== 'undefined') setPost(data.getPost);
  }, [data, props.post]);

  return (
    <div className={classes.root}>
      {(loading || post.thumbnail === '') && !!id ? (
        <div className={classes.progressWrapper}>
          <LinearProgress />
        </div>
      ) : (
        <Grid container spacing={4}>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <PostDetails user post={post} {...props} onClose={onClose} />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

PostDetail.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PostDetail);
