import React, { useState, useEffect } from 'react';
import { withStyles, Grid, GridList, GridListTile, GridListTileBar, Paper, useMediaQuery, Typography, Button, LinearProgress } from '@material-ui/core';
import { SearchInput } from 'components';
import ProductModal from 'components/ProductModal';
import ProductModalMobile from 'components/ProductModalMobile';
import styles from './styles';
import { currencyFormat, formatImage } from '../../../../utils';
import { GridListLoadingHolder } from '../../../../components/LoadingHolder';

const Products = props => {
  const { classes, products, category, loading } = props;
  const [search, setSearch] = useState('');
  const [total, setTotal] = useState(0);
  const [numShow, setNumShow] = useState(20);

  const [product, setProduct] = useState([]);
  const [open, setOpen] = useState(false);
  const [offset, setOffset] = useState(0);

  const onSearch = event => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    if (products) setTotal(products.length);
  }, [products]);

  useEffect(() => {
    let parallaxShift = () => {
      setOffset(window.pageYOffset);
    };

    window.addEventListener('scroll', parallaxShift);

    return () => {
      window.removeEventListener('scroll', parallaxShift);
    };
  }, []);

  const isThereACurrencyPrice = product => {
    try {
      if (product.skus.length === 0) {
        throw new Error('No skus found!');
      }

      if (product.skus.length === 1) {
        return <div className="price">{currencyFormat(product.skus[0].price)}</div>;
      }

      let max_sku = product.skus[0];
      let min_sku = product.skus[0];
      for (let sku of product.skus) {
        if (sku.price < min_sku.price) {
          min_sku = sku;
        } else if (sku.price > max_sku.price) {
          max_sku = sku;
        }
      }
      return <div className="price">{`${currencyFormat(min_sku.price)} - ${currencyFormat(max_sku.price)}`}</div>;
    } catch (e) {
      return <div className="price">Price not available</div>;
    }
  };
  const openProductModal = product => {
    setProduct(product);
    toggleModal();
  };

  const toggleModal = () => {
    setOpen(!open);
  };

  function filterProducts(q, item, index) {
    if (index && index > numShow) return false;
    function escapeRegExp(s) {
      return s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
    }
    const words = q
      .split(/\s+/g)
      .map(s => s.trim())
      .filter(s => !!s);
    const hasTrailingSpace = q.endsWith(' ');
    const searchRegex = new RegExp(
      words
        .map((word, i) => {
          if (i + 1 === words.length && !hasTrailingSpace) {
            // The last word - ok with the word being "startswith"-like
            return `(?=.*\\b${escapeRegExp(word)})`;
          } else {
            // Not the last word - expect the whole word exactly
            return `(?=.*\\b${escapeRegExp(word)}\\b)`;
          }
        })
        .join('') + '.+',
      'gi'
    );
    return searchRegex.test(item.id) || searchRegex.test(item.name);
  }

  function inverse_slugify(s) {
    return s
      .toLowerCase()
      .split('_')
      .map(i => i[0].toUpperCase() + i.substr(1))
      .join(' ');
  }

  const xl = useMediaQuery(theme => theme.breakpoints.up('xl'));
  const lg = useMediaQuery(theme => theme.breakpoints.up('lg'));
  const md = useMediaQuery(theme => theme.breakpoints.up('md'));
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const getGridListCols = () => {
    if (xl) {
      return 4;
    }

    if (lg) {
      return 3;
    }

    if (md) {
      return 2;
    }

    return 1;
  };

  return (
    <div style={{ marginTop: '-72px' }}>
      <div className={classes.headerImgContainer}>
        <div
          className={classes.parallaxImage}
          style={{
            backgroundImage: `url(${category ? formatImage(category.image) : ''})`,
            backgroundColor: `rgba(0,0,0,.5)`,
            backgroundPositionY: (-1 * offset) / 4,
            backgroundAttachment: 'fixed',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '90vh',
            width: '100%'
          }}
        />
        <h1
          className={category ? classes.h1 : classes.titleHolder}
          style={{ WebkitTransform: 'translate3d(0,' + Math.round(offset / 2) + 'px,0) scale(' + Math.max(0, 1 - offset / 900) + ')', willChange: 'transform' }}
        >
          {category && category.name}
        </h1>
        <div className={classes.overlay} />
      </div>
      <div className={classes.section}>
        <Paper elevation={20}>
          <div className={classes.card}>
            <SearchInput className={classes.searchInput} placeholder="Search products" onChange={onSearch} />
            {loading || !products ? (
              <GridListLoadingHolder numTiles={0} cellHeight={375} className={classes.gridList} cols={getGridListCols()} />
            ) : (
              <GridList cellHeight={375} className={classes.gridList} cols={getGridListCols()}>
                {products
                  .filter((product, index) => filterProducts(search, product, index))
                  .map(product => {
                    return (
                      <GridListTile key={product.id} onClick={() => openProductModal(product)}>
                        <img alt="product images" src={product.images[0]} />
                        <GridListTileBar
                          classes={{ title: classes.productTitle, subtitle: classes.productSubtitle, root: classes.productTitleBar, titleWrap: classes.productTitleWrap }}
                          title={product.name}
                          subtitle={<span>{isThereACurrencyPrice(product)}</span>}
                        />
                      </GridListTile>
                    );
                  })}
                {(!products || products.length === 0) && <Typography variant={'caption'}>No products found</Typography>}
                {numShow < total && (
                  <Grid item xs={12} className={classes.loadMore}>
                    <Button variant={'outlined'} onClick={() => setNumShow(numShow + 24)}>
                      Load more
                    </Button>
                  </Grid>
                )}
              </GridList>
            )}
            {product.id &&
              (!mobile ? <ProductModal product={product} open={open} toggleModal={toggleModal} /> : <ProductModalMobile product={product} open={open} toggleModal={toggleModal} />)}
          </div>
        </Paper>
      </div>
    </div>
  );
};
export default withStyles(styles)(Products);
