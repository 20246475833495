import React, { useState } from 'react';
import styled from 'styled-components';
import { Card, CardActionArea, CardContent, GridList, GridListTile, GridListTileBar, LinearProgress } from '@material-ui/core';

export const HeaderLoadingHolder = styled.div`
  margin: 10px 0;
  box-sizing: border-box;
  height: 50px;
  width: 75%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.2);
`;

export const TextLoadingHolder = styled.div`
  margin: 15px 0;
  box-sizing: border-box;
  height: 35px;
  width: 70%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.2);
`;

export const VideoLoadingHolder = styled.div`
  margin: 10px 0;
  box-sizing: border-box;
  width: 100%;
  padding-bottom: 60%;
  background: rgba(0, 0, 0, 0.2);
`;

export const ImageLoadingHolder = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.2);
`;

export const CardLoadingHolder = () => (
  <Card>
    <CardActionArea>
      <VideoLoadingHolder />
      <CardContent>
        <TextLoadingHolder />
      </CardContent>
    </CardActionArea>
  </Card>
);

export const GridListLoadingHolder = ({ numTiles = 8, ...props }) => {
  const [tiles] = useState(Array.from({ length: numTiles - 1 }, (_, i) => i.toString()));

  return (
    <>
      {numTiles === 0 ? (
        <GridList {...props} cols={1}>
          <GridListTile>
            <LinearProgress />
          </GridListTile>
        </GridList>
      ) : (
        <GridList {...props}>
          <GridListTile>
            <LinearProgress />
            <ImageLoadingHolder />
            <GridListTileBar />
          </GridListTile>
          {tiles.map(tile => (
            <GridListTile key={tile}>
              <ImageLoadingHolder />
              <GridListTileBar />
            </GridListTile>
          ))}
        </GridList>
      )}
    </>
  );
};
