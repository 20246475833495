/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    owner
    email
    phone
    first_name
    last_name
    photo_url
    cover_url
    notes
    active
    createdAt
    watchlist {
      watchlistVideoId
      createdAt
    }
    currentVideo {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    history {
      historyVideoId
      createdAt
    }
    favorites {
      favoriteVideoId
      createdAt
    }
    userDesignerId
    orders {
      items {
        id
        owner
        customer {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        orderCustomerId
        total
        order
        createdAt
      }
      nextToken
    }
    cart {
      id
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      owner
      items
    }
    subs {
      items {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subDesignerId
        stripeId
        stripeSubId
        createdAt
        owner
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
  }
}
`;
export const listUsers = `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      email
      phone
      first_name
      last_name
      photo_url
      cover_url
      notes
      active
      createdAt
      watchlist {
        watchlistVideoId
        createdAt
      }
      currentVideo {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      history {
        historyVideoId
        createdAt
      }
      favorites {
        favoriteVideoId
        createdAt
      }
      userDesignerId
      orders {
        items {
          id
          owner
          orderCustomerId
          total
          order
          createdAt
        }
        nextToken
      }
      cart {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        owner
        items
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      designer {
        id
        owner
        email
        phone
        name
        photo_url
        bio
        location
        active
        createdAt
        pro
        profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        draft_profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subs {
          nextToken
        }
        tags {
          nextToken
        }
        pendingVideos {
          nextToken
        }
        videos {
          nextToken
        }
        projects {
          nextToken
        }
        posts {
          nextToken
        }
      }
    }
    nextToken
  }
}
`;
export const getCart = `query GetCart($id: ID!) {
  getCart(id: $id) {
    id
    user {
      id
      owner
      email
      phone
      first_name
      last_name
      photo_url
      cover_url
      notes
      active
      createdAt
      watchlist {
        watchlistVideoId
        createdAt
      }
      currentVideo {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      history {
        historyVideoId
        createdAt
      }
      favorites {
        favoriteVideoId
        createdAt
      }
      userDesignerId
      orders {
        items {
          id
          owner
          orderCustomerId
          total
          order
          createdAt
        }
        nextToken
      }
      cart {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        owner
        items
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      designer {
        id
        owner
        email
        phone
        name
        photo_url
        bio
        location
        active
        createdAt
        pro
        profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        draft_profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subs {
          nextToken
        }
        tags {
          nextToken
        }
        pendingVideos {
          nextToken
        }
        videos {
          nextToken
        }
        projects {
          nextToken
        }
        posts {
          nextToken
        }
      }
    }
    owner
    items
  }
}
`;
export const listCarts = `query ListCarts(
  $filter: ModelCartFilterInput
  $limit: Int
  $nextToken: String
) {
  listCarts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      owner
      items
    }
    nextToken
  }
}
`;
export const getOrder = `query GetOrder($id: ID!) {
  getOrder(id: $id) {
    id
    owner
    customer {
      id
      owner
      email
      phone
      first_name
      last_name
      photo_url
      cover_url
      notes
      active
      createdAt
      watchlist {
        watchlistVideoId
        createdAt
      }
      currentVideo {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      history {
        historyVideoId
        createdAt
      }
      favorites {
        favoriteVideoId
        createdAt
      }
      userDesignerId
      orders {
        items {
          id
          owner
          orderCustomerId
          total
          order
          createdAt
        }
        nextToken
      }
      cart {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        owner
        items
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      designer {
        id
        owner
        email
        phone
        name
        photo_url
        bio
        location
        active
        createdAt
        pro
        profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        draft_profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subs {
          nextToken
        }
        tags {
          nextToken
        }
        pendingVideos {
          nextToken
        }
        videos {
          nextToken
        }
        projects {
          nextToken
        }
        posts {
          nextToken
        }
      }
    }
    orderCustomerId
    total
    order
    createdAt
  }
}
`;
export const listOrders = `query ListOrders(
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      customer {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      orderCustomerId
      total
      order
      createdAt
    }
    nextToken
  }
}
`;
export const getSub = `query GetSub($subDesignerId: String!, $createdAt: AWSDateTime!) {
  getSub(subDesignerId: $subDesignerId, createdAt: $createdAt) {
    id
    user {
      id
      owner
      email
      phone
      first_name
      last_name
      photo_url
      cover_url
      notes
      active
      createdAt
      watchlist {
        watchlistVideoId
        createdAt
      }
      currentVideo {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      history {
        historyVideoId
        createdAt
      }
      favorites {
        favoriteVideoId
        createdAt
      }
      userDesignerId
      orders {
        items {
          id
          owner
          orderCustomerId
          total
          order
          createdAt
        }
        nextToken
      }
      cart {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        owner
        items
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      designer {
        id
        owner
        email
        phone
        name
        photo_url
        bio
        location
        active
        createdAt
        pro
        profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        draft_profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subs {
          nextToken
        }
        tags {
          nextToken
        }
        pendingVideos {
          nextToken
        }
        videos {
          nextToken
        }
        projects {
          nextToken
        }
        posts {
          nextToken
        }
      }
    }
    subDesignerId
    stripeId
    stripeSubId
    createdAt
    owner
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
  }
}
`;
export const listSubs = `query ListSubs(
  $subDesignerId: String
  $createdAt: ModelStringKeyConditionInput
  $filter: ModelSubFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listSubs(
    subDesignerId: $subDesignerId
    createdAt: $createdAt
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subDesignerId
      stripeId
      stripeSubId
      createdAt
      owner
      designer {
        id
        owner
        email
        phone
        name
        photo_url
        bio
        location
        active
        createdAt
        pro
        profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        draft_profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subs {
          nextToken
        }
        tags {
          nextToken
        }
        pendingVideos {
          nextToken
        }
        videos {
          nextToken
        }
        projects {
          nextToken
        }
        posts {
          nextToken
        }
      }
    }
    nextToken
  }
}
`;
export const listCategorys = `query ListCategorys(
  $filter: ModelCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      name
      description
      createdAt
      videos {
        items {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getCategory = `query GetCategory($id: ID!) {
  getCategory(id: $id) {
    id
    owner
    name
    description
    createdAt
    videos {
      items {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      nextToken
    }
    pendingVideos {
      items {
        id
        owner
        title
        description
        status
        createdAt
        src_video
        pendingVideoCategoryId
        pendingVideoShowId
        pendingVideoDesignerId
        season
        episode
        duration
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
  }
}
`;
export const listShows = `query ListShows(
  $filter: ModelShowFilterInput
  $limit: Int
  $nextToken: String
) {
  listShows(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      title
      description
      release_date
      videos {
        items {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getShow = `query GetShow($id: ID!) {
  getShow(id: $id) {
    id
    owner
    title
    description
    release_date
    videos {
      items {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      nextToken
    }
    pendingVideos {
      items {
        id
        owner
        title
        description
        status
        createdAt
        src_video
        pendingVideoCategoryId
        pendingVideoShowId
        pendingVideoDesignerId
        season
        episode
        duration
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
  }
}
`;
export const listVideos = `query ListVideos(
  $filter: ModelVideoFilterInput
  $limit: Int
  $nextToken: String
) {
  listVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getVideo = `query GetVideo($id: ID!) {
  getVideo(id: $id) {
    id
    owner
    title
    description
    status
    url
    thumbnail
    createdAt
    views {
      user
      video
      createdAt
    }
    season
    episode
    release_date
    duration
    likes
    src_video
    category {
      id
      owner
      name
      description
      createdAt
      videos {
        items {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
    }
    show {
      id
      owner
      title
      description
      release_date
      videos {
        items {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
    }
    tags {
      items {
        id
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        owner
        tag {
          id
          tag
          type
          owner
        }
      }
      nextToken
    }
    products {
      items {
        id
        owner
        timestamp
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        product {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
      }
      nextToken
    }
    designers {
      items {
        id
        owner
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    comments {
      items {
        id
        owner
        content
        avatar
        name
        commentPostId
        commentVideoId
        createdAt
        likes
        subs
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        post {
          id
          owner
          title
          description
          content
          status
          thumbnail
          createdAt
          likes
          liked
        }
      }
      nextToken
    }
  }
}
`;
export const searchVideos = `query SearchVideos(
  $filter: SearchableVideoFilterInput
  $sort: SearchableVideoSortInput
  $limit: Int
  $nextToken: String
) {
  searchVideos(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    nextToken
    total
  }
}
`;
export const getTagsDesigners = `query GetTagsDesigners($id: ID!) {
  getTagsDesigners(id: $id) {
    id
    tagsDesignersTagId
    tagsDesignersDesignerId
    owner
    tag {
      id
      tag
      type
      designers {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      owner
    }
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
  }
}
`;
export const listTagsDesignerss = `query ListTagsDesignerss(
  $filter: ModelTagsDesignersFilterInput
  $limit: Int
  $nextToken: String
) {
  listTagsDesignerss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tagsDesignersTagId
      tagsDesignersDesignerId
      owner
      tag {
        id
        tag
        type
        designers {
          nextToken
        }
        videos {
          nextToken
        }
        owner
      }
      designer {
        id
        owner
        email
        phone
        name
        photo_url
        bio
        location
        active
        createdAt
        pro
        profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        draft_profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subs {
          nextToken
        }
        tags {
          nextToken
        }
        pendingVideos {
          nextToken
        }
        videos {
          nextToken
        }
        projects {
          nextToken
        }
        posts {
          nextToken
        }
      }
    }
    nextToken
  }
}
`;
export const getTagsVideos = `query GetTagsVideos($id: ID!) {
  getTagsVideos(id: $id) {
    id
    video {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    owner
    tag {
      id
      tag
      type
      designers {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      owner
    }
  }
}
`;
export const listTagsVideoss = `query ListTagsVideoss(
  $filter: ModelTagsVideosFilterInput
  $limit: Int
  $nextToken: String
) {
  listTagsVideoss(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      video {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      owner
      tag {
        id
        tag
        type
        designers {
          nextToken
        }
        videos {
          nextToken
        }
        owner
      }
    }
    nextToken
  }
}
`;
export const listTags = `query ListTags($filter: ModelTagFilterInput, $limit: Int, $nextToken: String) {
  listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tag
      type
      designers {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      owner
    }
    nextToken
  }
}
`;
export const getTag = `query GetTag($id: ID!) {
  getTag(id: $id) {
    id
    tag
    type
    designers {
      items {
        id
        tagsDesignersTagId
        tagsDesignersDesignerId
        owner
        tag {
          id
          tag
          type
          owner
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    videos {
      items {
        id
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        owner
        tag {
          id
          tag
          type
          owner
        }
      }
      nextToken
    }
    owner
  }
}
`;
export const getPendingVideo = `query GetPendingVideo($id: ID!) {
  getPendingVideo(id: $id) {
    id
    owner
    title
    description
    status
    createdAt
    src_video
    pendingVideoCategoryId
    pendingVideoShowId
    pendingVideoDesignerId
    season
    episode
    duration
    category {
      id
      owner
      name
      description
      createdAt
      videos {
        items {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
    }
    show {
      id
      owner
      title
      description
      release_date
      videos {
        items {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
    }
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
  }
}
`;
export const listPendingVideos = `query ListPendingVideos(
  $filter: ModelPendingVideoFilterInput
  $limit: Int
  $nextToken: String
) {
  listPendingVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      title
      description
      status
      createdAt
      src_video
      pendingVideoCategoryId
      pendingVideoShowId
      pendingVideoDesignerId
      season
      episode
      duration
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      designer {
        id
        owner
        email
        phone
        name
        photo_url
        bio
        location
        active
        createdAt
        pro
        profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        draft_profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subs {
          nextToken
        }
        tags {
          nextToken
        }
        pendingVideos {
          nextToken
        }
        videos {
          nextToken
        }
        projects {
          nextToken
        }
        posts {
          nextToken
        }
      }
    }
    nextToken
  }
}
`;
export const getProductVideo = `query GetProductVideo($id: ID!) {
  getProductVideo(id: $id) {
    id
    owner
    timestamp
    video {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    product {
      id
      upc
      owner
      name
      description
      caption
      collection
      attributes
      price
      image
      product_id
      meta
      active
      createdAt
      box_size
      designer
      productCategoryId
      mainProductCategoryId
      videos {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      relatedItems {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      relatedTo {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      category {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
      supplier {
        id
        name
        description
        address
        phone
        contact
        url
        api
        schema
        products {
          nextToken
        }
        owner
      }
    }
  }
}
`;
export const listProductVideos = `query ListProductVideos(
  $filter: ModelProductVideoFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      timestamp
      video {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      product {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
    }
    nextToken
  }
}
`;
export const getProduct = `query GetProduct($id: ID!) {
  getProduct(id: $id) {
    id
    upc
    owner
    name
    description
    caption
    collection
    attributes
    price
    image
    product_id
    meta
    active
    createdAt
    box_size
    designer
    productCategoryId
    mainProductCategoryId
    videos {
      items {
        id
        owner
        timestamp
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        product {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
      }
      nextToken
    }
    relatedItems {
      items {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      nextToken
    }
    relatedTo {
      id
      upc
      owner
      name
      description
      caption
      collection
      attributes
      price
      image
      product_id
      meta
      active
      createdAt
      box_size
      designer
      productCategoryId
      mainProductCategoryId
      videos {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      relatedItems {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      relatedTo {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      category {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
      supplier {
        id
        name
        description
        address
        phone
        contact
        url
        api
        schema
        products {
          nextToken
        }
        owner
      }
    }
    category {
      id
      owner
      name
      slug
      image
      createdAt
      isMainCategory
      productCategoryMainCategoryId
      products {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      subCategories {
        items {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        nextToken
      }
      mainCategory {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
    }
    supplier {
      id
      name
      description
      address
      phone
      contact
      url
      api
      schema
      products {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      owner
    }
  }
}
`;
export const listProducts = `query ListProducts(
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      upc
      owner
      name
      description
      caption
      collection
      attributes
      price
      image
      product_id
      meta
      active
      createdAt
      box_size
      designer
      productCategoryId
      mainProductCategoryId
      videos {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      relatedItems {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      relatedTo {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      category {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
      supplier {
        id
        name
        description
        address
        phone
        contact
        url
        api
        schema
        products {
          nextToken
        }
        owner
      }
    }
    nextToken
  }
}
`;
export const searchProducts = `query SearchProducts(
  $filter: SearchableProductFilterInput
  $sort: SearchableProductSortInput
  $limit: Int
  $nextToken: String
) {
  searchProducts(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      upc
      owner
      name
      description
      caption
      collection
      attributes
      price
      image
      product_id
      meta
      active
      createdAt
      box_size
      designer
      productCategoryId
      mainProductCategoryId
      videos {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      relatedItems {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      relatedTo {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      category {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
      supplier {
        id
        name
        description
        address
        phone
        contact
        url
        api
        schema
        products {
          nextToken
        }
        owner
      }
    }
    nextToken
    total
  }
}
`;
export const listProductCategorys = `query ListProductCategorys(
  $filter: ModelProductCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      name
      slug
      image
      createdAt
      isMainCategory
      productCategoryMainCategoryId
      products {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      subCategories {
        items {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        nextToken
      }
      mainCategory {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
    }
    nextToken
  }
}
`;
export const getProductCategory = `query GetProductCategory($id: ID!) {
  getProductCategory(id: $id) {
    id
    owner
    name
    slug
    image
    createdAt
    isMainCategory
    productCategoryMainCategoryId
    products {
      items {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      nextToken
    }
    subCategories {
      items {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
      nextToken
    }
    mainCategory {
      id
      owner
      name
      slug
      image
      createdAt
      isMainCategory
      productCategoryMainCategoryId
      products {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      subCategories {
        items {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        nextToken
      }
      mainCategory {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
    }
  }
}
`;
export const listSuppliers = `query ListSuppliers(
  $filter: ModelSupplierFilterInput
  $limit: Int
  $nextToken: String
) {
  listSuppliers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      address
      phone
      contact
      url
      api
      schema
      products {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      owner
    }
    nextToken
  }
}
`;
export const getSupplier = `query GetSupplier($id: ID!) {
  getSupplier(id: $id) {
    id
    name
    description
    address
    phone
    contact
    url
    api
    schema
    products {
      items {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      nextToken
    }
    owner
  }
}
`;
export const getVideoCast = `query GetVideoCast($id: ID!) {
  getVideoCast(id: $id) {
    id
    owner
    video {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
  }
}
`;
export const listVideoCasts = `query ListVideoCasts(
  $filter: ModelVideoCastFilterInput
  $limit: Int
  $nextToken: String
) {
  listVideoCasts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      video {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      designer {
        id
        owner
        email
        phone
        name
        photo_url
        bio
        location
        active
        createdAt
        pro
        profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        draft_profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subs {
          nextToken
        }
        tags {
          nextToken
        }
        pendingVideos {
          nextToken
        }
        videos {
          nextToken
        }
        projects {
          nextToken
        }
        posts {
          nextToken
        }
      }
    }
    nextToken
  }
}
`;
export const listDesigners = `query ListDesigners(
  $filter: ModelDesignerFilterInput
  $limit: Int
  $nextToken: String
) {
  listDesigners(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getDesigner = `query GetDesigner($id: ID!) {
  getDesigner(id: $id) {
    id
    owner
    email
    phone
    name
    photo_url
    bio
    location
    active
    createdAt
    pro
    profile {
      first_name
      last_name
      name
      bio
      company_name
      title
      address_line1
      address_line2
      city
      state
      zip_code
      country
      website_url
      allow_contact_email
      facebook_handle
      twitter_handle
      youtube_handle
      pinterest_handle
      instagram_handle
      linkedin_url
      blog_url
      google_plus_url
      houzz_url
      feature_video
      availables
      certifications
      photo_url
      cover_url
      status
    }
    draft_profile {
      first_name
      last_name
      name
      bio
      company_name
      title
      address_line1
      address_line2
      city
      state
      zip_code
      country
      website_url
      allow_contact_email
      facebook_handle
      twitter_handle
      youtube_handle
      pinterest_handle
      instagram_handle
      linkedin_url
      blog_url
      google_plus_url
      houzz_url
      feature_video
      availables
      certifications
      photo_url
      cover_url
      status
    }
    user {
      id
      owner
      email
      phone
      first_name
      last_name
      photo_url
      cover_url
      notes
      active
      createdAt
      watchlist {
        watchlistVideoId
        createdAt
      }
      currentVideo {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      history {
        historyVideoId
        createdAt
      }
      favorites {
        favoriteVideoId
        createdAt
      }
      userDesignerId
      orders {
        items {
          id
          owner
          orderCustomerId
          total
          order
          createdAt
        }
        nextToken
      }
      cart {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        owner
        items
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      designer {
        id
        owner
        email
        phone
        name
        photo_url
        bio
        location
        active
        createdAt
        pro
        profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        draft_profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subs {
          nextToken
        }
        tags {
          nextToken
        }
        pendingVideos {
          nextToken
        }
        videos {
          nextToken
        }
        projects {
          nextToken
        }
        posts {
          nextToken
        }
      }
    }
    subs {
      items {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subDesignerId
        stripeId
        stripeSubId
        createdAt
        owner
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    tags {
      items {
        id
        tagsDesignersTagId
        tagsDesignersDesignerId
        owner
        tag {
          id
          tag
          type
          owner
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    pendingVideos {
      items {
        id
        owner
        title
        description
        status
        createdAt
        src_video
        pendingVideoCategoryId
        pendingVideoShowId
        pendingVideoDesignerId
        season
        episode
        duration
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    videos {
      items {
        id
        owner
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    projects {
      items {
        id
        title
        description
        images
        videos
        projectDesignerId
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
        owner
      }
      nextToken
    }
    posts {
      items {
        id
        editor {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
        owner
        post {
          id
          owner
          title
          description
          content
          status
          thumbnail
          createdAt
          likes
          liked
        }
      }
      nextToken
    }
  }
}
`;
export const searchDesigners = `query SearchDesigners(
  $filter: SearchableDesignerFilterInput
  $sort: SearchableDesignerSortInput
  $limit: Int
  $nextToken: String
) {
  searchDesigners(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
    nextToken
    total
  }
}
`;
export const getProject = `query GetProject($id: ID!) {
  getProject(id: $id) {
    id
    title
    description
    images
    videos
    projectDesignerId
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
    owner
  }
}
`;
export const listProjects = `query ListProjects(
  $filter: ModelProjectFilterInput
  $limit: Int
  $nextToken: String
) {
  listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      description
      images
      videos
      projectDesignerId
      designer {
        id
        owner
        email
        phone
        name
        photo_url
        bio
        location
        active
        createdAt
        pro
        profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        draft_profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subs {
          nextToken
        }
        tags {
          nextToken
        }
        pendingVideos {
          nextToken
        }
        videos {
          nextToken
        }
        projects {
          nextToken
        }
        posts {
          nextToken
        }
      }
      owner
    }
    nextToken
  }
}
`;
export const getPostEditor = `query GetPostEditor($id: ID!) {
  getPostEditor(id: $id) {
    id
    editor {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
    owner
    post {
      id
      owner
      title
      description
      content
      status
      thumbnail
      createdAt
      likes
      liked
      editors {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
  }
}
`;
export const listPostEditors = `query ListPostEditors(
  $filter: ModelPostEditorFilterInput
  $limit: Int
  $nextToken: String
) {
  listPostEditors(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      editor {
        id
        owner
        email
        phone
        name
        photo_url
        bio
        location
        active
        createdAt
        pro
        profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        draft_profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subs {
          nextToken
        }
        tags {
          nextToken
        }
        pendingVideos {
          nextToken
        }
        videos {
          nextToken
        }
        projects {
          nextToken
        }
        posts {
          nextToken
        }
      }
      owner
      post {
        id
        owner
        title
        description
        content
        status
        thumbnail
        createdAt
        likes
        liked
        editors {
          nextToken
        }
        comments {
          nextToken
        }
      }
    }
    nextToken
  }
}
`;
export const listPosts = `query ListPosts(
  $filter: ModelPostFilterInput
  $limit: Int
  $nextToken: String
) {
  listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      title
      description
      content
      status
      thumbnail
      createdAt
      likes
      liked
      editors {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getPost = `query GetPost($id: ID!) {
  getPost(id: $id) {
    id
    owner
    title
    description
    content
    status
    thumbnail
    createdAt
    likes
    liked
    editors {
      items {
        id
        editor {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
        owner
        post {
          id
          owner
          title
          description
          content
          status
          thumbnail
          createdAt
          likes
          liked
        }
      }
      nextToken
    }
    comments {
      items {
        id
        owner
        content
        avatar
        name
        commentPostId
        commentVideoId
        createdAt
        likes
        subs
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        post {
          id
          owner
          title
          description
          content
          status
          thumbnail
          createdAt
          likes
          liked
        }
      }
      nextToken
    }
  }
}
`;
export const searchPosts = `query SearchPosts(
  $filter: SearchablePostFilterInput
  $sort: SearchablePostSortInput
  $limit: Int
  $nextToken: String
) {
  searchPosts(
    filter: $filter
    sort: $sort
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      owner
      title
      description
      content
      status
      thumbnail
      createdAt
      likes
      liked
      editors {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    nextToken
    total
  }
}
`;
export const getComment = `query GetComment($id: ID!) {
  getComment(id: $id) {
    id
    owner
    content
    avatar
    name
    commentPostId
    commentVideoId
    createdAt
    likes
    subs
    video {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    post {
      id
      owner
      title
      description
      content
      status
      thumbnail
      createdAt
      likes
      liked
      editors {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
  }
}
`;
export const listComments = `query ListComments(
  $filter: ModelCommentFilterInput
  $limit: Int
  $nextToken: String
) {
  listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      content
      avatar
      name
      commentPostId
      commentVideoId
      createdAt
      likes
      subs
      video {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      post {
        id
        owner
        title
        description
        content
        status
        thumbnail
        createdAt
        likes
        liked
        editors {
          nextToken
        }
        comments {
          nextToken
        }
      }
    }
    nextToken
  }
}
`;
export const getLike = `query GetLike($id: ID!) {
  getLike(id: $id) {
    id
    subject
    objectId
    createdBy
    createdAt
    liked
    owner
  }
}
`;
export const listLikes = `query ListLikes(
  $filter: ModelLikeFilterInput
  $limit: Int
  $nextToken: String
) {
  listLikes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      subject
      objectId
      createdBy
      createdAt
      liked
      owner
    }
    nextToken
  }
}
`;
export const likesByUser = `query LikesByUser(
  $createdBy: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelLikeFilterInput
  $limit: Int
  $nextToken: String
) {
  likesByUser(
    createdBy: $createdBy
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      subject
      objectId
      createdBy
      createdAt
      liked
      owner
    }
    nextToken
  }
}
`;
export const likesByObject = `query LikesByObject(
  $objectId: ID
  $createdBy: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelLikeFilterInput
  $limit: Int
  $nextToken: String
) {
  likesByObject(
    objectId: $objectId
    createdBy: $createdBy
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      subject
      objectId
      createdBy
      createdAt
      liked
      owner
    }
    nextToken
  }
}
`;
