import React from 'react';
import compose from 'recompose/compose';
import { withStyles, Typography, GridList, GridListTile, LinearProgress, useMediaQuery } from '@material-ui/core';
import SubscriptionVideo from './SubscriptionVideo';
import { useDesigner } from 'hooks/useDesigner';
import styles from './styles';

const SubscriptionDesigner = props => {
  const { classes, designer } = props;
  const designerData = useDesigner(designer.subDesignerId);
  const xl = useMediaQuery(theme => theme.breakpoints.up('xl'));
  const lg = useMediaQuery(theme => theme.breakpoints.up('lg'));
  const md = useMediaQuery(theme => theme.breakpoints.up('md'));

  React.useEffect(() => {
    console.log(designer);
    console.log('designer data: ', designerData);
  }, [designer, designerData]);

  const getGridListCols = () => {
    if (xl) {
      return 4;
    }

    if (lg) {
      return 3;
    }

    if (md) {
      return 2;
    }

    return 1;
  };

  return (
    <>
      <h1 className={classes.designerName}>{designerData.name}</h1>
      {!designerData.videos ? (
        <div>
          <LinearProgress />
        </div>
      ) : (
        <>
          {designerData && designerData.videos && designerData.videos.items.length > 0 ? (
            <GridList cols={getGridListCols()} cellHeight={'auto'}>
              {designerData.videos.items.map(video => {
                return (
                  <GridListTile key={video.id} cols={1}>
                    <SubscriptionVideo key={video.video.id} data={video} />
                  </GridListTile>
                );
              })}
            </GridList>
          ) : (
            <Typography>This designer does not have any shows!</Typography>
          )}
        </>
      )}
    </>
  );
};

export default compose(withStyles(styles))(SubscriptionDesigner);
