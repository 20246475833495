import React, { useEffect, useState } from 'react';
import { withStyles, GridList, GridListTile, GridListTileBar, useMediaQuery, LinearProgress } from '@material-ui/core';

import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { listProductCategorys } from '../../graphql/queries';

import styles from './styles';
import { formatImage } from '../../utils';
import { GridListLoadingHolder } from '../../components/LoadingHolder';

const Categories = props => {
  const { classes } = props;
  const [categories, setCategories] = useState(null);

  const { data: dataCategories } = useQuery(gql(listProductCategorys), { fetchPolicy: 'network-only', variables: { limit: 100000, filter: { isMainCategory: { eq: true } } } });

  useEffect(() => {
    if (dataCategories.listProductCategorys) {
      for (let cat of dataCategories.listProductCategorys.items) {
        cat.image = formatImage(cat.image, cat.slug);
      }
      setCategories(dataCategories.listProductCategorys.items);
    }
  }, [dataCategories]);

  const goToCategory = async category => {
    props.history.push({ pathname: '/categories/' + category.id });
  };

  const xl = useMediaQuery(theme => theme.breakpoints.up('xl'));
  const lg = useMediaQuery(theme => theme.breakpoints.up('lg'));
  const md = useMediaQuery(theme => theme.breakpoints.up('md'));
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const getGridListCols = () => {
    if (xl) {
      return 4;
    }

    if (lg) {
      return 4;
    }

    if (md) {
      return 2;
    }

    return 1;
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {categories ? (
          <GridList cellHeight={450} className={classes.gridList} cols={getGridListCols()}>
            {categories.map((category, i) => {
              return (
                <GridListTile cols={!mobile ? (i < 2 ? 2 : 1) : 1} key={category.slug} onClick={() => goToCategory(category)}>
                  <img src={category.image} alt={category.name} />
                  <GridListTileBar
                    titlePosition="top"
                    classes={{
                      title: classes.title,
                      subtitle: classes.subtitle,
                      root: classes.titleBar,
                      titleWrap: classes.titleWrap
                    }}
                    title={category.name}
                    subtitle={<span>{category.subtitle}</span>}
                  />
                </GridListTile>
              );
            })}
          </GridList>
        ) : (
          <GridListLoadingHolder numTiles={2} cellHeight={450} className={classes.gridList} cols={getGridListCols()} />
        )}
      </div>
    </div>
  );
};
export default withStyles(styles)(Categories);
