import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withStyles, useMediaQuery } from '@material-ui/core';
import { IconButton, Toolbar, Typography, Button } from '@material-ui/core';

// Material icons
import { Menu as MenuIcon, Close as CloseIcon } from '@material-ui/icons';

// Shared services
import { getNotifications } from 'services/notification';
import Cart from 'components/Cart';

import LoginButton from './components/LoginButton';

// Component styles
import styles from './styles';

const Topbar = props => {
  const [signal, setSignal] = useState(true);
  const [state, setState] = useState({
    notifications: [],
    notificationsLimit: 4,
    notificationsCount: 0,
    notificationsEl: null,
    scrolling: false
  });
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    autoScroll();
  }, [props.location]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY < 50) {
        setScrolling(false);
      } else {
        setScrolling(true);
      }
    };

    const updateNotifications = async () => {
      try {
        const { notificationsLimit } = state;

        const { notifications, notificationsCount } = await getNotifications(notificationsLimit);

        // if (signal) {
        //   setState({ ...state, notifications, notificationsCount });
        // }
      } catch (error) {
        return;
      }
    };

    window.addEventListener('scroll', handleScroll);
    updateNotifications();
    return () => {
      setSignal(false);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrolling, signal, state]);

  const { classes, className, title, isSidebarOpen, onToggleSidebar } = props;

  const rootClassName = classNames(classes.root, className);
  const fullScreenPages = ['shows', 'categories', 'about'];
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  function autoScroll() {
    window.scrollTo(0, 0);
  }

  return (
    <div className={classes.grow}>
      <div className={rootClassName}>
        <Toolbar
          className={
            mobile || scrolling || (!props.location.pathname.includes(fullScreenPages) && !props.location.pathname.indexOf(fullScreenPages))
              ? classes.toolbarBlack
              : classes.toolbar
          }
        >
          <IconButton className={classes.menuButton} onClick={onToggleSidebar} variant="text">
            {isSidebarOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
          <Typography className={classes.title} variant="h4">
            {title}
          </Typography>
          <div className={classes.titleBarLogo}>
            <Link className={classes.titleBarLogo} to="/shows">
              <img className={classes.logo} src="/images/logos/logo.png" alt="Home Design TV" />
            </Link>
          </div>
          {!mobile && (
            <>
              <Link className={classes.linkItem} to="/shows">
                <Button color="secondary" className={classes.button} onClick={autoScroll}>
                  Shows
                </Button>
              </Link>
              <Link className={classes.linkItem} to="/categories">
                <Button color="secondary" className={classes.button} onClick={autoScroll}>
                  Shop
                </Button>
              </Link>
              <Link className={classes.linkItem} to="/designers">
                <Button color="secondary" className={classes.button} onClick={autoScroll}>
                  The Designers
                </Button>
              </Link>
              <Link className={classes.linkItem} to="/project-pros">
                <Button color="secondary" className={classes.button} onClick={autoScroll}>
                  Project Pros
                </Button>
              </Link>
              <Link className={classes.linkItem} to="/articles">
                <Button color="secondary" className={classes.button} onClick={autoScroll}>
                  Articles
                </Button>
              </Link>
              <div style={{ flex: 1 }} />
              <div style={{ flex: 1 }} />
            </>
          )}
          {props.user && props.user.id ? <Cart type={'cart'} user={props.user} /> : <LoginButton onSidebarClose={props.onSidebarClose} autoScroll={autoScroll} />}
        </Toolbar>
      </div>
    </div>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isSidebarOpen: PropTypes.bool,
  onToggleSidebar: PropTypes.func,
  onSidebarClose: PropTypes.func,
  title: PropTypes.string
};

Topbar.defaultProps = {
  onToggleSidebar: () => {},
  onSidebarClose: () => {}
};

export default compose(withRouter, withStyles(styles))(Topbar);
