import React from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';

const ProductImages = props => {
  const { images, state, styleFn } = props;

  return (
    <ModalGateway>
      {state.lightboxIsOpen ? (
        <Modal styles={{ blanket: styleFn, positioner: styleFn }} onClose={() => props.toggleLightbox()}>
          <Carousel style={{ zIndex: 1500 }} views={images} currentIndex={state.selectedIndex} />
        </Modal>
      ) : null}
    </ModalGateway>
  );
};
export default ProductImages;
