/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUser = `subscription OnCreateUser($owner: String) {
  onCreateUser(owner: $owner) {
    id
    owner
    email
    phone
    first_name
    last_name
    photo_url
    cover_url
    notes
    active
    createdAt
    watchlist {
      watchlistVideoId
      createdAt
    }
    currentVideo {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    history {
      historyVideoId
      createdAt
    }
    favorites {
      favoriteVideoId
      createdAt
    }
    userDesignerId
    orders {
      items {
        id
        owner
        customer {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        orderCustomerId
        total
        order
        createdAt
      }
      nextToken
    }
    cart {
      id
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      owner
      items
    }
    subs {
      items {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subDesignerId
        stripeId
        stripeSubId
        createdAt
        owner
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
  }
}
`;
export const onUpdateUser = `subscription OnUpdateUser($owner: String) {
  onUpdateUser(owner: $owner) {
    id
    owner
    email
    phone
    first_name
    last_name
    photo_url
    cover_url
    notes
    active
    createdAt
    watchlist {
      watchlistVideoId
      createdAt
    }
    currentVideo {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    history {
      historyVideoId
      createdAt
    }
    favorites {
      favoriteVideoId
      createdAt
    }
    userDesignerId
    orders {
      items {
        id
        owner
        customer {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        orderCustomerId
        total
        order
        createdAt
      }
      nextToken
    }
    cart {
      id
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      owner
      items
    }
    subs {
      items {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subDesignerId
        stripeId
        stripeSubId
        createdAt
        owner
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
  }
}
`;
export const onDeleteUser = `subscription OnDeleteUser($owner: String) {
  onDeleteUser(owner: $owner) {
    id
    owner
    email
    phone
    first_name
    last_name
    photo_url
    cover_url
    notes
    active
    createdAt
    watchlist {
      watchlistVideoId
      createdAt
    }
    currentVideo {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    history {
      historyVideoId
      createdAt
    }
    favorites {
      favoriteVideoId
      createdAt
    }
    userDesignerId
    orders {
      items {
        id
        owner
        customer {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        orderCustomerId
        total
        order
        createdAt
      }
      nextToken
    }
    cart {
      id
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      owner
      items
    }
    subs {
      items {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subDesignerId
        stripeId
        stripeSubId
        createdAt
        owner
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
  }
}
`;
export const onCreateCart = `subscription OnCreateCart($owner: String) {
  onCreateCart(owner: $owner) {
    id
    user {
      id
      owner
      email
      phone
      first_name
      last_name
      photo_url
      cover_url
      notes
      active
      createdAt
      watchlist {
        watchlistVideoId
        createdAt
      }
      currentVideo {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      history {
        historyVideoId
        createdAt
      }
      favorites {
        favoriteVideoId
        createdAt
      }
      userDesignerId
      orders {
        items {
          id
          owner
          orderCustomerId
          total
          order
          createdAt
        }
        nextToken
      }
      cart {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        owner
        items
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      designer {
        id
        owner
        email
        phone
        name
        photo_url
        bio
        location
        active
        createdAt
        pro
        profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        draft_profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subs {
          nextToken
        }
        tags {
          nextToken
        }
        pendingVideos {
          nextToken
        }
        videos {
          nextToken
        }
        projects {
          nextToken
        }
        posts {
          nextToken
        }
      }
    }
    owner
    items
  }
}
`;
export const onUpdateCart = `subscription OnUpdateCart($owner: String) {
  onUpdateCart(owner: $owner) {
    id
    user {
      id
      owner
      email
      phone
      first_name
      last_name
      photo_url
      cover_url
      notes
      active
      createdAt
      watchlist {
        watchlistVideoId
        createdAt
      }
      currentVideo {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      history {
        historyVideoId
        createdAt
      }
      favorites {
        favoriteVideoId
        createdAt
      }
      userDesignerId
      orders {
        items {
          id
          owner
          orderCustomerId
          total
          order
          createdAt
        }
        nextToken
      }
      cart {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        owner
        items
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      designer {
        id
        owner
        email
        phone
        name
        photo_url
        bio
        location
        active
        createdAt
        pro
        profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        draft_profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subs {
          nextToken
        }
        tags {
          nextToken
        }
        pendingVideos {
          nextToken
        }
        videos {
          nextToken
        }
        projects {
          nextToken
        }
        posts {
          nextToken
        }
      }
    }
    owner
    items
  }
}
`;
export const onDeleteCart = `subscription OnDeleteCart($owner: String) {
  onDeleteCart(owner: $owner) {
    id
    user {
      id
      owner
      email
      phone
      first_name
      last_name
      photo_url
      cover_url
      notes
      active
      createdAt
      watchlist {
        watchlistVideoId
        createdAt
      }
      currentVideo {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      history {
        historyVideoId
        createdAt
      }
      favorites {
        favoriteVideoId
        createdAt
      }
      userDesignerId
      orders {
        items {
          id
          owner
          orderCustomerId
          total
          order
          createdAt
        }
        nextToken
      }
      cart {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        owner
        items
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      designer {
        id
        owner
        email
        phone
        name
        photo_url
        bio
        location
        active
        createdAt
        pro
        profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        draft_profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subs {
          nextToken
        }
        tags {
          nextToken
        }
        pendingVideos {
          nextToken
        }
        videos {
          nextToken
        }
        projects {
          nextToken
        }
        posts {
          nextToken
        }
      }
    }
    owner
    items
  }
}
`;
export const onCreateSub = `subscription OnCreateSub($owner: String) {
  onCreateSub(owner: $owner) {
    id
    user {
      id
      owner
      email
      phone
      first_name
      last_name
      photo_url
      cover_url
      notes
      active
      createdAt
      watchlist {
        watchlistVideoId
        createdAt
      }
      currentVideo {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      history {
        historyVideoId
        createdAt
      }
      favorites {
        favoriteVideoId
        createdAt
      }
      userDesignerId
      orders {
        items {
          id
          owner
          orderCustomerId
          total
          order
          createdAt
        }
        nextToken
      }
      cart {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        owner
        items
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      designer {
        id
        owner
        email
        phone
        name
        photo_url
        bio
        location
        active
        createdAt
        pro
        profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        draft_profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subs {
          nextToken
        }
        tags {
          nextToken
        }
        pendingVideos {
          nextToken
        }
        videos {
          nextToken
        }
        projects {
          nextToken
        }
        posts {
          nextToken
        }
      }
    }
    subDesignerId
    stripeId
    stripeSubId
    createdAt
    owner
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
  }
}
`;
export const onUpdateSub = `subscription OnUpdateSub($owner: String) {
  onUpdateSub(owner: $owner) {
    id
    user {
      id
      owner
      email
      phone
      first_name
      last_name
      photo_url
      cover_url
      notes
      active
      createdAt
      watchlist {
        watchlistVideoId
        createdAt
      }
      currentVideo {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      history {
        historyVideoId
        createdAt
      }
      favorites {
        favoriteVideoId
        createdAt
      }
      userDesignerId
      orders {
        items {
          id
          owner
          orderCustomerId
          total
          order
          createdAt
        }
        nextToken
      }
      cart {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        owner
        items
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      designer {
        id
        owner
        email
        phone
        name
        photo_url
        bio
        location
        active
        createdAt
        pro
        profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        draft_profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subs {
          nextToken
        }
        tags {
          nextToken
        }
        pendingVideos {
          nextToken
        }
        videos {
          nextToken
        }
        projects {
          nextToken
        }
        posts {
          nextToken
        }
      }
    }
    subDesignerId
    stripeId
    stripeSubId
    createdAt
    owner
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
  }
}
`;
export const onDeleteSub = `subscription OnDeleteSub($owner: String) {
  onDeleteSub(owner: $owner) {
    id
    user {
      id
      owner
      email
      phone
      first_name
      last_name
      photo_url
      cover_url
      notes
      active
      createdAt
      watchlist {
        watchlistVideoId
        createdAt
      }
      currentVideo {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      history {
        historyVideoId
        createdAt
      }
      favorites {
        favoriteVideoId
        createdAt
      }
      userDesignerId
      orders {
        items {
          id
          owner
          orderCustomerId
          total
          order
          createdAt
        }
        nextToken
      }
      cart {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        owner
        items
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      designer {
        id
        owner
        email
        phone
        name
        photo_url
        bio
        location
        active
        createdAt
        pro
        profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        draft_profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subs {
          nextToken
        }
        tags {
          nextToken
        }
        pendingVideos {
          nextToken
        }
        videos {
          nextToken
        }
        projects {
          nextToken
        }
        posts {
          nextToken
        }
      }
    }
    subDesignerId
    stripeId
    stripeSubId
    createdAt
    owner
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
  }
}
`;
export const onCreateCategory = `subscription OnCreateCategory($owner: String) {
  onCreateCategory(owner: $owner) {
    id
    owner
    name
    description
    createdAt
    videos {
      items {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      nextToken
    }
    pendingVideos {
      items {
        id
        owner
        title
        description
        status
        createdAt
        src_video
        pendingVideoCategoryId
        pendingVideoShowId
        pendingVideoDesignerId
        season
        episode
        duration
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
  }
}
`;
export const onUpdateCategory = `subscription OnUpdateCategory($owner: String) {
  onUpdateCategory(owner: $owner) {
    id
    owner
    name
    description
    createdAt
    videos {
      items {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      nextToken
    }
    pendingVideos {
      items {
        id
        owner
        title
        description
        status
        createdAt
        src_video
        pendingVideoCategoryId
        pendingVideoShowId
        pendingVideoDesignerId
        season
        episode
        duration
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
  }
}
`;
export const onDeleteCategory = `subscription OnDeleteCategory($owner: String) {
  onDeleteCategory(owner: $owner) {
    id
    owner
    name
    description
    createdAt
    videos {
      items {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      nextToken
    }
    pendingVideos {
      items {
        id
        owner
        title
        description
        status
        createdAt
        src_video
        pendingVideoCategoryId
        pendingVideoShowId
        pendingVideoDesignerId
        season
        episode
        duration
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
  }
}
`;
export const onCreateShow = `subscription OnCreateShow($owner: String) {
  onCreateShow(owner: $owner) {
    id
    owner
    title
    description
    release_date
    videos {
      items {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      nextToken
    }
    pendingVideos {
      items {
        id
        owner
        title
        description
        status
        createdAt
        src_video
        pendingVideoCategoryId
        pendingVideoShowId
        pendingVideoDesignerId
        season
        episode
        duration
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
  }
}
`;
export const onUpdateShow = `subscription OnUpdateShow($owner: String) {
  onUpdateShow(owner: $owner) {
    id
    owner
    title
    description
    release_date
    videos {
      items {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      nextToken
    }
    pendingVideos {
      items {
        id
        owner
        title
        description
        status
        createdAt
        src_video
        pendingVideoCategoryId
        pendingVideoShowId
        pendingVideoDesignerId
        season
        episode
        duration
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
  }
}
`;
export const onDeleteShow = `subscription OnDeleteShow($owner: String) {
  onDeleteShow(owner: $owner) {
    id
    owner
    title
    description
    release_date
    videos {
      items {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      nextToken
    }
    pendingVideos {
      items {
        id
        owner
        title
        description
        status
        createdAt
        src_video
        pendingVideoCategoryId
        pendingVideoShowId
        pendingVideoDesignerId
        season
        episode
        duration
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
  }
}
`;
export const onCreateVideo = `subscription OnCreateVideo($owner: String) {
  onCreateVideo(owner: $owner) {
    id
    owner
    title
    description
    status
    url
    thumbnail
    createdAt
    views {
      user
      video
      createdAt
    }
    season
    episode
    release_date
    duration
    likes
    src_video
    category {
      id
      owner
      name
      description
      createdAt
      videos {
        items {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
    }
    show {
      id
      owner
      title
      description
      release_date
      videos {
        items {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
    }
    tags {
      items {
        id
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        owner
        tag {
          id
          tag
          type
          owner
        }
      }
      nextToken
    }
    products {
      items {
        id
        owner
        timestamp
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        product {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
      }
      nextToken
    }
    designers {
      items {
        id
        owner
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    comments {
      items {
        id
        owner
        content
        avatar
        name
        commentPostId
        commentVideoId
        createdAt
        likes
        subs
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        post {
          id
          owner
          title
          description
          content
          status
          thumbnail
          createdAt
          likes
          liked
        }
      }
      nextToken
    }
  }
}
`;
export const onUpdateVideo = `subscription OnUpdateVideo($owner: String) {
  onUpdateVideo(owner: $owner) {
    id
    owner
    title
    description
    status
    url
    thumbnail
    createdAt
    views {
      user
      video
      createdAt
    }
    season
    episode
    release_date
    duration
    likes
    src_video
    category {
      id
      owner
      name
      description
      createdAt
      videos {
        items {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
    }
    show {
      id
      owner
      title
      description
      release_date
      videos {
        items {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
    }
    tags {
      items {
        id
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        owner
        tag {
          id
          tag
          type
          owner
        }
      }
      nextToken
    }
    products {
      items {
        id
        owner
        timestamp
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        product {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
      }
      nextToken
    }
    designers {
      items {
        id
        owner
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    comments {
      items {
        id
        owner
        content
        avatar
        name
        commentPostId
        commentVideoId
        createdAt
        likes
        subs
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        post {
          id
          owner
          title
          description
          content
          status
          thumbnail
          createdAt
          likes
          liked
        }
      }
      nextToken
    }
  }
}
`;
export const onDeleteVideo = `subscription OnDeleteVideo($owner: String) {
  onDeleteVideo(owner: $owner) {
    id
    owner
    title
    description
    status
    url
    thumbnail
    createdAt
    views {
      user
      video
      createdAt
    }
    season
    episode
    release_date
    duration
    likes
    src_video
    category {
      id
      owner
      name
      description
      createdAt
      videos {
        items {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
    }
    show {
      id
      owner
      title
      description
      release_date
      videos {
        items {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
    }
    tags {
      items {
        id
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        owner
        tag {
          id
          tag
          type
          owner
        }
      }
      nextToken
    }
    products {
      items {
        id
        owner
        timestamp
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        product {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
      }
      nextToken
    }
    designers {
      items {
        id
        owner
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    comments {
      items {
        id
        owner
        content
        avatar
        name
        commentPostId
        commentVideoId
        createdAt
        likes
        subs
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        post {
          id
          owner
          title
          description
          content
          status
          thumbnail
          createdAt
          likes
          liked
        }
      }
      nextToken
    }
  }
}
`;
export const onCreateTagsDesigners = `subscription OnCreateTagsDesigners($owner: String) {
  onCreateTagsDesigners(owner: $owner) {
    id
    tagsDesignersTagId
    tagsDesignersDesignerId
    owner
    tag {
      id
      tag
      type
      designers {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      owner
    }
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
  }
}
`;
export const onUpdateTagsDesigners = `subscription OnUpdateTagsDesigners($owner: String) {
  onUpdateTagsDesigners(owner: $owner) {
    id
    tagsDesignersTagId
    tagsDesignersDesignerId
    owner
    tag {
      id
      tag
      type
      designers {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      owner
    }
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
  }
}
`;
export const onDeleteTagsDesigners = `subscription OnDeleteTagsDesigners($owner: String) {
  onDeleteTagsDesigners(owner: $owner) {
    id
    tagsDesignersTagId
    tagsDesignersDesignerId
    owner
    tag {
      id
      tag
      type
      designers {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      owner
    }
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
  }
}
`;
export const onCreateTagsVideos = `subscription OnCreateTagsVideos($owner: String) {
  onCreateTagsVideos(owner: $owner) {
    id
    video {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    owner
    tag {
      id
      tag
      type
      designers {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      owner
    }
  }
}
`;
export const onUpdateTagsVideos = `subscription OnUpdateTagsVideos($owner: String) {
  onUpdateTagsVideos(owner: $owner) {
    id
    video {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    owner
    tag {
      id
      tag
      type
      designers {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      owner
    }
  }
}
`;
export const onDeleteTagsVideos = `subscription OnDeleteTagsVideos($owner: String) {
  onDeleteTagsVideos(owner: $owner) {
    id
    video {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    owner
    tag {
      id
      tag
      type
      designers {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      owner
    }
  }
}
`;
export const onCreateTag = `subscription OnCreateTag($owner: String) {
  onCreateTag(owner: $owner) {
    id
    tag
    type
    designers {
      items {
        id
        tagsDesignersTagId
        tagsDesignersDesignerId
        owner
        tag {
          id
          tag
          type
          owner
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    videos {
      items {
        id
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        owner
        tag {
          id
          tag
          type
          owner
        }
      }
      nextToken
    }
    owner
  }
}
`;
export const onUpdateTag = `subscription OnUpdateTag($owner: String) {
  onUpdateTag(owner: $owner) {
    id
    tag
    type
    designers {
      items {
        id
        tagsDesignersTagId
        tagsDesignersDesignerId
        owner
        tag {
          id
          tag
          type
          owner
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    videos {
      items {
        id
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        owner
        tag {
          id
          tag
          type
          owner
        }
      }
      nextToken
    }
    owner
  }
}
`;
export const onDeleteTag = `subscription OnDeleteTag($owner: String) {
  onDeleteTag(owner: $owner) {
    id
    tag
    type
    designers {
      items {
        id
        tagsDesignersTagId
        tagsDesignersDesignerId
        owner
        tag {
          id
          tag
          type
          owner
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    videos {
      items {
        id
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        owner
        tag {
          id
          tag
          type
          owner
        }
      }
      nextToken
    }
    owner
  }
}
`;
export const onCreatePendingVideo = `subscription OnCreatePendingVideo($owner: String) {
  onCreatePendingVideo(owner: $owner) {
    id
    owner
    title
    description
    status
    createdAt
    src_video
    pendingVideoCategoryId
    pendingVideoShowId
    pendingVideoDesignerId
    season
    episode
    duration
    category {
      id
      owner
      name
      description
      createdAt
      videos {
        items {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
    }
    show {
      id
      owner
      title
      description
      release_date
      videos {
        items {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
    }
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
  }
}
`;
export const onUpdatePendingVideo = `subscription OnUpdatePendingVideo($owner: String) {
  onUpdatePendingVideo(owner: $owner) {
    id
    owner
    title
    description
    status
    createdAt
    src_video
    pendingVideoCategoryId
    pendingVideoShowId
    pendingVideoDesignerId
    season
    episode
    duration
    category {
      id
      owner
      name
      description
      createdAt
      videos {
        items {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
    }
    show {
      id
      owner
      title
      description
      release_date
      videos {
        items {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
    }
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
  }
}
`;
export const onDeletePendingVideo = `subscription OnDeletePendingVideo($owner: String) {
  onDeletePendingVideo(owner: $owner) {
    id
    owner
    title
    description
    status
    createdAt
    src_video
    pendingVideoCategoryId
    pendingVideoShowId
    pendingVideoDesignerId
    season
    episode
    duration
    category {
      id
      owner
      name
      description
      createdAt
      videos {
        items {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
    }
    show {
      id
      owner
      title
      description
      release_date
      videos {
        items {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
    }
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
  }
}
`;
export const onCreateProductVideo = `subscription OnCreateProductVideo($owner: String) {
  onCreateProductVideo(owner: $owner) {
    id
    owner
    timestamp
    video {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    product {
      id
      upc
      owner
      name
      description
      caption
      collection
      attributes
      price
      image
      product_id
      meta
      active
      createdAt
      box_size
      designer
      productCategoryId
      mainProductCategoryId
      videos {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      relatedItems {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      relatedTo {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      category {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
      supplier {
        id
        name
        description
        address
        phone
        contact
        url
        api
        schema
        products {
          nextToken
        }
        owner
      }
    }
  }
}
`;
export const onUpdateProductVideo = `subscription OnUpdateProductVideo($owner: String) {
  onUpdateProductVideo(owner: $owner) {
    id
    owner
    timestamp
    video {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    product {
      id
      upc
      owner
      name
      description
      caption
      collection
      attributes
      price
      image
      product_id
      meta
      active
      createdAt
      box_size
      designer
      productCategoryId
      mainProductCategoryId
      videos {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      relatedItems {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      relatedTo {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      category {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
      supplier {
        id
        name
        description
        address
        phone
        contact
        url
        api
        schema
        products {
          nextToken
        }
        owner
      }
    }
  }
}
`;
export const onDeleteProductVideo = `subscription OnDeleteProductVideo($owner: String) {
  onDeleteProductVideo(owner: $owner) {
    id
    owner
    timestamp
    video {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    product {
      id
      upc
      owner
      name
      description
      caption
      collection
      attributes
      price
      image
      product_id
      meta
      active
      createdAt
      box_size
      designer
      productCategoryId
      mainProductCategoryId
      videos {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      relatedItems {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      relatedTo {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      category {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
      supplier {
        id
        name
        description
        address
        phone
        contact
        url
        api
        schema
        products {
          nextToken
        }
        owner
      }
    }
  }
}
`;
export const onCreateProduct = `subscription OnCreateProduct($owner: String) {
  onCreateProduct(owner: $owner) {
    id
    upc
    owner
    name
    description
    caption
    collection
    attributes
    price
    image
    product_id
    meta
    active
    createdAt
    box_size
    designer
    productCategoryId
    mainProductCategoryId
    videos {
      items {
        id
        owner
        timestamp
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        product {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
      }
      nextToken
    }
    relatedItems {
      items {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      nextToken
    }
    relatedTo {
      id
      upc
      owner
      name
      description
      caption
      collection
      attributes
      price
      image
      product_id
      meta
      active
      createdAt
      box_size
      designer
      productCategoryId
      mainProductCategoryId
      videos {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      relatedItems {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      relatedTo {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      category {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
      supplier {
        id
        name
        description
        address
        phone
        contact
        url
        api
        schema
        products {
          nextToken
        }
        owner
      }
    }
    category {
      id
      owner
      name
      slug
      image
      createdAt
      isMainCategory
      productCategoryMainCategoryId
      products {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      subCategories {
        items {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        nextToken
      }
      mainCategory {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
    }
    supplier {
      id
      name
      description
      address
      phone
      contact
      url
      api
      schema
      products {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      owner
    }
  }
}
`;
export const onUpdateProduct = `subscription OnUpdateProduct($owner: String) {
  onUpdateProduct(owner: $owner) {
    id
    upc
    owner
    name
    description
    caption
    collection
    attributes
    price
    image
    product_id
    meta
    active
    createdAt
    box_size
    designer
    productCategoryId
    mainProductCategoryId
    videos {
      items {
        id
        owner
        timestamp
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        product {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
      }
      nextToken
    }
    relatedItems {
      items {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      nextToken
    }
    relatedTo {
      id
      upc
      owner
      name
      description
      caption
      collection
      attributes
      price
      image
      product_id
      meta
      active
      createdAt
      box_size
      designer
      productCategoryId
      mainProductCategoryId
      videos {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      relatedItems {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      relatedTo {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      category {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
      supplier {
        id
        name
        description
        address
        phone
        contact
        url
        api
        schema
        products {
          nextToken
        }
        owner
      }
    }
    category {
      id
      owner
      name
      slug
      image
      createdAt
      isMainCategory
      productCategoryMainCategoryId
      products {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      subCategories {
        items {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        nextToken
      }
      mainCategory {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
    }
    supplier {
      id
      name
      description
      address
      phone
      contact
      url
      api
      schema
      products {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      owner
    }
  }
}
`;
export const onDeleteProduct = `subscription OnDeleteProduct($owner: String) {
  onDeleteProduct(owner: $owner) {
    id
    upc
    owner
    name
    description
    caption
    collection
    attributes
    price
    image
    product_id
    meta
    active
    createdAt
    box_size
    designer
    productCategoryId
    mainProductCategoryId
    videos {
      items {
        id
        owner
        timestamp
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        product {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
      }
      nextToken
    }
    relatedItems {
      items {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      nextToken
    }
    relatedTo {
      id
      upc
      owner
      name
      description
      caption
      collection
      attributes
      price
      image
      product_id
      meta
      active
      createdAt
      box_size
      designer
      productCategoryId
      mainProductCategoryId
      videos {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      relatedItems {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      relatedTo {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      category {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
      supplier {
        id
        name
        description
        address
        phone
        contact
        url
        api
        schema
        products {
          nextToken
        }
        owner
      }
    }
    category {
      id
      owner
      name
      slug
      image
      createdAt
      isMainCategory
      productCategoryMainCategoryId
      products {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      subCategories {
        items {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        nextToken
      }
      mainCategory {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
    }
    supplier {
      id
      name
      description
      address
      phone
      contact
      url
      api
      schema
      products {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      owner
    }
  }
}
`;
export const onCreateProductCategory = `subscription OnCreateProductCategory($owner: String) {
  onCreateProductCategory(owner: $owner) {
    id
    owner
    name
    slug
    image
    createdAt
    isMainCategory
    productCategoryMainCategoryId
    products {
      items {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      nextToken
    }
    subCategories {
      items {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
      nextToken
    }
    mainCategory {
      id
      owner
      name
      slug
      image
      createdAt
      isMainCategory
      productCategoryMainCategoryId
      products {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      subCategories {
        items {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        nextToken
      }
      mainCategory {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
    }
  }
}
`;
export const onUpdateProductCategory = `subscription OnUpdateProductCategory($owner: String) {
  onUpdateProductCategory(owner: $owner) {
    id
    owner
    name
    slug
    image
    createdAt
    isMainCategory
    productCategoryMainCategoryId
    products {
      items {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      nextToken
    }
    subCategories {
      items {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
      nextToken
    }
    mainCategory {
      id
      owner
      name
      slug
      image
      createdAt
      isMainCategory
      productCategoryMainCategoryId
      products {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      subCategories {
        items {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        nextToken
      }
      mainCategory {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
    }
  }
}
`;
export const onDeleteProductCategory = `subscription OnDeleteProductCategory($owner: String) {
  onDeleteProductCategory(owner: $owner) {
    id
    owner
    name
    slug
    image
    createdAt
    isMainCategory
    productCategoryMainCategoryId
    products {
      items {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      nextToken
    }
    subCategories {
      items {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
      nextToken
    }
    mainCategory {
      id
      owner
      name
      slug
      image
      createdAt
      isMainCategory
      productCategoryMainCategoryId
      products {
        items {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        nextToken
      }
      subCategories {
        items {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        nextToken
      }
      mainCategory {
        id
        owner
        name
        slug
        image
        createdAt
        isMainCategory
        productCategoryMainCategoryId
        products {
          nextToken
        }
        subCategories {
          nextToken
        }
        mainCategory {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
      }
    }
  }
}
`;
export const onCreateSupplier = `subscription OnCreateSupplier($owner: String) {
  onCreateSupplier(owner: $owner) {
    id
    name
    description
    address
    phone
    contact
    url
    api
    schema
    products {
      items {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      nextToken
    }
    owner
  }
}
`;
export const onUpdateSupplier = `subscription OnUpdateSupplier($owner: String) {
  onUpdateSupplier(owner: $owner) {
    id
    name
    description
    address
    phone
    contact
    url
    api
    schema
    products {
      items {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      nextToken
    }
    owner
  }
}
`;
export const onDeleteSupplier = `subscription OnDeleteSupplier($owner: String) {
  onDeleteSupplier(owner: $owner) {
    id
    name
    description
    address
    phone
    contact
    url
    api
    schema
    products {
      items {
        id
        upc
        owner
        name
        description
        caption
        collection
        attributes
        price
        image
        product_id
        meta
        active
        createdAt
        box_size
        designer
        productCategoryId
        mainProductCategoryId
        videos {
          nextToken
        }
        relatedItems {
          nextToken
        }
        relatedTo {
          id
          upc
          owner
          name
          description
          caption
          collection
          attributes
          price
          image
          product_id
          meta
          active
          createdAt
          box_size
          designer
          productCategoryId
          mainProductCategoryId
        }
        category {
          id
          owner
          name
          slug
          image
          createdAt
          isMainCategory
          productCategoryMainCategoryId
        }
        supplier {
          id
          name
          description
          address
          phone
          contact
          url
          api
          schema
          owner
        }
      }
      nextToken
    }
    owner
  }
}
`;
export const onCreateOrder = `subscription OnCreateOrder($owner: String) {
  onCreateOrder(owner: $owner) {
    id
    owner
    customer {
      id
      owner
      email
      phone
      first_name
      last_name
      photo_url
      cover_url
      notes
      active
      createdAt
      watchlist {
        watchlistVideoId
        createdAt
      }
      currentVideo {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      history {
        historyVideoId
        createdAt
      }
      favorites {
        favoriteVideoId
        createdAt
      }
      userDesignerId
      orders {
        items {
          id
          owner
          orderCustomerId
          total
          order
          createdAt
        }
        nextToken
      }
      cart {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        owner
        items
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      designer {
        id
        owner
        email
        phone
        name
        photo_url
        bio
        location
        active
        createdAt
        pro
        profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        draft_profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subs {
          nextToken
        }
        tags {
          nextToken
        }
        pendingVideos {
          nextToken
        }
        videos {
          nextToken
        }
        projects {
          nextToken
        }
        posts {
          nextToken
        }
      }
    }
    orderCustomerId
    total
    order
    createdAt
  }
}
`;
export const onUpdateOrder = `subscription OnUpdateOrder($owner: String) {
  onUpdateOrder(owner: $owner) {
    id
    owner
    customer {
      id
      owner
      email
      phone
      first_name
      last_name
      photo_url
      cover_url
      notes
      active
      createdAt
      watchlist {
        watchlistVideoId
        createdAt
      }
      currentVideo {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      history {
        historyVideoId
        createdAt
      }
      favorites {
        favoriteVideoId
        createdAt
      }
      userDesignerId
      orders {
        items {
          id
          owner
          orderCustomerId
          total
          order
          createdAt
        }
        nextToken
      }
      cart {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        owner
        items
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      designer {
        id
        owner
        email
        phone
        name
        photo_url
        bio
        location
        active
        createdAt
        pro
        profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        draft_profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subs {
          nextToken
        }
        tags {
          nextToken
        }
        pendingVideos {
          nextToken
        }
        videos {
          nextToken
        }
        projects {
          nextToken
        }
        posts {
          nextToken
        }
      }
    }
    orderCustomerId
    total
    order
    createdAt
  }
}
`;
export const onDeleteOrder = `subscription OnDeleteOrder($owner: String) {
  onDeleteOrder(owner: $owner) {
    id
    owner
    customer {
      id
      owner
      email
      phone
      first_name
      last_name
      photo_url
      cover_url
      notes
      active
      createdAt
      watchlist {
        watchlistVideoId
        createdAt
      }
      currentVideo {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      history {
        historyVideoId
        createdAt
      }
      favorites {
        favoriteVideoId
        createdAt
      }
      userDesignerId
      orders {
        items {
          id
          owner
          orderCustomerId
          total
          order
          createdAt
        }
        nextToken
      }
      cart {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        owner
        items
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      designer {
        id
        owner
        email
        phone
        name
        photo_url
        bio
        location
        active
        createdAt
        pro
        profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        draft_profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subs {
          nextToken
        }
        tags {
          nextToken
        }
        pendingVideos {
          nextToken
        }
        videos {
          nextToken
        }
        projects {
          nextToken
        }
        posts {
          nextToken
        }
      }
    }
    orderCustomerId
    total
    order
    createdAt
  }
}
`;
export const onCreateVideoCast = `subscription OnCreateVideoCast($owner: String) {
  onCreateVideoCast(owner: $owner) {
    id
    owner
    video {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
  }
}
`;
export const onUpdateVideoCast = `subscription OnUpdateVideoCast($owner: String) {
  onUpdateVideoCast(owner: $owner) {
    id
    owner
    video {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
  }
}
`;
export const onDeleteVideoCast = `subscription OnDeleteVideoCast($owner: String) {
  onDeleteVideoCast(owner: $owner) {
    id
    owner
    video {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
  }
}
`;
export const onCreateDesigner = `subscription OnCreateDesigner($owner: String) {
  onCreateDesigner(owner: $owner) {
    id
    owner
    email
    phone
    name
    photo_url
    bio
    location
    active
    createdAt
    pro
    profile {
      first_name
      last_name
      name
      bio
      company_name
      title
      address_line1
      address_line2
      city
      state
      zip_code
      country
      website_url
      allow_contact_email
      facebook_handle
      twitter_handle
      youtube_handle
      pinterest_handle
      instagram_handle
      linkedin_url
      blog_url
      google_plus_url
      houzz_url
      feature_video
      availables
      certifications
      photo_url
      cover_url
      status
    }
    draft_profile {
      first_name
      last_name
      name
      bio
      company_name
      title
      address_line1
      address_line2
      city
      state
      zip_code
      country
      website_url
      allow_contact_email
      facebook_handle
      twitter_handle
      youtube_handle
      pinterest_handle
      instagram_handle
      linkedin_url
      blog_url
      google_plus_url
      houzz_url
      feature_video
      availables
      certifications
      photo_url
      cover_url
      status
    }
    user {
      id
      owner
      email
      phone
      first_name
      last_name
      photo_url
      cover_url
      notes
      active
      createdAt
      watchlist {
        watchlistVideoId
        createdAt
      }
      currentVideo {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      history {
        historyVideoId
        createdAt
      }
      favorites {
        favoriteVideoId
        createdAt
      }
      userDesignerId
      orders {
        items {
          id
          owner
          orderCustomerId
          total
          order
          createdAt
        }
        nextToken
      }
      cart {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        owner
        items
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      designer {
        id
        owner
        email
        phone
        name
        photo_url
        bio
        location
        active
        createdAt
        pro
        profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        draft_profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subs {
          nextToken
        }
        tags {
          nextToken
        }
        pendingVideos {
          nextToken
        }
        videos {
          nextToken
        }
        projects {
          nextToken
        }
        posts {
          nextToken
        }
      }
    }
    subs {
      items {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subDesignerId
        stripeId
        stripeSubId
        createdAt
        owner
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    tags {
      items {
        id
        tagsDesignersTagId
        tagsDesignersDesignerId
        owner
        tag {
          id
          tag
          type
          owner
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    pendingVideos {
      items {
        id
        owner
        title
        description
        status
        createdAt
        src_video
        pendingVideoCategoryId
        pendingVideoShowId
        pendingVideoDesignerId
        season
        episode
        duration
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    videos {
      items {
        id
        owner
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    projects {
      items {
        id
        title
        description
        images
        videos
        projectDesignerId
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
        owner
      }
      nextToken
    }
    posts {
      items {
        id
        editor {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
        owner
        post {
          id
          owner
          title
          description
          content
          status
          thumbnail
          createdAt
          likes
          liked
        }
      }
      nextToken
    }
  }
}
`;
export const onUpdateDesigner = `subscription OnUpdateDesigner($owner: String) {
  onUpdateDesigner(owner: $owner) {
    id
    owner
    email
    phone
    name
    photo_url
    bio
    location
    active
    createdAt
    pro
    profile {
      first_name
      last_name
      name
      bio
      company_name
      title
      address_line1
      address_line2
      city
      state
      zip_code
      country
      website_url
      allow_contact_email
      facebook_handle
      twitter_handle
      youtube_handle
      pinterest_handle
      instagram_handle
      linkedin_url
      blog_url
      google_plus_url
      houzz_url
      feature_video
      availables
      certifications
      photo_url
      cover_url
      status
    }
    draft_profile {
      first_name
      last_name
      name
      bio
      company_name
      title
      address_line1
      address_line2
      city
      state
      zip_code
      country
      website_url
      allow_contact_email
      facebook_handle
      twitter_handle
      youtube_handle
      pinterest_handle
      instagram_handle
      linkedin_url
      blog_url
      google_plus_url
      houzz_url
      feature_video
      availables
      certifications
      photo_url
      cover_url
      status
    }
    user {
      id
      owner
      email
      phone
      first_name
      last_name
      photo_url
      cover_url
      notes
      active
      createdAt
      watchlist {
        watchlistVideoId
        createdAt
      }
      currentVideo {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      history {
        historyVideoId
        createdAt
      }
      favorites {
        favoriteVideoId
        createdAt
      }
      userDesignerId
      orders {
        items {
          id
          owner
          orderCustomerId
          total
          order
          createdAt
        }
        nextToken
      }
      cart {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        owner
        items
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      designer {
        id
        owner
        email
        phone
        name
        photo_url
        bio
        location
        active
        createdAt
        pro
        profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        draft_profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subs {
          nextToken
        }
        tags {
          nextToken
        }
        pendingVideos {
          nextToken
        }
        videos {
          nextToken
        }
        projects {
          nextToken
        }
        posts {
          nextToken
        }
      }
    }
    subs {
      items {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subDesignerId
        stripeId
        stripeSubId
        createdAt
        owner
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    tags {
      items {
        id
        tagsDesignersTagId
        tagsDesignersDesignerId
        owner
        tag {
          id
          tag
          type
          owner
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    pendingVideos {
      items {
        id
        owner
        title
        description
        status
        createdAt
        src_video
        pendingVideoCategoryId
        pendingVideoShowId
        pendingVideoDesignerId
        season
        episode
        duration
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    videos {
      items {
        id
        owner
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    projects {
      items {
        id
        title
        description
        images
        videos
        projectDesignerId
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
        owner
      }
      nextToken
    }
    posts {
      items {
        id
        editor {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
        owner
        post {
          id
          owner
          title
          description
          content
          status
          thumbnail
          createdAt
          likes
          liked
        }
      }
      nextToken
    }
  }
}
`;
export const onDeleteDesigner = `subscription OnDeleteDesigner($owner: String) {
  onDeleteDesigner(owner: $owner) {
    id
    owner
    email
    phone
    name
    photo_url
    bio
    location
    active
    createdAt
    pro
    profile {
      first_name
      last_name
      name
      bio
      company_name
      title
      address_line1
      address_line2
      city
      state
      zip_code
      country
      website_url
      allow_contact_email
      facebook_handle
      twitter_handle
      youtube_handle
      pinterest_handle
      instagram_handle
      linkedin_url
      blog_url
      google_plus_url
      houzz_url
      feature_video
      availables
      certifications
      photo_url
      cover_url
      status
    }
    draft_profile {
      first_name
      last_name
      name
      bio
      company_name
      title
      address_line1
      address_line2
      city
      state
      zip_code
      country
      website_url
      allow_contact_email
      facebook_handle
      twitter_handle
      youtube_handle
      pinterest_handle
      instagram_handle
      linkedin_url
      blog_url
      google_plus_url
      houzz_url
      feature_video
      availables
      certifications
      photo_url
      cover_url
      status
    }
    user {
      id
      owner
      email
      phone
      first_name
      last_name
      photo_url
      cover_url
      notes
      active
      createdAt
      watchlist {
        watchlistVideoId
        createdAt
      }
      currentVideo {
        id
        owner
        title
        description
        status
        url
        thumbnail
        createdAt
        views {
          user
          video
          createdAt
        }
        season
        episode
        release_date
        duration
        likes
        src_video
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        tags {
          nextToken
        }
        products {
          nextToken
        }
        designers {
          nextToken
        }
        comments {
          nextToken
        }
      }
      history {
        historyVideoId
        createdAt
      }
      favorites {
        favoriteVideoId
        createdAt
      }
      userDesignerId
      orders {
        items {
          id
          owner
          orderCustomerId
          total
          order
          createdAt
        }
        nextToken
      }
      cart {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        owner
        items
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      designer {
        id
        owner
        email
        phone
        name
        photo_url
        bio
        location
        active
        createdAt
        pro
        profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        draft_profile {
          first_name
          last_name
          name
          bio
          company_name
          title
          address_line1
          address_line2
          city
          state
          zip_code
          country
          website_url
          allow_contact_email
          facebook_handle
          twitter_handle
          youtube_handle
          pinterest_handle
          instagram_handle
          linkedin_url
          blog_url
          google_plus_url
          houzz_url
          feature_video
          availables
          certifications
          photo_url
          cover_url
          status
        }
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subs {
          nextToken
        }
        tags {
          nextToken
        }
        pendingVideos {
          nextToken
        }
        videos {
          nextToken
        }
        projects {
          nextToken
        }
        posts {
          nextToken
        }
      }
    }
    subs {
      items {
        id
        user {
          id
          owner
          email
          phone
          first_name
          last_name
          photo_url
          cover_url
          notes
          active
          createdAt
          userDesignerId
        }
        subDesignerId
        stripeId
        stripeSubId
        createdAt
        owner
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    tags {
      items {
        id
        tagsDesignersTagId
        tagsDesignersDesignerId
        owner
        tag {
          id
          tag
          type
          owner
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    pendingVideos {
      items {
        id
        owner
        title
        description
        status
        createdAt
        src_video
        pendingVideoCategoryId
        pendingVideoShowId
        pendingVideoDesignerId
        season
        episode
        duration
        category {
          id
          owner
          name
          description
          createdAt
        }
        show {
          id
          owner
          title
          description
          release_date
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    videos {
      items {
        id
        owner
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      nextToken
    }
    projects {
      items {
        id
        title
        description
        images
        videos
        projectDesignerId
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
        owner
      }
      nextToken
    }
    posts {
      items {
        id
        editor {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
        owner
        post {
          id
          owner
          title
          description
          content
          status
          thumbnail
          createdAt
          likes
          liked
        }
      }
      nextToken
    }
  }
}
`;
export const onCreateProject = `subscription OnCreateProject($owner: String) {
  onCreateProject(owner: $owner) {
    id
    title
    description
    images
    videos
    projectDesignerId
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
    owner
  }
}
`;
export const onUpdateProject = `subscription OnUpdateProject($owner: String) {
  onUpdateProject(owner: $owner) {
    id
    title
    description
    images
    videos
    projectDesignerId
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
    owner
  }
}
`;
export const onDeleteProject = `subscription OnDeleteProject($owner: String) {
  onDeleteProject(owner: $owner) {
    id
    title
    description
    images
    videos
    projectDesignerId
    designer {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
    owner
  }
}
`;
export const onCreatePostEditor = `subscription OnCreatePostEditor($owner: String) {
  onCreatePostEditor(owner: $owner) {
    id
    editor {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
    owner
    post {
      id
      owner
      title
      description
      content
      status
      thumbnail
      createdAt
      likes
      liked
      editors {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
  }
}
`;
export const onUpdatePostEditor = `subscription OnUpdatePostEditor($owner: String) {
  onUpdatePostEditor(owner: $owner) {
    id
    editor {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
    owner
    post {
      id
      owner
      title
      description
      content
      status
      thumbnail
      createdAt
      likes
      liked
      editors {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
  }
}
`;
export const onDeletePostEditor = `subscription OnDeletePostEditor($owner: String) {
  onDeletePostEditor(owner: $owner) {
    id
    editor {
      id
      owner
      email
      phone
      name
      photo_url
      bio
      location
      active
      createdAt
      pro
      profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      draft_profile {
        first_name
        last_name
        name
        bio
        company_name
        title
        address_line1
        address_line2
        city
        state
        zip_code
        country
        website_url
        allow_contact_email
        facebook_handle
        twitter_handle
        youtube_handle
        pinterest_handle
        instagram_handle
        linkedin_url
        blog_url
        google_plus_url
        houzz_url
        feature_video
        availables
        certifications
        photo_url
        cover_url
        status
      }
      user {
        id
        owner
        email
        phone
        first_name
        last_name
        photo_url
        cover_url
        notes
        active
        createdAt
        watchlist {
          watchlistVideoId
          createdAt
        }
        currentVideo {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        history {
          historyVideoId
          createdAt
        }
        favorites {
          favoriteVideoId
          createdAt
        }
        userDesignerId
        orders {
          nextToken
        }
        cart {
          id
          owner
          items
        }
        subs {
          nextToken
        }
        designer {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
      }
      subs {
        items {
          id
          subDesignerId
          stripeId
          stripeSubId
          createdAt
          owner
        }
        nextToken
      }
      tags {
        items {
          id
          tagsDesignersTagId
          tagsDesignersDesignerId
          owner
        }
        nextToken
      }
      pendingVideos {
        items {
          id
          owner
          title
          description
          status
          createdAt
          src_video
          pendingVideoCategoryId
          pendingVideoShowId
          pendingVideoDesignerId
          season
          episode
          duration
        }
        nextToken
      }
      videos {
        items {
          id
          owner
        }
        nextToken
      }
      projects {
        items {
          id
          title
          description
          images
          videos
          projectDesignerId
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          owner
        }
        nextToken
      }
    }
    owner
    post {
      id
      owner
      title
      description
      content
      status
      thumbnail
      createdAt
      likes
      liked
      editors {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
  }
}
`;
export const onCreatePost = `subscription OnCreatePost($owner: String) {
  onCreatePost(owner: $owner) {
    id
    owner
    title
    description
    content
    status
    thumbnail
    createdAt
    likes
    liked
    editors {
      items {
        id
        editor {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
        owner
        post {
          id
          owner
          title
          description
          content
          status
          thumbnail
          createdAt
          likes
          liked
        }
      }
      nextToken
    }
    comments {
      items {
        id
        owner
        content
        avatar
        name
        commentPostId
        commentVideoId
        createdAt
        likes
        subs
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        post {
          id
          owner
          title
          description
          content
          status
          thumbnail
          createdAt
          likes
          liked
        }
      }
      nextToken
    }
  }
}
`;
export const onUpdatePost = `subscription OnUpdatePost($owner: String) {
  onUpdatePost(owner: $owner) {
    id
    owner
    title
    description
    content
    status
    thumbnail
    createdAt
    likes
    liked
    editors {
      items {
        id
        editor {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
        owner
        post {
          id
          owner
          title
          description
          content
          status
          thumbnail
          createdAt
          likes
          liked
        }
      }
      nextToken
    }
    comments {
      items {
        id
        owner
        content
        avatar
        name
        commentPostId
        commentVideoId
        createdAt
        likes
        subs
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        post {
          id
          owner
          title
          description
          content
          status
          thumbnail
          createdAt
          likes
          liked
        }
      }
      nextToken
    }
  }
}
`;
export const onDeletePost = `subscription OnDeletePost($owner: String) {
  onDeletePost(owner: $owner) {
    id
    owner
    title
    description
    content
    status
    thumbnail
    createdAt
    likes
    liked
    editors {
      items {
        id
        editor {
          id
          owner
          email
          phone
          name
          photo_url
          bio
          location
          active
          createdAt
          pro
        }
        owner
        post {
          id
          owner
          title
          description
          content
          status
          thumbnail
          createdAt
          likes
          liked
        }
      }
      nextToken
    }
    comments {
      items {
        id
        owner
        content
        avatar
        name
        commentPostId
        commentVideoId
        createdAt
        likes
        subs
        video {
          id
          owner
          title
          description
          status
          url
          thumbnail
          createdAt
          season
          episode
          release_date
          duration
          likes
          src_video
        }
        post {
          id
          owner
          title
          description
          content
          status
          thumbnail
          createdAt
          likes
          liked
        }
      }
      nextToken
    }
  }
}
`;
export const onCreateComment = `subscription OnCreateComment($owner: String) {
  onCreateComment(owner: $owner) {
    id
    owner
    content
    avatar
    name
    commentPostId
    commentVideoId
    createdAt
    likes
    subs
    video {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    post {
      id
      owner
      title
      description
      content
      status
      thumbnail
      createdAt
      likes
      liked
      editors {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
  }
}
`;
export const onUpdateComment = `subscription OnUpdateComment($owner: String) {
  onUpdateComment(owner: $owner) {
    id
    owner
    content
    avatar
    name
    commentPostId
    commentVideoId
    createdAt
    likes
    subs
    video {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    post {
      id
      owner
      title
      description
      content
      status
      thumbnail
      createdAt
      likes
      liked
      editors {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
  }
}
`;
export const onDeleteComment = `subscription OnDeleteComment($owner: String) {
  onDeleteComment(owner: $owner) {
    id
    owner
    content
    avatar
    name
    commentPostId
    commentVideoId
    createdAt
    likes
    subs
    video {
      id
      owner
      title
      description
      status
      url
      thumbnail
      createdAt
      views {
        user
        video
        createdAt
      }
      season
      episode
      release_date
      duration
      likes
      src_video
      category {
        id
        owner
        name
        description
        createdAt
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      show {
        id
        owner
        title
        description
        release_date
        videos {
          nextToken
        }
        pendingVideos {
          nextToken
        }
      }
      tags {
        items {
          id
          owner
        }
        nextToken
      }
      products {
        items {
          id
          owner
          timestamp
        }
        nextToken
      }
      designers {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
    post {
      id
      owner
      title
      description
      content
      status
      thumbnail
      createdAt
      likes
      liked
      editors {
        items {
          id
          owner
        }
        nextToken
      }
      comments {
        items {
          id
          owner
          content
          avatar
          name
          commentPostId
          commentVideoId
          createdAt
          likes
          subs
        }
        nextToken
      }
    }
  }
}
`;
export const onCreateLike = `subscription OnCreateLike($owner: String) {
  onCreateLike(owner: $owner) {
    id
    subject
    objectId
    createdBy
    createdAt
    liked
    owner
  }
}
`;
export const onUpdateLike = `subscription OnUpdateLike($owner: String) {
  onUpdateLike(owner: $owner) {
    id
    subject
    objectId
    createdBy
    createdAt
    liked
    owner
  }
}
`;
export const onDeleteLike = `subscription OnDeleteLike($owner: String) {
  onDeleteLike(owner: $owner) {
    id
    subject
    objectId
    createdBy
    createdAt
    liked
    owner
  }
}
`;
