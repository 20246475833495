import { useState, useEffect } from 'react';

import { useApolloClient } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { getProductCategory } from '../graphql/custom-queries';
import { formatImage } from '../utils';

export default function useProductCategory(id, fetchPolicy = 'network-only') {
  const [category, setCategory] = useState(null);
  const client = useApolloClient();

  useEffect(() => {
    if (id)
      (async function() {
        const _category = await getProductCategoryData(id, client, fetchPolicy);

        setCategory(_category);
      })();
  }, [client, fetchPolicy, id]);

  return category;
}

export const getProductCategoryData = async function(id, client, fetchPolicy = 'network-only') {
  try {
    const { data } = await client.query({
      query: gql(getProductCategory),
      variables: { id },
      fetchPolicy
    });

    if (data.getProductCategory) {
      data.getProductCategory.image = formatImage(data.getProductCategory.image, data.getProductCategory.slug);
      return data.getProductCategory;
    }

    return data;
  } catch (e) {
    throw new Error(e);
  }
};
