export default theme => ({
  root: {
    minHeight: '100vh',
    paddingTop: 64
  },
  header: {
    minHeight: 550,
    backgroundImage:
      'url(https://hdtv-prod.s3.amazonaws.com/public/images/protection-business-company-risk-management-security-safety-insurance-support-defense-man-holds-hands_t20_znE8ZQ.jpg)',
    backgroundPosition: 'top',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    '& .header-content': {
      height: '100%',
      maxWidth: 700,
      margin: '10% 10%',
      padding: '5%',
      background: 'rgba(0, 0, 0, .7)',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      '& h1': {
        color: '#FFF',
        '@media (max-width: 769px)': {
          fontSize: '2em'
        },
        '@media (max-width: 569px)': {
          fontSize: '1.8em'
        }
      },
      '& p': {
        color: '#FFF',
        lineHeight: theme.spacing(0.2),
        '@media (max-width: 769px)': {
          width: '75%',
          padding: 0,
          margin: 0
        },
        '@media (max-width: 569px)': {
          width: '90%',
          padding: 0,
          margin: 0
        }
      },
      '@media (max-width: 569px)': {
        margin: '15% 10%'
      }
    },
    '@media (max-width: 769px)': {
      height: 400
    },
    '@media (max-width: 569px)': {
      height: 350
    }
  },
  content: {
    padding: 15,
    '& h1': {
      textAlign: 'center',
      fontWeight: 400
    },
    '& a': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textDecoration: 'none',
      color: 'inherit',
      '& p': {
        fontWeight: 600,
        fontSize: '1.2em'
      }
    },
    '& .categories': {
      minHeight: 250,
      '@media (min-width: 800px)': {
        padding: '40px 100px'
      }
    },
    '@media (max-width: 569px)': {
      '& h1': {
        fontSize: '1.7em'
      }
    }
  },
  section: {
    position: 'relative',
    paddingBottom: 60,
    minHeight: 350,
    '& .MuiAvatar-root': {
      width: 80,
      height: 80,
      marginBottom: 50
    },
    '& img': {
      width: '100%'
    },
    '& .MuiSvgIcon-root': {
      color: '#649FCB',
      height: 16
    },
    '& .MuiTypography-caption': {
      marginBottom: 15,
      display: 'inline-block'
    },
    '& .applyNow': {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'flex-end',
      paddingTop: 40,
      paddingRight: 20,
      background: 'transparent',
      zIndex: 1
    },
    '& .background': {
      backgroundImage: `url('https://hdtv-prod.s3.amazonaws.com/public/images/greenhouse-in-the-spring-at-the-sunset_t20_v3rAwO.jpg')`,
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      opacity: 0.5,
      backgroundPosition: 'center',
      backgroundSize: 'cover'
    },
    '& .sub-section': {
      minHeight: 250,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: 40,
      '& p': {
        maxWidth: 400,
        opacity: 0.7
      },
      '& svg': {
        height: 70,
        width: 'auto',
        marginBottom: 20
      }
    },
    '& p': {
      marginBottom: 5
    }
  },
  footer: {
    height: 200,
    '@media (max-width: 569px)': {
      height: 170
    }
  },
  horizontalView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around'
  }
});
