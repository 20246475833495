import React, { useEffect, useState } from 'react';
import { withStyles, useMediaQuery, GridList, GridListTile, GridListTileBar, Paper } from '@material-ui/core';
import compose from 'recompose/compose';

//graphql stuff
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';

//styles
import styles from './styles';
import query from './query';
import { GridListLoadingHolder } from '../../components/LoadingHolder';

const gqlQuery = gql(query);

const Designers = props => {
  const { classes, pro, history } = props;
  const { data, error, loading } = useQuery(gqlQuery, {
    fetchPolicy: 'network-only',
    variables: { limit: 1000, filter: { active: { eq: true }, pro: pro ? { eq: true } : { ne: true } } }
  });
  const [designers, setDesigners] = useState(null);
  const mobile = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const tablet = useMediaQuery(theme => theme.breakpoints.down('md'));

  useEffect(() => {
    if (data.listDesigners) {
      setDesigners(data.listDesigners.items);
    }
  }, [data]);

  return (
    <>
      <div className={classes.root}>
        <div className={!tablet ? classes.content : classes.mobileContent}>
          {loading && !designers && (
            <div>
              <GridListLoadingHolder numTiles={1} cellHeight={450} cols={mobile ? 1 : tablet ? 2 : 4} spacing={6} />
            </div>
          )}
          {error &&
            (error.graphql ? (
              error.graphql.error.map(err => {
                return <span>{JSON.stringify(err)}</span>;
              })
            ) : (
              <span>{JSON.stringify(error)}</span>
            ))}
          {designers && (
            <GridList cellHeight={mobile ? 450 : tablet ? 500 : 350} cols={mobile ? 1 : tablet ? 2 : 4} spacing={10}>
              {designers.map(designer => (
                <GridListTile
                  key={designer.id}
                  onClick={() => {
                    history.push(`/designers/${designer.id}`);
                  }}
                  classes={{ tile: classes.cursorPointer }}
                >
                  <Paper
                    className={classes.imgContainer}
                    style={{
                      backgroundImage: `url(${
                        designer.photo_url
                          ? 'https://hdtv-prod.s3.amazonaws.com/public/' + designer.photo_url
                          : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
                      })`
                    }}
                  />
                  <GridListTileBar
                    classes={{
                      title: classes.title,
                      subtitle: classes.subtitle,
                      root: classes.titleBar,
                      titleWrap: classes.titleWrap
                    }}
                    title={designer.name}
                  />
                </GridListTile>
              ))}
            </GridList>
          )}
        </div>
      </div>
    </>
  );
};

export default compose(withStyles(styles))(Designers);
