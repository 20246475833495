import React from 'react';
import styled from 'styled-components';

function ModalFooter() {
  return <Wrapper />;
}

const Wrapper = styled.footer`
  margin-top: 1.5rem;
`;

export default ModalFooter;
