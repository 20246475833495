import React, { useState, useEffect } from 'react';
import { withStyles, Card, CardActionArea, CardContent, CardMedia, Typography, Paper } from '@material-ui/core';
import compose from 'recompose/compose';
import TimeAgo from 'react-timeago';
import styles from './styles';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { VideoLoadingHolder } from '../../../../components/LoadingHolder';

const GetUserQuery = gql`
  query GetUser($id: ID!) {
    getUser(id: $id) {
      first_name
      last_name
      photo_url
    }
  }
`;
const Article = props => {
  const { classes, post } = props;
  const [author, setAuthor] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const { data } = useQuery(GetUserQuery, { variables: { id: post.owner } });

  useEffect(() => {
    if (data.getUser) {
      setAuthor(data.getUser);
    }
  }, [data]);

  return (
    <Paper className={classes.paper} elevation={10}>
      <Card className={classes.card}>
        <CardActionArea>
          <CardMedia component="img" className={classes.cardMedia} image={post.thumbnail} title={post.title} onLoad={() => setImageLoaded(true)} />
          {!imageLoaded && <VideoLoadingHolder />}
          <CardContent>
            <Typography variant={'caption'}>
              {author ? `${author.first_name} ${author.last_name}` : 'HDTV'} - <TimeAgo date={post.createdAt} />
            </Typography>
            <Typography gutterBottom variant="h5" component="h2">
              {post.title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {post.description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Paper>
  );
};

export default compose(withStyles(styles))(Article);
