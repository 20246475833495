import React, { useState, useEffect } from 'react';
import { withStyles, Button, useMediaQuery } from '@material-ui/core';
import styles from './styles';

import { useVideoTime } from 'hooks/useVideoTime';
import ProductModal from 'components/ProductModal';
import ProductModalMobile from 'components/ProductModalMobile';
import { currencyFormat } from '../../../../utils';

const isThereACurrencyPrice = product => {
  try {
    if (product.skus.length === 0) {
      throw new Error('No skus found!');
    }

    if (product.skus.length === 1) {
      return <div className="price">{currencyFormat(product.skus[0].price)}</div>;
    }

    let max_sku = product.skus[0];
    let min_sku = product.skus[0];
    for (let sku of product.skus) {
      if (sku.price < min_sku.price) {
        min_sku = sku;
      } else if (sku.price > max_sku.price) {
        max_sku = sku;
      }
    }
    return <div className="price">{`${currencyFormat(min_sku.price)} - ${currencyFormat(max_sku.price)}`}</div>;
  } catch (e) {
    return <div className="price">Price not available</div>;
  }
};

const VideoProductItem = props => {
  const { classes, product, timestamp } = props;
  const [seen, setSeen] = useState(false);
  const [open, setModalOpen] = useState(false);
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const time = useVideoTime();

  useEffect(() => {
    if (time > timestamp) setSeen(true);
  }, [time, timestamp]);

  const convertTime = value => {
    const minutes = Math.floor(value / 60);
    return (
      Number(minutes)
        .toFixed(0)
        .toString()
        .padStart(2, '0') +
      ':' +
      Number(value - minutes * 60)
        .toFixed(0)
        .toString()
        .padStart(2, '0')
    );
  };

  const toggleModal = () => setModalOpen(!open);

  return (
    <div className={classes.productContainer + ' ' + (!seen ? classes.hideProduct : undefined)}>
      {product.id && (
        <>
          <div className={classes.productContainerWrapper}>
            <div className={classes.productContainerInner}>
              <img className={classes.productImage} src={product.images.length > 0 ? product.images[0] : null} alt={product.name} />
              <div className={classes.productInfo}>
                <div>
                  <h3>{product.name}</h3>
                  <p>{product.description.slice(0, 40)}</p>
                </div>
                <div className={classes.productFooter}>
                  <div>
                    <h4>{isThereACurrencyPrice(product)}</h4>
                    <p>Seen At {convertTime(timestamp)}</p>
                  </div>
                  <Button onClick={toggleModal} variant={'outlined'} color={'primary'}>
                    Add To Cart
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {!mobile ? <ProductModal product={product} open={open} toggleModal={toggleModal} /> : <ProductModalMobile product={product} open={open} toggleModal={toggleModal} />}
        </>
      )}
    </div>
  );
};

export default withStyles(styles)(VideoProductItem);
