import React, { useEffect } from 'react';
import { withStyles, useMediaQuery, Typography, GridList, GridListTile } from '@material-ui/core';
import ResponsiveVideo from 'components/ResponsiveVideo';

import styles from './styles';
import { useStore } from 'easy-peasy';

const Feed = props => {
  const { classes } = props;
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const user = useStore(state => state.user.user);
  const path = props.match.path.slice(1);
  const cols = mobile ? 1 : 3;
  useEffect(() => console.log(user), [user]);

  const goToVideoDetail = id => {
    props.history.push('/videos/' + id);
  };

  return (
    <div className={classes.root}>
      <div className={!mobile ? classes.content : classes.mobileContent}>
        <div className={classes.categoryTitle + ' ' + (mobile ? classes.mobileTitle : undefined)}>{path.toUpperCase()}</div>
        {user ? (
          <>
            {props.match.path === '/history' &&
              (user.history && user.history.length > 0 ? (
                <GridList cellHeight={'auto'} cols={cols}>
                  {user.history.map(x => {
                    return (
                      <GridListTile key={x.createdAt}>
                        <ResponsiveVideo key={x.historyVideoId} videoId={x.historyVideoId} date={x.createdAt} goToVideoDetail={goToVideoDetail} details />
                      </GridListTile>
                    );
                  })}
                </GridList>
              ) : (
                <Typography>Your history is empty. Go browse some shows!</Typography>
              ))}
            {props.match.path === '/watchlist' &&
              (user.watchlist && user.watchlist.length > 0 ? (
                <GridList cellHeight={'auto'} cols={cols}>
                  {user.watchlist.map(x => {
                    return (
                      <GridListTile key={x.createdAt}>
                        <ResponsiveVideo key={x.watchlistVideoId} videoId={x.watchlistVideoId} date={x.createdAt} goToVideoDetail={goToVideoDetail} details />
                      </GridListTile>
                    );
                  })}
                </GridList>
              ) : (
                <Typography>Your watchlist is empty. Go browse some shows!</Typography>
              ))}
            {props.match.path === '/favorites' &&
              (user.favorites && user.favorites.length > 0 ? (
                <GridList cellHeight={'auto'} cols={cols}>
                  {user.favorites.map(x => {
                    return (
                      <GridListTile key={x.createdAt}>
                        <ResponsiveVideo key={x.favoriteVideoId} videoId={x.favoriteVideoId} date={x.createdAt} goToVideoDetail={goToVideoDetail} details />
                      </GridListTile>
                    );
                  })}
                </GridList>
              ) : (
                <Typography>Your favorites is empty. Go browse and fav some shows!</Typography>
              ))}
          </>
        ) : (
          <Typography>Loading...</Typography>
        )}
      </div>
    </div>
  );
};
export default withStyles(styles)(Feed);
