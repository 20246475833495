import React from 'react';
import { withStyles } from '@material-ui/core';
import compose from 'recompose/compose';
import Showcase from 'components/Showcase';

//styles
import styles from './styles';
import { Auth } from 'aws-amplify';
import { useActions, useStore } from 'easy-peasy';

const Videos = props => {
  const { classes } = props;
  const user = useStore(state => state.user.user);
  const { goToLogin } = useActions(actions => actions.modal);

  const goToVideoDetail = id => {
    user && user.id ? props.history.push('/videos/' + id) : goToLogin();
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Showcase goToVideoDetail={goToVideoDetail} />
      </div>
    </div>
  );
};

export default compose(withStyles(styles))(Videos);
