import React, { useState, useEffect } from 'react';
import { Popover, Typography, withStyles, Link, useMediaQuery, Grid } from '@material-ui/core';
import TimeAgo from 'react-timeago';
import { currencyFormat } from '../../utils';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  textHint: {
    opacity: 0.4
  }
});

const PopOverAddress = ({ shipping }) => (
  <div style={{ padding: 15 }}>
    <Typography>{shipping.name}</Typography>
    <Typography>{shipping.address.line1}</Typography>
    <Typography>
      {shipping.address.city}, {shipping.address.state} {shipping.address.postal_code}
    </Typography>
    <Typography>{shipping.address.country}</Typography>
    {shipping.phone && <Typography>Phone: {shipping.phone}</Typography>}
  </div>
);

const OrderHeader = ({ order, classes }) => {
  const [createdAt, setCreatedAt] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  useEffect(() => {
    if (order) {
      const createdAt = new Date(0);
      createdAt.setUTCSeconds(order.created);
      setCreatedAt(createdAt);
    }
  }, [order]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Grid container spacing={3}>
      <Grid item xs={mobile ? 4 : 3}>
        <Typography>Placed: </Typography>
        {createdAt && <TimeAgo date={createdAt} />}
      </Grid>
      <Grid item xs={mobile ? 4 : 3}>
        <Typography>Total: </Typography>
        <Typography className={classes.textHint}>{currencyFormat(order.amount)}</Typography>
      </Grid>
      <Grid item xs={mobile ? 4 : 3}>
        <Typography>Ship To</Typography>
        <Link aria-describedby={id} onClick={handleClick}>
          {order.shipping.name}
        </Link>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <PopOverAddress shipping={order.shipping} />
        </Popover>
      </Grid>
      {!mobile && (
        <>
          <Grid item xs={3}>
            <Typography>ORDER #:</Typography>
            <Typography className={classes.textHint}>{order.id}</Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default withStyles(styles)(OrderHeader);
