import React from 'react';
import { withStyles, Paper } from '@material-ui/core';
import compose from 'recompose/compose';

import styles from './styles';

const About = props => {
  const { classes } = props;

  return (
    <div>
      <div className={classes.headerImgContainer}>
        <h1 className={classes.h1}>HomeDesignTV</h1>
        <img alt="HomeDesignTV" className={classes.headerImg} src="https://res.cloudinary.com/tylerholden/image/upload/v1560549668/HDTV/about-page-header.jpg" />
      </div>
      <div className={classes.section}>
        <Paper className={classes.card} elevation={20}>
          <h2 className={classes.h2}>A letter from the founders</h2>
          <p className={classes.p}>
            Thank you for your interest in participating in the exciting new HDTV Community! HDTV is the new “OTT” kid on the block for the <i>home design</i> and
            <i>DIY</i> enthusiast. For the record, <b>OTT</b> stands for “<b>over</b>-the-<b>top</b>”. This is the term used for the delivery of film and TV content via the
            internet,
            <i>without</i> requiring users to subscribe to a traditional cable or satellite pay-TV service. In other words, no subscription needed. We sit in the center of the
            inevitable and unstoppable merger between the worlds of television and digital video. <br />
            <br />
            Have you ever noticed that most cable or <i>on demand</i> networks think that interior designers and DIY pros can’t get along? It’s either one or the other. That’s what
            inspired us to launch HDTV. We’re here to merge these two amazing “home hashtags”, bringing you the best of both worlds. We strive to create a community where you (the
            audience) not only learn, but interact with our pros. We not only encourage our audience to connect, but we've created a platform where both the emerging pro, and the
            passionate amateur can share their love of the industry. <br />
            <br />
            We know there’s no shortage of style, tips or inspiration on the internet, but in order to truly find the best of what you're looking for, you have to know where to
            look.
            <b>HDTV</b> is here to provide a reliable “one stop shop” resource to give you a healthy dose of daily knowledge and inspiration. Whether it’s home decorating, interior
            design, remodeling, gardening or even crafty tips for entertaining the neighbors, the all-new HomeDesignTV.com has you covered.
          </p>
          <br />
          <p className={classes.p}>
            <b>- Josh & Donna</b>
          </p>
          <br />
        </Paper>
      </div>
      <div className={classes.section}>
        <h2 className={classes.h2}>What is HomeDesignTV?</h2>
        <p className={classes.p}>
          We’re not just a budding network, we are creating a fun and interactive resource for Home decor, DIY and residential construction. Think of Home Advisor, meets Angies
          List, meet NETFLIX, but with the ability to actually shop Whether you're looking for a little inspiration from one of our deisgners, or maybe your seeking a local
          contractor who specializes in creating custom kitchens on a amazing budget, or just want to browse our product catalogue, we’ve got you covered. Our “ground zero” for
          HDTV is Fort Worth/Dallas. All of our initial efforts will be to focus on both local relationships and a local audience.
        </p>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default compose(withStyles(styles))(About);
