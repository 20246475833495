import React from 'react';

import { LinearProgress, List, ListItem, Typography, withStyles, ListItemText, Button, ListItemSecondaryAction, IconButton, useMediaQuery } from '@material-ui/core';

import { ArrowForwardIos, EditOutlined } from '@material-ui/icons';

import styles from './styles';

const PortfolioListComponent = ({ projects, classes, setSelected }) => {
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const onSelectProject = product => () => {
    setSelected(product);
  };

  return (
    <>
      {!projects && <LinearProgress />}

      {projects && projects.length > 0 && (
        <>
          {mobile && (
            <List>
              {projects.map(project => (
                <ListItem key={project.id}>
                  <ListItemText
                    primary={project.title}
                    secondary={
                      <React.Fragment>
                        <Typography component="span" variant="body2" color="textPrimary">
                          {project.description}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton onClick={onSelectProject(project)} aria-label={'delete'}>
                      <ArrowForwardIos />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          )}
          {!mobile && (
            <List className={classes.list}>
              {projects.map(project => (
                <ListItem key={project.id} className={classes.listItem}>
                  <Typography variant={'h4'} color={'secondary'}>
                    {project.title}
                  </Typography>
                  <Typography>{project.description}</Typography>
                  <Button onClick={() => setSelected(project)}>
                    <EditOutlined />
                    Edit
                  </Button>
                </ListItem>
              ))}
            </List>
          )}
        </>
      )}
      {projects && projects.length === 0 && <Typography color={'textSecondary'}>You haven't created any projects yet.</Typography>}
    </>
  );
};

export default withStyles(styles)(PortfolioListComponent);
