import React, { useState } from 'react';
import ScrollToBottom from 'react-scroll-to-bottom';
import { withStyles } from '@material-ui/core';
import { TextField, Button } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import uuid from 'uuid';
import TimeAgo from 'react-timeago';
import { useHistory } from 'react-router-dom';

import styles from './styles';

import { API, graphqlOperation } from 'aws-amplify';
// import {useVideoComments} from 'hooks/useVideoComments'
import { createComment } from '../../graphql/mutations';
import { useActions, useStore } from 'easy-peasy';

const VideoComments = props => {
  const { classes, video, comments, isArticle } = props;
  const [disabled, setDisabled] = useState(false);
  const [comment, setComment] = useState([]);
  const history = useHistory();
  const user = useStore(state => state.user.user);
  const { goToLogin } = useActions(actions => actions.modal);

  const handleChange = e => {
    setComment(e.target.value);
  };

  const sendVideoComment = async () => {
    const payload = {
      id: uuid(),
      name: user.first_name ? user.first_name + ' ' + (user.last_name ? user.last_name.slice(0, 1) + '.' : '') : user.email ? user.email : 'Anonymous',
      avatar: user.photo_url,
      commentVideoId: video.id,
      content: comment,
      createdAt: new Date()
    };

    if (user.photo_url) payload.avatar = 'https://hdtv-prod.s3.amazonaws.com/public/' + user.photo_url;
    await API.graphql(graphqlOperation(createComment, { input: payload }));
  };

  const sendArticleComment = async () => {
    const payload = {
      id: uuid(),
      name: user.first_name ? user.first_name + ' ' + user.last_name : user.email ? user.email : 'Anonymous',
      avatar: user.photo_url,
      commentPostId: video.id,
      content: comment,
      createdAt: new Date()
    };

    if (user.photo_url) payload.avatar = 'https://hdtv-prod.s3.amazonaws.com/public/' + user.photo_url;
    await API.graphql(graphqlOperation(createComment, { input: payload }));
  };

  const handleSendComment = async () => {
    setDisabled(true);
    isArticle ? await sendArticleComment() : await sendVideoComment();
    setComment('');
    setDisabled(false);
  };

  return (
    <>
      <TextField
        label="Add a comment"
        className={classes.input}
        value={comment}
        onChange={event => handleChange(event)}
        multiline
        rowsMax="4"
        variant="outlined"
        InputProps={{
          endAdornment:
            user && user.id ? (
              <Button disabled={disabled} variant="outlined" color="primary" onClick={() => handleSendComment()}>
                Send
              </Button>
            ) : (
              <Button variant="outlined" color="primary" onClick={() => goToLogin()}>
                Sign In
              </Button>
            )
        }}
      />
      <ScrollToBottom className={classes.commentBox}>
        <List className={classes.list}>
          {comments &&
            comments.length > 0 &&
            comments.map(comment => {
              return (
                <ListItem key={comment.id} alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar alt={comment.name} src={comment.avatar} />
                  </ListItemAvatar>
                  <ListItemText
                    classes={{ secondary: classes.inline }}
                    primary={
                      <React.Fragment>
                        <Typography component="span" variant={'caption'}>
                          {comment.content}
                        </Typography>
                      </React.Fragment>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography component="span" variant="body2">
                          {comment.name} - <TimeAgo date={comment.createdAt} />
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              );
            })}
        </List>
      </ScrollToBottom>
    </>
  );
};

VideoComments.defaultProps = {
  isArticle: false
};

export default withStyles(styles)(VideoComments);
