import React from 'react';
import { useTrending } from 'hooks/useTrending';
import VideoList from 'components/VideoList/VideoList';
import { makeStyles } from '@material-ui/core';
import { useActions, useStore } from 'easy-peasy';

const useStyles = makeStyles({
  page: {
    marginTop: 70,
    '@media (min-width: 700px)': {
      marginLeft: 40
    }
  }
});

const TrendingPage = props => {
  const trending = useTrending();
  const classes = useStyles();
  const user = useStore(state => state.user.user);
  const { goToLogin } = useActions(actions => actions.modal);

  const goToVideoDetail = id => {
    user && user.id ? props.history.push('/videos/' + id) : goToLogin();
  };

  return (
    <div className={classes.page}>
      <div>
        <h1>Trending</h1>

        {trending ? (
          trending.map((video, idx) => {
            return <VideoList goToVideoDetail={goToVideoDetail} key={idx} video={video} />;
          })
        ) : (
          <div className="empty-page-container">
            <h3>Your trending is empty. Go browse some shows!</h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default TrendingPage;
