import React, { useRef, useState } from 'react';
import { withStyles, Grid, GridList, GridListTile, Button, Typography, useMediaQuery } from '@material-ui/core';
import { ArrowBackIos, EditOutlined, Delete } from '@material-ui/icons';
import { DropzoneArea } from 'material-ui-dropzone';

import styles from './styles';
import ProgressBar from '../../../../../../../../components/ProgressBar';
import uuid from 'uuid';
import { Storage } from 'aws-amplify';
import { updateProjectData } from '../../../../../../../../hooks/useUpdateProject';
import ReactPlayer from 'react-player';
import MediaPreviewModal from '../../../../../../../../components/MediaPreviewModal';

const PortfolioDetail = ({ onBack, project, classes, onDelete, onEdit }) => {
  const dropZoneRef = useRef();
  const [files, setFiles] = useState([]);
  const [loadingFile, setLoadingFile] = useState(null);
  const [loaded, setLoaded] = useState(null);
  const [loadingFiles, setLoadingFiles] = useState([]);
  const [prevItem, setPrevItem] = useState(null);

  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const onFilesChange = file => {
    const _files = [];
    for (let f of file) {
      f.key = uuid();
      _files.push(f);
    }
    setFiles(_files);
  };

  const uploadFile = async file => {
    setLoadingFile(file);
    const ext = file.name.split('.').pop();
    const key = uuid() + '.' + ext;
    const res = await Storage.put('images/' + key, file, {
      bucket: 'hdtv-prod',
      level: 'public',
      progressCallback(progress) {
        setLoaded(progress);
      }
    });
    setLoaded(null);
    if (file.type.indexOf('image') === 0) {
      await updateProjectData(project.id, [res.key], 'images');
    } else if (file.type.indexOf('video') === 0) {
      await updateProjectData(project.id, [res.key], 'videos');
    }

    setLoadingFiles(loadingFiles.filter(f => f.key !== file.key));
  };

  const openPreviewModal = (type, src) => () => {
    setPrevItem({ type, src });
  };

  const closePreviewModal = () => {
    setPrevItem(null);
  };

  const onUploadMedias = async () => {
    setLoadingFiles(files);
    try {
      for (let file of files) {
        const res = await uploadFile(file);
      }
      setLoadingFile(null);
      setFiles([]);
    } catch (e) {
      throw new Error(e);
    }
  };

  return (
    <Grid container direction={'row'} justify={'center'} alignItems={'flex-start'} spacing={3}>
      <Grid item xs={4} className={classes.pullLeft}>
        <Button onClick={onBack}>
          <ArrowBackIos /> All Projects
        </Button>
      </Grid>
      {!mobile && (
        <Grid item xs={mobile ? 6 : 4} className={classes.centered}>
          <Grid container spacing={3} justify={'center'} alignItems={'center'} direction={'column'}>
            <Typography variant={'h2'} color={'textPrimary'}>
              {project.title}
            </Typography>
            <Typography>{project.description}</Typography>
            <Button onClick={onEdit}>
              <EditOutlined /> Edit
            </Button>
          </Grid>
        </Grid>
      )}
      <Grid item xs={mobile ? 6 : 4} className={classes.pullRight}>
        <Button onClick={onDelete}>
          <Delete />
        </Button>
      </Grid>
      {mobile && (
        <Grid item xs={12} className={classes.centered}>
          <Grid container spacing={3} justify={'center'} alignItems={'center'} direction={'column'}>
            <Typography variant={'h2'} color={'textPrimary'}>
              {project.title}
            </Typography>
            <Typography>{project.description}</Typography>
            <Button onClick={onEdit}>
              <EditOutlined /> Edit
            </Button>
          </Grid>
        </Grid>
      )}

      <Grid item xs={12} className={classes.centered}>
        {(project.images && project.images.length > 0) || (project.videos && project.videos.length > 0) ? (
          <Grid container direction={'column'} spacing={3} className={classes.mediaList}>
            <Grid item xs={12}>
              <Typography variant={'h4'}>Media: </Typography>
            </Grid>
            <GridList className={classes.gridList} cols={2.5}>
              {project.images.map(image => {
                return (
                  <GridListTile key={image} onClick={openPreviewModal('image', `https://hdtv-prod.s3.amazonaws.com/public/${image}`)} className={classes.gridListTile}>
                    <img src={`https://hdtv-prod.s3.amazonaws.com/public/${image}`} alt={image} />
                  </GridListTile>
                );
              })}
              {project.videos.map(e => (
                <GridListTile onClick={openPreviewModal('video', `https://hdtv-prod.s3.amazonaws.com/public/${e}`)} className={classes.gridListTile}>
                  <ReactPlayer
                    style={{ backgroundColor: '#000000' }}
                    className={classes.reactPlayer}
                    url={`https://hdtv-prod.s3.amazonaws.com/public/${e}`}
                    progressInterval={1000}
                    height={'100%'}
                    width={'100%'}
                  />
                </GridListTile>
              ))}
            </GridList>
          </Grid>
        ) : (
          <Typography variant={'caption'}> 0 Images</Typography>
        )}
      </Grid>
      {!loadingFile ? (
        <>
          <Grid item xs={12} className={classes.centered}>
            <DropzoneArea
              maxFileSize={1000000000}
              showFileNames
              filesLimit={10}
              onChange={onFilesChange}
              dropzoneText={'To add media, click or drag and drop photos and/or videos here.'}
              acceptedFiles={['image/*', 'video/*']}
              ref={dropZoneRef}
            />
          </Grid>
          <Grid item xs={12} className={classes.pullRight}>
            <Button disabled={!files || files.length === 0} onClick={onUploadMedias}>
              Upload
            </Button>
          </Grid>
        </>
      ) : (
        <>
          {
            <Grid container direction={'column'} className={classes.uploadingFile}>
              <Typography variant={'caption'}>Uploading {loadingFile.name}</Typography>
              {loaded && <ProgressBar progress={loaded} />}
            </Grid>
          }
        </>
      )}
      {prevItem && <MediaPreviewModal open={!!prevItem} type={prevItem.type} onClose={closePreviewModal} src={prevItem.src} />}
    </Grid>
  );
};

export default withStyles(styles)(PortfolioDetail);
