import { useState, useEffect } from 'react';
import { useApolloClient } from 'react-apollo-hooks';
import { listShows } from '../graphql/queries';
import gql from 'graphql-tag';

export default function useShows(fetchPolicy = 'cache-first') {
  const [shows, setShows] = useState(null);

  const client = useApolloClient();

  useEffect(() => {
    client
      .query({
        query: gql(listShows),
        fetchPolicy
      })
      .then(res => {
        setShows(res.data.listShows.items);
      });
  }, [client, fetchPolicy]);

  return shows;
}
