import React, { useState, useEffect } from 'react';
import { withRouter, Link as RouterLink } from 'react-router-dom';
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withStyles, useMediaQuery } from '@material-ui/core';
import { Toolbar, Link } from '@material-ui/core';

import styles from './styles';

const UpperTopbar = props => {
  const [signal, setSignal] = useState(true);
  const [state, setState] = useState({
    scrolling: false
  });
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    autoScroll();
  }, [props.location]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY < 50) {
        setScrolling(false);
      } else {
        setScrolling(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      setSignal(false);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrolling, signal, state]);

  const { classes, className, title, isSidebarOpen, onToggleSidebar } = props;

  const rootClassName = classNames(classes.root, className);
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  function autoScroll() {
    window.scrollTo(0, 0);
  }

  return (
    <div className={classes.grow}>
      <div className={rootClassName}>
        <Toolbar className={classes.toolbar}>
          {!mobile && (
            <>
              <Link className={(classes.imgLink, classes.fLink)} target={'_blank'} href="https://www.facebook.com/HomeDesignTelevsion/">
                <img alt="Facebook" src="/assets/facebook_social_icon.svg" />
              </Link>
              <Link className={classes.imgLink} target={'_blank'} href="https://twitter.com/TheHDTVnetwork">
                <img alt="Twitter" src="/assets/twitter_social_icon.svg" />
              </Link>
              <Link className={classes.imgLink} target={'_blank'} href="https://www.instagram.com/homedesigntelevision/">
                <img alt="Instagram" src="/assets/instagram_social_icon.svg" />
              </Link>
              <Link className={classes.imgLink} target={'_blank'} href="https://www.pinterest.com/HomeDesignTV/">
                <img alt="Pinterest" src="/assets/pinterest_social_icon.svg" />
              </Link>
            </>
          )}
        </Toolbar>
      </div>
    </div>
  );
};

UpperTopbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isSidebarOpen: PropTypes.bool,
  onToggleSidebar: PropTypes.func,
  onSidebarClose: PropTypes.func,
  title: PropTypes.string
};

UpperTopbar.defaultProps = {
  onToggleSidebar: () => {},
  onSidebarClose: () => {}
};

export default compose(withRouter, withStyles(styles))(UpperTopbar);

////{props.user && props.user.id ? <Cart type={'cart'} user={props.user} /> : <LoginButton onSidebarClose={props.onSidebarClose} autoScroll={autoScroll} />}
