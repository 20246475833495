import React, { useContext, useState } from 'react';
import { css } from 'glamor';
import { Auth } from 'aws-amplify';
import UserContext from '../../../contexts/UserContext';
import { TextField, Button, Typography, Link } from '@material-ui/core';

const ForgotPassword = props => {
  const [state, setState] = useState({
    username: '',
    confirmationCode: '',
    password: '',
    showConfirmation: false
  });

  const context = useContext(UserContext);

  const onChange = (key, value) => {
    props.updateErrorMessage(null);
    setState({
      ...state,
      [key]: value
    });
  };
  const forgotPassword = () => {
    Auth.forgotPassword(state.username)
      .then(() => {
        setState({ ...state, showConfirmation: true });
      })
      .catch(err => {
        console.log('error: ', err);
        props.updateErrorMessage(err.message ? err.message : err);
      });
  };
  const forgotPasswordSubmit = () => {
    const { username, password, confirmationCode } = state;
    Auth.forgotPasswordSubmit(username, confirmationCode, password)
      .then(() => {
        alert('successfully changed password!');
        props.switchState('showSignIn');
      })
      .catch(err => console.log('error resetting password:', err));
  };
  return (
    <div {...css(styles.container)}>
      {!state.showConfirmation && (
        <div {...css(styles.formContainer)}>
          <h2 {...css(styles.signInHeader)}>Forgot Password?</h2>
          <p>Email *</p>
          <TextField variant={'outlined'} value={state.username} onChange={evt => onChange('username', evt.target.value)} {...css(styles.input)} placeholder="Email" />
          <span {...css(styles.actionContainer)}>
            <span>
              <Link onClick={props.showSignIn}>
                <Typography variant={'caption'}>Back to Sign In</Typography>
              </Link>
            </span>
            <Button variant={'contained'} color={'primary'} {...css(styles.button)} onClick={forgotPassword}>
              <p {...css(styles.buttonText)}>Send Code</p>
            </Button>
          </span>
        </div>
      )}
      {state.showConfirmation && (
        <form {...css(styles.formContainer)}>
          <h2 {...css(styles.signInHeader)}>Reset your password</h2>
          <TextField
            variant={'outlined'}
            value={state.confirmationCode}
            onChange={evt => onChange('confirmationCode', evt.target.value)}
            {...css(styles.input)}
            placeholder="Confirmation Code"
          />
          <TextField
            variant={'outlined'}
            value={state.password}
            autoComplete={'on'}
            onChange={evt => onChange('password', evt.target.value)}
            {...css(styles.input)}
            type="password"
            placeholder="New Password"
          />
          <Button variant={'contained'} color={'primary'} {...css(styles.button)} onClick={forgotPasswordSubmit}>
            <p {...css(styles.buttonText)}>Submit</p>
          </Button>
        </form>
      )}
    </div>
  );
};

const styles = {
  signInHeader: {
    textAlign: 'left',
    margin: '0px 0px 30px',
    fontSize: 18,
    fontWeight: 500,
    '@media (max-width: 500px)': {
      margin: '0px 0px 15px'
    }
  },
  button: {
    padding: '10px 60px',
    marginTop: 10,
    marginBottom: 10,
    cursor: 'pointer',
    height: 45,
    width: 153
  },
  buttonText: {
    margin: 0,
    color: 'white'
  },
  input: {
    marginBottom: '20px !important',
    fontSize: '16px',
    '::placeholder': {
      color: 'rgba(0, 0, 0, .3)'
    }
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '10%',
    paddingBottom: 50,
    margin: 'auto',
    paddingRight: 10,
    paddingLeft: 10
  },
  formContainer: {
    padding: '35px 40px',
    width: 400,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, .2)',
    backgroundColor: '#fff',
    borderRadius: 6,
    '> p': {
      fontSize: 14,
      margin: '0px 0px 8px 0px'
    },
    '@media (max-width: 500px)': {
      width: '100% !important',
      boxSizing: 'border-box',
      padding: '20px 30px'
    }
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiTypography-caption': {
      fontSize: 14
    },
    '& a': {
      '> .MuiTypography-caption': {
        color: 'rgb(163, 51, 200)',
        cursor: 'pointer'
      }
    }
  }
};

export default ForgotPassword;
