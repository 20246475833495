import React, { useState, useEffect, useContext } from 'react';
import { Collapse, Paper, Box, SvgIcon, Button, Grid, Typography } from '@material-ui/core';
import { CheckCircleOutlined } from '@material-ui/icons';
import { useApolloClient } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { createDesigner, createTagsDesigners, deleteTagsDesigners } from '../../../../graphql/mutations';

import { Spinner } from '../../../../components/Cart/components/Button';
import updateUserData from '../../../../hooks/useUpdateUser';
import { updateDesignerData } from '../../../../hooks/useUpdateDesigner';
import UserContext from '../../../../contexts/UserContext';

const CompleteScreen = ({ user, state: { first_name, last_name, name, service_id, isPro, email }, classes, onRedirect }) => {
  const [done, setDone] = useState(false);
  const client = useApolloClient();
  const { updateCurrentUser } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    async function createNewDesigner() {
      try {
        const res = await client.mutate({
          mutation: gql(createDesigner),
          variables: {
            input: {
              email: user.email,
              phone: user.phone,
              name: name,
              pro: isPro,
              createdAt: new Date(Date.now()),
              designerUserId: user.id,
              profile: { first_name, last_name, name },
              draft_profile: { first_name, last_name, name }
            }
          }
        });
        const designer = res.data.createDesigner;
        await updateUserData(designer.id, 'userDesignerId');
        if (isPro) {
          await client.mutate({
            mutation: gql(createTagsDesigners),
            variables: { input: { tagsDesignersTagId: service_id, tagsDesignersDesignerId: designer.id } }
          });
        }
        updateCurrentUser();
        setDone(true);
      } catch (e) {
        console.log(e);
        throw new Error(e);
      }
    }

    async function updateExistingDesigner() {
      for (let tag of user.designer.tags.items) {
        await client.mutate({
          mutation: gql(deleteTagsDesigners),
          variables: { input: { id: tag.id } }
        });
      }

      if (isPro) {
        await client.mutate({
          mutation: gql(createTagsDesigners),
          variables: { input: { tagsDesignersTagId: service_id, tagsDesignersDesignerId: user.designer.id } }
        });
      }

      delete user.designer.profile.__typename;
      delete user.designer.draft_profile.__typename;

      await updateDesignerData(user.designer.id, null, null, {
        name: name,
        active: null,
        pro: isPro,
        profile: { ...user.designer.profile, first_name, last_name, name },
        draft_profile: { ...user.designer.draft_profile, first_name, last_name, name }
      });
      updateCurrentUser();
      setDone(true);
    }

    if (user) {
      if (!user.designer) {
        createNewDesigner();
      } else updateExistingDesigner();
    }
  }, [user]);

  const onGoToDesignerPortal = () => {
    onRedirect(isPro ? '/project-pro-portal' : '/designer-portal');
  };

  const onGoToShow = () => {
    onRedirect('/shows');
  };

  return (
    <div className={classes.root}>
      <Collapse in={!done}>
        <div className={classes.header}>
          <h1>Become a Designer</h1>
          <p>Build your online presence by becoming a part of HDTV's interactive Home Design TV network.</p>
        </div>
      </Collapse>
      <div className={classes.content}>
        <Grid container spacing={3}>
          <Collapse in={!done}>
            <Grid container item xs={12} direction={'column'} justify={'center'} alignItems={'center'} style={{ height: '100%' }}>
              <h1>Finalizing your Account. Almost there!</h1>
              <SvgIcon className={classes.icon}>
                <Spinner />
              </SvgIcon>
            </Grid>
          </Collapse>

          <Collapse in={done}>
            <Paper className={classes.complete}>
              <div className={'completeContent'}>
                <h1>Congratulations!</h1>
                <br />
                <Typography align={'center'}>
                  You are now a member of The Design Network's designer community. A confirmation email has been sent to {user ? user.email : email}
                </Typography>
                <br />
                <Typography align={'center'}>
                  Build your online presence by becoming a part of HDTV's interactive home design TV network. Your professional profile showcases your work to our audience of home
                  enthusiasts, creating elevated brand exposure.
                </Typography>
              </div>
              <Button variant={'contained'} color={'secondary'} size={'large'} className={classes.button} onClick={onGoToDesignerPortal}>
                Set Up Designer Profile
              </Button>
            </Paper>
          </Collapse>
        </Grid>
      </div>
    </div>
  );
};

export default CompleteScreen;
