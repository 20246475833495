import React, { useState, useEffect } from 'react';
import { Grid, TextField } from '@material-ui/core';

const NewProjectForm = ({ classes, onValuesChange, editElement }) => {
  const [state, setState] = useState({});

  useEffect(() => {
    onValuesChange(state);
  }, [onValuesChange, state]);

  useEffect(() => {
    if (editElement) {
      setState({ title: editElement.title, description: editElement.description });
    }
  }, [editElement]);

  return (
    <Grid container spacing={3} justify={'center'} alignItems={'center'} direction={'column'} className={classes.dialogComponent}>
      <TextField
        id="title"
        fullWidth
        label={'Title'}
        className={classes.textField}
        margin="normal"
        variant={'outlined'}
        value={state.title}
        InputLabelProps={
          state.title && state.title.length > 0
            ? {
                shrink: true
              }
            : {}
        }
        onChange={e => setState({ ...state, title: e.target.value })}
      />
      <TextField
        id="description"
        fullWidth
        label={'Description'}
        className={classes.textField}
        multiline
        rows={15}
        InputLabelProps={
          state.description && state.description.length > 0
            ? {
                shrink: true
              }
            : {}
        }
        margin="normal"
        variant={'outlined'}
        value={state.description}
        onChange={e => setState({ ...state, description: e.target.value })}
      />
    </Grid>
  );
};

export default NewProjectForm;
