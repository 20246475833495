import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { TextButton } from './Button';
import ShopkitIcon from './ShopkitIcon';
import QuantityStepper from './QuantityStepper';
import { useActions } from 'easy-peasy';
import { currencyFormat } from '../../../utils';

const Wrapper = styled.div`
  display: flex;
  padding: 0.75rem 0;
  width: 100%;

  ${({ removing }) =>
    removing &&
    css`
      opacity: 0.3;
      cursor: not-allowed;
    `};
`;

const PhotoBox = styled.div`
  border: 1px solid ${props => props.theme.border};
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 1rem;
  width: 75px;
  height: 75px;

  img {
    max-width: 100%;
    border-radius: 0.25rem;
    overflow: hidden;
  }
`;

const Info = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  font-size: ${props => props.theme.textBase};
  justify-content: space-between;
  color: ${props => props.theme.dark};
  margin: 0;
  line-height: 1.5;
`;

const ProductName = styled.p`
  color: ${props => props.theme.dark};
  font-size: ${props => props.theme.textBase};
  font-weight: 500;
  margin: 0;
`;

const Price = styled.p`
  color: ${props => props.theme.placeholder};
  font-size: ${props => props.theme.textSmall};
  margin: 0;
`;

const StyledButton = styled(TextButton)`
  font-size: ${props => props.theme.textSmall};
  text-decoration: none;
  margin-top: 0.5rem;
`;

const Extra = styled.div`
  margin-left: 1.5rem;
`;

function CartItem({ product }) {
  const {
    name,
    images,
    sku: { price }
  } = product;

  const [removing, setRemoving] = useState(false);

  const { removeItem } = useActions(({ cart }) => cart);

  const handleRemoveFromCart = async () => {
    await setRemoving(true);
    removeItem(product);
  };

  return (
    <Wrapper removing={removing}>
      <PhotoBox>{images && images.length > 0 ? <img src={images[0]} title={name} alt={name} /> : <ShopkitIcon />}</PhotoBox>

      <Info>
        <div>
          <ProductName>{name}</ProductName>
          <Price>{currencyFormat(price)}</Price>
          <StyledButton noPadding onClick={handleRemoveFromCart}>
            Remove
          </StyledButton>
        </div>
      </Info>

      <Extra>
        <QuantityStepper product={product} />
      </Extra>
    </Wrapper>
  );
}

export default CartItem;
