import { useState, useEffect } from 'react';
import { useApolloClient } from 'react-apollo-hooks';
import { listCategorys } from '../graphql/queries';
import gql from 'graphql-tag';

export default function useCategories(fetchPolicy = 'cache-first') {
  const [categories, setCategories] = useState(null);

  const client = useApolloClient();

  useEffect(() => {
    client
      .query({
        query: gql(listCategorys),
        fetchPolicy
      })
      .then(res => {
        setCategories(res.data.listCategorys.items);
      });
  }, [client, fetchPolicy]);

  return categories;
}
