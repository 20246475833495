import React, { useState, useEffect, useContext } from 'react';
import { withStyles, CircularProgress, Grid, useMediaQuery } from '@material-ui/core';
import compose from 'recompose/compose';
import { PortletContent } from 'components';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';

import styles from './styles';
import useArticleComments from '../../../../hooks/useArticleComments';
import VideoComments from '../../../../components/VideoComments';
import { getPost } from './query';
import UserContext from '../../../../contexts/UserContext';

const getPostQuery = gql`
  ${getPost}
`;

const ArticleDetail = props => {
  const { classes } = props;
  const { data, loading, error } = useQuery(getPostQuery, { variables: { id: props.match.params.id } });
  const [post, setPost] = useState(null);
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const comments = useArticleComments(props.match.params.id);
  const { openSnackBar } = useContext(UserContext);

  React.useEffect(() => {
    if (error) {
      console.log(error);
      openSnackBar('Something went wrong!');
    }
  }, [error]);

  function createMarkup() {
    return { __html: post.content };
  }

  useEffect(() => {
    if (data.getPost && data.getPost !== 'undefined') setPost(data.getPost);
  }, [data]);

  return (
    <div className={classes.root}>
      {loading || post === null ? (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      ) : (
        <div className={!mobile ? classes.content : classes.mobileContent}>
          <Grid container>
            {loading && data.length === 0 && (
              <div className={classes.progressWrapper}>
                <CircularProgress />
              </div>
            )}
            <Grid item key={post.id} lg={12} md={12} xs={12} container justify={'center'}>
              <div className={classes.container}>
                <PortletContent className={classes.video}>
                  <h1>{post.title}</h1>
                  <h2>{post.description}</h2>
                  <h3>{new Date(post.createdAt).toDateString()}</h3>
                  <div dangerouslySetInnerHTML={createMarkup()} className={classes.innerHtml} />
                </PortletContent>
                <VideoComments video={post} isArticle={true} comments={comments} />
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default compose(withStyles(styles))(ArticleDetail);
