import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import classNames from 'classnames';
import { useMediaQuery, withStyles } from '@material-ui/core';
import { Typography, Grid, Link } from '@material-ui/core';

// Component styles
import styles from './styles';

const Footer = props => {
  const { classes, className } = props;

  const rootClassName = classNames(classes.root, className);
  function autoScroll() {
    window.scrollTo(0, 0);
  }
  return (
    <div className={rootClassName}>
      <div className={classes.ngFooter}>
        <Grid container>
          <Grid item sm={12} lg={4} xs={12} style={{ textAlign: 'center' }}>
            <img className={'logo'} alt="home design tv" src="/images/logos/logo.png" />
            <div className={classes.socialIcons}>
              <Link target={'_blank'} href="https://www.facebook.com/HomeDesignTelevsion/">
                <img alt="facebook" src="/assets/facebook_social_icon.svg" />
              </Link>
              <Link target={'_blank'} href="https://twitter.com/TheHDTVnetwork">
                <img alt="twitter" src="/assets/twitter_social_icon.svg" />
              </Link>
              <Link target={'_blank'} href="https://www.instagram.com/homedesigntelevision/">
                <img alt="Instagram" src="/assets/instagram_social_icon.svg" />
              </Link>
              <Link target={'_blank'} href="https://www.pinterest.com/HomeDesignTV/">
                <img alt="pinterest" src="/assets/pinterest_social_icon.svg" />
              </Link>
            </div>
          </Grid>
          <Grid item sm={6} lg={2} xs={12}>
            <h4>About</h4>
            <RouterLink to="/about" onClick={autoScroll}>
              What is Home Design TV?
            </RouterLink>
            <br />
            <RouterLink to={'/sponsors'} onClick={autoScroll}>
              Sponsors
            </RouterLink>
            <br />
            <RouterLink to={'/contact-us'} onClick={autoScroll}>
              Contact Us
            </RouterLink>
          </Grid>
          <Grid item sm={6} lg={2} xs={12}>
            <h4>Content</h4>
            <RouterLink to="/shows" onClick={autoScroll}>
              Videos
            </RouterLink>
            <br />
            <RouterLink to="/articles" onClick={autoScroll}>
              Articles
            </RouterLink>
          </Grid>
          <Grid item sm={6} lg={2} xs={12}>
            <h4>Designers</h4>
            <RouterLink to={'/become-a-designer'} onClick={autoScroll}>
              Become a Designer
            </RouterLink>
          </Grid>
          <Grid item sm={6} lg={2} xs={12}>
            <h4>Policies</h4>
            <RouterLink to={'/terms'} onClick={autoScroll}>
              Terms of Use
            </RouterLink>
            <br />
            <RouterLink to={'/privacy'} onClick={autoScroll}>
              Privacy Policy
            </RouterLink>
          </Grid>
        </Grid>
        <div className={classes.ngFooterBottomContainer}>
          <div className={classes.ngMiddleSpacer} />
          <div>
            <Typography className={classes.company} variant="body1">
              &copy; Home Design TV. {new Date().getYear() + 1900} <br />
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(Footer));
