export default theme => ({
  ':root': {
    light: 80,
    threshold: 60
  },
  videoPlayer: {
    width: '100%',
    height: '100%',
    overflow: 'inherit',
    position: 'relative',
    maxWidth: '100vw',
    zIndex: '0'
  },
  placeHolder: {
    height: '1000px'
  },
  overlay: {
    backgroundImage: 'linear-gradient(to top, rgba(200,200,200,.9), rgba(200,200,200,.1),transparent, transparent)',
    zIndex: 1,
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    pointerEvents: 'none'
  },
  videoInfo: {
    marginLeft: '8vw',
    top: '20%',
    position: 'absolute',
    maxWidth: '50vw',
    opacity: 1,
    transition: '3s linear',
    '&:hover': {
      opacity: 1,
      transition: 'opacity 1s'
    }
  },
  mobileVideoInfo: {
    width: '100vw',
    maxWidth: '100%',
    opacity: 1,
    '& p': {
      paddingLeft: '10px'
    }
  },
  hdtvLogo: {
    color: 'white',
    display: 'flex',
    fontSize: '22px',
    fontWeight: 900,
    verticalAlign: 'middle',
    alignItems: 'center',
    letterSpacing: '3px',
    minHeight: '40px',
    '& span': {
      paddingLeft: '5px',
      lineHeight: '26px'
    },
    '@media (max-width: 960px)': {
      marginLeft: 10,
      '& span': {
        color: 'black',
        transform: 'scale(.8)'
      }
    },
    animation: '2s fadeout'
  },
  title: {
    fontSize: '2rem',
    color: 'white',
    fontWeight: 900,
    textShadow: '2px 8px 6px rgba(0,0,0,0.3), 0px -5px 35px rgba(255,255,255,0.3)',
    '@media (max-width: 960px)': {
      color: '#000',
      opacity: 0.9,
      textShadow: 'none'
    }
  },
  description: {
    fontSize: '1.5rem',
    color: 'white',
    fontWeight: 600,
    textShadow: '1px 4px 3px rgba(0,0,0,0.3), 0px -1px 7px rgba(255,255,255,0.3)',
    '@media (max-width: 960px)': {
      color: '#002b42',
      opacity: 0.7,
      textShadow: 'none'
    }
  },
  actionButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& button': {
      color: 'white',
      borderColor: 'white',
      borderSize: '4px',
      fontWeight: 600,
      '@media (max-width: 960px)': {
        color: '#000',
        borderColor: '#000'
      }
    }
  },
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  }
});
