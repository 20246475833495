import { Button, FormControl, Grid, TextField, InputLabel, Typography } from '@material-ui/core';
import React, { useState, useEffect, useRef } from 'react';

const SocialMediaLinks = ({ onSubmit, user, classes }) => {
  const [state, setState] = useState(null);
  const facebookRef = useRef();
  const twitterRef = useRef();
  const youtubeRef = useRef();
  const pinterestRef = useRef();
  const instagramRef = useRef();
  const linkedinRef = useRef();
  const blogRef = useRef();
  const googlePlusRef = useRef();
  const houzzRef = useRef();

  useEffect(() => {
    if (user.id) {
      const _state = user.designer.draft_profile ? { ...user.designer.draft_profile } : {};

      for (let key of Object.keys(_state)) {
        if (!_state[key]) {
          _state[key] = '';
        }
      }
      setState(_state);
    }
  }, [user]);

  const onSaveData = () => {
    const data = {
      facebook_handle: facebookRef.current.value,
      twitter_handle: twitterRef.current.value,
      youtube_handle: youtubeRef.current.value,
      pinterest_handle: pinterestRef.current.value,
      instagram_handle: instagramRef.current.value,
      linkedin_url: linkedinRef.current.value,
      blog_url: blogRef.current.value,
      google_plus_url: googlePlusRef.current.value,
      houzz_url: houzzRef.current.value
    };

    onSubmit(data);
  };

  return state ? (
    <Grid container spacing={3}>
      <Grid item xs={12} className={classes.centered}>
        <Typography variant={'h2'} color={'textPrimary'}>
          Social Media Links
        </Typography>
        <Typography color={'textSecondary'}>Fill out any accounts that you'd like for people to see. It could result in some flowers... :)</Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.centered}>
        <FormControl fullWidth>
          <InputLabel shrink={true} htmlFor="my-input">
            Facebook Handle
          </InputLabel>
          <TextField id="facebook_handle" margin="normal" variant="outlined" defaultValue={state.facebook_handle} inputRef={facebookRef} />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.centered}>
        <FormControl fullWidth>
          <InputLabel shrink={true} htmlFor="my-input">
            Twitter Handle
          </InputLabel>
          <TextField id="twitter_handle" margin="normal" variant="outlined" defaultValue={state.twitter_handle} inputRef={twitterRef} />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.centered}>
        <FormControl fullWidth>
          <InputLabel shrink={true} htmlFor="my-input">
            Youtube Handle
          </InputLabel>
          <TextField id="youtube_handle" margin="normal" variant="outlined" defaultValue={state.youtube_handle} inputRef={youtubeRef} />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.centered}>
        <FormControl fullWidth>
          <InputLabel shrink={true} htmlFor="my-input">
            Pinterest Handle
          </InputLabel>
          <TextField id="pinterest_handle" margin="normal" variant="outlined" defaultValue={state.pinterest_handle} inputRef={pinterestRef} />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.centered}>
        <FormControl fullWidth>
          <InputLabel shrink={true} htmlFor="my-input">
            Instagram Handle
          </InputLabel>
          <TextField id="instagram_handle" margin="normal" variant="outlined" defaultValue={state.instagram_handle} inputRef={instagramRef} />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.centered}>
        <FormControl fullWidth>
          <InputLabel shrink={true} htmlFor="my-input">
            Linkedin URL
          </InputLabel>
          <TextField id="linkedin_url" margin="normal" variant="outlined" defaultValue={state.linkedin_url} inputRef={linkedinRef} />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.centered}>
        <FormControl fullWidth>
          <InputLabel shrink={true} htmlFor="my-input">
            Blog URL
          </InputLabel>
          <TextField id="blog_url" margin="normal" variant="outlined" defaultValue={state.blog_url} inputRef={blogRef} />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.centered}>
        <FormControl fullWidth>
          <InputLabel shrink={true} htmlFor="my-input">
            Google+ URL
          </InputLabel>
          <TextField id="google_url" margin="normal" variant="outlined" defaultValue={state.google_plus_url} inputRef={googlePlusRef} />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.centered}>
        <FormControl fullWidth>
          <InputLabel shrink={true} htmlFor="my-input">
            Houzz URL
          </InputLabel>
          <TextField id="houzz_url" margin="normal" variant="outlined" defaultValue={state.houzz_url} inputRef={houzzRef} />
        </FormControl>
      </Grid>
      <Grid item xs={12} className={classes.centered}>
        <Button variant={'contained'} color={'primary'} onClick={onSaveData}>
          Save Changes
        </Button>
      </Grid>
    </Grid>
  ) : (
    <Typography>Loading...</Typography>
  );
};

export default SocialMediaLinks;
