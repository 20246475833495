import React, { useContext, useEffect, useState } from 'react';
import { withStyles, Grid, Typography, Select, Dialog, DialogContent, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import styles from './styles';
import useStripeProductSkus from '../../hooks/useStripeProductSkus';
import { Spinner } from '../Cart/components/Button';
import BuyButton from './buy-button';
import { useActions } from 'easy-peasy';
import { currencyFormat } from '../../utils';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import UserContext from '../../contexts/UserContext';

const ProductModal = props => {
  const { classes, product } = props;
  const [fullWidth] = useState(true);
  const [maxWidth] = useState('md');
  const skus = useStripeProductSkus(product.id);
  const [selectedSku, setSelectedSku] = useState({ id: '', sku: null });
  const { user } = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    console.log(selectedSku);
  }, [selectedSku]);

  const {
    modal: { goToCart, goToLogin }
  } = useActions(actions => actions);

  React.useEffect(() => {
    if (skus && skus.length > 0) setSelectedSku({ id: skus[0].id, sku: skus[0] });
  }, [skus]);

  function handleClose() {
    props.toggleModal();
  }

  const onFinishAddProduct = () => {
    props.toggleModal();
    goToCart();
  };

  return (
    <>
      <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={props.open} onClose={handleClose} aria-labelledby="max-width-dialog-title">
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Grid container spacing={0}>
            <Grid item lg={8} md={8} xl={8} xs={12}>
              <div className={classes.imgContainer}>
                <img src={product.images[0]} alt={product.name} />
              </div>
            </Grid>
            <Grid item lg={4} md={4} xl={4} xs={12}>
              <div className={classes.descriptionContainer}>
                <div className={classes.productDescription}>
                  <h2 className={classes.productName}>{product.name}</h2>
                  <div className={classes.manufacturer}>
                    Presented By <span className={classes.workMark}>HDTV</span>
                  </div>
                  <div className={classes.price}>
                    {skus ? (
                      <>
                        {skus.length > 1 && (
                          <Select
                            native
                            value={selectedSku.id}
                            onChange={e => {
                              const sku = e.target.value.length > 0 ? skus.filter(sku => sku.id === e.target.value)[0] : null;
                              setSelectedSku({ id: e.target.value, sku: sku });
                            }}
                          >
                            <option value="">Select variants</option>
                            {skus.map(sku => (
                              <option key={sku.id} value={sku.id}>
                                {sku.text}
                              </option>
                            ))}
                          </Select>
                        )}
                        {selectedSku.sku && <p>{currencyFormat(selectedSku.sku.price)}</p>}
                      </>
                    ) : (
                      <Spinner />
                    )}
                  </div>
                  <div className={classes.description}>
                    <div>{product.description}</div>
                  </div>
                  <div className={classes.buyButton}>
                    {selectedSku.sku &&
                    (selectedSku.sku.inventory.type === 'infinite' ||
                      selectedSku.sku.inventory.quantity > 0 ||
                      (selectedSku.sku.inventory.type === 'bucket' && selectedSku.sku.inventory.value === 'in_stock')) ? (
                      <BuyButton product={product} sku={selectedSku.sku} onFinish={onFinishAddProduct} />
                    ) : (
                      <Typography>Out of stock.</Typography>
                    )}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default withStyles(styles)(ProductModal);
