import React from 'react';
import { ThemeProvider } from 'styled-components';

import Modal from './components/Modal';
import BuyButton from './components/BuyButton';
import CartButton from './components/CartButton';
import LoginButton from './components/LoginButton';

import theme from './theme';

const Cart = props => {
  const stripePublishableKey = 'pk_test_TDtuYShbPeom0ZP4VWbnUdBs00iTipqvYH';
  return (
    <>
      {props.type === 'buy' && (
        <ThemeProvider theme={theme}>
          <BuyButton {...props} />
        </ThemeProvider>
      )}
      {props.type === 'cart' && (
        <ThemeProvider theme={theme}>
          <CartButton user={props.user} {...props} />
        </ThemeProvider>
      )}
      {props.type === 'login' && (
        <ThemeProvider theme={theme}>
          <LoginButton {...props} />
        </ThemeProvider>
      )}
      <ThemeProvider theme={theme}>
        <Modal stripeKey={stripePublishableKey} />
      </ThemeProvider>
    </>
  );
};

export default Cart;
