export default theme => ({
  root: {
    padding: 0,
    margin: 0
  },
  container: {
    position: 'relative'
  },
  video: {
    position: 'relative',
    height: '100%',
    outline: 'none',
    paddingTop: '56.25%'
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0
  },
  overlay: {
    position: 'absolute',
    backgroundImage: 'linear-gradient(to right,rgba(0,0,0,.75) 0,rgba(0,0,0,.5) 25%,rgba(0,0,0,.25) 50%,rgba(0,0,0,0) 75%,rgba(0,0,0,0) 100%)',
    display: 'flex',
    flexDirection: 'column',
    top: 0,
    left: 0,
    padding: 10,
    opacity: 0.1,
    cursor: 'pointer',
    transition: '1s linear',
    '&:hover': {
      opacity: 1
    }
  },
  title: {
    padding: 0,
    margin: 0,
    fontWeight: 600,
    color: '#ffffff',
    fontSize: '1.2rem'
  },
  description: {
    marginTop: '-15px',
    fontSize: '1rem',
    color: '#ffffff'
  },
  details: {
    padding: theme.spacing(1)
  },
  detailTitle: {
    fontSize: '1rem',
    fontWeight: 600,
    display: 'block',
    width: '100%'
  },
  detailDescription: {
    fontSize: '.8rem',
    display: 'block',
    maxHeight: '54px',
    transition: '1s linear',
    '&:hover': {
      translateZ: 0,
      maxHeight: '100vh',
      height: 'auto'
    }
  },
  controls: {
    position: 'absolute',
    zIndex: 2000,
    top: '50%',
    bottom: '50%',
    right: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexFlow: 'row-reverse',
    width: 95,
    height: 35
  },
  controlButton: {
    height: 25,
    width: 25,
    fill: '#fff',
    marginLeft: 10,
    cursor: 'pointer',
    border: 'white solid 2px',
    borderRadius: '50%',
    padding: 3,
    opacity: 0.9,
    '&:hover': {
      height: 30,
      width: 30,
      opacity: 1
    }
  }
});
