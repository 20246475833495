import React, { useContext, useState } from 'react';
import compose from 'recompose/compose';
import { withStyles, IconButton, Tooltip, Snackbar } from '@material-ui/core';
import { SubscriptionsOutlined } from '@material-ui/icons';
import { useMutation } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import uuid from 'uuid';
//styles
import styles from './styles';
import { Spinner } from '../Cart/components/Button';
import { useStore } from 'easy-peasy';
import UserContext from '../../contexts/UserContext';

const CreateSubQuery = gql`
  mutation CreateSub($input: CreateSubInput!) {
    createSub(input: $input) {
      id
    }
  }
`;
const DeleteSubQuery = gql`
  mutation DeleteSub($input: DeleteSubInput!) {
    deleteSub(input: $input) {
      id
    }
  }
`;

const SubscribeButton = props => {
  const { classes, designer } = props;
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });
  const [disabled, setDisabled] = useState(false);
  const user = useStore(state => state.user.user);
  const { updateCurrentUser } = useContext(UserContext);
  const CreateSub = useMutation(CreateSubQuery);
  const DeleteSub = useMutation(DeleteSubQuery);

  const addToFavoriteList = async () => {
    try {
      setDisabled(true);
      const subs = user.subs.items.filter(item => item.designer.id === designer.id);
      if (subs.length === 0) {
        const variables = {
          input: {
            id: uuid(),
            subUserId: user.id,
            subDesignerId: designer.id,
            createdAt: new Date()
          }
        };
        await CreateSub({ variables: variables });
        setSnackbar({ open: true, message: 'Successfully subscribed!' });
      } else {
        const variables = {
          input: {
            subDesignerId: designer.id,
            createdAt: subs[0].createdAt
          }
        };

        await DeleteSub({ variables });
        setSnackbar({ open: true, message: 'Successfully unsubscribed' });
      }
    } catch (e) {
      setSnackbar({ open: true, message: 'Something went wrong!' });
    }
    updateCurrentUser();
    setDisabled(false);
  };

  return (
    <>
      {user && user.id && (
        <Tooltip title="Subscribe" placement="top">
          <IconButton disabled={disabled} aria-label={`star ${designer.name}`} onClick={() => addToFavoriteList()}>
            <SubscriptionsOutlined className={classes.title} />
          </IconButton>
        </Tooltip>
      )}
      {!user && <Spinner />}
      <Snackbar open={snackbar.open} message={snackbar.message} autoHideDuration={3000} onClose={() => setSnackbar({ open: false, message: '' })} />
    </>
  );
};

export default compose(withStyles(styles))(SubscribeButton);
