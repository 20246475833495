import React, { useEffect } from 'react';
import { Paper } from '@material-ui/core';

import { withStyles } from '@material-ui/core';

import styles from './styles';

const Terms = ({ classes }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className={classes.headerImgContainer}>
        <h1 className={classes.h1}>Terms of Use</h1>
        <img alt="HomeDesignTV" className={classes.headerImg} src="https://res.cloudinary.com/tylerholden/image/upload/v1560549668/HDTV/about-page-header.jpg" />
      </div>
      <div className={classes.section}>
        <Paper className={classes.card} elevation={20}>
          <div>
            <h1>Terms of Service</h1>
            <ul>
              <h4>HOME DESIGN TELEVISION, LLC: TERMS OF SERVICE HOME DESIGN TELEVISION ["HDTV"] IS PROVIDING ITS SERVICES ON THE FOLLOWING TERMS.</h4>
              <li type={'I'}>
                By using any existing or future HDTV media, platforms, including but not limited to apps, websites, or social media pages ["Platforms"] and/or HDTV's online
                services, data feeds, or other goods and services of any kind ["Services"] you understand and affirm your agreement to be bound by these Terms of Service, and to
                the HDTV Guidelines ["Terms"]. The Terms apply to all users. Unless you agree to all of the Terms, you should not use the Platforms or Services.
              </li>
              <br />
              <li type={'I'}>
                Access to certain functionalities of Platforms and Services may require you to register with and/or provide certain information to HDTV. We reserve the right to
                decline to provide access to Platforms or Services to any person for any or no reason. When you register with or provide information to HDTV, you agree to provide
                accurate, current and complete information about yourself as prompted, and maintain and update your information to keep it accurate, current, and complete.
              </li>
              <br />
              <li type={'I'}>
                The Services as well as any kind of material and information provided on, by, or through HDTV's Platforms, including but not limited to data, text, graphics,
                designs, logos, images, audio/visual materials, links, User Content, and references ["HDTV Content"] are provided for individual use only and not for resale.
              </li>
              <br />
              <li type={'I'}>
                Content and Conduct As part of your use of the Platforms and Services, you may post, share, send, submit or otherwise provide material and information of many kinds
                including but not limited to photos, images, videos, comments, messages, names, voices, ideas, sounds, feedback, ratings, and reviews ["User Content"]. By choosing
                to participate in these or other ways you agree to, acknowledge, represent and warrant the following:
                <ul>
                  <li type="A">You are solely responsible for the User Content and for the consequences of having it posted;</li>
                  <li type="A">User Content may be viewed by the general public and will not be treated as private, proprietary or confidential;</li>
                  <li type="A">
                    You grant HDTV a worldwide, non-exclusive, royalty-free, assignable, perpetual, irrevocable, and transferable license to use, reproduce, distribute, derive
                    works from, post and display the User Content in connection with the Services and HDTV's business, including without limitation for promoting and redistributing
                    part or all of the User Content (and derivative works thereof) in any media format and through any media channel;
                  </li>
                  <li type="A">
                    You grant each user of the Platform and Services a non-exclusive license to access your User Content, and to use, reproduce, distribute, display and perform
                    such Content;
                  </li>
                  <li type="A">The above licenses granted by you in user comments you submit are perpetual and irrevocable;</li>
                  <li type="A">You waive and release any moral rights that you may have in any User Content posted or provided by you;</li>
                  <li type="A">
                    The User Content does not infringe on the copyrights of any person or entity and it is not subject to third-party proprietary rights of any kind;
                  </li>
                  <li type="A">
                    You are legally entitled to post User Content and grant HDTV all of the license rights granted herein, and you have the right to use and permit us to use and
                    license such Content in the manner stated in this Agreement. (This means, for example, that if you provide the name, voice or likeness of any person, you have
                    obtained a release from that person permitting its use as stated in this Agreement. It also means that if you post any kind of image of a work of art of any
                    kind including but not limited to sculpture, photography, or architecture that is copyrighted, you have obtained a release from the owner of the copyright to
                    reproduce the work as stated in this Agreement or that you are the owner of the copyright.)
                  </li>
                </ul>
              </li>
              <br />
              <li type={'I'}>
                Prohibitions. In utilizing the Platforms and Services you agree that you will not:
                <ul>
                  <li type="A">Harm HDTV's public image, goodwill or reputation or that of its sponsors;</li>
                  <li type="A">Infringe on any copyright, patent, trademark, or other proprietary rights or rights of publicity or privacy;</li>
                  <li type="A">Express or imply that HDTV endorses any of your statements or actions or those of others;</li>
                  <li type="A">Violate any law or give rise to criminal or civil liability;</li>
                  <li type="A">Defame, harass, threaten, discriminate, invade privacy;</li>
                  <li type="A">
                    Distribute in any medium any part of the Service, User Content or HDTV Content without HDTV's written authorization, unless HDTV intentionally makes available
                    the means for such distribution;
                  </li>
                  <li type="A">Modify any part of the Platforms or Services;</li>
                  <li type="A">
                    Copy, reproduce, distribute, transmit, sell, license, display, republish, post, digitize, translate, modify, or otherwise exploit any content for any other
                    purposes without the prior written consent of HDTV or the respective licensors of the content;
                  </li>
                  <li type="A">Disable or interfere with security-related features of the Services or features that prevent or restrict use or copying of any content;</li>
                  <li type="A">Post User Content that is in any way tortious, pornographic or obscene;</li>
                  <li type="A">Post trade secrets or other non-public information about any person or business;</li>
                  <li type="A">Modify, adapt, sell, reverse engineer, decompile, or disassemble any portion of the Platforms or Services;</li>
                  <li type="A">
                    Post any reviews about a HDTV sponsor if you are an employee, contractor, officer or director of the sponsor; an employee, contractor, officer or director of a
                    competitor of the sponsor; or related to the sponsor in any way, including blood, adoption or by marriage;
                  </li>
                  <li type="A">
                    Post any rating or review or User Content that is not based upon your first-hand experience with the vendor and/or service provider that is the subject of the
                    review or rating;
                  </li>
                  <li type="A">Make any statement that is inaccurate, misleading or untruthful;</li>
                  <li type="A">Remove any copyright, trademark, or other proprietary rights;</li>
                  <li type="A">
                    Sublicense, sell, lease, assign, or convey any rights under this Agreement to any third party, or otherwise commercially exploit or profit from the User Content
                    or HDTV Content;
                  </li>
                  <li type="A">Interfere with or disrupt any Platforms or Services;</li>
                  <li type="A">Violate the CAN-SPAM Act of 2003;</li>
                  <li type="A">
                    Use any robot, spider, site search/retrieval application, or other manual or automatic device or process to retrieve, index, or data mine the Platforms or
                    Services or circumvent its security measures;
                  </li>
                  <li type="A">Collect information about any HDTV Users without their express consent.</li>
                </ul>
              </li>
              <br />
              <li type={'I'}>
                Account Termination Policy HDTV reserves the right to decide whether User Content violates these Terms. Based upon that, or upon any other factor HDTV deems
                appropriate, HDTV may remove User Content and/or terminate a user's access or account at any time, without notice.
              </li>
              <br />
              <li type={'I'}>
                Digital Millennium Copyright Act
                <ul>
                  <li type={'A'}>
                    A copyright owner or owner's agent who believes that any content infringes upon their copyrights, may submit a notification under the Digital Millennium
                    Copyright Act ("DMCA") by giving our Copyright Agent the following information in writing. See 17 USC — 512(c)(3).
                    <br />
                    1. A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.
                    <br />
                    2. Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single
                    notification, a representative list of such works at that site.
                    <br />
                    3. Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is
                    to be disabled, and information reasonably sufficient to permit the service provider to locate the material.
                    <br />
                    4. Information reasonably sufficient to permit the service provider to contact the complaining party, such as an address, telephone number, and, if available,
                    an electronic mail address at which the complaining party may be contacted.
                    <br />
                    5. A statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner,
                    its agent, or the law.
                    <br />
                    6. A statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of
                    the owner of an exclusive right that is allegedly infringed.
                  </li>
                  <li type={'A'}>
                    HDTV's designated Copyright Agent to receive notifications of claimed infringement is L. Frye; dmca@thedesignnetwork.com; Post Office Box 1550; Jamestown, NC
                    27282; 336.822.3300, extension 2147; fax: 336.822.3036. You acknowledge that if you fail to comply with all of the requirements of this Section, your DMCA
                    notice may not be valid.
                  </li>
                  <li type={'A'}>Upon being properly notified that any Content infringes on another valid intellectual property rights, HDTV will remove it.</li>
                </ul>
              </li>
              <br />
              <li type={'I'}>
                Privacy Policy
                <ul>
                  <li type={'A'}>
                    HDTV wants you to feel comfortable searching and exchanging information on our site. We realize the importance of feeling safe online and are committed to
                    ensuring that your privacy is respected and protected when you interact with our Platform and Services website. Privacy refers to the way HDTV collects and uses
                    personal information.
                  </li>
                  <li type={'A'}>
                    We collect and store the following information:
                    <br />
                    1. Email address, IP address, and physical contact information;
                    <br />
                    2. Comments, reviews, images, other uploaded content, questions and answers, discussion area posts and other messages, including correspondence between HDTV and
                    you;
                    <br />
                    3. Measurable actions of profiles such as comments, ratings; and
                    <br />
                    4. Other information automatically collected whenever you interact with HDTV, such as computer sign-on data, statistics on page views and traffic to and from
                    the HDTV site.
                  </li>
                  <li type={'A'}>
                    We may use or disclose your information for the following purposes:
                    <br />
                    1. Provide the Services;
                    <br />
                    2. Verify your identity when you sign in;
                    <br />
                    3. Encourage appropriate online experiences;
                    <br />
                    4. Customize your experience, analyze site usage, improve and measure interest in our services, and inform users about services and updates;
                    <br />
                    5. Communicate administrative announcements when we need to provide users with information that may affect their use of HDTV;
                    <br />
                    6. Communicate marketing and promotional offers to our users; and
                    <br />
                    7. Deliver information to you that is targeted to your interests. Upon receipt of the first communication of this type, you will have the opportunity to opt-out
                    of future communications by following unsubscribe instructions described within the communication. As part of your registration on HDTV, you agree that you may
                    receive certain administrative communications from HDTV, and you will not be able to opt out of receiving such administrative communications.
                    <br />
                    8. Help with our business operations and assist in the delivery of Services
                    <br />
                    9. Comply with relevant laws or in cooperation with law enforcement.
                    <br />
                    10. Allow the affiliates of HDTV or third parties in order to do the following:
                    <br />
                    a. Identify your reviews as your own;
                    <br />
                    b. Enforce our Terms and other HDTV agreements and policies, or to protect anyone's rights, property, or safety;
                    <br />
                    c. Respond to claims that photos or other content that you upload violates other's rights;
                  </li>
                  <li type={'A'}>
                    Cookies
                    <br />
                    Our site uses a feature of your browser to set a "cookie" on your computer. Cookies are small packets of information that a site's computer stores on your
                    computer. HDTV can then read the cookies whenever you visit the HDTV website. We use cookies to save your password so you don't have to re-enter it each time
                    you visit our site; to deliver content specific to your interests; and to track the pages you've visited. These cookies allow us to use the information we
                    collect to assure your visit to the site is as relevant and as valuable to you as possible. Once you have provided information, we keep it in your personal
                    account file so you don't have to re-enter it next time.
                  </li>
                  <li type={'A'}>
                    Opt-In/Opt-Out Policy
                    <br />
                    Upon registration, you may be asked to indicate whether you are interested in receiving occasional information from us about special offers or news that we feel
                    may be of interest to you. We will do so only with your permission. If you do choose to receive these communications by means of an opt-in, we will occasionally
                    send you an email message that matches your request. You may, of course, always choose not to receive any of this information from us. At any time, you can
                    follow a link provided in every newsletter or email message to unsubscribe from that mailing list or email service. Further, you may contact us through
                    info@thedesignnetwork.com.
                  </li>
                  <li type={'A'}>If you have any questions about this policy or believe there is a problem during your visit, you can contact us at info@thedesignnetwork.com.</li>
                </ul>
              </li>
              <br />
              <li type={'I'}>
                DISCLAIMER, WAIVERS, LEGAL MATTERS, ETC.
                <ul>
                  <li type={'A'}>
                    HDTV DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY CONTENT, PRODUCT, OPINION, RECOMMENDATION OR SERVICE ADVERTISED OR OFFERED BY A
                    THIRD PARTY THROUGH THE SERVICES OR ANY HYPERLINKED SERVICES OR FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND HDTV WILL NOT BE A PARTY TO OR IN ANY WAY BE
                    RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
                  </li>
                  <li type={'A'}>
                    YOU AGREE THAT YOUR USE OF THE SERVICES SHALL BE AT YOUR SOLE RISK. WE PROVIDE THE SERVICES WITHOUT EXPRESSED OR IMPLIED WARRANTIES OF ANY KIND INCLUDING, BUT
                    NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE DO NOT GUARANTEE THAT THE SERVICES WILL ALWAYS
                    BE SAFE, SECURE OR WITHOUT ERROR OR THAT IT WILL FUNCTION WITHOUT FLAWS, DISRUPTIONS, OR DELAYS. WE ARE NOT RESPONSIBLE FOR THE ACTIONS, CONTENT, INFORMATION,
                    OR DATA OF THIRD PARTIES. YOU RELEASE HDTV, OUR DIRECTORS, OFFICERS, EMPLOYEES, AND AGENTS FROM ANY CLAIMS AND DAMAGES, KNOWN AND UNKNOWN, ARISING OUT OF OR IN
                    ANY WAY CONNECTED WITH ANY CLAIM YOU HAVE AGAINST ANY SUCH THIRD PARTIES. OUR AGGREGATE LIABILITY FOR ANY CLAIM WILL NOT EXCEED THE GREATER OF ONE HUNDRED
                    DOLLARS ($100). APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY OR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR
                    EXCLUSION MAY NOT APPLY TO YOU. IN SUCH CASES, HDTV'S LIABILITY WILL BE LIMITED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
                  </li>
                  <li type={'A'}>
                    HDTV, ITS OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS, SHALL IN NO EVENT BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR
                    CONSEQUENTIAL DAMAGES WHATSOEVER RESULTING FROM ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT, (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE
                    WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF OUR SERVICES, (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL
                    INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM OUR SERVICES, (IV) ANY BUGS, VIRUSES,
                    TROJAN HORSES, OR THE LIKE, WHICH MAY BE TRANSMITTED TO OR THROUGH OUR SERVICES BY ANY THIRD PARTY, AND/OR (V) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY
                    LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF YOUR USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES, WHETHER
                    BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT THE COMPANY IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING
                    LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.
                  </li>
                  <li type={'A'}>
                    YOU SPECIFICALLY ACKNOWLEDGE THAT HDTV SHALL NOT BE LIABLE FOR CONTENT OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY AND THAT THE RISK OF
                    HARM OR DAMAGE FROM THE FOREGOING RESTS ENTIRELY WITH YOU.
                  </li>
                  <li type={'A'}>
                    Governing Law and Jurisdiction
                    <br />
                    You agree that the Platforms and Services are to be deemed solely based in the State of North Carolina; and the Platforms and Services shall be deemed passive
                    and do not give rise to personal jurisdiction over HDTV, either specific or general, in jurisdictions other than the State of North Carolina. These terms shall
                    be governed by the internal substantive laws of the State of North Carolina, without respect to its conflict of laws principles. Any claim or dispute between
                    you and HDTV that arises in whole or in part from the Platforms or Services shall be decided exclusively by a court of competent jurisdiction located in
                    Guilford County, North Carolina.
                  </li>
                  <li type={'A'}>
                    FOR ANY DISPUTE YOU HAVE WITH HDTV, YOU AGREE TO FIRST CONTACT US AND ATTEMPT TO RESOLVE THE DISPUTE WITH US INFORMALLY. IF HDTV HAS NOT BEEN ABLE TO RESOLVE
                    THE DISPUTE WITH YOU INFORMALLY, WE EACH AGREE TO RESOLVE ANY CLAIM, DISPUTE, OR CONTROVERSY (EXCLUDING CLAIMS FOR INJUNCTIVE OR OTHER EQUITABLE RELIEF) ARISING
                    OUT OF OR IN CONNECTION WITH OR RELATING TO THESE TERMS BY BINDING ARBITRATION BY THE AMERICAN ARBITRATION ASSOCIATION ("AAA") UNDER THE COMMERCIAL ARBITRATION
                    RULES AND SUPPLEMENTARY PROCEDURES FOR CONSUMER RELATED DISPUTES THEN IN EFFECT FOR THE AAA, EXCEPT AS PROVIDED HEREIN. THE ARBITRATION WILL BE CONDUCTED IN
                    GUILFORD COUNTY, NORTH CAROLINA. EACH PARTY WILL BE RESPONSIBLE FOR PAYING ANY AAA FILING, ADMINISTRATIVE AND ARBITRATOR FEES IN ACCORDANCE WITH AAA RULES. ANY
                    JUDGMENT ON THE AWARD RENDERED BY THE ARBITRATOR MAY BE ENTERED IN ANY COURT OF COMPETENT JURISDICTION. NOTHING IN THIS SECTION SHALL PREVENT EITHER PARTY FROM
                    SEEKING INJUNCTIVE OR OTHER EQUITABLE RELIEF FROM THE COURTS FOR MATTERS RELATED TO DATA SECURITY, INTELLECTUAL PROPERTY OR UNAUTHORIZED ACCESS TO THE SERVICES.
                    ALL CLAIMS MUST BE BROUGHT IN THE PARTIES' INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING, AND,
                    UNLESS WE AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON'S CLAIMS. YOU AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND HDTV ARE EACH
                    WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.
                  </li>
                  <li type={'A'}>
                    Indemnity
                    <br />
                    To the greatest extent permitted by law, you agree to defend, indemnify and hold harmless HDTV officers, directors, employees, agents, sponsors, and any
                    affiliated companies from and against any and all claims, damages, obligations, losses, liabilities, costs and/or expenses (including attorney's fees) arising
                    from your use of and access to the Platforms and Services; your violation of any term of the Terms; your violation of any third party right, including without
                    limitation any copyright, property, or privacy right; or any claim that user content caused damage to a third party. This defense and indemnification obligation
                    will survive the term and your use of the Platform or Services.
                  </li>
                  <li type={'A'}>
                    Legal Capacity to Accept Terms
                    <br />
                    You affirm that you at least 18 years of age and competent to enter into the Terms, representations, and promises set forth in the Terms.
                  </li>
                  <li type={'A'}>
                    Assignment
                    <br />
                    The Terms, and any rights and licenses granted hereunder, may not be transferred or assigned by you, but may be assigned by HDTV without restriction.
                  </li>
                  <li type={'A'}>
                    General
                    <br />
                    The Terms and any legal notices published by HDTV on its Platforms or Services shall constitute the entire agreement between you and HDTV. If any provision of
                    the Terms is deemed invalid by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of
                    the Terms, which shall remain in full force and effect. No waiver of any of the Terms shall be deemed a continuing waiver of such term or any other term. HDTV
                    's failure to assert any right under the Terms shall not constitute a waiver of such right. HDTV reserves the right to amend the Terms at any time and without
                    notice. You are responsible for reviewing the Terms for any changes. Your use of the services following any amendment of the Terms will signify your acceptance
                    of any revisions. You and HDTV agree that any cause of action arising out of or related to the Platform or Services must commence within one (1) year after the
                    cause of action accrues or such cause of action shall otherwise be permanently barred.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default withStyles(styles)(Terms);
