export default theme => ({
  root: {},
  form: {},
  textField: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  portletFooter: {},
  formControl: {
    width: '100px'
  },
  img: {
    width: '100%'
  }
});
