import { useEffect, useState } from 'react';
import { getSkuByProductId } from '../services/stripe';

export default function useStripeProductSkus(product_id) {
  const [skus, setSkus] = useState(null);

  const attributesToText = attrs => {
    let res = '';
    for (let key of Object.keys(attrs)) {
      res += `${key.toUpperCase()} ${attrs[key].toLowerCase()} -`;
    }

    return res.substr(0, res.length - 1);
  };

  useEffect(() => {
    (async function() {
      const res = await getSkuByProductId(product_id);
      setSkus(res.map(sku => ({ ...sku, text: attributesToText(sku.attributes) })));
    })();
  }, [product_id]);
  return skus;
}
