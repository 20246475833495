const images = [
  '5b6c88ab32a72.jpg',
  'accessories.jpg',
  'figures.jpg',
  'screens.jpg',
  '5b6c891b322d6.jpg',
  'animals.jpg',
  'floor-lamps.jpg',
  'sculpture.jpg',
  '5b6c8959df2a7.jpg',
  'bar-tables.jpg',
  'fountains.jpg',
  'side-accent-tables.jpg',
  '5b6c899a2119c.jpg',
  'benches.jpg',
  'furniture.jpg',
  'stools.jpg',
  '5b6c8a4223439.jpg',
  'bowls-vessels.jpg',
  'hanging-lamps.jpg',
  'table-bases.jpg',
  '5b6c8a9024404.jpg',
  'chairs.jpg',
  'lighting.jpg',
  'table-glass-tops.jpg',
  '5ba10b79b56e0.jpg',
  'coffee-tables.jpg',
  'mirrors.jpg',
  'table-lamps.jpg',
  '5ba10bc5daa3b.jpg',
  'consoles-sofa-tables.jpg',
  'other-accents.jpg',
  'vases.jpg',
  '5ba10bec2c861.jpg',
  'desks.jpg',
  'other.jpg',
  'wall-decor.jpg',
  '5ba10c345172d.jpg',
  'dimensional.jpg',
  'pedestals.jpg',
  'wall-tiles.jpg',
  'abstract.jpg',
  'dining-tables.jpg',
  'planters.jpg'
];

export const currencyFormat = num => '$' + (num * 0.01).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

export const formatImage = (image, slug) =>
  image
    ? image.indexOf('http') > -1
      ? `https://hdtv-prod.s3.amazonaws.com/public/${image}`
      : image
    : slug
    ? `/images/categories/${slug}.jpg`
    : images[Math.floor(Math.random() * images.length)];

export const isEmailAddress = email => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);

export const UserType = {
  VIEWER: 'USER',
  DESIGNER: 'DESIGNER'
};
