import { useEffect, useReducer } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { onCreateComment } from '../graphql/subscriptions';
import { useUser } from './useUser';
import { useApolloClient, useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';

const initialState = { videoCommentList: [] };

export const listComments = `query ListComments(
  $filter: ModelCommentFilterInput
  $limit: Int
  $nextToken: String
) {
  listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      content
      avatar
      name
      commentPostId
      commentVideoId
      createdAt
      likes
      subs
    }
    nextToken
  }
}
`;

function reducer(state, action) {
  switch (action.type) {
    case 'set':
      return { videoCommentList: action.payload };
    case 'add':
      return { videoCommentList: [action.payload, ...state.videoCommentList] };
    default:
      return;
  }
}

export function useVideoComments(id) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const user = useUser();
  const client = useApolloClient();

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await client.query({ query: gql(listComments), variables: { filter: { commentVideoId: { eq: id } } } });
        dispatch({ type: 'set', payload: res.data.listComments.items });
      } catch (e) {
        console.log(e);
      }
    }
    if (id) fetchData();
  }, [id]);

  useEffect(() => {
    if (id && user && user.id) {
      const subscriber = API.graphql(graphqlOperation(onCreateComment, { owner: user.id })).subscribe({
        next: data => {
          const {
            value: {
              data: { onCreateComment }
            }
          } = data;

          if (onCreateComment.commentVideoId === id) {
            dispatch({ type: 'add', payload: onCreateComment });
          }
        }
      });
      return () => subscriber.unsubscribe();
    }
  }, [id, user]);

  return state.videoCommentList;
}
